import React from 'react';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import {connect} from 'react-redux';
import {AppStoreState} from 'src/store';
import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {fetchKPIs} from './store/services';
import {LaneKPI} from './store/types';
import {OCard} from '../../../shared/style';
import DatePicker from 'react-date-picker';
import {createStyles, withStyles} from '@material-ui/core/styles';
import {DailyLaneCostsChart} from './charts/DailyLaneCostsChart';
import {Grid, Paper, Typography} from '@material-ui/core';
import {ParametersSelectors} from '../../../utils/Parameters/selectors';

const styles = () =>
    createStyles({
        itemValue: {
            float: 'right',
        },
        itemHead: {
            color: 'grey',
        },
        kpiList: {
            margin: '6px 0 0 0',
            paddingLeft: '20px',
        },
        loadingKPIs: {
            textAlign: 'center',
            color: 'red',
        },
        paper: {
            padding: '12px',
        },
    });

type State = {
    kpis?: LaneKPI;
    areKpisLoaded: boolean;
    daysBefore: number;
    dateFilter: Date | undefined;
};
const initialState: Readonly<State> = {
    kpis: undefined,
    areKpisLoaded: false,
    daysBefore: 0,
    dateFilter: new Date(),
};

type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
    daysBefore: string;
};

type Props = {
    type: string;
    classes?: any;
};

type ComponentProps = SProps & Props;

export class LaneReportComponent extends React.Component<ComponentProps, State> {
    readonly state = initialState;

    componentDidMount() {
        this.fetchKPIs(this.state.dateFilter);
    }

    fetchKPIs(dateFilter: Date | undefined) {
        let dateSearch = format(new Date(), 'YYYY-MM-DD', {
            locale: es,
        });

        if (dateFilter !== undefined) {
            dateSearch = format(dateFilter, 'YYYY-MM-DD', {
                locale: es,
            });
        }

        this.setState({
            kpis: undefined,
            areKpisLoaded: false,
        });

        fetchKPIs(this.props.lane.laneId, dateSearch, this.props.daysBefore).then((res: any) => {
            try {
                this.setState({
                    kpis: res.data,
                    areKpisLoaded: true,
                });
            } catch (e) {}
        });
    }

    render() {
        const {kpis, areKpisLoaded, dateFilter} = this.state;
        const classes = this.props.classes;
        let dateSearch = format(new Date(), 'YYYY-MM-DD', {
            locale: es,
        });

        if (dateFilter !== undefined) {
            dateSearch = format(dateFilter, 'YYYY-MM-DD', {
                locale: es,
            });
        }

        return (
            <>
                <Typography
                    component="div"
                    style={{
                        marginBottom: 10,
                        marginTop: 5,
                        display: 'flex',
                        flexDirection: 'row',
                    }}
                >
                    <div style={{flexGrow: 12}} />
                    <div style={{flexGrow: 1, paddingRight: '70px', marginBottom: '15px'}}>
                        <DatePicker
                            value={this.state.dateFilter}
                            onChange={(date: any) => {
                                this.setState({dateFilter: date});
                                this.fetchKPIs(date);
                            }}
                        />
                    </div>
                </Typography>
                <OCard
                    title={'Costes y kilos trabajados en los últimos ' + this.props.daysBefore + ' días'}
                    heightTitle={'30'}
                    width={'100%'}
                    contentHeight={'100%'}
                    contentPadding={'20px'}
                    margin={'20px'}
                >
                    <DailyLaneCostsChart laneId={this.props.lane.laneId} workshift={dateSearch} daysBefore={this.props.daysBefore} />
                </OCard>
                <OCard
                    title={'KPIs de los últimos ' + this.props.daysBefore + ' días'}
                    heightTitle={'30'}
                    width={'100%'}
                    contentHeight={'100%'}
                    contentPadding={'16px'}
                    margin={'20px'}
                    marginBottom={'40px'}
                >
                    {areKpisLoaded && (
                        <Grid container spacing={24}>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <b className={classes.itemHead}>Media de empleados:</b>
                                    <span className={classes.itemValue}>{kpis?.employeesAv}</span>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <b className={classes.itemHead}>Kilos trabajados:</b>
                                    <span className={classes.itemValue}>{kpis?.workedKg}</span>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <b className={classes.itemHead}>Rendimiento (Kg/H):</b>
                                    <span className={classes.itemValue}>{kpis?.perfomance}</span>
                                </Paper>
                            </Grid>
                            <Grid item xs={6}>
                                <Paper className={classes.paper}>
                                    <b className={classes.itemHead}>Coste total (€/Kg):</b>
                                    <span className={classes.itemValue}>{kpis?.totalCost}</span>
                                </Paper>
                            </Grid>
                        </Grid>
                    )}
                    {!areKpisLoaded && (
                        <Typography variant="h6" className={classes.loadingKPIs}>
                            ...Obteniendo datos...
                        </Typography>
                    )}
                </OCard>
            </>
        );
    }
}

const mapStateToProps = (state: AppStoreState) => {
    return {
        lane: LaneSelectSelectors.getLane(state),
        daysBefore: ParametersSelectors.getLineKPIsDaysBefore(state),
    };
};

export const LaneReport = connect<SProps, {}, Props, AppStoreState>(mapStateToProps)(withStyles(styles)(LaneReportComponent));
