import React, {ChangeEvent, FormEvent} from 'react';
import {connect} from 'react-redux';

import {CardStyled, TerminalWrap} from 'src/terminal/components';
import {authorizeInTerminal} from 'src/terminal/store/actions';
import {notify} from 'src/utils/Notification';

interface State {
  inputValue: string;
  val: any;
}

export interface AccessCardDispatchProps {
  authorize: (code: string) => any;
  notif: () => void;
}
class AccessCard extends React.Component<AccessCardDispatchProps, State> {
  state: State = {
    inputValue: '',
    val: 'e',
  };

  handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {value} = e.target;
    // if () {
    this.setState((prevState: State) => ({
      ...prevState,
      inputValue: value,
    }));
    // }
  };

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state !== null && this.state.inputValue !== '') {
      this.props.authorize(this.state.inputValue);
    } else {
      this.props.notif();
    }
  };

  render() {
    return (
      <TerminalWrap>
        <CardStyled
          isList={false}
          isInput={true}
          titleText={'Terminal de acceso'}
          inputLabelText={'Código del trabajador'}
          buttonText={'Acceder al sistema'}
          mainText={'Acceda al terminal con su código de usuario.'}
          inputValue={this.state.inputValue}
          handleInputChange={this.handleInput}
          handleSubmit={this.handleSubmit}
        />
        {/* {this.state.val.a.map((x: any) => x)} */}
      </TerminalWrap>
    );
  }
}

// const mapStateToProps = {};
const mapDispatchToProps = (dispatch: any) => ({
  authorize: (code: string) => dispatch(authorizeInTerminal({code})),
  notif: () =>
    dispatch(
      notify({
        message: 'No se puede accceder sin número.',
        status: 'warning',
      }),
    ),
});

export default connect<null, AccessCardDispatchProps>(
  null,
  mapDispatchToProps,
)(AccessCard);
