import {EmployeeCostsControlData} from './types/index';
import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/employee-costs';

export const EmployeeCostsControlServices = {
    get: ({params}: PaginatedServiceArgs) => guardedFetcher.get<PaginateResponse<EmployeeCostsControlData>>(`${prefix}`, {params}),
    update: (id: string, params: Partial<EmployeeCostsControlData>) => guardedFetcher.patch(`${prefix}/${id}`, params),
};
