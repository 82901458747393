import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

type SpinnerProps = {
    isLoading: boolean;
    position?: 'absolute' | 'relative';
};

export class Spinner extends React.Component<SpinnerProps, {}> {
    render() {
        const positionValue = this.props.position ? this.props.position : 'absolute';

        if (!this.props.isLoading) return null;
        return (
            <div className="Spinner" style={{position: positionValue}}>
                <CircularProgress /> <span style={{margin: '15px'}}>Loading...</span>
            </div>
        );
    }
}
