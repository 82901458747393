import {JobtypeResponseData} from 'src/app/administration/employeesControl/store/types';
import {TCTaskResponse} from 'src/app/administration/tasksControl/store/tasksControl.types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/jobtypes';
const taskPrefix = 'crud/tasks';

type Options = {
  id: string;
  name?: string;
};
export const JobtypesControlServices = {
  get: () => guardedFetcher.get<TCTaskResponse>(prefix),
  create: ({name}: Partial<Options>) =>
    guardedFetcher.post<JobtypeResponseData>(prefix, {name}),
  update: ({id, name}: Options) => guardedFetcher.patch(`${prefix}/${id}`, {name}),
  delete: ({id}: Options) => guardedFetcher.delete(`${prefix}/${id}`),
  toggleJobtype: ({taskId, jobtypeId}: {taskId: string; jobtypeId: string}) =>
    guardedFetcher.post(`${prefix}/toggle-relation/task`, {taskId, jobtypeId}),
  getAllTasksFromJobtype: (jobtypeId: string) =>
    guardedFetcher.get<TCTaskResponse>(`${taskPrefix}/jobtype/${jobtypeId}`),
};
