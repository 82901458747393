import {push} from 'connected-react-router';
import React from 'react';
import {connect} from 'react-redux';
// import {Route} from 'react-router-dom';
import styled from 'styled-components';

import {GrantsForGroupsControl} from 'src/app/grants/grantsForGroupsControl/GrantsForGroupsControl';
// import {EmployeesControl} from 'src/app/administration/employeesControl/EmployeesControl';
import {RulegroupsControl} from 'src/app/grants/rulegroupsControl/RulegroupsControl';
import {UsersControl} from 'src/app/grants/usersControl/UsersControl';
import {StatusBar} from 'src/app/statusbar/StatusBar';
import {TabContainerNoTabs} from 'src/app/lanemanager/Wrap';
import {Typography} from '@material-ui/core';
import {createStyles, withStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import GrainIcon from '@material-ui/icons/Grain';
import PeopleIcon from '@material-ui/icons/People';
import {GuardSingleAccessRoute} from 'src/global/authorization/GuardAuthorizationRoute';
import {AppStoreState} from 'src/store';
import {RouteHelpers} from 'src/utils/routerHelpers';

type SProps = {pathname: string};
type DProps = {push(path: string): void};
type Props = {
  classes: {
    root: string;
  };
};

type State = {
  routeTitle: string;
  generic: boolean;
};
const initialState: State = {routeTitle: '', generic: true};

const styles = () =>
  createStyles({
    root: {
      backgroundColor: '#efefef',
      padding: 0,
      height: 'calc(100% - 48px)',
      width: '100%',
    },
  });

export class GrantsIndexPageBase extends React.Component<Props & SProps & DProps, State> {
  readonly state = initialState;

  componentDidMount() {
    this.findRoute();
  }
  componentWillReceiveProps(nextProps: SProps) {
    this.findRoute(nextProps.pathname);
  }

  render() {
    const {classes} = this.props;
    const {generic} = this.state;
    return (
      <>
        <StatusBar title={this.state.routeTitle} />
        <div className={classes.root}>
          <TabContainerNoTabs>
            <GuardSingleAccessRoute
              accessSet={['ADMIN', 'SYSTEM']}
              exact={true}
              path="/grants/users"
              component={UsersControl}
            />
            <GuardSingleAccessRoute
              accessSet={['ADMIN', 'SYSTEM']}
              exact={true}
              path="/grants/rulegroups"
              component={RulegroupsControl}
            />
            <GuardSingleAccessRoute
              accessSet={['ADMIN', 'SYSTEM']}
              exact={true}
              path="/grants/selection"
              component={GrantsForGroupsControl}
            />
            {generic && (
              <WrapList>
                <Typography style={{textAlign: 'center'}} variant="h5">
                  Permisos y Accesos
                </Typography>
                <List component="nav">
                  <ListItem
                    button={true}
                    component={'a'}
                    onClick={e => {
                      this.props.push('/grants/users');
                      e.preventDefault();
                    }}
                    href="/grants/users">
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Usuarios del sistema" />
                  </ListItem>
                  <ListItem
                    button={true}
                    component={'a'}
                    onClick={e => {
                      this.props.push('/grants/rulegroups');
                      e.preventDefault();
                    }}
                    href="/grants/rulegroups">
                    <ListItemIcon>
                      <GrainIcon />
                    </ListItemIcon>
                    <ListItemText primary="Grupos de acceso" />
                  </ListItem>
                  <ListItem
                    button={true}
                    component={'a'}
                    onClick={e => {
                      this.props.push('/grants/selection');
                      e.preventDefault();
                    }}
                    href="/grants/selection">
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText primary="Alcance de los grupos de acceso" />
                  </ListItem>
                </List>
              </WrapList>
            )}
          </TabContainerNoTabs>
        </div>
      </>
    );
  }
  private findRoute = (pathname: string = this.props.pathname) => {
    const path = pathname.split('/')[2];
    const routeTitle = this.getTitle(path);
    let generic = false;
    if (routeTitle === 'Permisos y Accesos') generic = true;
    this.setState({routeTitle, generic});
  };

  private getTitle = (path: string) => {
    switch (path) {
      case 'users':
        return 'Gestión de usuarios';
      case 'rulegroups':
        return 'Gestión de grupos de acceso';
      case 'selection':
        return 'Gestión de alcance de los grupos de acceso';
      default:
        return 'Permisos y Accesos';
    }
  };
}

export const GrantsIndexPage = connect<SProps, DProps, Props, AppStoreState>(
  state => ({
    pathname: RouteHelpers.getLocation(state),
  }),
  {push},
)(withStyles(styles)(GrantsIndexPageBase));

const WrapList = styled.div`
  width: 400px;
  margin: auto;
  a {
    display: flex;
    text-decoration: inherit;
    color: inherit;
  }
`;
