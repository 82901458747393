import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {AreaShiftServices} from 'src/app/lanemanager/areashift/store/areashift.services';
import {AreaShiftUnit} from 'src/app/lanemanager/areashift/store/areashift.types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';
import { fetchWithFeedback } from 'src/utils/fetcherValidate';
import { ShiftsMapService } from 'src/shared/components/services';

type State = {
    shifts : Array<{
        id:string;
        name:string;
    }>;
    shiftsMap : Map<string, string>;
    shiftsLoaded : boolean;
}

const initialState : State = {
    shifts: [],
    shiftsMap: new Map<string, string>(),
    shiftsLoaded : false
};

type SProps = {
    userData: ReturnType<typeof LoginSelectors.getUserData>;
};

export class AreaShiftBase extends React.Component<SProps, State> {
    readonly state = initialState;
    columns : ColumnType<AreaShiftUnit> = [];

    private fetchShiftsMap = async () => {
        const shifts : Array<{
            id:string;
            name:string;
        }> = await fetchWithFeedback(ShiftsMapService(), {accessor: 'shifts'});
        this.setState({shifts});
        shifts.forEach(v => this.state.shiftsMap.set(v.id, v.name));
    }

    componentDidMount() {
        this.fetchShiftsMap().then(() => {
            this.columns = this.getColumnFormat();
            this.setState({shiftsLoaded : true});
        });
    }

    private getColumnFormat = () : ColumnType<AreaShiftUnit> => {
        return [
            {
                Header: 'Nombre de línea',
                headerClassName: 'header_cell_string',
                accessor: 'area_name',
                filterable: true,
                sortable: true,
                className: 'cell_string',
            },
            {
                Header: 'Centro',
                headerClassName: 'header_cell_string',
                accessor: 'center_name',
                filterable: true,
                sortable: true,
                className: 'cell_string',
            },
            {
                Header: 'Jornada',
                headerClassName: 'header_cell_string',
                accessor: 'workshift',
                dateFormat: true,
                daterange: true,
                width: 260,
                filterable: true,
                className: 'cell_string',
            },
            {
                Header: 'Turno',
                headerClassName: 'header_cell_string',
                Cell: item => <>{this.state.shiftsMap.get(item.original.area_shift)}</>,
                dropdownFilter: true,
                dropdownFilterData: this.state.shifts,
                id: 'area_shift',
                sortable: false,
                className: 'cell_string',
            },
            {
                Header: 'Estado de turno',
                headerClassName: 'header_cell_string',
                Cell: item => <>{item.original.end_date ? 'CERRADA' : 'ABIERTA'}</>,
                accessor: 'end_date',
                width: 140,
                sortable: true,
                className: 'cell_string',
            },
            {
                Header: 'H.Inicio',
                headerClassName: 'header_cell_string',
                accessor: 'start_date',
                dateTimeFormat: true,
                filterable: false,
                sortable: true,
                className: 'cell_string',
            },
            {
                Header: 'H.Fin',
                headerClassName: 'header_cell_string',
                accessor: 'end_date',
                dateTimeFormat: true,
                filterable: false,
                sortable: true,
                className: 'cell_string',
            },
            {
                Header: 'Emp.Nombre',
                headerClassName: 'header_cell_string',
                accessor: 'employee_name',
                filterable: true,
                sortable: true,
                className: 'cell_string',
            },
            {
                Header: 'Emp.Apellidos',
                headerClassName: 'header_cell_string',
                accessor: 'employee_surname',
                filterable: true,
                sortable: true,
                className: 'cell_string',
            },
            {
                Header: 'Emp.Codigo',
                headerClassName: 'header_cell_string',
                accessor: 'employee_code',
                filterable: true,
                sortable: false,
                className: 'cell_string',
            },
        ];
    }

    render() {
        return IsInRuleset(['REPORTS_AREASHIFTS_VIEW', 'REPORTS_AREASHIFTS_EXCEL']) ?
        this.state.shiftsLoaded && (
            <TableWrap>
                <PaginatedTableComponent
                    defaultPageSize={20}
                    columnFormat={this.columns}
                    service={AreaShiftServices.get}
                    loading={true}
                    excel={IsInRuleset('REPORTS_AREASHIFTS_EXCEL')}
                />
            </TableWrap>
        ) : null;
    }
}

export const AreaShifts = connect<SProps, {}, {}, AppStoreState>(state => ({
    userData: LoginSelectors.getUserData(state),
}))(AreaShiftBase);

const TableWrap = styled.div`
    width: calc(100% - 15px);
    height: 100%;
    margin: auto;
    margin-top: 0;
`;
