import * as React from 'react';
import styled from 'styled-components';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {MenuItemsControlServices} from "./store/menuItemsControl.services";
import {MenuItemsControlPanel} from "./MenuItemsControlPanel";
import {Button, Grid, IconButton} from "@material-ui/core";
import {ExchangeStatusType, FunctionalityType, ModulesTreeType, StatusKey} from "./store/menuItemsControl.types";
import ReloadIcon from "@material-ui/icons/Refresh";



export type StatusType = {
    type: 'module' | 'menu_item' | 'functionality';
    enabled: boolean;
}

type State = {
    enableSave: boolean;
    expanded: string | boolean;
    modules : ModulesTreeType;
    functionalities : FunctionalityType;
    modifiedItems : Map<StatusKey, StatusType>;
};
const initialState: Readonly<State> = {
    enableSave: false,
    expanded: false,
    modules: {},
    functionalities: {},
    modifiedItems: new Map<StatusKey, StatusType>()
};

export class MenuItemsControl extends React.Component<{}, State> {
    readonly state = initialState;

    componentDidMount() {
        this.fetchModules().then(data => {
            this.setState({modules: data.modules, functionalities: data.functionalities});
        });
    }

    componentWillUnmount() {
        this.state.modifiedItems.clear();
    }

    private fetchModules = async () => {
        return await fetchWithFeedback(MenuItemsControlServices.get(), {
            accessor: 'data',
        });
    };

    private reload = () => {
        this.fetchModules().then(data => {
            this.state.modifiedItems.clear();
            this.setState({
                modules: data.modules,
                functionalities: data.functionalities,
                enableSave: false
            });
        });
    }

    private handleChange = (panel: string, expanded: boolean) =>
        this.setState({expanded: expanded ? panel : false});

    private handleSave = () => {

        this.setState({
            enableSave: false
        });

        const exchangeStatus : ExchangeStatusType[] = [];

        this.state.modifiedItems.forEach((value, key) => {
            exchangeStatus.push({
               key: key,
               type: value.type,
               enabled: value.enabled
            });
        });

        fetchWithFeedback(
            MenuItemsControlServices.save({menuItemsStatus : exchangeStatus}),
            {
                showMessage: true,
                successMessage: 'Se han vinculado las opciones de menú con las funcionalidades.',
                errorMessage: 'No se han podido vincular las opciones de menú con las funcionalidades. Vuelva a intentarlo.'
            }
        ).then(
            (r) => {
                if (r && r.result === 'OK') {
                    this.state.modifiedItems.clear();
                    this.setState({
                        modules: r.modules,
                        enableSave: false
                    });
                }
                else {
                    this.setState({
                        enableSave: true
                    });
                }
            }
        );
    }

    private enableSave = () => {
        this.setState({enableSave: true})
    }


    render() {
        const {
            expanded,
            modules,
            functionalities,
            enableSave,
            modifiedItems
        } = this.state;
        return (
            <WrappingDiv>
                <Grid container alignContent="flex-start">
                    <Grid item xs={1}/>
                    <Grid item xs={10}>
                        { modules && Object.entries(modules).map(([id, module]) => (
                            <MenuItemsControlPanel
                                key={id}
                                expanded={expanded}
                                moduleId={id}
                                module={module}
                                functionalities={functionalities}
                                modifiedItems={modifiedItems}
                                change={this.handleChange}
                                enableSave={this.enableSave}
                            />
                        ))}
                        <div style={{textAlign: 'center'}}>
                            <Button
                                disabled={!enableSave}
                                style={{
                                    marginTop: '25px',
                                    marginBottom: '25px',
                                    width: 'auto',
                                    height: '4.1%',
                                    padding: '4 15',
                                    fontWeight: 900,
                                }}
                                color="secondary"
                                variant="contained"
                                onClick={this.handleSave}>
                                GUARDAR
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton aria-label="reload">
                            <ReloadIcon fontSize="large" onClick={this.reload}/>
                        </IconButton>
                    </Grid>


                </Grid>


            </WrappingDiv>

        );
    }
}



const WrappingDiv = styled.div`
  /*width: calc(75%);*/
  margin: auto;
  min-height: calc(100% - 7px);
  background-color: #efefef;
  /* min-height: calc(100% - 5px); */
  /* margin-bottom: 5px; */
  padding: 5px;
  /* margin-bottom: 5px; */
`;
