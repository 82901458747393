import animated from 'react-select/lib/animated';
import isEqual from 'react-fast-compare';
import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import {DevicesControlServices} from 'src/app/administration/devicesControl/store/devicesControl.services';
import {DeviceControlUnitNoAutomatas} from 'src/app/administration/devicesControl/store/devicesControl.types';
import {WrapForm, WrapSaveButton} from 'src/app/administration/export/styled/Wrap';
import {FormLabel, Typography, Checkbox, Divider} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import UpdateIcon from '@material-ui/icons/Update';
import {CustomDropdown} from 'src/shared/style';
import {CustomButton} from 'src/shared/style/CustomButton';
import {CustomTextField} from 'src/shared/style/CustomTextField';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notifyValidator} from 'src/utils/notifyValidator';
import {notify} from 'src/utils/Notification';

type State = Readonly<{
    addressValid: boolean;
    file: any;
    pn532Reader: boolean;
    smartcardIOReader: boolean;
    id: string;
    name: string;
    areaName: string;
    regTimeoutSec: string;
    regTimeoutSecValid: boolean;
    address: string;
    idleUrl: any;
    idleUrlValid: boolean;
    nameValid: boolean;
    areaId: string;
    selectedArea: string;
    isStatistics: boolean;
    assignedAreas: Array<{value: string; label: string}>;
    areasListLabeled: Array<{value: string; label: string}>;
}>;

const initialState: Readonly<State> = {
    addressValid: true,
    address: '',
    areaId: '',
    areaName: '',
    nameValid: true,
    idleUrl: '',
    pn532Reader: false,
    regTimeoutSec: '',
    regTimeoutSecValid: true,
    idleUrlValid: true,
    smartcardIOReader: false,
    isStatistics: false,
    name: '',
    file: '',
    id: '',
    selectedArea: '',
    assignedAreas: [],
    areasListLabeled: [],
};

type Props = {
    device?: DeviceControlUnitNoAutomatas;
    areasList: Array<{id: string; name: string}>;
    handleClose(): void;
    updateDevice(): void;
};

export class DeviceForm extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidMount() {
        if (this.props.device) {
            const {id, name, address, areaId, areaName, idleUrl, pn532Reader, regTimeoutSec, smartcardIOReader, isStatistics, stadisticAreas} = this.props.device;
            this.setState({
                id,
                name,
                areaId,
                areaName,
                nameValid: true,
                idleUrl,
                pn532Reader: !!+pn532Reader,
                regTimeoutSec,
                regTimeoutSecValid: true,
                smartcardIOReader: !!+smartcardIOReader,
                addressValid: true,
                idleUrlValid: true,
                address,
                isStatistics,
                assignedAreas: stadisticAreas ? stadisticAreas.map(al => ({label: al.name, value: al.id})) : [],
                areasListLabeled: this.props.areasList ? this.props.areasList.map(al => ({label: al.name, value: al.id})) : [],
            });
        } else this.setState(initialState);
        this.firstSelectArea();
    }

    componentWillUnmount() {
        this.setState({assignedAreas: []});
    }

    private firstSelectArea = () => {
        if (this.props.areasList.length && this.props.device) {
            this.setState({selectedArea: this.props.device.areaId});
        }
    };

    private handleStadisticCheckbox = () => {
        this.setState({isStatistics: !this.state.isStatistics});
    };

    render() {
        const {
            idleUrl,
            pn532Reader,
            regTimeoutSec,
            smartcardIOReader,
            addressValid,
            address,
            name,
            id,
            areaId,
            nameValid,
            regTimeoutSecValid,
            idleUrlValid,
            assignedAreas,
            isStatistics,
            areasListLabeled,
        } = this.state;
        const {areasList} = this.props;
        return (
            <WrapForm margin={'5px 10px'}>
                <DataDiv>
                    <LeftPart>
                        <CustomTextField
                            margin={'0 0 5px 0'}
                            label={'Nombre'}
                            variant="outlined"
                            error={!nameValid}
                            width={'80%'}
                            onChange={this.handleInputs('name')}
                            value={name}
                        />
                        <CustomTextField
                            margin={'5px 0'}
                            label={'Dirección IP'}
                            variant="outlined"
                            error={!addressValid}
                            width={'80%'}
                            onChange={this.handleInputs('address')}
                            value={address}
                        />
                        <CustomTextField
                            margin={'5px 0'}
                            variant="outlined"
                            label={'regTimeoutSec'}
                            error={!regTimeoutSecValid}
                            width={'80%'}
                            onChange={this.handleInputs('regTimeoutSec')}
                            value={regTimeoutSec}
                        />
                        <FormLabel component="section" style={{marginTop: 5}}>
                            Tipo lector NFC
                        </FormLabel>
                        <RadioGroup name="nfcDevices" value={pn532Reader ? 'pn532Reader' : smartcardIOReader ? 'smartcardIOReader' : ''} onChange={this.handleCheckbox}>
                            <FormControlLabel value="smartcardIOReader" control={<Radio />} label="smartcardIOReader" />
                            <FormControlLabel value="pn532Reader" control={<Radio />} label="pn532Reader" />
                        </RadioGroup>
                        <WrapSaveButton>
                            <CustomButton
                                // bgcolor={'#34baa2'}
                                onClick={this.handleSend}
                                width={'60%'}
                                margin="unset"
                                padding={'5px 0'}
                            >
                                {id ? 'Guardar' : 'Crear'} <br />
                                dispositivo
                            </CustomButton>
                            <CustomButton color="primary" width={'10%'} margin={'0 5px'} onClick={this.handleSend}>
                                <UpdateIcon />
                            </CustomButton>
                        </WrapSaveButton>
                    </LeftPart>
                    <RightPart>
                        <PhotoDiv>
                            {idleUrl && (
                                <img
                                    style={{
                                        margin: 'auto',
                                        // marginTop: 10,
                                        width: 200,
                                        height: 200,
                                        objectFit: 'scale-down',
                                    }}
                                    src={idleUrl}
                                    alt={'img'}
                                />
                            )}
                            {!idleUrl && (
                                <Typography
                                    component="div"
                                    variant="h4"
                                    style={{
                                        display: 'flex',
                                        margin: 'auto',
                                        // marginTop: 10,
                                        width: 200,
                                        height: 200,
                                        alignContent: 'center',
                                    }}
                                >
                                    <span style={{margin: 'auto'}}>SIN FOTO</span>
                                </Typography>
                            )}
                            <div>
                                <CustomTextField
                                    margin={'20px 0'}
                                    label={'URL externa a la foto'}
                                    width={'100%'}
                                    variant="outlined"
                                    error={!idleUrlValid}
                                    onChange={this.handleInputs('idleUrl')}
                                    value={idleUrl}
                                />
                            </div>
                            <CustomDropdown
                                title={'Área'}
                                dropdownName={'areas'}
                                data={areasList || []}
                                value={areaId}
                                margin={'0'}
                                handleChoose={e => this.setState({areaId: e.target.value})}
                            />
                        </PhotoDiv>
                    </RightPart>
                </DataDiv>
                <Divider style={{margin: '15px 0 0 0'}} />
                <div>
                    <FormControlLabel
                        style={{width: 290}}
                        control={<Checkbox checked={isStatistics} onChange={this.handleStadisticCheckbox} value="activeCB" />}
                        label="Es un terminal de estadística?"
                    />
                    <Typography component="div" style={{overflow: 'inherit'}}>
                        <label>Indicar las áreas para este dispositivo</label>
                        <Select
                            backspaceRemovesValue={false}
                            // isDisabled={!isStatistics}
                            isMulti={true}
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            isClearable={false}
                            menuPlacement="top"
                            // @ts-ignore
                            components={animated()}
                            options={areasListLabeled}
                            placeholder={'Áreas a elegir...'}
                            value={assignedAreas}
                            onChange={async sel => {
                                const cast = sel as Array<{
                                    value: string;
                                    label: string;
                                }>;
                                try {
                                    const newAssigned = cast.filter(c => !assignedAreas.find(tgl => isEqual(c, tgl)));

                                    if (newAssigned.length) {
                                        const response = await fetchWithFeedback(DevicesControlServices.toggleAreaToDevice(id, newAssigned[0].value), {
                                            successMessage: 'Se ha añadido el área correctamente',
                                            errorMessage: 'No se ha podido añadir el área a este dispositivo',
                                            returnConfirmation: true,
                                        });
                                        if (!response) throw new Error('Error en el toggle');
                                    } else {
                                        const newUnassigned = assignedAreas.filter(tgl => !cast.find(c => isEqual(c, tgl)));
                                        if (newUnassigned.length) {
                                            const response = await fetchWithFeedback(DevicesControlServices.toggleAreaToDevice(id, newUnassigned[0].value), {
                                                successMessage: 'Se ha quitado el área correctamente',
                                                errorMessage: 'No se ha podido quitar el área de este dispositivo',
                                                returnConfirmation: true,
                                            });
                                            if (!response) throw new Error('Error en el toggle');
                                        }
                                    }
                                    this.setState({assignedAreas: cast});
                                } catch (error) {
                                    console.error(error);
                                }
                            }}
                        />
                    </Typography>
                </div>
            </WrapForm>
        );
    }

    private handleSend = async () => {
        const {id, name, address, areaId, idleUrl, pn532Reader, regTimeoutSec, smartcardIOReader, isStatistics: isStadistics} = this.state;
        const validator = notifyValidator(this);
        if (
            // tslint:disable-next-line
            !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
                address,
            )
        ) {
            this.setState({addressValid: false});
            notify({message: 'La dirección IP no es válida.', status: 'error'});
        } else if (id) {
            const res = await fetchWithFeedback(
                DevicesControlServices.update(id, {
                    address,
                    areaId,
                    idleUrl,
                    name,
                    pn532Reader,
                    regTimeoutSec: regTimeoutSec ? +regTimeoutSec : null,
                    smartcardIOReader,
                    isStadistics,
                }),
                {showMessage: true, returnConfirmation: true, notifyValidator: validator},
            );
            if (res) {
                this.props.updateDevice();
                this.props.handleClose();
            }
        } else {
            const res = await fetchWithFeedback(
                DevicesControlServices.create({
                    address,
                    areaId,
                    idleUrl,
                    name,
                    pn532Reader,
                    regTimeoutSec: regTimeoutSec ? +regTimeoutSec : null,
                    smartcardIOReader,
                    isStadistics,
                }),
                {showMessage: true, returnConfirmation: true, notifyValidator: validator},
            );
            if (res) {
                this.setState(initialState);
                this.props.updateDevice();
            }
        }
    };

    private handleInputs: HandleNamedChange<State> = name => e => {
        this.setState({[name]: e.target.value, [`${name}Valid`]: true} as Pick<State, keyof State>);
    };

    private handleCheckbox: HandleChange = e => {
        const {value} = e.target;
        if (value === 'smartcardIOReader')
            this.setState({
                smartcardIOReader: true,
                pn532Reader: false,
            });
        if (value === 'pn532Reader')
            this.setState({
                smartcardIOReader: false,
                pn532Reader: true,
            });
    };
}

export const LeftPart = styled.div`
    width: 55%;
`;
export const RightPart = styled.div`
    width: 47%;
    display: flex;
    flex-direction: column;
`;
export const DataDiv = styled.div`
    display: flex;
`;
export const PhotoDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

const customStyles = {
    menu: (provided: any) => ({
        ...provided,
        height: 150,
    }),
    menuList: (provided: any) => ({
        ...provided,
        height: 150,
    }),
};
