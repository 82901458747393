import {EmployeeTaskSelectStoreState} from 'src/app/presencemanagement/store/employeeTaskSelect.reducer';
import {Action, ActionRich} from 'src/types';

export enum EmployeeTaskSelectActionTypes {
  select = '[SelectEmployeeTask] Selecting employee task',
  clear = '[SelectEmployeeTask] Clear selected employee task',
}

export type EmployeeTaskSelectActions =
  | EmployeeTaskSelectActionSelect
  | EmployeeTaskSelectActionClear;

export type EmployeeTaskSelectActionSelect = ActionRich<
  EmployeeTaskSelectActionTypes.select,
  EmployeeTaskSelectStoreState
>;

export type EmployeeTaskSelectActionClear = Action<EmployeeTaskSelectActionTypes.clear>;
