import {ChartData, ChartOptions} from 'chart.js';
import React from 'react';
import {HourPieGroupedTotalType} from 'src/app/information/kpis/store/types/chart-types';

import {Pie} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {Spinner} from 'src/app/global/spinner';
//const initialState = {chart};

const chart: ChartData = {
    labels: [],
    datasets: [],
};

/***
 * CHART OPTIONS
 */
const options: ChartOptions = {
    legend: {
        display: true,
        position: 'top',
    },
    responsive: true,
    title: {
        display: false,
        text: 'Tendencia de palés/hora',
    },
    tooltips: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
            label: (tooltipItem: any, data: any) => {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                const total = meta.total;
                const currentValue = dataset.data[tooltipItem.index];
                const percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
                return customFormatNumber(currentValue) + ' (' + customFormatNumber(percentage) + '%)';
            },
            title: (tooltipItem: any, data: any) => {
                return data.labels[tooltipItem[0].index];
            },
        },
    },
    maintainAspectRatio: false,
    hover: {
        mode: 'nearest',
        intersect: true,
    },
    plugins: {
        datalabels: {
            formatter: (value: any) => {
                return customFormatNumber(value);
            },
        },
    },
};

const initialState = {chart, options};

type State = {
    chart: ChartData;
    options: ChartOptions;
};

type Props = {
    chartData: HourPieGroupedTotalType;
    isLoading: boolean;
};

export class HourPieGroupedTotalChart extends React.Component<Props, State> {
    readonly state = initialState;
    static getDerivedStateFromProps(props: Props) {
        let dataInfo: number[] = [];
        let labelInfo: string[] = [];

        if (props.chartData.hourByGroupedValues && props.chartData.hourByGroupedValues.length > 0) {
            dataInfo = props.chartData.hourByGroupedValues;
            labelInfo = props.chartData.hourByGroupedLabels;
        }

        const datasets = [
            {
                label: 'Horas',
                backgroundColor: ['#99ff99', '#f8c1bb'],
                data: dataInfo,
                fill: false,
            },
        ] as any;

        const chartShow = {
            labels: labelInfo,
            datasets,
        };

        return {
            chart: chartShow,
        };
    }

    //readonly state = initialState;
    render() {
        if (this.props.isLoading) {
            return <Spinner isLoading={this.props.isLoading} position={'relative'} />;
        }

        return (
            <>
                <Pie data={this.state.chart} options={this.state.options} />
            </>
        );
    }
}
