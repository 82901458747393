import {
  EmployeesControlResponseData,
  JobtypesResponse,
} from 'src/app/administration/employeesControl/store/types';
import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const empPrefix = 'crud/employees';
const jobtPrefix = 'crud/jobtypes';

export const EmployeesControlServices = {
  getAllEmployees: ({params, args}: PaginatedServiceArgs) => {
    let paramsDone;
    if (params) {
      const filtered = params.filtered;
      paramsDone = {
        params: {
          ...params,
          filtered: args && args.length ? {...filtered, ...args[0]} : filtered,
          'page-size': args && args.length ? 9999 : params['page-size'],
        },
      };
    }
    return guardedFetcher.get<PaginateResponse<EmployeesControlResponseData>>(
      empPrefix,
      paramsDone,
    );
  },
  createEmployee: (employee: {
    name: string;
    surname: string;
    identification: string;
    active: boolean;
    file: any;
    code: string;
  }) => {
    const data = new FormData();
    data.append('name', employee.name);
    data.append('surname', employee.surname);
    data.append('identification', employee.identification);
    data.append('active', '' + !!employee.active);
    data.append('file', employee.file);
    data.append('code', employee.code);
    return guardedFetcher.post<EmployeesControlResponseData>(empPrefix, data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  },
  updateEmployee: (employee: {
    id: string;
    name: string;
    surname: string;
    identification: string;
    active: boolean;
    file: any;
    code: string;
  }) => {
    const data = new FormData();
    // data.append('id', employee.id);
    data.append('name', employee.name);
    data.append('surname', employee.surname);
    data.append('identification', employee.identification);
    data.append('active', '' + !!employee.active);
    data.append('file', employee.file);
    data.append('code', employee.code);
    return guardedFetcher.post<EmployeesControlResponseData>(
      `${empPrefix}/${employee.id}`,
      data,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    );
  },
  deleteEmployee: (id: string) => guardedFetcher.delete(`${empPrefix}/${id}`),
  toggleJobtype: (employeeId: string, jobtypeId: string) =>
    guardedFetcher.post(`${empPrefix}/toggle-relation/jobtype`, {
      employeeId,
      jobtypeId,
    }),
  mAssignJobtypes: (params: MAssignParams) =>
    guardedFetcher.post(empPrefix + '/attach-mass-relation/jobtype', params),
  mDesassignJobtypes: (params: MAssignParams) =>
    guardedFetcher.post(empPrefix + '/detach-mass-relation/jobtype', params),

  getAllEmployeeJobtypes: (employeeId: string) =>
    guardedFetcher.get<JobtypesResponse>(`${jobtPrefix}/employee/${employeeId}`),
  getAllJobtypes: () => guardedFetcher.get<JobtypesResponse>(`${jobtPrefix}`),
  downloadFromErp: () => guardedFetcher.post(`${empPrefix}/import-erp`),
  getProfileUrl: (employeeId: string) =>
    guardedFetcher.get<string>(`${empPrefix}/profileUrl/${employeeId}`),
};

export type MAssignParams = {employeeIds: string[]; jobtypeIds: string[]};
