import {
  BarReadersControlCreateParams,
  BarReadersControlResponse,
} from 'src/app/administration/barReadersControl/store/barReadersControl.types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/bar-readers';

export const BarReadersControlServices = {
  getAll: () => guardedFetcher.get<BarReadersControlResponse>(prefix),
  getAllTypes: () => guardedFetcher.get(`${prefix}/types`),
  create: (params: BarReadersControlCreateParams) => guardedFetcher.post(prefix, params),
  update: (id: string, params: Partial<BarReadersControlCreateParams>) =>
    guardedFetcher.patch(`${prefix}/${id}`, params),
  delete: (id: string) => guardedFetcher.delete(`${prefix}/${id}`),
};
