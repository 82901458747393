import {guardedFetcher} from 'src/utils/guardedFetcher';
import {ModulesWithFunctionalities, ExchangeStatusType} from "./menuItemsControl.types";

const prefix = 'system/functionalities';

export const MenuItemsControlServices = {
    get: () => guardedFetcher.get<{data: ModulesWithFunctionalities}>(prefix + '/allMenuItems'),

    save: (modules: {menuItemsStatus: ExchangeStatusType[]}) =>
        guardedFetcher.patch(prefix + '/bindMenuItems', modules),
};
