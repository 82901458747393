import { fetcher } from 'src/global/fetcher';
import {PaginateParams} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

export const ExcelService = (url: string, params: PaginateParams) =>
  guardedFetcher.get(`${url}/excel`, {params});


export const ShiftsMapService = async () => {
    return await fetcher.get('options/shifts');
}
