import React from 'react';

import {withStyles} from '@material-ui/core/styles';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {WrapCustomDropdown} from 'src/shared/style/Wrap';

export type CustomDropdownType = {
  children?: any;
  classes: any;
  title?: string;
  handleChoose?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  data: any[];
  value?: string | number;
  dropdownName: string;
  nullEntry?: boolean;
  containerWidth?: string;
  margin?: string;
  height?: string;
  error?: boolean | string | number;
  MenuProps?: any;
};

export const CustomDropdown = withStyles({
  select: {
    padding: '18px 32px 12px 12px',
  },
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
})((props: CustomDropdownType) => {
  const unique = `dropdown-${
    !!props.title ? props.title.toLowerCase() : Math.ceil(Math.random() * 10000)
  }`;
  return (
    <WrapCustomDropdown width={props.containerWidth} margin={props.margin}>
      <InputLabel htmlFor={unique}>{props.title}</InputLabel>
      <Select
        className={props.classes.root}
        classes={{select: props.classes.select}}
        value={props.value || ''}
        onChange={props.handleChoose}
        error={!!props.error}
        style={{height: props.height}}
        MenuProps={
          props.MenuProps || {
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }
        }
        input={
          <FilledInput
            // fullWidth={true}
            // style={{padding: '20px 32px 12px 12px'}}
            placeholder={props.title}
            name={props.dropdownName}
            value={props.value}
            id={unique}
          />
        }>
        {props.nullEntry && (
          <MenuItem value={undefined}>
            <em />
          </MenuItem>
        )}
        {props.data &&
          props.data.map((item, i) => (
            <MenuItem key={i} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </WrapCustomDropdown>
  );
});
