export const minipalletScheme = {
  additionalProperties: false,
  type: 'object',
  required: [
    'barcode',
    'ptable_name',
    'boxes',
    'pallet_total_kg',
    'prodpart_code',
    'order_code',
    'customer_info',
  ],
  properties: {
    barcode: {type: 'string'},
    ptable_name: {type: 'string'},
    boxes: {oneOf: [{type: 'string'}, {type: 'number'}]},
    pallet_total_kg: {type: 'string'},
    prodpart_code: {type: 'string'},
    order_code: {type: 'string'},
    customer_info: {type: 'string'},
  },
};
