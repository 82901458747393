import {guardedFetcher} from 'src/utils/guardedFetcher';

import {
  CardControlEmployeesResponse,
  CardControlPreflightResponse,
  CardControlRegisteredCardsResponse,
  CardControlResponse,
} from './types';

const prefix = 'presence/assignment';
// import {resolvedStore} from 'src/App';

export const CardControlServices = {
  enableDevice: (deviceId: string) =>
    guardedFetcher.post(`${prefix}/device/${deviceId}/enable`),
  disableDevice: (deviceId: string) =>
    guardedFetcher.post(`${prefix}/device/${deviceId}/disable`),
  getDeviceState: (deviceId: string) =>
    guardedFetcher.get<{active: boolean; id: string}>(
      `${prefix}/device/${deviceId}/state`,
    ),
  newAssign: (cardId: string, employeeId: string) =>
    guardedFetcher.post(`${prefix}/assign-card`, {
      assignment_card_id: cardId,
      employee_id: employeeId,
      admin_employee_id: employeeId,
    }),
  newUnassign: (cardId: string) =>
    guardedFetcher.patch(`${prefix}/unassign-card`, {cardCode: cardId}),
  unassignAll: (employeeId: string) =>
    guardedFetcher.patch(`${prefix}/unassign-all-cards`, {employeeId: employeeId}),
  getEmployees: () =>
    guardedFetcher.get<CardControlEmployeesResponse>(`${prefix}/employees`),
  getByEmployeeId: (employeeId: string) =>
    guardedFetcher.get<CardControlResponse>(`${prefix}/employee/${employeeId}`),
  preflight: () =>
    guardedFetcher.get<CardControlPreflightResponse>(`${prefix}/preflight`),
  registeredCards: (assigmentModeId: string) =>
    guardedFetcher.get<CardControlRegisteredCardsResponse>(
      `${prefix}/registered-cards/${assigmentModeId}`,
    ),
};
