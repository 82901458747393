import {ajv} from 'src/utils/ajv';
import {areaScheme} from './schemes/area';
import {prodPartScheme} from './schemes/prodpart';

const areaProdPartsResScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['area', 'prodparts'],
  properties: {
    area: areaScheme,
    prodparts: {type: 'array', items: prodPartScheme},
  },
};

const precompile = ajv.compile(areaProdPartsResScheme);
export const areaProdPartsResSchemeValidate = precompile;
