// import {fetcher} from 'src/global/fetcher';

const getUrl = (append?: string): string => {
    // if (ENV === 'development') return 'http://192.168.1.42/api' + (append || '');
    //if (ENV === 'development') return 'http://cassrv014:1080/api' + (append || '');
    //if (ENV === 'development') return 'https://casiprod.seyte.com/api' + (append || '');
    //if (ENV === 'development') return 'http://localhost/api' + (append || '');
    //else if (ENV === 'test') return '';
    //return window.location.origin + '/api' + (append || '');
    if (process.env.REACT_APP_API && process.env.REACT_APP_API !== '') {
        return process.env.REACT_APP_API + (append || '');
    } else {
        if (window.location.origin === 'http://localhost:3000') {
            return 'http://localhost/api' + (append || '');
        }
        return window.location.origin + '/api' + (append || '');
    }
};

export const API_URL = getUrl;

const getEnv = () => {
    if (process.env.NODE_ENV !== undefined) {
        if (process.env.NODE_ENV.match('development')) return 'development';
        else if (process.env.NODE_ENV.match('test')) return 'test';
        else if (process.env.NODE_ENV.match('production')) return 'production';
    }
    return 'development';
};

export const ENV = getEnv();

// export const EXCEL_SERVICE = (serviceUrl: string, headings: string[]) =>
//   guardedFetcher.post(`${API_URL()}/reports/excel-generate`, {serviceUrl, headings});
