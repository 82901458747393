import React from 'react';
import styled from 'styled-components';

import {BarReadersControlServices} from 'src/app/administration/barReadersControl/store/barReadersControl.services';
import {BarReaderUnit} from 'src/app/administration/barReadersControl/store/barReadersControl.types';
import {DeviceControlUnitNoAutomatas} from 'src/app/administration/devicesControl/store/devicesControl.types';
import {WrapForm, WrapSaveButton} from 'src/app/administration/export/styled/Wrap';
import {CustomDropdown} from 'src/shared/style';
import {CustomButton} from 'src/shared/style/CustomButton';
import {CustomTextField} from 'src/shared/style/CustomTextField';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notifyValidator} from 'src/utils/notifyValidator';
import {notify} from 'src/utils/Notification';

const initialState: State = {
  addressValid: true,
  address: '',
  area_id: '',
  area_idValid: true,
  device_id: '',
  device_idValid: true,
  name: '',
  id: '',
  bar_reader_type_id: '',
  bar_reader_type_idValid: true,
  port: '',
  nameValid: true,
  portValid: true,
};

type State = Readonly<{
  addressValid: boolean;
  id: string;
  name: string;
  address: string;
  port: string;
  area_id: string;
  device_id: string;
  bar_reader_type_id: string;
  nameValid: boolean;
  portValid: boolean;
  area_idValid: boolean;
  device_idValid: boolean;
  bar_reader_type_idValid: boolean;
}>;

type Props = {
  barReader?: BarReaderUnit;
  devicesList: DeviceControlUnitNoAutomatas[];
  areasList: Array<{id: string; name: string}>;
  barReaderTypes: any[];
  handleClose(): void;
  updateBarReader(): void;
};

export class BarReaderForm extends React.Component<Props, State> {
  readonly state = initialState;

  inputRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    if (this.props.barReader) {
      const {id, name, address, area_id, device_id, type, port} = this.props.barReader;
      const typeValue = this.props.barReaderTypes
        ? this.props.barReaderTypes.find(it => it.name === type)
        : undefined;
      this.setState({
        id,
        name,
        address,
        area_id: area_id || '',
        device_id: device_id || '',
        bar_reader_type_id: typeValue ? typeValue.id : '',
        port: '' + port,
        addressValid: true,
        area_idValid: true,
        device_idValid: true,
        bar_reader_type_idValid: true,
        nameValid: true,
        portValid: true,
      });
    } else
      this.setState({
        addressValid: true,
        address: '',
        area_id: '',
        area_idValid: true,
        device_id: '',
        device_idValid: true,
        name: '',
        id: '',
        bar_reader_type_id: '',
        bar_reader_type_idValid: true,
        port: '',
        nameValid: true,
        portValid: true,
      });
  }

  render() {
    const {
      device_id,
      area_id,
      area_idValid,
      bar_reader_type_idValid,
      device_idValid,
      addressValid,
      address,
      name,
      id,
      port,
      bar_reader_type_id,
      nameValid,
      portValid,
    } = this.state;
    const {areasList, devicesList, barReaderTypes} = this.props;
    return (
      <WrapForm margin={'5px 10px'}>
        <DataDiv>
          <LeftSide>
            <CustomDropdown
              title={'Dispositivo'}
              dropdownName={'devices'}
              error={!device_idValid}
              data={devicesList || []}
              value={device_id}
              margin={'0 0 5px 0'}
              handleChoose={e => this.setState({device_id: e.target.value})}
            />
            <CustomDropdown
              title={'Área'}
              dropdownName={'areas'}
              data={areasList || []}
              error={!area_idValid}
              value={area_id}
              margin={'5px 0'}
              handleChoose={e => this.setState({area_id: e.target.value})}
            />
            <CustomDropdown
              title={'Tipos de automatas'}
              dropdownName={'types'}
              data={barReaderTypes || []}
              error={!bar_reader_type_idValid}
              value={bar_reader_type_id}
              margin={'5px 0'}
              handleChoose={e => this.setState({bar_reader_type_id: e.target.value})}
            />
          </LeftSide>
          <RightSide>
            <CustomTextField
              margin={'11px 0'}
              label={'Dirección IP'}
              variant="outlined"
              error={!addressValid}
              width={'100%'}
              onChange={this.handleInputs('address')}
              value={address}
            />
            <CustomTextField
              margin={'11px 0'}
              label={'Puerto'}
              variant="outlined"
              error={!portValid}
              width={'100%'}
              onChange={this.handleInputs('port')}
              value={port}
            />
            <CustomTextField
              margin={'11px 0'}
              label={'Nombre'}
              variant="outlined"
              error={!nameValid}
              width={'100%'}
              onChange={this.handleInputs('name')}
              value={name}
            />
          </RightSide>
          {/* <CustomTextField
            margin={'5px 0 15px 0'}
            label={'Tipo'}
            width={'100%'}
            onChange={this.handleInputs('type')}
            value={type}
          /> */}
        </DataDiv>{' '}
        <WrapSaveButton>
          <CustomButton
            bgcolor="#34baa2"
            onClick={this.handleSend}
            width="100%"
            margin="unset">
            {id ? 'Guardar' : 'Crear'} dispositivo
          </CustomButton>
        </WrapSaveButton>
      </WrapForm>
    );
  }

  private handleSend = async () => {
    const {device_id, area_id, address, name, id, port, bar_reader_type_id} = this.state;
    const validator = notifyValidator(this);
    if (
      // tslint:disable-next-line
      !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        address,
      )
    ) {
      this.setState({addressValid: false});
      notify({message: 'La dirección IP no es válida.', status: 'error'});
    } else if (id) {
      const res = await fetchWithFeedback(
        BarReadersControlServices.update(id, {
          device_id,
          area_id,
          address,
          name,
          port: port ? +port : null,
          bar_reader_type_id,
        }),
        {returnConfirmation: true, showMessage: true, notifyValidator: validator},
      );
      if (res) {
        this.props.updateBarReader();
        this.props.handleClose();
      }
    } else {
      const res = await fetchWithFeedback(
        BarReadersControlServices.create({
          device_id,
          area_id,
          address,
          name,
          port: port ? +port : null,
          bar_reader_type_id,
        }),
        {returnConfirmation: true, showMessage: true, notifyValidator: validator},
      );
      if (res) {
        this.setState({
          addressValid: true,
          address: '',
          area_id: '',
          area_idValid: true,
          device_id: '',
          device_idValid: true,
          name: '',
          id: '',
          bar_reader_type_id: '',
          bar_reader_type_idValid: true,
          port: '',
          nameValid: true,
          portValid: true,
        });
        this.props.updateBarReader();
      }
    }
  };

  private handleInputs: HandleNamedChange<State> = name => e => {
    this.setState({[name]: e.target.value, [`${name}Valid`]: true} as Pick<
      State,
      keyof State
    >);
  };
}
const DataDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const RightSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;

const LeftSide = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;
