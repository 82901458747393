import {PaginatedServiceArgs} from 'src/types';
import {FestiveControlData} from 'src/app/administration/festivesControl/store/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/festives';

export const FestivesControlServices = {
    get: ({params}: PaginatedServiceArgs) => guardedFetcher.get<PaginateResponse<FestiveControlData>>(`${prefix}`, {params}),
    update: (id: string, params: Partial<FestiveControlData>) => guardedFetcher.patch(`${prefix}/${id}`, params),
    create: (params: Partial<FestiveControlData>) => guardedFetcher.post(`${prefix}`, params),
};
