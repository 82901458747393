import {ChartData, ChartOptions} from 'chart.js';
import 'chartjs-plugin-datalabels';

import React from 'react';
import {LanePerformanceHourType} from 'src/app/lanemanager/charts/store/types';
import {Bar} from 'react-chartjs-2';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';

const chart: ChartData = {
    labels: [],
    datasets: [
        {
            label: 'Kilogramos',
            backgroundColor: '#4ab25a',
            data: [],
            fill: true,
        },
    ],
};
const options: ChartOptions = {
    responsive: true,
    legend: {labels: {fontSize: 20}},
    animation: false as any,
    plugins: {
        datalabels: {
            align: 'center',
            // formatter: Math.round,
            color: 'black',
            font: {
                weight: 'bold',
                size: 22,
            },
            formatter: (value: any) => {
                return customFormatNumber(value);
            },
        },
    },
    tooltips: {
        mode: 'index',
        intersect: false,
    },
    hover: {
        mode: 'nearest',
        intersect: true,
    },
    maintainAspectRatio: false,
    scales: {
        xAxes: [
            {
                display: true,
                scaleLabel: {
                    display: true,
                },
                ticks: {
                    fontSize: 17,
                },
            },
        ],
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    callback(value: string) {
                        // you can add your own method here (just an example)
                        return customFormatNumber(Number(value));
                    },
                },
            },
        ],
        // yAxes: [
        //   {
        //     display: true,
        //     scaleLabel: {
        //       display: true,
        //       labelString: 'Kilogramos',
        //     },
        //   },
        //   {
        //     display: true,
        //     id: 'perf',
        //     position: 'right',
        //     scaleLabel: {
        //       display: true,
        //       labelString: 'Rendimiento %',
        //     },
        //     // ticks: {
        //     //   reverse: true,
        //     // },
        //   },
        // ],
    },
};

const initialState = {chart, options};

type State = {
    chart: ChartData;
    options: ChartOptions;
};

type Props = {
    chartData?: LanePerformanceHourType;
};
export class PerformanceGraph extends React.Component<Props, State> {
    readonly state = initialState;
    static getDerivedStateFromProps(props: Props) {
        if (props.chartData)
            return {
                options,
                chart: {
                    labels: [...props.chartData.data],
                    datasets: [
                        // {
                        //   label: 'Rendimiento',
                        //   data: [...props.chartData.performance.map(it => +it)],
                        //   borderColor: '#5c4c33',
                        //   fill: false,
                        //   // Changes this dataset to become a line
                        //   type: 'line',
                        //   yAxisID: 'perf',
                        // },
                        {
                            label: 'Kilogramos',
                            backgroundColor: '#4ab25a',
                            data: [...props.chartData.kg.map(it => +it)],
                            fill: true,
                        },
                    ],
                },
            };
        else return {chart, options};
    }

    render() {
        return <>{this.state && <Bar data={this.state.chart} options={this.state.options} />}</>;
    }
}
