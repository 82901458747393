import * as React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
import {LanePtableState, LaneProdPartState} from 'src/app/lanemanager/lane/store/types';
import {CustomDialog} from 'src/shared/components/CustomDialog';

export interface ListItemProps {
    pt: LanePtableState;
    pp: LaneProdPartState;
    add: any;
    reserve: any;
    transfer: any;
}

type State = {
    showConfirmTransfer: boolean;
};

const initialState = {
    showConfirmTransfer: false,
};

export class CustomListItem extends React.Component<ListItemProps, State> {
    //export const CustomListItem = (props: ListItemProps) => (
    readonly state = initialState;
    render() {
        return (
            <ListItem>
                <ListItemText
                    primary={this.props.pt.name}
                    secondary={`${this.props.pt.state.toLowerCase()} ${this.props.pt.reserved_to_code ? '- Reservado a ' + this.props.pt.reserved_to_code : ''}`}
                />
                <ListItemSecondaryAction>
                    <Button
                        style={{marginRight: 10}}
                        variant="contained"
                        disabled={!!this.props.pt.assigned_to || this.props.pt.reserved_to === this.props.pp.id}
                        onClick={this.props.add}
                    >
                        Asignar
                    </Button>
                    <Button
                        style={{marginRight: 10}}
                        variant="contained"
                        disabled={!!this.props.pt.reserved_to || this.props.pt.assigned_to === this.props.pp.id || !this.props.pt.assigned_to}
                        onClick={this.props.reserve}
                    >
                        Reservar
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!!this.props.pt.reserved_to || this.props.pt.assigned_to === this.props.pp.id || !this.props.pt.assigned_to}
                        onClick={() => this.setState({showConfirmTransfer: true})}
                    >
                        Transferir
                    </Button>
                </ListItemSecondaryAction>
                <CustomDialog
                    title="Confirmación"
                    contentText={this.getTextConfirmTransfer()}
                    handleClose={() => this.setState({showConfirmTransfer: false})}
                    open={this.state.showConfirmTransfer}
                    handle={() => {
                        // this.props.handleTransfer({
                        //   prodpart: this.props.pp.id,
                        //   pt: this.state.pt,
                        // });
                        this.setState({showConfirmTransfer: false});
                        this.props.transfer();
                    }}
                />
            </ListItem>
        );
    }

    getTextConfirmTransfer() {
        return '¿Desea transferir la mesa ' + this.props.pt.name + ' del parte ' + this.props.pt.assigned_to_prodpart_code + ' al parte ' + this.props.pp.erp_prodpart_code + '?';
    }
}
