import * as React from 'react';
import styled from 'styled-components';

import {RatesControlServices} from 'src/app/administration/ratesControl/store/ratesControl.services';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';

type State = {
    open: boolean;
    id: string;
};

const initialState: State = {
    open: false,
    id: '',
};

export class RatesControl extends React.Component<{}, State> {
    readonly state = initialState;

    private columns: ColumnType<{id: string; name: string}> = [
        {
            Header: 'Nombre',
            headerClassName: 'header_cell_string',
            filterable: true,
            width: 260,
            accessor: 'employee_name',
            className: 'cell_string',
        },
        {
            Header: 'Apellidos',
            headerClassName: 'header_cell_string',
            filterable: true,
            width: 260,
            accessor: 'employee_surname',
            className: 'cell_string',
        },
        {
            Header: 'Jornada',
            headerClassName: 'header_cell_string',
            daterange: true,
            dateFormat: true,
            filterable: true,
            width: 260,
            accessor: 'workShift',
            className: 'cell_string',
        },
        {
            Header: 'Horas',
            headerClassName: 'header_cell_number',
            sortable: false,
            accessor: 'hours',
            Cell: item => <>{customFormatNumber(item.original.hours)}</>,
            className: 'cell_number',
        },
        {
            Header: 'Tarifa normal',
            headerClassName: 'header_cell_number',
            sortable: false,
            accessor: 'rate',
            Cell: item => <>{customFormatNumber(item.original.rate)}</>,
            className: 'cell_number',
        },
        {
            Header: 'Tarifa Extra',
            headerClassName: 'header_cell_number',
            sortable: false,
            accessor: 'rate_special',
            Cell: item => <>{customFormatNumber(item.original.rate_special)}</>,
            className: 'cell_number',
        },
        {
            Header: 'Festivo',
            headerClassName: 'header_cell_string',
            sortable: false,
            accessor: 'festive',
            Cell: item => <>{item.original.festive === true ? 'SI' : 'NO'}</>,
            className: 'cell_string',
        },
    ];

    render() {
        return IsInRuleset(['RATES_TOTAL', 'RATES_VIEW']) ? (
            <TableWrap>
                <PaginatedTableComponent
                    columnFormat={this.columns}
                    defaultPageSize={20}
                    service={RatesControlServices.get}
                    showSizeOptions={true}
                    twoWeeksFilter={true}
                    loading={true}
                />
            </TableWrap>
        ) : null;
    }
}

const TableWrap = styled.div`
    width: 95%;
    height: 100%;
    margin: auto;
`;
