import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

import {BatchesResponse} from './types';
import {batchesValidate} from './validators/BatchesResponse';

export const BatchesServices = {
  getAll: async () => {
    try {
      const validate = await batchesValidate;
      const {data, status} = await guardedFetcher.get<BatchesResponse>(
        'production/info/batches/area/{areaId}',
      );

      if (!validate(data)) {
        console.log('no he validado>> ', validate.errors);
      }

      if (status === 200) return data;
      else {
        console.log('no he validado>> ', validate.errors);
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  },
  getAllPaginated: ({params, args}: PaginatedServiceArgs) =>
    guardedFetcher.get<PaginateResponse<BatchesResponse>>(
      'production/info/batches/area/' + args,
      {
        params: args
          ? {...params, filtered: {area_id: args, ...params!.filtered}}
          : {...params},
      },
    ),
};
