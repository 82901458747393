import * as React from 'react';
import styled from 'styled-components';

import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {TableModal} from 'src/shared/components/Modal';
//import {FestiveForm} from './FestiveForm';
import {CalculateCostExecutionControlData} from './store/types';
import { CalculateCostExecutionsControlServices } from './store/calculateCostExecutionsControl.services';
import { CalculateCostExecutionForm } from './CalculateCostExecutionForm';
import throttle from 'lodash/throttle';
import { CustomButton } from 'src/shared/style';
//import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import { CustomDialog } from 'src/shared/components/CustomDialog';
import { notify } from 'src/utils/Notification';

type State = {
    open: boolean;
    selectedCalculateCostExecution: CalculateCostExecutionControlData | undefined;
    showModal: boolean;
    idToCalculate: string;
};

const initialState: State = {
    open: false,
    selectedCalculateCostExecution: undefined,
    showModal: false,
    idToCalculate: ''
};

export class CalculateCostExecutionsControl extends React.Component<{}, State> {
    readonly state = initialState;
    private throttled = throttle((fn: () => any) => fn(), 3000);
    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };


private handleRecalculate = async (idCalculateCostExecution: string) => {
    console.log(idCalculateCostExecution);
    if (idCalculateCostExecution) {
        CalculateCostExecutionsControlServices.recalculate(idCalculateCostExecution).then(res =>
            {
                if(res.status === 404 && res.data.message === 'CALCULATE_COST_IN_PROGRESS') {
                    notify({message: 'No se puede calcular coste porque hay algún cálculo en ejecución', status: 'error'});
                }else{
                    notify({message: 'Iniciado recálculo', status: 'success'});
                    
                    this.useTableHook();
                }
            } 
        );
        }
}

private handleUpdateCalculateCostExecution = () => {
        this.useTableHook();
        this.setState({open: false, selectedCalculateCostExecution: undefined});
    };

    private handleOpen = () => {
        this.setState({
            open: true,
        });
    };

    private handleClose = () => {
        this.setState({open: false, selectedCalculateCostExecution: undefined});
    };

    private columns: ColumnType<{id: string; name: string}> = [
        {
            Header: 'Jornada',
            headerClassName: 'header_cell_string',
            daterange: true,
            dateFormat: true,
            filterable: true,
            width: 260,
            accessor: 'workShift',
            className: 'cell_string',
        },
        {
            Header: 'Estado',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'state',
            className: 'cell_string',
            dropdownFilter: true,
            dropdownFilterData: [
                {id: 'wip', name: 'En progreso'},
                {id: 'finished', name: 'Finalizado'},
                {id: 'failed', name: 'Error'},
            ],
            width: 140,
            Cell: items => {
                return <span style={{color: items.original.state === 'wip' ? '#00bd4b' : '#eb0044'}}>{items.original.state === 'wip' ? 'En progreso' : 
                    (items.original.state === 'finished' ? 'Finalizado' : 'Error')}</span>;
            },
        },
        {
            Header: 'Fecha de inicio',
            headerClassName: 'header_cell_string',
            filterable: false,
            accessor: 'started_at',
            className: 'cell_string',
            dateTimeFormat: true,
        },
        {
            Header: 'Fecha de fin',
            headerClassName: 'header_cell_string',
            filterable: false,
            accessor: 'finished_at',
            className: 'cell_string',
            dateTimeFormat: true,
        },
        {
            Header: 'Modo',
            headerClassName: 'header_cell_string',
            filterable: false,
            accessor: 'last_mode',
            className: 'cell_string',
        },
        {
            style: {display: 'flex', justifyContent: 'center'},
            sortable: false,
            Header: 'Acción',
            Cell: (item: any) => (
                <CustomButton
                    disabled={item.original.state === 'wip' || !IsInRuleset(['CALCULATE_COST_EXECUTIONS_TOTAL'])}
                    onClick={() => this.throttled(() => {
                     this.setState({
                            showModal: true,
                            idToCalculate: item.original.id,
                        })   
                    })}
                >
                    Lanzar recálculo
                </CustomButton>
            ),
        },
    ];

    render() {
        const {selectedCalculateCostExecution, open} = this.state;
        console.log(selectedCalculateCostExecution);
        return IsInRuleset(['CALCULATE_COST_EXECUTIONS_TOTAL', 'CALCULATE_COST_EXECUTIONS_VIEW']) ? (
            <>
            <CustomDialog
                    title="Confirmación"
                    contentText="¿Está seguro que quiere lanzar el recálculo de una fecha?"
                    handleClose={() => this.setState({showModal: false})}
                    open={this.state.showModal}
                    handle={() => {
                        this.handleRecalculate(this.state.idToCalculate)
                        this.setState({showModal: false});
                    }}
                />
            <TableWrap>
                <TableModal title={'Cálculo de costes'} open={open} handleClose={this.handleClose}>
                    <CalculateCostExecutionForm 
                    calculateCostExecution={selectedCalculateCostExecution} 
                    handleClose={this.handleClose} 
                    update={this.handleUpdateCalculateCostExecution} 
                    />
                </TableModal>
                <PaginatedTableComponent
                    columnFormat={this.columns}
                    defaultPageSize={20}
                    service={CalculateCostExecutionsControlServices.get}
                    showSizeOptions={true}
                    twoWeeksFilter={true}
                    loading={true}
                    getTableFetchHook={this.getTableHook}
                    createHook={IsInRuleset('CALCULATE_COST_EXECUTIONS_TOTAL') ? this.handleOpen : undefined}
                />
            </TableWrap>
            </>
        ) : null;
    }
}

const TableWrap = styled.div`
    width: 95%;
    height: 100%;
    margin: auto;
`;
