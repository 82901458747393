import invoke from 'lodash/invoke';
import * as React from 'react';
import Select, {components} from 'react-select';
import animated from 'react-select/lib/animated';
import styled from 'styled-components';

import {GrantsService} from 'src/app/grants/grantsForGroupsControl/store/grants.service';
import {GrantsCategoryPanel} from 'src/app/grants/grantsForGroupsControl/GrantsCategoryPanel';
import {RulegroupsControlServices} from 'src/app/grants/rulegroupsControl/store/rulegroupsControl.services';
import {Button, Typography} from '@material-ui/core';
import {OCard} from 'src/shared/style';
import {firstNormalizationStep, firstNormalizationStepNEW, secondNormalizationGetValues, NormalizedGrantset} from 'src/global/authorization/normalizeGrants';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';

type State = {
    expanded: string | null;
    rulegroupsList: Array<{id: string; name: string}>;
    grantsKV: {[k: string]: string[]};
    normalizedGrants: NormalizedGrantset[];
    // parsedGrants: any[];
    selectedRulegroup?: {value: string; label: string};
    selectedGrants: string[];
};
const initialState: Readonly<State> = {
    expanded: null,
    rulegroupsList: [],
    grantsKV: {},
    normalizedGrants: [],
    selectedGrants: [],
};
export class GrantsForGroupsControl extends React.Component {
    readonly state = initialState;
    componentDidMount() {
        this.fetchRulegroups();
        this.fetchGrants();
    }
    private handleChange = (panel: string, expanded: boolean) => this.setState({expanded: expanded ? panel : false});

    private fetchRulegroups = async () => {
        const rulegroupsList = await fetchWithFeedback(RulegroupsControlServices.get(), {
            accessor: 'rulegroups',
        });
        this.setState({rulegroupsList: rulegroupsList || []});
    };

    private fetchGrants = async () => {
        const grantsList = await fetchWithFeedback(GrantsService.get(), {
            accessor: 'grantsets',
        });
        this.setState({
            normalizedGrants: firstNormalizationStepNEW(grantsList) || [],
        });
    };

    private chooseGrant = (id: string | null) => {
        const {selectedGrants} = this.state;
        if (typeof id === 'string') {
            const exist = selectedGrants.findIndex(sg => sg === id);
            if (exist === -1) {
                this.setState({selectedGrants: selectedGrants.concat(id)});
            } else if (exist > -1) {
                selectedGrants.splice(exist, 1);
                this.setState({selectedGrants: selectedGrants});
            }
        }
    };

    private fetchGrantsForRulegroup = async (id?: string) => {
        const res = await fetchWithFeedback(
            RulegroupsControlServices.getGrantset({
                id: id ? id : this.state.selectedRulegroup ? this.state.selectedRulegroup.value : 'NO_HAY_RULEGROUP',
            }),
            {accessor: 'grantsets'},
        );
        if (res && Array.isArray(res)) {
            const parsed = firstNormalizationStep(res);
            const second = secondNormalizationGetValues(parsed);
            this.setState({selectedGrants: second});
        }
    };

    private saveGrants = async () => {
        const {selectedGrants, selectedRulegroup} = this.state;
        if (selectedRulegroup) {
            await fetchWithFeedback(
                RulegroupsControlServices.updateGrants({
                    id: selectedRulegroup.value,
                    grants: selectedGrants,
                }),
                {showMessage: true},
            );
        } else notify({message: 'Seleccione Grupo de Acceso', status: 'warning', timeout: 3000});
    };

    render() {
        const {expanded, selectedRulegroup, rulegroupsList, normalizedGrants, selectedGrants} = this.state;

        return (
            <WrappingDiv>
                <OCard width={'100%'} contentHeight={'100%'} contentPadding={'5px'} margin={'auto'}>
                    <Typography component="div" style={{margin: '0px', display: 'flex'}}>
                        <ReactSelectClasses>
                            <Select
                                backspaceRemovesValue={false}
                                ignoreAccents={true}
                                placeholder={'Elegir el Grupo de Acceso...'}
                                menuPlacement="bottom"
                                value={selectedRulegroup}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                menuPortalTarget={document.querySelector('body')}
                                isClearable={true}
                                // @ts-ignore
                                components={{...animated(), Menu}}
                                options={rulegroupsList ? rulegroupsList.map(t => ({value: t.id, label: t.name})) : []}
                                onChange={(sel: any) => {
                                    let isReset = false;
                                    if (sel && !Array.isArray(sel)) {
                                        this.fetchGrantsForRulegroup(sel.value as any);
                                    } else {
                                        isReset = true;
                                    }
                                    this.setState({
                                        selectedRulegroup: sel as any,
                                        selectedGrants: isReset ? [] : this.state.selectedGrants,
                                    });
                                }}
                            />
                        </ReactSelectClasses>
                        <Button variant="text" color="primary" onClick={this.saveGrants}>
                            Guardar
                        </Button>
                    </Typography>
                </OCard>
                {invoke(normalizedGrants, 'map', ({grants, category}: NormalizedGrantset) => (
                    <GrantsCategoryPanel
                        key={category}
                        grants={grants}
                        expanded={expanded}
                        category={category}
                        // category={'Categoria'}
                        chooseGrant={this.chooseGrant}
                        change={this.handleChange}
                        selectedGrants={selectedGrants}
                    />
                ))}
            </WrappingDiv>
        );
    }
}

const WrappingDiv = styled.div`
    width: calc(75%);
    margin: auto;
    height: 100%;
    background-color: #efefef;
    /* min-height: calc(100% - 5px); */
    /* margin-bottom: 5px; */
    padding: 5px;
    margin-bottom: 5px;
`;

const ReactSelectClasses = styled.div`
    width: 100%;
    margin-right: 5px;
`;

// const tasksListStyle = {
// container: (provided: any) => ({
//   ...provided,
//   zIndex: '1000 !important',
// }),
// };

const Menu = (props: any) => (
    <Typography component="div">
        <components.Menu {...props}>{props.children}</components.Menu>
    </Typography>
);
