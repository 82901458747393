import React from 'react';
import {connect} from 'react-redux';
import {match} from 'react-router-dom';

import {AppStoreState} from 'src/store/interface';
import {CardStyled, TerminalWrap} from 'src/terminal/components';
import {
  changeTaskThunk,
  finalizeTaskThunk,
  leaveTerminal,
} from 'src/terminal/store/actions';
import {User} from 'src/terminal/store/reducer';
import {
  getAuthorizedUserData,
  getAuthorizedUserTasks,
} from 'src/terminal/store/selectors';

interface MatchExt extends match {
  params: {
    id: string;
  };
}
export interface UserDataCardProps {
  match: MatchExt;
  uTasks: any;
  userData: User;
  logout: () => void;
  choose: (userId: number, task: number) => void;
  unchoose: (userId: number, task: number) => void;
  finalizeTask: (userId: number, task: number) => void;
}

class UserDataCard extends React.Component<any & UserDataCardProps, {}> {
  handleButtonClick = () => {
    this.props.logout();
    // return <Redirect to={'/'} />;
  };
  findActiveTask = () => {
    return this.props.uTasks.find((ut: any) => ut.state === 'current');
  };

  handleToggle = (index: string) => () => {
    const chosenTask = this.props.uTasks[index];
    const active = this.findActiveTask();
    if (!active && chosenTask.state !== 'finalized')
      this.props.choose(this.props.userData.id, chosenTask.taskId);
    if (active && active.taskId !== chosenTask.taskId)
      this.props.choose(this.props.userData.id, chosenTask.taskId);
  };

  handleFinishTask = () => {
    const active = this.findActiveTask();
    if (active) this.props.finalizeTask(this.props.userData.id, active.taskId);
  };

  render = () => (
    <TerminalWrap>
      <CardStyled
        isInput={false}
        titleText={`Datos del trabajador ${this.props.match.params.id}`}
        inputLabelText={'Código del trabajador'}
        buttonText={'Acceder al sistema'}
        isList={true}
        handleToggle={this.handleToggle}
        handleButtonClick={this.handleButtonClick}
        handleFinishTask={this.handleFinishTask}
        uTasks={this.props.uTasks}
        uData={this.props.userData}
      />
    </TerminalWrap>
  );
}

const mapStateToProps = (state: AppStoreState) => ({
  uTasks: getAuthorizedUserTasks(state),
  userData: getAuthorizedUserData(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(leaveTerminal()),
  choose: (userId: string, task: string) => dispatch(changeTaskThunk(userId, task)),
  // unchoose: (userId: number, task: number) =>
  //   dispatch(unChooseTask(userId, task)),
  finalizeTask: (userId: string, task: string) =>
    dispatch(finalizeTaskThunk(userId, task)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserDataCard);
