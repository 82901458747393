import {ptableScheme} from 'src/app/lanemanager/lane/store/validators/schemes/ptable';
import {ajv} from 'src/utils/ajv';

const ptablesResScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['ptables'],
  properties: {
    ptables: {type: 'array', items: ptableScheme},
  },
};

const precompile = ajv.compile(ptablesResScheme);
export const ptablesSchemeResValidate = precompile;
