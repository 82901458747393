import * as React from 'react';
import {Typography} from '@material-ui/core';
import {TableModal} from 'src/shared/components/Modal';
import {BatchesExceededKgBatchType} from './store/types';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';

export interface Props {
    openAlertExceededKgBatch: boolean;
    exceededBatches: BatchesExceededKgBatchType[] | undefined;
    handleClose(): void;
}

const divStyleAlert = {
    padding: '20px',
    margin: '20px',
    backgroundColor: '#e4b9b9',
};
export class AlertExceededKgBatchModal extends React.Component<Props> {
    render() {
        return (
            <>
                <TableModal open={this.props.openAlertExceededKgBatch} handleClose={() => this.props.handleClose()} title={'Atencion, se ha excedido los kilos del origen'}>
                    <>
                        {this.props.exceededBatches && this.props.exceededBatches.length ? (
                            this.props.exceededBatches.map((exceededBatch: any) => (
                                <div key={exceededBatch.id} style={divStyleAlert}>
                                    <p>Origen: {exceededBatch.batch_code}</p>
                                    <p>Volcados: {customFormatNumber(exceededBatch.kg_dumped)} Kg</p>
                                    <p>Repartidos: {customFormatNumber(exceededBatch.kg_palletized)} Kg</p>
                                </div>
                            ))
                        ) : (
                            <Typography variant="body1">No hay orígenes excedidos</Typography>
                        )}
                    </>
                </TableModal>
            </>
        );
    }
}
