import * as React from 'react';
import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import styled from 'styled-components';

import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {LoginThunks} from 'src/app/login/store/login.thunks';
import {Message} from 'src/app/messages/store/messages.action-types';
import {MessagesServices} from 'src/app/messages/store/messages.services';
import {MessagesPopup} from 'src/app/messages/MessagesPopup';
// import seyte from 'src/assets/seyte.svg';
import {IconButton, Tooltip} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {fade} from '@material-ui/core/styles/colorManipulator';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Notifications from '@material-ui/icons/Notifications';
import Person from '@material-ui/icons/Person';
import {DrawerMenu} from 'src/app/menu/Menu';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {findInitialParameter} from 'src/global/initalParameters/initialParameters';
import {ParameterCPN} from 'src/types/app_types';

type StyledProps = {
    classes: {[k: string]: string};
};
type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
    userData: ReturnType<typeof LoginSelectors.getUserData>;
    // messages: ReturnType<typeof MessagesSelectors.getMessages>;
};

type DProps = {logout(): void};

type Props = {title: string; disableBurger: boolean};

type State = Readonly<{
    openMessages: boolean;
    messages: Message[];
    numMessagesNoViewed: number;
}>;
const initialState: State = {openMessages: false, messages: [], numMessagesNoViewed: 0};

class StatusBarBase extends React.Component<StyledProps & SProps & DProps & Props, State> {
    readonly state = initialState;
    private timer: any;

    componentDidMount() {
        if (IsInRuleset(['MESSAGES_VIEW', 'MESSAGES_PERSONAL', 'MESSAGES_VIEW_READ'])) {
            this.fetchNewMessages();
            this.timer = setInterval(() => {
                this.fetchNewMessages();
            }, 300000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    private fetchNewMessages = () => {
        if (this.props.userData.employeeId) {
            fetchWithFeedback(MessagesServices.getNew(this.props.userData.employeeId)).then(res => {
                if (res) {
                    this.setState({messages: res.data});
                    this.setState({numMessagesNoViewed: res.total});
                }
            });
        }
    };

    render() {
        document.documentElement.style.setProperty('--header-background-color', `#${findInitialParameter('FRONT_APP_COLOR')?.value || '#939393'}`);
        document.documentElement.style.setProperty('--header-background-color-odd', `#${findInitialParameter('FRONT_APP_COLOR')?.value || '#939393'}55`);
        document.documentElement.style.setProperty('--header-background-color-even', `#${findInitialParameter('FRONT_APP_COLOR')?.value || '#939393'}99`);

        const {classes, userData, title, disableBurger} = this.props;
        const {openMessages, messages, numMessagesNoViewed} = this.state;
        return (
            <AppBarWrapper>
                {openMessages && <MessagesPopup messages={messages} clickHook={() => this.setState({openMessages: !openMessages})} />}
                <AppBar
                    style={{height: 40, backgroundColor: `#${findInitialParameter('FRONT_APP_COLOR')?.value || ''}`}}
                    color="default"
                    position="static"
                    className={classes.root}
                >
                    <Toolbar style={{paddingLeft: 4, paddingRight: 5, minHeight: 40}}>
                        {!disableBurger && true && <DrawerMenu />}
                        <Typography className={classes.title} variant="h5" noWrap={true}>
                            {findInitialParameter(ParameterCPN.CUSTOMER_NAME)?.value} {process.env.NODE_ENV !== 'production' && ' - DEV'}
                        </Typography>
                        <div className={classes.grow} />
                        <Typography variant="h5" style={{color: '#f4f5f7'}}>
                            {title && title.toUpperCase()}
                        </Typography>
                        <div className={classes.grow} />
                        {!userData.employeeId && (
                            <Tooltip title={'Este usuario no tiene empleado asociado. Muchas functiones podrán no estar disponibles'}>
                                <span style={{marginRight: 7, fontSize: 22}}>SIN EMPLEADO</span>
                            </Tooltip>
                        )}
                        <Typography component="div" style={{display: 'flex'}}>
                            {IsInRuleset(['MESSAGES_VIEW', 'MESSAGES_PERSONAL', 'MESSAGES_VIEW_READ']) && (
                                <CustomBadge display={messages && messages.length ? true : false} content={messages ? '' + numMessagesNoViewed : undefined}>
                                    <Tooltip title={'Mensajes'}>
                                        <IconButton
                                            style={{
                                                marginLeft: 5,
                                                marginRight: 5,
                                                padding: 2,
                                                height: 35,
                                                width: 35,
                                                color: '#f4f5f7',
                                            }}
                                            onClick={() => this.setState({openMessages: !openMessages})}
                                        >
                                            <Notifications />
                                        </IconButton>
                                    </Tooltip>
                                </CustomBadge>
                            )}

                            <div style={{alignSelf: 'center', color: '#f4f5f7'}}>
                                {userData.username || 'username...'}
                                <Tooltip title="Mi perfil">
                                    <IconButton
                                        style={{
                                            marginLeft: 5,
                                            marginRight: 5,
                                            padding: 2,
                                            height: 35,
                                            width: 35,
                                            color: '#f4f5f7',
                                        }}
                                    >
                                        <Person />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Tooltip title={'Cerrar sesión'}>
                                <IconButton
                                    onClick={this.props.logout}
                                    style={{
                                        marginLeft: 5,
                                        marginRight: 5,
                                        padding: 2,
                                        height: 35,
                                        width: 35,
                                        color: '#f4f5f7',
                                    }}
                                >
                                    <ExitToApp />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Toolbar>
                </AppBar>
            </AppBarWrapper>
        );
    }
}

export const StatusBar = connect<SProps, any, any, AppStoreState>(
    state => ({
        lane: LaneSelectSelectors.getLane(state),
        userData: LoginSelectors.getUserData(state),
        // messages: MessagesSelectors.getMessages(state),
    }),
    (dispatch: ThunkDispatch<AppStoreState, null, AnyAction>) => ({
        logout: () => dispatch(LoginThunks.logout()),
    }),
)(
    withStyles(theme => ({
        root: {
            width: '100%',
            // backgroundColor: `#${findInitialParameter('FRONT_APP_COLOR')?.value || ''}`,
            backgroundColor: `#${findInitialParameter('FRONT_APP_COLOR')?.value || ''}`,
        },
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginLeft: -12,
            marginRight: 20,
        },
        title: {
            display: 'none',
            color: '#f4f5f7',
            marginLeft: 10,
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing.unit,
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing.unit * 9,
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
            width: '100%',
        },
        inputInput: {
            paddingTop: theme.spacing.unit,
            paddingRight: theme.spacing.unit,
            paddingBottom: theme.spacing.unit,
            paddingLeft: theme.spacing.unit * 10,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 120,
                '&:focus': {
                    width: 200,
                },
            },
        },
    }))(StatusBarBase),
);

const AppBarWrapper = styled.div`
    width: 100%;
`;

type BadgeProps = {
    display: boolean;
    content?: string;
    margin?: string;
    classes?: any;
    color?: 'primary' | 'secondary' | 'default' | 'error';
    style?: {[k: string]: any};
};

const styles = {
    badge: {
        top: 8,
        padding: 1,
        right: 33,
        width: 20,
        height: 20,
    },
};

const CustomBadgeBase: React.FunctionComponent<BadgeProps> = props => {
    return (
        <div
            style={{
                margin: props.margin,
            }}
        >
            <Badge
                component={'div'}
                // style={props.style}
                classes={{badge: props.classes.badge}}
                invisible={!props.display}
                badgeContent={props.content || ''}
                color={props.color || 'primary'}
            >
                {props.children}
            </Badge>
        </div>
    );
};

const CustomBadge = withStyles(styles)(CustomBadgeBase);

// const WhiteSvg = (
//   <svg
//     style={{
//       marginLeft: 5,
//       // borderColor: 'white',
//       // borderRightStyle: 'solid',
//       // borderRightWidth: 1,
//       // paddingRight: 10,
//     }}
//     version="1.1"
//     id="Capa_1"
//     xmlns="http://www.w3.org/2000/svg"
//     xmlnsXlink="http://www.w3.org/1999/xlink"
//     x="0px"
//     y="0px"
//     width="100px"
//     height="35px"
//     viewBox="0 0 356 125"
//     enable-background="new 0 0 356 125"
//     xmlSpace="preserve">
//     <g>
//       <g>
//         <g>
//           <path
//             fill="white"
//             d="M159.42,36.93c9.313,0,15.428,3.104,18.25,9.407c0.658,1.411,0.376,2.446-1.035,3.104l-3.01,1.223
// 				c-1.693,0.659-1.881,0.471-2.916-0.94c-1.882-3.763-4.892-5.645-11.195-5.645c-6.679,0-9.972,2.069-9.972,6.303
// 				c0,5.174,3.387,6.209,11.853,6.867c5.739,0.659,10.16,1.788,13.076,3.575c2.917,1.881,4.328,5.174,4.328,9.878
//         c0,8.654-6.397,14.017-18.25,14.017c-10.066,
//         0-16.462-3.575-19.191-10.725c-0.658-1.411-0.188-2.352,1.317-2.822l3.01-1.034
// 				c1.317-0.471,2.164,0,2.822,1.316c1.693,4.233,5.644,6.303,11.853,6.303c7.244,0,10.913-2.163,10.913-6.679
// 				c0-4.892-3.292-6.115-11.665-6.867c-4.328-0.471-6.491-0.753-9.972-1.976c-1.788-0.659-3.104-1.317-4.139-2.258
// 				c-1.976-1.788-3.669-5.08-3.669-9.407C141.829,42.009,147.944,36.93,159.42,36.93z"
//           />
//           <path
//             fill="white"
//             d="M184.914,55.65c0-11.853,6.867-18.72,19.756-18.72c12.981,0,19.755,7.244,19.755,18.062v5.08
// c0,1.976-1.034,3.104-3.198,3.104h-28.504v4.233c0,
// 6.773,4.139,10.536,11.759,10.536c5.645,0,9.784-1.976,12.136-6.021
//         c1.034-1.505,1.881-1.787,3.387-0.753l2.352,
//         1.224c1.505,0.752,1.787,1.881,0.753,3.386c-3.387,6.021-9.689,9.031-18.815,9.031
// 				c-13.076,0-19.379-6.961-19.379-18.156V55.65z M216.617,54.521c0-6.961-4.233-11.006-11.947-11.006
// 				c-7.809,0-11.947,4.139-11.947,11.006v2.54h23.988v-2.54H216.617z"
//           />
//           <path
//             fill="white"
//             d="M268.827,38.247c1.693,0,2.07,0.47,2.07,2.07v45.249c0,12.605-6.304,18.908-18.909,18.908
//         c-8.467,0-14.393-3.01-17.779-8.937c-0.941-1.505-0.659-2.54,
//         0.847-3.293l2.54-1.411c1.692-0.752,2.352-0.658,3.386,0.753
// 				c2.258,3.951,5.833,5.832,10.818,5.832c7.433,0,11.195-3.856,
// 11.195-11.57v-4.704c-4.422,1.224-8.561,1.882-12.229,1.882
// 				c-11.854,0-17.78-5.738-17.78-17.31V40.316c0-1.693,0.471-2.07,
// 2.07-2.07h3.669c1.505,0,1.975,0.659,1.975,2.07v25.117
// 				c0,6.867,3.669,10.442,11.007,10.442c3.48,0,7.244-0.659,11.194-1.882V40.316c0-1.693,0.659-2.07,2.07-2.07H268.827
// 				L268.827,38.247z"
//           />
//           <path
//             fill="white"
//             d="M291.781,27.71c1.505,0,2.069,0.47,2.069,2.07v8.372h11.478c1.505,0,1.976,0.659,1.976,2.07v3.104
// 				c0,1.505-0.564,2.069-1.976,2.069h-11.478V65.81c0,4.61,0.753,7.62,2.353,9.125c1.599,1.506,4.515,2.258,8.937,2.258
// 				c1.693,0,2.163,0.471,2.163,2.07v3.104c0,1.505-0.564,1.976-2.163,2.069c-7.056,0.188-11.947-1.035-14.77-3.763
//         c-2.916-2.635-4.327-7.526-4.327-14.77V45.302h-7.244c-1.505,
//         0-2.069-0.659-2.069-2.07v-3.104c0-1.693,0.658-2.069,2.069-2.069
// 				h7.244v-8.373c0-1.693,0.564-2.07,1.976-2.07h3.763V27.71z"
//           />
//           <path
//             fill="white"
//             d="M312.478,55.65c0-11.853,6.867-18.72,19.755-18.72c12.982,0,19.756,7.244,19.756,18.062v5.08
// 				c0,1.976-1.035,3.104-3.199,3.104h-28.504v4.233c0,6.773,4.14,10.536,11.76,10.536c5.644,0,9.783-1.976,12.135-6.021
//         c1.035-1.505,1.882-1.787,3.387-0.753l2.352,1.224c1.506,0.752,
//         1.788,1.881,0.753,3.386c-3.387,6.021-9.689,9.031-18.814,9.031
// 				c-13.076,0-19.379-6.961-19.379-18.156V55.65z M344.273,54.521c0-6.961-4.232-11.006-11.947-11.006
// 				c-7.808,0-11.947,4.139-11.947,11.006v2.54h23.989v-2.54H344.273z"
//           />
//         </g>
//       </g>
//       <g>
//         <path
//           fill="white"
//           d="M51.613,4.851c-0.659-0.376-1.411-0.282-2.07,0.094L5.517,34.295c-0.564,0.376-0.846,1.035-0.846,1.693
//       v25.87c0,0.752,0.376,1.411,1.035,1.788c0.282,0.188,0.658,0.282,
//       0.94,0.282c0.376,0,0.753-0.095,1.129-0.377l44.026-29.351
// 			c0.564-0.376,0.847-1.035,0.847-1.693V6.638C52.648,5.885,52.271,5.227,51.613,4.851z"
//         />
//         <path
//           fill="white"
//           d="M65.442,73.523L7.775,34.86c-0.658-0.376-1.411-0.47-2.069-0.094c-0.659,0.376-1.035,1.035-1.035,1.788
//       v25.87c0,0.659,0.282,1.224,0.846,1.6l37.724,26.435c0.376,0.282,
//       0.752,0.376,1.129,0.376c0.376,0,0.752-0.094,1.128-0.376
// 			l19.944-13.734c0.564-0.377,0.847-1.035,0.847-1.693C66.382,74.465,66.006,73.9,65.442,73.523z"
//         />
//         <path
//           fill="white"
//           d="M119.91,59.507c-0.659-0.376-1.411-0.282-2.07,0.094L73.814,88.952c-0.564,0.376-0.847,1.034-0.847,1.693
//       v25.87c0,0.752,0.376,1.411,1.035,1.787c0.282,0.188,0.659,0.282,
//       0.941,0.282c0.376,0,0.752-0.094,1.128-0.376l44.026-29.352
// 			c0.564-0.376,0.847-1.034,0.847-1.693v-25.87C120.945,60.542,120.568,59.883,119.91,59.507z"
//         />
//         <path
//           fill="white"
//           d="M120.098,59.131L82.375,32.696c-0.659-0.47-1.6-0.47-2.258,0L60.174,46.431
// 			c-0.564,0.376-0.847,1.035-0.847,1.693c0,0.659,0.376,1.317,
// 0.847,1.6l57.667,38.664c0.376,0.188,0.752,0.376,1.129,0.376
//       c0.282,0,0.659-0.094,0.941-0.282c0.658-0.376,1.035-1.035,
//       1.035-1.787v-25.87C120.945,60.166,120.568,59.507,120.098,59.131z"
//         />
//         <path
//           fill="white"
//           d="M119.91,59.507c-0.659-0.376-1.411-0.282-2.07,0.094L96.298,73.9l21.542,14.393
// 			c0.376,0.188,0.752,0.377,1.129,0.377c0.282,0,0.659-0.095,0.941-0.282c0.658-0.377,1.035-1.035,1.035-1.788V61.2
// 			C120.945,60.542,120.568,59.883,119.91,59.507z"
//         />
//         <path
//           fill="white"
//           d="M5.799,63.74c0.282,0.188,0.659,0.282,0.941,0.282c0.376,0,0.752-0.094,1.129-0.376l21.449-14.299
// 			L7.869,34.954c-0.659-0.376-1.411-0.471-2.07-0.094c-0.658,0.376-1.035,1.035-1.035,1.788v25.399
// 			C4.765,62.705,5.141,63.364,5.799,63.74z"
//         />
//       </g>
//     </g>
//   </svg>
// );
