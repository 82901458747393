import {LaneActionTypes} from '../types/lane.action-types';
import {AreaPtablesRes, LaneActionInterface, LanePtableState} from '../types';

export const LanePtableActions = {
  set: (payload: AreaPtablesRes): LaneActionInterface<AreaPtablesRes> => ({
    type: LaneActionTypes.setPtables,
    payload,
  }),
  update: (payload: LanePtableState): LaneActionInterface<LanePtableState> => ({
    type: LaneActionTypes.updatePtable,
    payload,
  }),
  clear: (): LaneActionInterface => ({type: LaneActionTypes.clearPtables}),
};
