import invoke from 'lodash/invoke';
import * as React from 'react';
import isEqual from 'react-fast-compare';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {EmployeesControlServices} from 'src/app/administration/employeesControl/store/employeesControl.services';
import {EmployeesControlResponseData, JobtypeResponseData} from 'src/app/administration/employeesControl/store/types';
import {EmployeeForm} from 'src/app/administration/employeesControl/EmployeeForm';
import {MassJobtypesForm} from 'src/app/administration/employeesControl/MassJobtypesForm';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {withStyles, Badge, Checkbox} from '@material-ui/core';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import EmployeesFromErpIcon from '@material-ui/icons/SaveAlt';
import {PaginatedTableSelectors} from 'src/shared/components/store/paginatedTable.selectors';
import {TableModal} from 'src/shared/components/Modal';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';
import {ParametersSelectors} from 'src/utils/Parameters/selectors';
import {ParameterCPN} from 'src/types/app_types';

type SProps = {
    userData: ReturnType<typeof LoginSelectors.getUserData>;
    tableFilters: ReturnType<typeof PaginatedTableSelectors.getFilters>;
    employeeCodeModeParam?: String;
};

type State = {
    open: boolean;
    massOpen: boolean;
    massSelect: boolean;
    selected?: EmployeesControlResponseData;
    selectedEmployees: Array<{employeeId: string; jobtypes: string[]}>;
    allJobtypes?: JobtypeResponseData[];
};
const initialState: State = {
    open: false,
    massOpen: false,
    massSelect: false,
    selected: undefined,
    selectedEmployees: [],
    allJobtypes: undefined,
};

type ComponentProps = SProps;

export class EmployeesControlBase extends React.Component<ComponentProps, State> {
    readonly state = initialState;
    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    componentDidMount() {
        this.fetchJobtypes();
    }

    componentWillReceiveProps(nextProps: ComponentProps) {
        if (!isEqual(nextProps.tableFilters, this.props.tableFilters)) this.setState({massSelect: false});
    }

    private handleClose = () => {
        this.setState({open: false});
        this.handleClear();
        this.useTableHook();
    };

    private handleOpen = () => this.setState({open: true});
    private handleChoose = (employee: EmployeesControlResponseData) => this.setState(() => ({selected: employee}), this.handleOpen);
    private handleClear = () => this.setState({selected: undefined});
    private handleSelect = (employee: EmployeesControlResponseData) => {
        if (!employee) return;
        const {id} = employee;
        const {selectedEmployees} = this.state;
        const exist = selectedEmployees.find(it => it.employeeId === id);
        if (exist) {
            this.setState({
                selectedEmployees: selectedEmployees.filter(it => it.employeeId !== id),
                massSelect: false,
            });
        } else {
            this.setState({
                selectedEmployees: selectedEmployees.concat(this.parseEmployee(employee)),
                massSelect: false,
            });
        }
    };

    private handleToggleMassiveModal = () => this.setState({massOpen: !this.state.massOpen});

    private handleMassiveUpdate = () => {
        this.useTableHook();
        this.setState({
            massOpen: !this.state.massOpen,
            selectedEmployees: [],
            massSelect: false,
        });
    };

    private parseEmployee = (
        employee: EmployeesControlResponseData,
    ): {
        employeeId: string;
        jobtypes: string[];
    } => ({
        employeeId: employee.id,
        jobtypes: employee.jobtypes.map(jt => jt.id),
    });

    private fetchJobtypes = async () => {
        const jobtypes = (await fetchWithFeedback(EmployeesControlServices.getAllJobtypes(), {
            accessor: 'jobtypes',
        })) as JobtypeResponseData[];
        this.setState({allJobtypes: jobtypes || []});
    };

    private selectAllFiltered = async () => {
        if (!this.state.massSelect) {
            const selected = await fetchWithFeedback(
                EmployeesControlServices.getAllEmployees({
                    params: {...(this.props.tableFilters as any), 'page-size': 999999},
                    args: [{idOnly: true}],
                }),
            );
            const mapForState = selected.data.map((s: any) => ({
                employeeId: s.id,
                jobtypes: s.jobtypes,
            }));
            const mergedArray = this.state.selectedEmployees.concat(
                mapForState.filter((item: {employeeId: string}) => this.state.selectedEmployees.findIndex(emp => emp.employeeId === item.employeeId) < 0),
            );
            this.setState({selectedEmployees: mergedArray, massSelect: true});
        } else this.setState({selectedEmployees: [], massSelect: false});
    };

    private printRolesForTable = (jobtypes: Array<{id: string; name: string}>) =>
        invoke(jobtypes, 'map', (item: {id: string; name: string}) => (
            <span key={item.id}>
                {item.name}
                <br />
            </span>
        ));

    private handleERPDownload = async () => {
        notify({message: 'El proceso de recarga se está realizando...', status: 'notify'});
        await fetchWithFeedback(EmployeesControlServices.downloadFromErp(), {
            showMessage: true,
            // successMessage: 'El proceso de recarga se está realizando...',
        });
    };
    render() {
        const TOTAL_ACCESS = typeof IsInRuleset === 'function' ? IsInRuleset('EMPLOYEES_TOTAL') : false;
        const EXCEL_ACCESS = typeof IsInRuleset === 'function' ? IsInRuleset('EMPLOYEES_VIEW_EXCEL') : false;
        const VIEW_ACCESS = typeof IsInRuleset === 'function' ? IsInRuleset('EMPLOYEES_VIEW') : false;

        const {selected, open, massOpen, allJobtypes, selectedEmployees} = this.state;

        const {employeeCodeModeParam} = this.props;
        const showErpEmployeeCode = employeeCodeModeParam === 'ERP_MODE' ? true : false;
        const showInternalEmployeeCode = employeeCodeModeParam === 'INTERNAL_MODE' ? true : false;
        if (allJobtypes && (TOTAL_ACCESS || EXCEL_ACCESS || VIEW_ACCESS))
            return (
                <TableWrap>
                    <TableModal title={'Gestión del empleado'} open={open} handleClose={this.handleClose}>
                        <EmployeeForm handleClose={this.handleClose} employee={selected} updateEmployee={this.useTableHook} allJobtypes={allJobtypes} />
                    </TableModal>
                    <TableModal title={'Asignación masiva de roles'} open={massOpen} handleClose={this.handleToggleMassiveModal}>
                        <MassJobtypesForm
                            handleClose={this.handleToggleMassiveModal}
                            handleUpdate={this.handleMassiveUpdate}
                            employees={selectedEmployees}
                            allJobtypes={allJobtypes}
                        />
                    </TableModal>
                    <PaginatedTableComponent
                        defaultPageSize={20}
                        columnFormat={[
                            {
                                Header: 'Selección',
                                filterable: true,
                                Filter: () => {
                                    return (
                                        <div style={{textAlign: 'left'}}>
                                            <CustomBadge display={true} color="primary" content={'' + this.state.selectedEmployees.length}>
                                                <Checkbox
                                                    value="activeCB"
                                                    disabled={!TOTAL_ACCESS}
                                                    checked={this.state.massSelect}
                                                    style={{padding: 2}}
                                                    onClick={() => this.selectAllFiltered()}
                                                />
                                            </CustomBadge>
                                        </div>
                                    );
                                },
                                Cell: info => (
                                    <>
                                        <Checkbox
                                            disabled={!TOTAL_ACCESS}
                                            checked={this.state.selectedEmployees.findIndex(it => it.employeeId === info.original.id) > -1}
                                            value="activeCB"
                                            style={{padding: 10}}
                                        />
                                    </>
                                ),
                                sortable: false,
                                width: 80,
                                style: {
                                    textAlign: 'center',
                                    whiteSpace: 'normal',
                                    alignSelf: 'center',
                                },
                            },
                            {
                                Header: 'Código empleado',
                                headerClassName: 'header_cell_string',
                                accessor: 'code',
                                show: showInternalEmployeeCode,
                                sortable: true,
                                filterable: true,
                                width: 140,
                                className: 'cell_string',
                            },
                            {
                                Header: 'Código empleado',
                                headerClassName: 'header_cell_string',
                                accessor: 'erp_employee_code',
                                show: showErpEmployeeCode,
                                sortable: true,
                                filterable: true,
                                width: 140,
                                className: 'cell_string',
                            },
                            {
                                Header: 'Estado',
                                headerClassName: 'header_cell_string',
                                accessor: 'active',
                                sortable: true,
                                dropdownFilter: true,
                                dropdownFilterData: [
                                    {id: 'true', name: 'Activo'},
                                    {id: 'false', name: 'Inactivo'},
                                ],
                                width: 140,
                                Cell: items => {
                                    return <span style={{color: items.original.active ? '#00bd4b' : '#eb0044'}}>{items.original.active ? 'ACTIVO' : 'INACTIVO'}</span>;
                                },
                                className: 'cell_string',
                            },
                            {
                                Header: 'Foto',
                                Cell: item =>
                                    item.original.profileUrl ? (
                                        <img
                                            style={{
                                                height: 100,
                                                margin: 0,
                                                objectFit: 'scale-down',
                                            }}
                                            src={item.original.profileUrl}
                                            alt={'img'}
                                        />
                                    ) : null,
                                style: {textAlign: 'center', whiteSpace: 'normal', alignSelf: 'center'},
                                sortable: false,
                            },
                            {
                                Header: 'Nombre',
                                headerClassName: 'header_cell_string',
                                accessor: 'name',
                                className: 'cell_string',
                                sortable: true,
                                filterable: true,
                            },
                            {
                                Header: 'Apellidos',
                                headerClassName: 'header_cell_string',
                                accessor: 'surname',
                                className: 'cell_string',
                                sortable: true,
                                filterable: true,
                            },
                            {
                                Header: 'DNI',
                                headerClassName: 'header_cell_string',
                                accessor: 'identification',
                                sortable: false,
                                className: 'cell_string',
                                width: 120,
                                filterable: true,
                            },
                            {
                                Header: 'Roles',
                                headerClassName: 'header_cell_string',
                                sortable: false,
                                accessor: 'jobtypeFilter',
                                dropdownFilter: true,
                                dropdownFilterData: this.state.allJobtypes,
                                Cell: item => <>{this.printRolesForTable(item.original.jobtypes)}</>,
                                className: 'cell_string',
                            },
                        ]}
                        service={EmployeesControlServices.getAllEmployees}
                        createHook={TOTAL_ACCESS ? this.handleOpen : undefined}
                        getTableFetchHook={this.getTableHook}
                        excel={EXCEL_ACCESS}
                        upload={TOTAL_ACCESS}
                        loading={true}
                        toStore={'employees_control'}
                        additionalButtons={
                            TOTAL_ACCESS
                                ? [
                                      {
                                          tooltipText: 'Asignación masiva',
                                          icon: <PlaylistAddCheck />,
                                          onClick: () => this.handleToggleMassiveModal(),
                                      },
                                      {
                                          tooltipText: 'Recargar empleados del ERP',
                                          icon: <EmployeesFromErpIcon />,
                                          onClick: () => this.handleERPDownload(),
                                      },
                                  ]
                                : undefined
                        }
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: any) => ({
                                onClick: (evt: any) => {
                                    if (TOTAL_ACCESS) {
                                        if (evt.target.type !== 'checkbox') this.handleChoose(rowInfo.original);
                                        else this.handleSelect(rowInfo.original);
                                    }
                                },
                                style: {cursor: 'pointer'},
                            }),
                        }}
                    />
                </TableWrap>
            );
        return null;
    }
}

export const EmployeesControl = connect<SProps, {}, {}, AppStoreState>(state => ({
    userData: LoginSelectors.getUserData(state),
    tableFilters: PaginatedTableSelectors.getFilters('employees_control', state),
    employeeCodeModeParam: ParametersSelectors.getValueOfParameter(state, ParameterCPN.EMPLOYEE_CODE_MODE),
}))(EmployeesControlBase);

const TableWrap = styled.div`
    width: 92%;
    height: 100%;
    margin: auto;
`;

/****  BADGE */

type BadgeProps = {
    display: boolean;
    content?: string;
    margin?: string;
    classes?: any;
    color?: 'primary' | 'secondary' | 'default' | 'error';
    style?: {[k: string]: any};
};

const styles = {
    badge: {
        top: 14,
        padding: 3,
        right: -15,
        width: 20,
        height: 20,
    },
};

const CustomBadgeBase: React.FunctionComponent<BadgeProps> = props => {
    return (
        <div
            style={{
                margin: props.margin,
            }}
        >
            <Badge
                component={'div'}
                // tslint:disable-next-line
                title="Selección TOTAL, selecciona todos los empleados con los criteros de los filtros"
                classes={{badge: props.classes.badge}}
                invisible={!props.display}
                badgeContent={props.content || ''}
                color={props.color || 'primary'}
            >
                {props.children}
            </Badge>
        </div>
    );
};

const CustomBadge = withStyles(styles)(CustomBadgeBase);
