import {ajv} from 'src/utils/ajv';

import {areaScheme} from './schemes/area';
import {ptableScheme} from './schemes/ptable';

const areaPtablesResScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['area', 'ptables'],
  properties: {
    area: areaScheme,
    ptables: {type: 'array', items: ptableScheme},
  },
};

export const areaPtablesResSchemeValidate = ajv.compile(areaPtablesResScheme);
