import * as React from 'react';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {AlertServices} from './store/alert.services';
import {ActivitiesTaskRegisteredBetweenTimeIntervalType} from './store/types';
import {AlertTaskRegisteredBetweenTimeIntervalModal} from './AlertTaskRegisteredBetweenTimeIntervalModal';

export interface Props {
    laneId: string;
    employeeId: string;
}

type State = {
    openAlertTaskRegisteredBetweenTimeInterval: boolean;
    taskRegisteredBetweenTimeInterval: ActivitiesTaskRegisteredBetweenTimeIntervalType[] | undefined;
    showConfirmCheckAlert: boolean;
    idToCheck: string;
};

const initialState: Readonly<State> = {
    openAlertTaskRegisteredBetweenTimeInterval: false,
    taskRegisteredBetweenTimeInterval: undefined,
    showConfirmCheckAlert: false,
    idToCheck: '',
};

export class AlertTaskRegisteredBetweenTimeInterval extends React.Component<Props, State> {
    readonly state = initialState;

    async componentDidMount() {
        this.fetchAlertTaskRegisteredBetweenTimeInterval();
        setInterval(() => {
            if (this.state.openAlertTaskRegisteredBetweenTimeInterval === false) {
                this.fetchAlertTaskRegisteredBetweenTimeInterval();
            }
        }, 120000);
    }

    private checkAlertTaskRegisteredBetweenTimeInterval = (idToCheck: string) => {
        if (this.state.taskRegisteredBetweenTimeInterval && this.state.taskRegisteredBetweenTimeInterval.length) {
            fetchWithFeedback(AlertServices.checkedTaskRegisteredBetweenTimeInterval(idToCheck, this.props.employeeId)); // !**

            const activitiesWithAlerts = this.state.taskRegisteredBetweenTimeInterval.filter(item => item.id !== idToCheck);

            this.setState({
                taskRegisteredBetweenTimeInterval: activitiesWithAlerts,
            });
        }
    };

    private fetchAlertTaskRegisteredBetweenTimeInterval = async () => {
        const isAdmin = IsInRuleset(['ADMIN']) ? 1 : 0;
        const isAllowed = IsInRuleset(['ALERTS_TASK_REGISTERED_BETWEEN_NOT_USUAL']) ? 1 : 0;

        if (this.props.laneId && (isAdmin || isAllowed)) {
            const alertTaskRegisteredBetweenTimeInterval = await fetchWithFeedback(AlertServices.taskRegisteredBetweenTimeInterval(this.props.laneId, isAdmin));
            if (alertTaskRegisteredBetweenTimeInterval) {
                this.setState({
                    taskRegisteredBetweenTimeInterval: alertTaskRegisteredBetweenTimeInterval.activitiesRegisteredBetweenTimeInterval,
                });

                if (alertTaskRegisteredBetweenTimeInterval.activitiesRegisteredBetweenTimeInterval.length !== 0 && !this.state.openAlertTaskRegisteredBetweenTimeInterval)
                    this.toggleAlertTaskRegisteredBetweenTimeInterval();
            }
        }
    };

    private toggleAlertTaskRegisteredBetweenTimeInterval = () => {
        this.setState({
            openAlertTaskRegisteredBetweenTimeInterval: !this.state.openAlertTaskRegisteredBetweenTimeInterval,
        });
    };

    render() {
        return (
            <>
                <AlertTaskRegisteredBetweenTimeIntervalModal
                    openAlertTaskRegisteredBetweenTimeInterval={this.state.openAlertTaskRegisteredBetweenTimeInterval}
                    taskRegisteredBetweenTimeInterval={this.state.taskRegisteredBetweenTimeInterval}
                    handleClose={() => this.setState({openAlertTaskRegisteredBetweenTimeInterval: false})}
                    checkAlertTaskRegisteredBetweenTimeInterval={this.checkAlertTaskRegisteredBetweenTimeInterval}
                />
            </>
        );
    }
}
