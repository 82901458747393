import {ParameterControlUnit} from 'src/app/administration/parametersControl/store/parametersControl.types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'parameters';

export const ParametersControlServices = {
    get: () => guardedFetcher.get<{parameters: ParameterControlUnit[]}>(prefix),
    getAppParameters: () => guardedFetcher.get<{parameters: ParameterControlUnit[]}>(`${prefix}/app`),
    geInternalParameters: () => guardedFetcher.get<{parameters: ParameterControlUnit[]}>(`${prefix}/internal`),
    save: ({parameterId, value}: {parameterId: string; value: string}) => guardedFetcher.patch(`${prefix}/${parameterId}`, {value}),
};
