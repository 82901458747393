import {actionTypes, Map} from './types';

export interface MapState {
    maps: Map[];
    isLoading: boolean;
}

const initState: MapState = {
    maps: [],
    isLoading: false,
};

export default (state: MapState = initState, action: {type: actionTypes; playload?: Map[]; id?: string; map: Map}): MapState => {
    switch (action.type) {
        case actionTypes.FETCH_MAPS + '_START':
            return {...state, isLoading: true};
        case actionTypes.FETCH_MAPS + '_SUCCESS':
            return {...state, isLoading: false, maps: action.playload || []};
        case actionTypes.FETCH_MAPS + '_FAILURE':
            return {...state, isLoading: false};
        case actionTypes.FETCH_MAP + '_START':
            return {...state, isLoading: true};
        case actionTypes.FETCH_MAP + '_SUCCESS':
            if (action.id)
                return {
                    ...state,
                    isLoading: false,
                    maps: {...state.maps, [action.id]: action.map},
                };
            else return state;
        case actionTypes.FETCH_MAP + '_FAILURE':
            return {...state, isLoading: false};

        default:
            return state;
    }
};

export const getMaps = (state: MapState): Map[] => state.maps;
export const getIsLoading = (state: MapState): boolean => state.isLoading;
