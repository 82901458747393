import {push} from 'connected-react-router';
import * as React from 'react';
import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import styled from 'styled-components';

import {LoginThunks} from 'src/app/login/store/login.thunks';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Help from '@material-ui/icons/Help';
import {AppStoreState} from 'src/store';
import {findInitialParameter} from 'src/global/initalParameters/initialParameters';
import {ParameterCPN} from 'src/types/app_types';

type SProps = {
    userId: string;
};

type DProps = {
    login(data: {username: string; password: string}, cb: () => void): void;
};

type State = {
    username: string;
    password: string;
    usernameError: boolean;
    passwordError: boolean;
    clickedLogin: boolean;
};
const initialState: State = {
    username: '',
    usernameError: false,
    password: '',
    passwordError: false,
    clickedLogin: false,
};

class LoginBase extends React.Component<SProps & DProps, State> {
    readonly state = initialState;
    render() {
        return (
            <LoginContainer>
                <Card style={{width: 330, height: 270, margin: 'auto'}}>
                    <CardContent
                        style={{
                            height: '70%',
                            paddingBottom: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                        }}
                    >
                        <Typography component={'div'} variant={'body1'} style={{textAlign: 'center', textTransform: 'uppercase'}}>
                            {findInitialParameter(ParameterCPN.CUSTOMER_NAME)?.value}
                        </Typography>
                        <TextField
                            style={{
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginTop: 20,
                                marginBottom: 15,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title={'El nombre de usuario debe ser entre 5 y 50 carácteres.'} placement="top">
                                            <Help />
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                            label="Nombre de usuario"
                            autoFocus={true}
                            error={this.state.usernameError}
                            name="username"
                            variant="outlined"
                            onChange={this.handleChange('username')}
                            value={this.state.username}
                        />
                        <TextField
                            style={{marginLeft: 'auto', marginRight: 'auto'}}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title={'La contraseña de usuario debe ser entre 8 y 50 carácteres.'} placement="top">
                                            <Help />
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                            }}
                            label="Contaseña"
                            name="password"
                            error={this.state.passwordError}
                            // required={true}
                            type="password"
                            variant="outlined"
                            onChange={this.handleChange('password')}
                            onKeyPress={ev => {
                                if (ev.key === 'Enter') {
                                    this.handleLogin();
                                    ev.preventDefault();
                                }
                            }}
                            value={this.state.password}
                        />
                    </CardContent>
                    <CardActions style={{height: '30%'}}>
                        <Button
                            variant="outlined"
                            style={{
                                width: 100,
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: 'auto',
                            }}
                            onClick={this.handleLogin}
                        >
                            {this.state.clickedLogin ? <CircularProgress color="secondary" style={{height: 24, width: 24}} /> : 'Entrar'}
                        </Button>
                    </CardActions>
                </Card>
            </LoginContainer>
        );
    }

    private handleChange: HandleNamedChange<State> = name => e => this.setState({[name]: e.target.value} as Pick<State, keyof State>);
    private offClickedLogin = () => this.setState({clickedLogin: false});
    private handleLogin = () => {
        const {username, password} = this.state;
        let uvalid = false;
        let passvalid = false;
        if (password.length >= 8 && password.length < 50) passvalid = true;
        if (username.length >= 4 && username.length < 50) uvalid = true;
        if (uvalid && passvalid) this.props.login({username: username.trim(), password: password.trim()}, this.offClickedLogin);
        this.setState({
            usernameError: !uvalid,
            passwordError: !passvalid,
            clickedLogin: true,
        });
    };
}

const md2p = (dispatch: ThunkDispatch<AppStoreState, null, AnyAction>) => ({
    login: (data: {username: string; password: string}, cb: () => void) => dispatch(LoginThunks.login(data, cb)),
    push,
});
export const Login = connect<SProps, DProps, {}, AppStoreState>(
    state => ({
        userId: state.login.userId,
    }),
    md2p,
)(LoginBase);

const LoginContainer = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
`;
