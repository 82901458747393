import {merchandiseScheme} from './merchandise';
import {providerScheme} from './provider';

export const dumpScheme = {
  additionalProperties: false,
  type: 'object',
  required: [
    'id',
    'merchandise',
    'provider',
    'dumped_at',
    'arrival_at',
    'total_kg',
    'dumped_kg',
    'dumped_boxes',
    'total_boxes',
    'state',
    'batches',
    'allowed_customers',
    'quality_protocols',
  ],
  properties: {
    id: {type: 'string'},
    batches: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          erp_batch_code: {type: 'string'},
          boxes: {oneOf: [{type: 'string'}, {type: 'number'}]},
        },
      },
    },
    total_boxes: {type: 'number', minimum: 0},
    total_kg: {type: 'number', minimum: 0},
    merchandise: merchandiseScheme,
    provider: {oneOf: [providerScheme, {type: 'null'}]},
    dumped_kg: {type: 'number', minimum: 0},
    dumped_boxes: {type: 'number', minimum: 0},
    arrival_at: {type: 'string'},
    dumped_at: {type: 'string'},
    state: {
      type: 'string',
      // oneOf: ['dumped', 'ongoing', 'batched'],
    },
    allowed_customers: {
      oneOf: [{type: 'array', items: {type: 'string'}}, {type: 'null'}],
    },
    quality_protocols: {
      oneOf: [{type: 'string'}, {type: 'null'}],
    },
  },
};
