import differenceInMinutes from 'date-fns/difference_in_minutes';
import * as React from 'react';
import styled from 'styled-components';

import {EmployeesServices} from 'src/app/information/employees/store/services';
import {EmployeeReportUnit} from 'src/app/information/employees/store/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {EmployeeTaskSelectActions} from 'src/app/presencemanagement/store';
import {connect} from 'react-redux';
import {push, ConnectedRouterProps, RouterAction} from 'connected-react-router';
import {
    Tooltip, FormControlLabel, Switch,
} from '@material-ui/core';

import {PresenceManagementModal} from 'src/app/presencemanagement/PresenceManagementModal';
import {ParametersSelectors} from 'src/utils/Parameters/selectors';
import {AppStoreState} from 'src/store';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {ParameterCPN} from 'src/types/app_types';
import { ShiftsMapService } from 'src/shared/components/services';
import { fetchWithFeedback } from 'src/utils/fetcherValidate';
//import { OCard } from 'src/shared/style';

// const EXCEL_ACCESS = IsInRuleset('REPORTS_WORKSHIFT_TIMES_EXCEL');
// const VIEW_ACCESS = IsInRuleset('REPORTS_WORKSHIFT_TIMES_VIEW');

type DProps = {
    push(path: string, state?: any): RouterAction;
    selectEmployeeTask(employeeId: string, employeeName: string, employeeSurname: string, workShift: string, centerId: string): void;
};
type SProps = {
    presenceManagementActive: ReturnType<typeof ParametersSelectors.getValuePresenceManagement>;
    employeeCodeModeParam?: string;
};

type State = {
    showPresenceManagement: boolean;
    showGrouped:boolean;
    shifts : Array<{
        id:string;
        name:string;
    }>;
    shiftsMap : Map<string, string>;
    shiftsLoaded : boolean;
}

const initialState : State = {
    showPresenceManagement: false,
    showGrouped:true,
    shifts: [],
    shiftsMap: new Map<string, string>(),
    shiftsLoaded : false
};

export class EmployeesBase extends React.Component<ConnectedRouterProps & DProps & SProps, State> {
    state = initialState;
    columnFormatGrouped : ColumnType<EmployeeReportUnit> = [];
    columnFormat : ColumnType<EmployeeReportUnit> = [];

    private fetchShiftsMap = async () => {
        const shifts : Array<{
            id:string;
            name:string;
        }> = await fetchWithFeedback(ShiftsMapService(), {accessor: 'shifts'});
        this.setState({shifts});
        shifts.forEach(v => this.state.shiftsMap.set(v.id, v.name));
    }

    componentDidMount() {
        this.fetchShiftsMap().then(() => {
            this.columnFormat = this.getColumnFormat();
            this.columnFormatGrouped = this.getColumnFormatGrouped();
            this.setState({shiftsLoaded : true});
        });
    }

    getContentCell(props: any, rowInfo: any, value: string) {
        const tooltipTextPresenceManagement = 'GESTIÓN DE MARCAJES DE EMPLEADO';

        return props.presenceManagementActive === '1' && IsInRuleset(['PRESENCEMANAGEMENT_TOTAL', 'PRESENCEMANAGEMENT_VIEW']) ? (
            <Tooltip title={tooltipTextPresenceManagement} placement="top">
                <div
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        props.selectEmployeeTask(
                            rowInfo.original.employee_id,
                            rowInfo.original.employee_name,
                            rowInfo.original.employee_surname,
                            rowInfo.original.workShift,
                            rowInfo.original.center_id,
                        );
                        this.setState({
                            showPresenceManagement: true,
                        });
                    }}
                >
                    {value}
                </div>
            </Tooltip>
        ) : (
            <div>{value}</div>
        );
    }

    showErpEmployeeCode = this.props.employeeCodeModeParam === 'ERP_MODE' ? true : false;
    showInternalEmployeeCode = this.props.employeeCodeModeParam === 'INTERNAL_MODE' ? true : false;

    tooltipTextPresenceManagement = 'GESTIÓN DE MARCAJES DE EMPLEADO';



    private getColumnFormatGrouped = () : ColumnType<EmployeeReportUnit> => {
        return [
            {
                Header: 'Jornada',
                headerClassName: 'header_cell_string',
                accessor: 'workShift',
                filterable: true,
                dateFormat: true,
                daterange: true,
                width: 260,
                className: 'cell_string',
            },
            {
                Header: 'Turno',
                headerClassName: 'header_cell_string',
                //Cell: item => <>{item.original.area_shift === 1 ? 'MAÑANA' : 'TARDE'}</>,
                Cell: item => <>{this.state.shiftsMap.get(item.original.area_shift)}</>,
                dropdownFilter: true,
                dropdownFilterData: this.state.shifts,
                /*dropdownFilterData: [
                    {id: '1', name: 'MAÑANA'},
                    {id: '2', name: 'TARDE'},
                ],*/
                id: 'area_shift',
                className: 'cell_string',
            },
            {
                Header: 'EMP.Nombre',
                headerClassName: 'header_cell_string',
                accessor: 'employee_name',
                className: 'cell_string',
                filterable: true,
                Cell: rowInfo => this.getContentCell(this.props, rowInfo, rowInfo.original.employee_name),
            },
            {
                Header: 'EMP.Apellidos',
                headerClassName: 'header_cell_string',
                accessor: 'employee_surname',
                className: 'cell_string',
                filterable: true,
                Cell: rowInfo => this.getContentCell(this.props, rowInfo, rowInfo.original.employee_surname),
            },
            {
                Header: 'Centro',
                headerClassName: 'header_cell_string',
                accessor: 'center_name',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'Empezada En',
                headerClassName: 'header_cell_string',
                accessor: 'start_tasks',
                dateTimeFormat: true,
                className: 'cell_string',
                sortable: true,
            },
            {
                Header: 'Finalizada En',
                headerClassName: 'header_cell_string',
                accessor: 'end_tasks',
                dateTimeFormat: true,
                className: 'cell_string',
                sortable: true,
            },
            {
                id: 'duration_total',
                headerClassName: 'header_cell_string',
                Header: 'Duración',
                sortable: true,
                Cell: props => {

                    let timeToHHMM = '00:00';

                    if (props.original.duration_total > 0) {
                        let totalSeconds = props.original.duration_total;
                        const hours = Math.floor(totalSeconds / 3600);
                        totalSeconds %= 3600;
                        const minutes = Math.floor(totalSeconds / 60);
                        const digitsHours = (hours < 10) ? '0'+ hours : hours;
                        const digitsMinutes = (minutes < 10) ? '0'+ minutes : minutes;
                        timeToHHMM = digitsHours + ':' + digitsMinutes;
                    }

                    return props.original.duration_total > 0 ?  timeToHHMM + ' H' : '';
                },
                className: 'cell_number',
            },
        ];
    }

    private getColumnFormat = () : ColumnType<EmployeeReportUnit> => {
        return [
            {
                Header: 'Jornada',
                headerClassName: 'header_cell_string',
                accessor: 'workShift',
                filterable: true,
                dateFormat: true,
                daterange: true,
                width: 260,
                className: 'cell_string',
            },
            {
                Header: 'Turno',
                headerClassName: 'header_cell_string',
                Cell: item => <>{this.state.shiftsMap.get(item.original.area_shift)}</>,
                dropdownFilter: true,
                dropdownFilterData: this.state.shifts,
                id: 'area_shift',
                className: 'cell_string',
            },
            {
                Header: 'EMP.Nombre',
                headerClassName: 'header_cell_string',
                accessor: 'employee_name',
                className: 'cell_string',
                filterable: true,
                Cell: rowInfo => this.getContentCell(this.props, rowInfo, rowInfo.original.employee_name),
            },
            {
                Header: 'EMP.Apellidos',
                headerClassName: 'header_cell_string',
                accessor: 'employee_surname',
                className: 'cell_string',
                filterable: true,
                Cell: rowInfo => this.getContentCell(this.props, rowInfo, rowInfo.original.employee_surname),
            },
            {
                Header: 'EMP.Código',
                headerClassName: 'header_cell_string',
                accessor: 'code',
                show: this.showInternalEmployeeCode,
                className: 'cell_string',
                filterable: true,
                Cell: rowInfo => this.getContentCell(this.props, rowInfo, rowInfo.original.code),
            },
            {
                Header: 'EMP.Código',
                headerClassName: 'header_cell_string',
                accessor: 'erp_employee_code',
                show: this.showErpEmployeeCode,
                className: 'cell_string',
                filterable: true,
                Cell: rowInfo => this.getContentCell(this.props, rowInfo, rowInfo.original.erp_employee_code),
            },
            {
                Header: 'Área',
                headerClassName: 'header_cell_string',
                accessor: 'area_name',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'Centro',
                headerClassName: 'header_cell_string',
                accessor: 'center_name',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'Tarea',
                headerClassName: 'header_cell_string',
                accessor: 'task_name',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'Empezada En',
                headerClassName: 'header_cell_string',
                accessor: 'created_at',
                dateTimeFormat: true,
                className: 'cell_string',
                sortable: true,
            },
            {
                Header: 'Finalizada En',
                headerClassName: 'header_cell_string',
                accessor: 'finished_at',
                dateTimeFormat: true,
                className: 'cell_string',
            },
            {
                id: 'duration',
                headerClassName: 'header_cell_string',
                Header: 'Duración',
                Cell: props => {
                    const startDate = new Date(props.original.created_at);
                    const now = new Date();
                    const time = differenceInMinutes(now, startDate);
                    const coerce = props.original.duration !== null ? +props.original.duration : undefined;
                    return <>{typeof coerce === 'number' ? customFormatNumber(Number((coerce / 60).toFixed(0))) : customFormatNumber(time)} m</>;
                },
                className: 'cell_number',
            },
        ];
    }

    private hidePresenceManagementModal = () => {
        this.setState({
            showPresenceManagement: false,
        });
    };

    private handleGroupedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        this.setState({showGrouped: isChecked});
    }

    render() {
        return IsInRuleset(['REPORTS_REGISTERED_TASKS_VIEW', 'REPORTS_REGISTERED_TASKS_EXCEL']) ? (
            <>
                <PresenceManagementModal
                    //history={false}
                    open={this.state.showPresenceManagement}
                    hidePresenceManagementModal={this.hidePresenceManagementModal}
                />
                {/* <OCard width={'100%'} height={'10%'} contentHeight={'10%'}> */}
                   <div style={divStyleChecks}>
                        <FormControlLabel
                            style={{width: 200, height: 20, marginTop: 0}}
                            control={<Switch
                                checked={this.state.showGrouped}
                                onChange={this.handleGroupedChange}
                            />}
                            label="Ver agrupados"
                        />
                   </div>

                {/* </OCard> */}
                {this.state.shiftsLoaded && this.state.showGrouped && (
                <TableComponentWrap>
                    <PaginatedTableComponent
                        service={EmployeesServices.getGroupedPaginated}
                        columnFormat={this.columnFormatGrouped}
                        defaultPageSize={20}
                        loading={true}
                        twoWeeksFilter={true}
                        excel={IsInRuleset('REPORTS_REGISTERED_TASKS_EXCEL')}
                    />
                    </TableComponentWrap>
                )}
                {this.state.shiftsLoaded && !this.state.showGrouped && (
                    <TableComponentWrap>
                    <PaginatedTableComponent
                        service={EmployeesServices.getPaginated}
                        columnFormat={this.columnFormat}
                        defaultPageSize={20}
                        loading={true}
                        twoWeeksFilter={true}
                        excel={IsInRuleset('REPORTS_REGISTERED_TASKS_EXCEL')}
                    />
                    </TableComponentWrap>
                )}
            </>
        ) : null;
    }
}

const divStyleChecks = {
    padding: '5px',
    margin: '5px',
    height: '20px',
    paddingLeft: '30px',
    backgroundColor: 'white',
};

const TableComponentWrap = styled.div`
    height: calc(100% - 40px);
`;

const selectEmployeeTask = (employeeId: string, employeeName: string, employeeSurname: string, workShift: string, centerId: string) =>
    EmployeeTaskSelectActions.select(employeeId, workShift, employeeName + ' ' + employeeSurname, centerId);

export const Employees = connect<SProps, DProps, {}, AppStoreState>(
    state => ({
        presenceManagementActive: ParametersSelectors.getValuePresenceManagement(state),
        employeeCodeModeParam: ParametersSelectors.getValueOfParameter(state, ParameterCPN.EMPLOYEE_CODE_MODE),
    }),
    {
        push,
        selectEmployeeTask,
    },
)(EmployeesBase);
