import {ajv} from 'src/utils/ajv';
import {areaScheme} from './schemes/area';
import {dumpScheme} from './schemes/dump';

const areaDumpResScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['area', 'dump'],
  properties: {
    area: areaScheme,
    dump: {
      oneOf: [dumpScheme, {type: 'null'}],
    },
  },
};

const precompile = ajv.compile(areaDumpResScheme);
export const areaDumpSchemeResValidate = precompile;
