import localForage from 'localforage';
import {ParameterCPN} from 'src/types/app_types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {SharedServices} from 'src/shared/shared.services';
import {fillStorage} from 'src/utils/localforage';

export type InitialParameter = {
    name: ParameterCPN;
    value: string;
};

export type InitialParameters = {
    parameters: InitialParameter[];
};

export let INITIAL_PARAMETERS: InitialParameters = {
    parameters: [],
};

export const FetchInitialParameters = async () => {
    let set;

    set = await fetchWithFeedback(SharedServices.getInitialParameters());
    await initialParametersInstance.clear();
    await fillStorage(initialParametersInstance, set);

    INITIAL_PARAMETERS.parameters = [...set.initialParameters];

    return Array.isArray(set) ? false : true;
};

export const initialParametersInstance = localForage.createInstance({
    name: 'initial-parameters-store',
    version: 1.0,
    storeName: 'initial-parameters-store',
});

export const findInitialParameter = (parameterName: string): InitialParameter | undefined => {
    return INITIAL_PARAMETERS.parameters.find((param: InitialParameter) => param.name === parameterName);
};
