import * as React from 'react';
import {Typography} from '@material-ui/core';
import {TableModal} from 'src/shared/components/Modal';
import {CustomButton} from 'src/shared/style';
//import {IsInRuleset} from 'src/global/authorization/grantSet';
//import {fetchWithFeedback} from 'src/utils/fetcherValidate';
//import {AlertServices} from './store/alert.services';
import {ActivitiesTaskRegisteredBetweenTimeIntervalType} from './store/types';
import CheckIcon from '@material-ui/icons/CheckCircle';
import {CustomDialog} from 'src/shared/components/CustomDialog';

export interface Props {
    openAlertTaskRegisteredBetweenTimeInterval: boolean;
    taskRegisteredBetweenTimeInterval: ActivitiesTaskRegisteredBetweenTimeIntervalType[] | undefined;
    handleClose(): void;
    checkAlertTaskRegisteredBetweenTimeInterval(idCheck: string): void;
}

type State = {
    showConfirmCheckAlert: boolean;
    idToCheck: string;
};

const initialState: Readonly<State> = {
    showConfirmCheckAlert: false,
    idToCheck: '',
};

const divStyleAlert = {
    padding: '20px',
    margin: '20px',
    backgroundColor: '#e4b9b9',
};

export class AlertTaskRegisteredBetweenTimeIntervalModal extends React.Component<Props, State> {
    readonly state = initialState;

    render() {
        return (
            <>
                <CustomDialog
                    title="Confirmación"
                    contentText="¿Está seguro que quiere marcar como revisada la alerta?"
                    handleClose={() => this.setState({showConfirmCheckAlert: false})}
                    open={this.state.showConfirmCheckAlert}
                    handle={() => {
                        this.props.checkAlertTaskRegisteredBetweenTimeInterval(this.state.idToCheck);
                        this.setState({showConfirmCheckAlert: false});
                    }}
                />
                <TableModal
                    open={this.props.openAlertTaskRegisteredBetweenTimeInterval}
                    handleClose={() => this.props.handleClose()}
                    title={'Actividad registrada en horario no usual'}
                >
                    <>
                        {this.props.taskRegisteredBetweenTimeInterval && this.props.taskRegisteredBetweenTimeInterval.length ? (
                            this.props.taskRegisteredBetweenTimeInterval.map((taskRegisteredBetweenTimeInterval: any) => (
                                <div key={taskRegisteredBetweenTimeInterval.id} style={divStyleAlert}>
                                    <p>
                                        {taskRegisteredBetweenTimeInterval.name} {taskRegisteredBetweenTimeInterval.surname} ( {taskRegisteredBetweenTimeInterval.identification} )
                                        ( {taskRegisteredBetweenTimeInterval.code} )
                                    </p>
                                    <p>
                                        {taskRegisteredBetweenTimeInterval.taskName} [ {taskRegisteredBetweenTimeInterval.areaName} - {taskRegisteredBetweenTimeInterval.centerName}{' '}
                                        ]
                                    </p>
                                    <p>
                                        Hora de inicio de la tarea: {taskRegisteredBetweenTimeInterval.started_at} ( {taskRegisteredBetweenTimeInterval.duration_control} min )
                                    </p>
                                    <CustomButton
                                        onClick={() =>
                                            this.setState({
                                                showConfirmCheckAlert: true,
                                                idToCheck: taskRegisteredBetweenTimeInterval.id,
                                            })
                                        }
                                        width={'100%'}
                                        margin="unset"
                                    >
                                        Visto <CheckIcon />
                                    </CustomButton>
                                </div>
                            ))
                        ) : (
                            <Typography variant="body1">No hay tareas en horario no usual</Typography>
                        )}
                    </>
                </TableModal>
            </>
        );
    }
}
