import {ChartData, ChartOptions} from 'chart.js';
import React from 'react';
import {Bar} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {LanePersonnelKgDumpedWeekType} from 'src/app/lanemanager/charts/store/types';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {Spinner} from 'src/app/global/spinner';

const chart: ChartData = {
    labels: ['8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00'],
    datasets: [
        {
            label: 'Trabajadores',
            backgroundColor: '#82a1bf',
            // borderColor: '#5c4c33',
            data: [15, 15, 22, 11, 11, 25, 27, 0, 25, 15, 15],
            fill: true,
        },
    ],
};
const initialState = {chart};

type State = {
    chart: ChartData;
};
type Props = {
    chartData: LanePersonnelKgDumpedWeekType;
    isLoading: boolean;
};
export class PersonnelHourChart extends React.Component<Props, State> {
    readonly state = initialState;
    static getDerivedStateFromProps(props: Props) {
        const datasets = [
            {
                yAxisID: 'kg',
                label: 'kilos mañana',
                borderColor: '#005986',
                data: props.chartData.PersonnelKgsDumpedDays1,
                fill: false,
                // Changes this dataset to become a line
                type: 'line',
            },
            {
                yAxisID: 'kg',
                label: 'kilos tarde',
                borderColor: '#860200',
                data: props.chartData.PersonnelKgsDumpedDays2,
                fill: false,
                // Changes this dataset to become a line
                type: 'line',
            },
            {
                label: 'Trabajadores mañana',
                borderColor: '#4f81bd',
                backgroundColor: '#4f81bd',
                data: props.chartData.PersonnelDays1,
                fill: true,
            },
            {
                label: 'Trabajadores tarde',
                borderColor: '#eb4034',
                backgroundColor: '#eb4034',
                data: props.chartData.PersonnelDays2,
                fill: false,
            },
        ] as any;

        // if (props.chartData.kg && props.ch) {

        // }
        return {
            chart: {
                labels: props.chartData.days,
                datasets,
            },
        };
    }
    render() {
        if (this.props.isLoading) {
            return <Spinner isLoading={this.props.isLoading} position={'relative'} />;
        }
        return <Bar data={this.state.chart} options={options} />;
    }
}

/***
 * CHART OPTIONS
 */
const options: ChartOptions = {
    responsive: true,
    title: {
        display: false,
        text: 'Personal trabajando en personas / hora',
    },
    tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
            label: tooltipItem => {
                return tooltipItem && tooltipItem.yLabel ? customFormatNumber(Number(tooltipItem.yLabel)) : '';
            },
        },
    },
    hover: {
        mode: 'nearest',
        intersect: true,
    },
    maintainAspectRatio: false,
    scales: {
        yAxes: [
            {
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Trabajadores',
                },
                ticks: {
                    callback: value => {
                        // you can add your own method here (just an example)
                        return customFormatNumber(Number(value));
                    },
                },
            },
            {
                display: true,
                id: 'kg',
                position: 'right',
                scaleLabel: {
                    display: true,
                    labelString: 'Kilogramos',
                },
                ticks: {
                    callback: value => {
                        // you can add your own method here (just an example)
                        return customFormatNumber(Number(value));
                    },
                },
            },
        ],
    },
    plugins: {
        datalabels: {
            font: {
                size: 0, // Para que no muestre los valores en la gráfica
            },
        },
    },
};
