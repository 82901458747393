import {Reducer} from 'redux';

import {AreaBatchesRes, LaneActionInterface, LaneBatchState} from '../types';
import {LaneActionTypes} from '../types/lane.action-types';

export const defaultState = {
  // provider_id: '1557/123',
  // batch_id: '545/34234',
  // merchandise: 'tomate',
  // total_kg: 500,
  // arrival_at: new Date().toLocaleString(),
  // state_batch: 'dumped',
  // allowed_customers: ['LIDL'],
  // quality_protocols: 'GLOBAL GAP',
};

export const laneBatchesReducer: Reducer<LaneBatchState, LaneActionInterface> = (
  state = defaultState as any,
  {type, payload},
): LaneBatchState => {
  if (!state) return state;
  switch (type) {
    case LaneActionTypes.setBatchData: {
      const {batches} = payload as AreaBatchesRes;
      const active = batches.find(b => b.state_batch === 'ongoing');
      let batchData;
      if (active)
        batchData = {
          batch_id: active.id,
          provider_id: active.provider.id,
          arrival_at: active.dumped_at,
          erp_batch_code: active.erp_batch_code,
          merchandise: active.merchandise.name,
          state_batch: active.state_batch,
          total_kg: active.total_kg,
          allowed_customers: active.allowed_customers,
          quality_protocols: active.quality_protocols,
        } as LaneBatchState;
      else batchData = {} as LaneBatchState;
      return {...batchData};
    }
    case LaneActionTypes.clearBatchData: {
      return {} as LaneBatchState;
    }
    default:
      return state;
  }
};
