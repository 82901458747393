import styled from 'styled-components';

export const WrapForm = styled('div')<{margin?: string}>`
  margin: ${({margin}) => margin || '0px'};
  /* width: 100%; */
  height: 92%;
  display: flex;
  flex-direction: column;
`;

export const WrapSaveButton = styled('div')<{grow?: number}>`
  display: flex;
  flex-direction: row;
  flex-grow: ${({grow}) => grow};
  align-items: flex-end;
`;
