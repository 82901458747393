import * as React from 'react';

import {BoxesServices} from 'src/app/information/boxes/store/services';
import {Box} from 'src/app/information/boxes/store/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';

export const Boxes: React.FunctionComponent = () => {
    const columns: ColumnType<Box> = [
        {
            Header: 'Área',
            headerClassName: 'header_cell_string',
            accessor: 'areaName',
            filterable: true,
            className: 'cell_string',
        },
        {
            Header: 'Código de caja',
            headerClassName: 'header_cell_string',
            accessor: 'boxBarcode',
            className: 'cell_string',
            filterable: true,
            sortable: false,
        },
        {
            Header: 'Cód. de origen',
            headerClassName: 'header_cell_string',
            accessor: 'batchCode',
            className: 'cell_string',
            filterable: true,
            sortable: false,
        },
        {
            Header: 'Código de UL',
            headerClassName: 'header_cell_string',
            accessor: 'logisticunitBarcode',
            className: 'cell_string',
            filterable: true,
            sortable: false,
        },
        {
            Header: 'Código de palé',
            headerClassName: 'header_cell_string',
            accessor: 'palletBarcode',
            className: 'cell_string',
            filterable: true,
            sortable: false,
        },
        {
            Header: 'Emp.Nombre',
            headerClassName: 'header_cell_string',
            accessor: 'employeeName',
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'Emp.Apellidos',
            headerClassName: 'header_cell_string',
            accessor: 'employeeSurname',
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'Emp.Código',
            headerClassName: 'header_cell_string',
            accessor: 'employeeCode',
            sortable: false,
            className: 'cell_string',
            filterable: false,
        },

        {
            Header: 'Finalizada',
            headerClassName: 'header_cell_string',
            accessor: 'finished_at',
            dateTimeFormat: true,
            className: 'cell_string',
        },
        {
            Header: 'Paletizada',
            headerClassName: 'header_cell_string',
            accessor: 'palletized_at',
            dateTimeFormat: true,
            className: 'cell_string',
        },
    ];
    return IsInRuleset('REPORTS_BOXES_VIEW') ? (
        <PaginatedTableComponent service={BoxesServices.getAllPaginated} columnFormat={columns} defaultPageSize={20} loading={true} />
    ) : null;
};
