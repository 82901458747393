import React from 'react';
import {Table, TableRow, TableCell, TableBody} from '@material-ui/core';
import {format} from 'date-fns';
import es from 'date-fns/locale/es';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';

export interface Props {
    infoCard: any;
}

export class ResumeDataCard extends React.Component<Props> {
    getDateFormat(dateString: string) {
        if (dateString === null) {
            return '';
        }
        return format(new Date(dateString), '(DD/MM/YYYY) HH:mm:ss', {locale: es});
    }

    render() {
        return (
            <div
                style={{
                    backgroundColor: '#EDEDED',
                }}
            >
                <div>
                    <div
                        style={{
                            backgroundColor: this.props.infoCard.isTotal === true ? '#FFC000' : '#DCE6F1',
                            textAlign: 'center',
                            marginTop: '1em',
                            borderBottom: '3px solid rgb(212, 212, 212)',
                        }}
                    >
                        {this.props.infoCard.lineManagerName}
                        {this.props.infoCard.isTotal === false && (
                            <>
                                {' - Turno '}
                                {this.getDateFormat(this.props.infoCard.startAreashift)} - {this.getDateFormat(this.props.infoCard.endAreashift)}
                            </>
                        )}
                    </div>

                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow
                                style={{
                                    backgroundColor: this.props.infoCard.isTotal === true ? '#FFC000' : '#DCE6F1',
                                }}
                            >
                                <TableCell align="right">Nº Orígenes volcados</TableCell>
                                <TableCell align="right">KG VOLCADO</TableCell>
                                <TableCell align="right">DURACION TURNO</TableCell>
                                <TableCell align="right">KILOS/HORA</TableCell>
                                <TableCell align="right">€/KG (dir+env)</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">
                                    <p>{customFormatNumber(this.props.infoCard.batchesNumber)}</p>
                                </TableCell>
                                <TableCell align="right">
                                    <p>{customFormatNumber(this.props.infoCard.dumpsKg)}</p>
                                </TableCell>
                                <TableCell align="right">
                                    <p>{customFormatNumber(this.props.infoCard.durationAreashift)} h</p>
                                </TableCell>
                                <TableCell align="right">
                                    <p>{customFormatNumber(this.props.infoCard.kgByHour)}</p>
                                </TableCell>
                                <TableCell align="right">
                                    <p>{customFormatNumber(this.props.infoCard.eurosByKgDirectCost)}</p>
                                </TableCell>
                            </TableRow>
                            <TableRow
                                style={{
                                    backgroundColor: this.props.infoCard.isTotal === true ? '#FFC000' : '#DCE6F1',
                                }}
                            >
                                <TableCell align="right">Nº Personas Dir+Env</TableCell>
                                <TableCell align="right">Cajas totales</TableCell>
                                <TableCell align="right">Cajas palet / no palet</TableCell>
                                <TableCell align="right">Palets confeccionados</TableCell>
                                <TableCell align="right">Kilos confeccionados</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">
                                    <p>{customFormatNumber(this.props.infoCard.employeesNumberDirect)}</p>
                                </TableCell>
                                <TableCell align="right">
                                    <p>{customFormatNumber(this.props.infoCard.totalBoxes)}</p>
                                </TableCell>
                                <TableCell align="right">
                                    <p>
                                        {customFormatNumber(this.props.infoCard.boxesInPallets)} / {customFormatNumber(this.props.infoCard.boxesWithoutPallets)}
                                    </p>
                                </TableCell>
                                <TableCell align="right">
                                    <p>{customFormatNumber(this.props.infoCard.palletsNumber)}</p>
                                </TableCell>
                                <TableCell align="right">
                                    <p>{customFormatNumber(this.props.infoCard.kgInPallets)}</p>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    }
}
