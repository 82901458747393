import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {AppStoreState} from 'src/store';
import {notifyConnect, notifyServerError} from 'src/utils/Notification';

import {PreflightActions} from '../actions';
import {DowntimeServices} from '../services';
import {downtimePreflightResValidate} from '../validators/DowntimePreflightRes';

export const getPreflight = () => async (
  dispatch: (action: any) => any,
  getState: () => AppStoreState,
) => {
  try {
    const lane = LaneSelectSelectors.getLane(getState());
    // const res = await DowntimeServices.getDowntimes(
    //   lane ? lane.laneId : 'NO_EXISTE_LINEA',
    // );
    const response = await DowntimeServices.getPreflight(
      lane ? lane.laneId : 'NO_EXISTE_LINEA',
    );
    if (response.status === 200) {
      const {data} = response;
      const validate = await downtimePreflightResValidate;
      const valid = await validate(data);

      if (!valid) {
        console.error('NO HE VALIDADO>> ', validate.errors);
      }
      dispatch(PreflightActions.setCategories(data));
      dispatch(PreflightActions.setCauses(data));
    } else dispatch(notifyServerError()); // data.message
  } catch (error) {
    dispatch(notifyConnect());
  }
};
