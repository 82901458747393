import {ChartData, ChartOptions} from 'chart.js';
import React from 'react';
import {Bar} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {Spinner} from 'src/app/global/spinner';

// import {fetchWithFeedback} from 'src/global/fetcher';
// import {PerformanceServices} from 'src/app/lanemanager/performance/store/services';

const chart: ChartData = {
    labels: [],
    datasets: [],
};
const initialState = {chart};

type State = {
    chart: ChartData;
};

type Props = {
    chartData: any;
    isLoading: boolean;
};

export class KgHourTotalChart extends React.Component<Props, State> {
    readonly state = initialState;
    static getDerivedStateFromProps(props: Props) {
        const datasets = [
            {
                label: 'Kilogramos',
                backgroundColor: '#99ff99',
                data: props.chartData.kg,
                fill: true,
            },
        ] as any;

        const chartShow = {
            labels: props.chartData.hours,
            datasets,
        };

        return {
            chart: chartShow,
        };
    }
    render() {
        if (this.props.isLoading) {
            return <Spinner isLoading={this.props.isLoading} position={'relative'} />;
        }
        return <Bar data={this.state.chart} options={options} />;
    }
}

/***
 * CHART OPTIONS
 */
const options: ChartOptions = {
    responsive: true,
    title: {
        display: false,
        text: 'Tendencia de kilos/hora',
    },
    tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
            label: (tooltipItem: any) => {
                return tooltipItem && tooltipItem.yLabel ? customFormatNumber(Number(tooltipItem.yLabel)) : '';
            },
        },
    },
    hover: {
        mode: 'nearest',
        intersect: true,
    },
    maintainAspectRatio: false,
    scales: {
        xAxes: [
            {
                display: true,
                scaleLabel: {
                    display: true,
                    // labelString: 'Month',
                },
            },
        ],
        yAxes: [
            {
                display: true,
                scaleLabel: {
                    display: true,
                    // labelString: 'Value',
                },
                ticks: {
                    beginAtZero: true,
                    callback(value: string) {
                        // you can add your own method here (just an example)
                        return customFormatNumber(Number(value));
                    },
                    //min: 0,
                    //max: 100
                },
            },
        ],
    },
    plugins: {
        datalabels: {
            display: false,
            formatter: (value: any) => {
                return customFormatNumber(value);
            },
        },
    },
};
