import * as React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {FilterActions} from 'src/app/lanemanager/downtime/store/actions';
import {DowntimeSelectors} from 'src/app/lanemanager/downtime/store/selectors';
import {DowntimeServices} from 'src/app/lanemanager/downtime/store/services';
import {getPreflight} from 'src/app/lanemanager/downtime/store/thunks/getPreflight';
import {LaneDowntime} from 'src/app/lanemanager/downtime/store/types';
import {WrapActive} from 'src/app/lanemanager/downtime/styled/Wrap';
import {DowntimeCreate} from 'src/app/lanemanager/downtime/DowntimeCreate';
import {TableModal} from 'src/shared/components/Modal';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {CustomButton} from 'src/shared/style/CustomButton';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import throttle from 'lodash/throttle';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';

// import {notify} from 'src/utils/Notification';

type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
    userData: ReturnType<typeof LoginSelectors.getUserData>;
    catAndCause: ReturnType<typeof DowntimeSelectors.getCategoriesAndCauses>;
    filterState: ReturnType<typeof DowntimeSelectors.getDowntimesFilterState>;
};
export type DProps = {
    setActive: () => void;
    setInactive: () => void;
    clearFilters: () => void;
    // createDowntime: (causeId: string, description: string) => void;
    getPreflight: () => void;
};

type State = {
    open: boolean;
};
const initialState = {
    open: false,
};

export class DowntimesBase extends React.Component<SProps & DProps, State> {
    private throttled = throttle((fn: () => any) => fn(), 3000);
    readonly state = initialState;
    private tableHook: () => void = () => ({});

    componentDidMount() {
        IsInRuleset(['LANEMANAGER_DOWNTIMES_TOTAL', 'LANEMANAGER_COMMON_TOTAL']) && this.props.getPreflight();
    }

    private columns: ColumnType<LaneDowntime> = [
        {
            Header: 'Estado',
            headerClassName: 'header_cell_string',
            accessor: 'finished_at',
            className: 'cell_string',
            Cell: (items: any) => <WrapActive color={items.original.finished_at ? '#727272' : '#FF0000'}>{items.original.finished_at ? 'INACTIVA' : 'ACTIVA'}</WrapActive>,
        },
        {
            Header: 'Categoría',
            headerClassName: 'header_cell_string',
            accessor: 'category',
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'Causa',
            headerClassName: 'header_cell_string',
            accessor: 'cause',
            filterable: true,
            className: 'cell_string',
            width: 150,
        },
        {
            Header: 'Descripción',
            headerClassName: 'header_cell_string',
            accessor: 'description',
            filterable: true,
            className: 'cell_string',
            width: 200,
        },
        {
            Header: 'Hora inicio',
            headerClassName: 'header_cell_string',
            accessor: 'started_at',
            dateTimeFormat: true,
            filterable: false,
            // date: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'Hora final',
            headerClassName: 'header_cell_string',
            accessor: 'finished_at',
            sortable: false,
            dateTimeFormat: true,
            className: 'cell_string',
            filterable: false,
        },
        {
            Header: 'Tiempo total',
            headerClassName: 'header_cell_number',
            accessor: 'total_time',
            Cell: item => (
                <>
                    {item.original.total_time ? customFormatNumber(Number(item.original.total_time.replace('m', ''))) : '0'} {'m'}
                </>
            ),
            className: 'cell_number',
        },
        {
            Header: 'Personal en parada',
            headerClassName: 'header_cell_number',
            accessor: 'personnel_affected',
            Cell: item => <>{customFormatNumber(item.original.personnel_affected)}</>,
            className: 'cell_number',
            width: 145,
        },

        {
            style: {display: 'flex', justifyContent: 'center'},
            sortable: false,
            Header: 'Acción',
            Cell: (item: any) => (
                <CustomButton
                    disabled={!!item.original.finished_at || !IsInRuleset(['LANEMANAGER_DOWNTIMES_TOTAL', 'LANEMANAGER_COMMON_TOTAL'])}
                    onClick={() => this.throttled(() => this.handleContinue(item.original.id))}
                    color={!item.original.finished_at ? 'primary' : 'default'}
                >
                    REANUDAR
                </CustomButton>
            ),
        },
    ];

    private handleClose = () => {
        this.setState({open: false});
    };

    private handleOpen = () => {
        this.setState({open: true});
    };

    private handleSending = async (causeId: string, description: string) => {
        const {lane, userData} = this.props;
        if (lane && userData)
            try {
                await fetchWithFeedback(
                    DowntimeServices.new(lane.laneId, {
                        cause_id: causeId,
                        description,
                        employee_id: userData.employeeId,
                    }),
                    {
                        successMessage: 'Se ha creado la parada',
                        errorMessage: 'Error al crear la parada. Revisar las condiciones.',
                    },
                );
                // notify({message: 'Se ha creado la parada', status: 'success'});
                this.useTableHook();
                this.setState({open: false});
            } catch (error) {
                console.error('Error al crear', error);
            }
    };

    private handleContinue = async (downtimeId: string) => {
        if (downtimeId && this.props.lane)
            try {
                await DowntimeServices.continue(this.props.lane.laneId, downtimeId);
                this.useTableHook();
            } catch (error) {
                console.error('Error al crear', error);
            }
    };

    private getTableHook = (fn: () => void) => {
        this.tableHook = fn;
    };

    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    render() {
        const VIEW_ACCESS = IsInRuleset(['LANEMANAGER_DOWNTIMES_VIEW', 'LANEMANAGER_COMMON_VIEW']);
        const TOTAL_ACCESS = IsInRuleset(['LANEMANAGER_DOWNTIMES_TOTAL', 'LANEMANAGER_COMMON_TOTAL']);
        const {lane, catAndCause} = this.props;
        return (
            (TOTAL_ACCESS || VIEW_ACCESS) && (
                <TableWrap>
                    <TableModal title={'Gestión de paradas'} open={this.state.open} handleClose={this.handleClose}>
                        <DowntimeCreate categories={catAndCause.categories} causes={catAndCause.causes} handleCreate={this.handleSending} />
                    </TableModal>
                    <PaginatedTableComponent
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        service={DowntimeServices.getDowntimes}
                        serviceArgs={[lane.laneId]}
                        createHook={TOTAL_ACCESS ? this.handleOpen : undefined}
                        getTableFetchHook={this.getTableHook}
                        loading={true}
                    />
                </TableWrap>
            )
        );
    }
}

const md2p = (dispatch: any) => ({
    getPreflight: () => dispatch(getPreflight()),
    setActive: () => dispatch(FilterActions.active()),
    setInactive: () => dispatch(FilterActions.inactive()),
    clearFilters: () => dispatch(FilterActions.clear()),
});

export const Downtimes = connect<SProps, DProps, {}, AppStoreState>(
    state => ({
        userData: LoginSelectors.getUserData(state),
        lane: LaneSelectSelectors.getLane(state),
        catAndCause: DowntimeSelectors.getCategoriesAndCauses(state),
        filterState: DowntimeSelectors.getDowntimesFilterState(state),
    }),
    md2p,
)(DowntimesBase);

const TableWrap = styled.div`
    width: 100%;
    height: 100%;
    margin: auto;
`;
