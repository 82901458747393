import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'auth/users';

type IdOption = {id: string};
type NonEssentialOptions = {
  username: string;
  password: string;
  banned: boolean;
  employee_id: string;
  rulegroupsIds: string[];
  centersIds: string[];
};
type Options = IdOption & Partial<NonEssentialOptions>;
type MassOptions = {
  ids: string[];
  rulegroupIds: string[];
};
export const UsersControlServices = {
  get: ({params}: PaginatedServiceArgs) =>
    guardedFetcher.get<PaginateResponse>(`${prefix}`, {params}),
  getSelected: ({params}: PaginatedServiceArgs) =>
    guardedFetcher.get(`${prefix}/select`, {params}),
  update: ({id, username, password, banned, employee_id, rulegroupsIds, centersIds}: Options) =>
    guardedFetcher.patch(`${prefix}/${id}`, {
      username,
      password,
      banned,
      employee_id,
      rulegroupsIds,
      centersIds
    }),
  create: ({
    username,
    password,
    banned,
    employee_id,
    rulegroupsIds,
    centersIds
  }: Partial<Options>) => {
    return guardedFetcher.post(`${prefix}`, {
      username,
      password,
      banned,
      employee_id,
      rulegroupsIds,
      centersIds
    });
  },
  delete: ({id}: Options) => guardedFetcher.delete(`${prefix}/${id}`),
  rulegroups: ({id}: Options) => guardedFetcher.get(`${prefix}/${id}/rulegroups`),
  toRulegroups: (users: MassOptions) =>
    guardedFetcher.put(`${prefix}/to-rulegroups`, users),
};
