import * as React from 'react';
import {WrapFullContainer, OCard} from 'src/shared/style';
import {CostTotalMakingType, CostTotalExpeditionType, CostTotalHourType} from 'src/app/information/kpis/store/types/chart-types';
import {KpiChartsServices} from 'src/app/information/kpis/store/services';
import {CostTotalMakingChart} from 'src/app/information/kpis/charts/CostCalculation/CostTotalMakingChart';
import {CostTotalExpeditionChart} from 'src/app/information/kpis/charts/CostCalculation/CostTotalExpeditionChart';
import {CostTotalHourChart} from 'src/app/information/kpis/charts/CostCalculation/CostTotalHourChart';

export interface ConfectionCalculationProps {
    areaId: string;
    centerId: string;
}
type State = {
    areaId: string | undefined;
    centerId: string | undefined;
    date: Date | Date[] | undefined;
    costTotalMaking: CostTotalMakingType | undefined;
    costTotalExpedition: CostTotalExpeditionType | undefined;
    costTotalHour: CostTotalHourType | undefined;
    loadingCostTotalMakingData: boolean;
    loadingCostTotalExpeditionData: boolean;
    loadingCostTotalHourData: boolean;
};

const initialState = {
    areaId: undefined,
    centerId: undefined,
    date: undefined,
    costTotalMaking: {
        costProduccion: '',
        costByCentersValues: [],
        costByCentersLabels: [],
        costByAreasValues: [],
        costByAreasLabels: [],
        kgPerHourCentersValues: [],
        kgPerHourCentersLabels: [],
        kgPerHourAreasValues: [],
        kgPerHourAreasLabels: [],
        indirectCostByKgValues: [],
        indirectCostByKgLabels: [],
    },
    costTotalExpedition: {
        costProduccion: '',
        costByCentersValues: [],
        costByCentersLabels: [],
        costByAreasValues: [],
        costByAreasLabels: [],
    },
    costTotalHour: {
        hours: [],
        cost: [],
    },
    loadingCostTotalMakingData: false,
    loadingCostTotalExpeditionData: false,
    loadingCostTotalHourData: false,
};

type Props = {
    areaId: string;
    centerId: string;
    startDate: string;
    endDate: string;
};

export class CostCalculation extends React.Component<Props, State> {
    state = initialState;

    componentDidMount() {
        this.showData(this.props);
    }

    componentWillReceiveProps(props: Props) {
        this.showData(props);
    }

    showData(props: Props) {
        const {centerId, areaId, startDate, endDate} = props;
        this.setState({loadingCostTotalMakingData: true});
        this.setState({loadingCostTotalExpeditionData: true});
        this.setState({loadingCostTotalHourData: true});

        KpiChartsServices.costTotalMaking(centerId, areaId, startDate, endDate).then((res: any) => {
            const centersLabel = [];
            const centersValue = [];
            const areasLabel = [];
            const areasValue = [];
            const kgPerHourCentersLabel = [];
            const kgPerHourCentersValue = [];
            const kgPerHourAreasLabel = [];
            const kgPerHourAreasValue = [];
            const indirectCostByKgLabels = [];
            const indirectCostByKgValues = [];

            if (res) {
                for (const index in res.data.costByCenters) {
                    if (index) {
                        centersLabel.push(index);
                        centersValue.push(res.data.costByCenters[index]);
                    }
                }
                for (const index in res.data.costByAreas) {
                    if (index) {
                        areasLabel.push(index);
                        areasValue.push(res.data.costByAreas[index]);
                    }
                }
                for (const index in res.data.kgPerHourByCenters) {
                    if (index) {
                        kgPerHourCentersLabel.push(index);
                        kgPerHourCentersValue.push(res.data.kgPerHourByCenters[index]);
                    }
                }
                for (const index in res.data.kgPerHourByAreas) {
                    if (index) {
                        kgPerHourAreasLabel.push(index);
                        kgPerHourAreasValue.push(res.data.kgPerHourByAreas[index]);
                    }
                }
                for (const index in res.data.indirectCostByKg) {
                    if (index) {
                        indirectCostByKgLabels.push(index);
                        indirectCostByKgValues.push(res.data.indirectCostByKg[index]);
                    }
                }
            }

            const data = {
                costProduccion: res.data.costProduccion,
                costByCentersValues: centersValue,
                costByCentersLabels: centersLabel,
                costByAreasValues: areasValue,
                costByAreasLabels: areasLabel,
                kgPerHourCentersLabels: kgPerHourCentersLabel,
                kgPerHourCentersValues: kgPerHourCentersValue,
                kgPerHourAreasLabels: kgPerHourAreasLabel,
                kgPerHourAreasValues: kgPerHourAreasValue,
                indirectCostByKgValues: indirectCostByKgValues,
                indirectCostByKgLabels: indirectCostByKgLabels,
            };

            this.setState({costTotalMaking: data});
            this.setState({loadingCostTotalMakingData: false});
        });
        KpiChartsServices.costTotalExpedition(centerId, areaId, startDate, endDate).then((res: any) => {
            const centersLabel = [];
            const centersValue = [];
            const areasLabel = [];
            const areasValue = [];

            if (res) {
                for (const index in res.data.costByCenters) {
                    if (index) {
                        centersLabel.push(index);
                        centersValue.push(res.data.costByCenters[index]);
                    }
                }
                for (const index in res.data.costByAreas) {
                    if (index) {
                        areasLabel.push(index);
                        areasValue.push(res.data.costByAreas[index]);
                    }
                }
            }

            const data = {
                costProduccion: res.data.costProduccion,
                costByCentersValues: centersValue,
                costByCentersLabels: centersLabel,
                costByAreasValues: areasValue,
                costByAreasLabels: areasLabel,
            };

            this.setState({costTotalExpedition: data});
            this.setState({loadingCostTotalExpeditionData: false});
        });

        KpiChartsServices.costTotalHour(centerId, areaId, startDate, endDate).then((res: any) => {
            const hours = [];
            const cost = [];

            if (res) {
                for (const index in res.data) {
                    if (index) {
                        hours.push(index);
                        cost.push(res.data[index]);
                    }
                }
            }

            const data2 = {
                hours: hours,
                cost: cost,
            };

            this.setState({costTotalHour: data2});
            this.setState({loadingCostTotalHourData: false});
        });
    }

    public render() {
        return (
            <>
                <h1 className="text-center">Cálculo de costes</h1>
                <WrapFullContainer>
                    <OCard title={'Coste confección (€/kg)'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'20px'}>
                        <CostTotalMakingChart chartData={this.state.costTotalMaking} isLoading={this.state.loadingCostTotalMakingData} />
                    </OCard>
                    <OCard title={'Coste expedición (€/palé)'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'20px'}>
                        <CostTotalExpeditionChart chartData={this.state.costTotalExpedition} isLoading={this.state.loadingCostTotalExpeditionData} />
                    </OCard>
                    <OCard title={'Coste hora (€/kg)'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'20px'}>
                        <CostTotalHourChart chartData={this.state.costTotalHour} isLoading={this.state.loadingCostTotalHourData} />
                    </OCard>
                </WrapFullContainer>
            </>
        );
    }
}
