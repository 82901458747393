import React from 'react';
import {connect} from 'react-redux';

import Snackbar, {SnackbarOrigin} from '@material-ui/core/Snackbar';
import {GetGrantSet, RULESET} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {RouteHelpers} from 'src/utils/routerHelpers';
import {notifyCloseWrapper} from 'src/utils/Notification/actions';

import {NotifyActionType} from './action-types';
import {NotificationStoreState} from './reducer';
import {getNotify} from './selectors';
import {CustomSnackbar} from './Wrap';

type SProps = {
  notifyProps: NotificationStoreState;
  pathname: string;
};

type DProps = {
  notifyClose(): void;
};
interface State {
  origin: SnackbarOrigin;
  message: string;
  open: boolean;
  type?: NotifyActionType;
  timeout: number;
}

const initialState = {
  origin: {vertical: 'top', horizontal: 'right'} as SnackbarOrigin,
  open: false,
  timeout: 7000,
  message: 'Prueba',
  type: NotifyActionType.notify,
};

type ComponentProps = SProps & DProps;
class Notification extends React.Component<ComponentProps, State> {
  readonly state = initialState;

  static getDerivedStateFromProps(props: ComponentProps) {
    if (props.notifyProps.message !== undefined) {
      const {message, type, timeout} = props.notifyProps;
      return {
        message,
        open: true,
        type,
        timeout: timeout || 7000,
      };
    }
    return {open: false};
  }

  componentDidMount() {
    if (
      RULESET &&
      RULESET.grants &&
      RULESET.grantsets &&
      RULESET.grants.length <= 0 &&
      RULESET.grantsets.length <= 0 &&
      this.props.pathname !== '/login' &&
      this.props.pathname !== '/graphics' &&
      this.props.pathname !== '/test'
    ) {
      GetGrantSet();
    }
  }

  shouldComponentUpdate() {
    return true;
  }

  handleClose = () => {
    this.setState({open: false});
  };

  render() {
    return (
      <Snackbar
        anchorOrigin={this.state.origin}
        autoHideDuration={this.state.timeout}
        onClose={this.props.notifyClose}
        open={this.state.open}
        ContentProps={{'aria-describedby': 'message-id'}}>
        <CustomSnackbar
          variant={this.state.type}
          message={this.state.message}
          onClose={this.props.notifyClose}
        />
      </Snackbar>
    );
  }
}

const mapStateToProps = (state: AppStoreState) => ({
  notifyProps: getNotify(state),
  pathname: RouteHelpers.getLocation(state),
});

const md2p = (dispatch: any) => ({
  notifyClose: () => dispatch(notifyCloseWrapper()),
});

export const NotificationConnected = connect(
  mapStateToProps,
  md2p,
)(Notification);
