import {push} from 'connected-react-router';
import React from 'react';
import {connect} from 'react-redux';
import {Link, Route} from 'react-router-dom';
import styled from 'styled-components';

import {LoggingErrors} from 'src/app/logging/LoggingErrors';
import {LoggingIncidences} from 'src/app/logging/LoggingIncidences';
import {StatusBar} from 'src/app/statusbar/StatusBar';
import {TabContainerNoTabs} from 'src/app/lanemanager/Wrap';
import {createStyles, withStyles, Theme} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ErrorIcon from '@material-ui/icons/Error';
import IncidenceIcon from '@material-ui/icons/Warning';
import {AppStoreState} from 'src/store';
import {RouteHelpers} from 'src/utils/routerHelpers';
import {IsInRuleset} from 'src/global/authorization/grantSet';

type SProps = {pathname: string};
type DProps = {push(path: string): void};
type Props = {
  classes: {
    root: string;
  };
};

type State = {
  routeTitle: string;
  generic: boolean;
};
const initialState: State = {routeTitle: '', generic: true};

const styles = ({palette}: Theme) =>
  createStyles({
    root: {
      backgroundColor: palette.background.paper,
      padding: 0,
      height: 'calc(100% - 47px)',
      width: '100%',
    },
  });

export class LoggingIndexPageBase extends React.Component<
  Props & SProps & DProps,
  State
> {
  readonly state = initialState;
  componentDidMount() {
    this.findRoute();
  }

  componentWillReceiveProps(nextProps: SProps) {
    this.findRoute(nextProps.pathname);
  }

  render() {
    const {classes} = this.props;
    const {generic} = this.state;
    return (
      <>
        <StatusBar title={this.state.routeTitle} />
        <div className={classes.root}>
          <TabContainerNoTabs>
            <Route
              exact={true}
              path="/logging/incidences"
              component={LoggingIncidences}
            />
            {IsInRuleset('SYSTEM') && (
              <Route exact={true} path="/logging/errors" component={LoggingErrors} />
            )}
            {generic && (
              <WrapList>
                <List component="nav">
                  <ListItem button={true}>
                    <Link to="/logging/incidences" href="/logging/incidences">
                      <ListItemIcon>
                        <IncidenceIcon />
                      </ListItemIcon>
                      <ListItemText primary="Incidencias" />
                    </Link>
                  </ListItem>
                  {IsInRuleset('SYSTEM') && (
                    <ListItem button={true}>
                      <Link to="/logging/errors" href="/logging/errors">
                        <ListItemIcon>
                          <ErrorIcon />
                        </ListItemIcon>
                        <ListItemText primary="Errores" />
                      </Link>
                    </ListItem>
                  )}
                </List>
              </WrapList>
            )}
          </TabContainerNoTabs>
        </div>
      </>
    );
  }

  private findRoute = (pathname: string = this.props.pathname) => {
    const path = pathname.split('/')[2];
    const routeTitle = this.getTitle(path);
    let generic = false;
    if (routeTitle === 'Sección de errores') generic = true;
    this.setState({routeTitle, generic});
  };

  private getTitle = (path: string) => {
    switch (path) {
      case 'incidences':
        return 'Incidencias';
      case 'errors':
        return 'Errores';
      default:
        return 'Sección de errores';
    }
  };
}

export const LoggingIndexPage = connect<SProps, DProps, Props, AppStoreState>(
  state => ({pathname: RouteHelpers.getLocation(state)}),
  {push},
)(withStyles(styles)(LoggingIndexPageBase));

const WrapList = styled.div`
  width: 400px;
  margin: auto;
  a {
    display: flex;
    text-decoration: inherit;
    color: inherit;
  }
`;
