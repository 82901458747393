import * as React from 'react';
import {RowInfo} from 'react-table';
import styled from 'styled-components';

import {TasksControlServices} from 'src/app/administration/tasksControl/store/tasksControl.services';
import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {CustomButton, CustomDropdown, CustomTextField} from 'src/shared/style';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';

type State = {
    open: boolean;
    id: string;
    name: string;
    nameValid: boolean;
    direct: number;
    is_downtime: boolean;
};
const initialState: State = {
    open: false,
    id: '',
    name: '',
    direct: -1,
    is_downtime: false,
    nameValid: true,
};

export class TasksControl extends React.Component<{}, State> {
    readonly state = initialState;
    private tableHook: () => void = () => ({});
    private getTableHooks = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    private columns: ColumnType<{id: string; name: string; direct: boolean}> = [
        {
            Header: 'Nombre',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'name',
            className: 'cell_string',
            width: 350,
        },
        {
            Header: 'Directa?',
            headerClassName: 'header_cell_string',
            accessor: 'direct',
            Cell: item => <>{this.getValuesDirect(item.original.direct)}</>,
            className: 'cell_string',
        },
        {
            Header: 'Parada?',
            headerClassName: 'header_cell_string',
            accessor: 'is_downtime',
            Cell: item => <>{item.original.is_downtime ? 'Sí' : 'No'}</>,
            className: 'cell_string',
        },
    ];

    private handleClose = () => {
        this.setState({
            open: false,
            id: '',
            name: '',
            direct: -1,
            is_downtime: false,
        });
    };

    private handleOpen = () => this.setState({open: true});

    private handleSelect = ({id, name, direct, is_downtime}: {id: string; name: string; is_downtime: boolean; direct: number}) =>
        this.setState({
            open: true,
            id,
            name,
            direct,
            is_downtime,
        });

    private handleSending = async () => {
        const {name, id, nameValid, direct, is_downtime} = this.state;
        if (nameValid) {
            if (id) {
                await fetchWithFeedback(TasksControlServices.update({id, name, direct, is_downtime}));
                this.handleClose();
            } else {
                await fetchWithFeedback(TasksControlServices.create({name, direct, is_downtime}));
                this.setState({id: '', name: '', direct: -1, is_downtime: false});
            }
            this.useTableHook();
        } else notify({message: 'Campo nombre no puede ir vacio.', status: 'error'});
    };

    private handleChecking = () => {
        const {name} = this.state;
        this.setState({nameValid: !!name}, this.handleSending);
    };

    private handleChange: HandleChange = e => {
        const {name, value} = e.target;
        if (name === 'name') this.setState({name: value, nameValid: true});
        if (name === 'direct')
            this.setState({
                direct: value === 'manufacturing' ? 2 : value === 'direct' ? 1 : 0,
            });
        if (name === 'isDowntime') this.setState({is_downtime: value === 'stop' ? true : false});
    };

    render() {
        const TOTAL_ACCESS = typeof IsInRuleset === 'function' ? IsInRuleset('TASKS_TOTAL') : false;
        const VIEW_ACCESS = typeof IsInRuleset === 'function' ? IsInRuleset('TASKS_VIEW') : false;

        const {name, open, nameValid, is_downtime} = this.state;
        return (
            (TOTAL_ACCESS || VIEW_ACCESS) && (
                <TableWrap>
                    <TableModal title={'Gestión de tareas'} open={open} handleClose={this.handleClose}>
                        <div
                            style={{
                                maxWidth: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <CustomTextField
                                margin={'5px auto 5px auto'}
                                width={'100%'}
                                variant={'outlined'}
                                // style={{zIndex: 20, marginTop: 5}}
                                label="Nombre"
                                error={!nameValid}
                                value={name}
                                name="name"
                                onChange={this.handleChange}
                            />
                            <CustomDropdown
                                margin={'10px 0'}
                                containerWidth={'100%'}
                                data={[
                                    {
                                        id: 'direct',
                                        name: this.getValuesDirect(1),
                                    },
                                    {
                                        id: 'indirect',
                                        name: this.getValuesDirect(0),
                                    },
                                    {
                                        id: 'manufacturing',
                                        name: this.getValuesDirect(2),
                                    },
                                ]}
                                handleChoose={this.handleChange}
                                value={this.state.direct === 1 ? 'direct' : this.state.direct === 0 ? 'indirect' : 'manufacturing'}
                                dropdownName="direct"
                                title="Categoría"
                            />
                            <CustomDropdown
                                margin={'10px 0'}
                                containerWidth={'100%'}
                                data={[
                                    {id: 'stop', name: 'Parada'},
                                    {id: 'notStop', name: 'No es parada'},
                                ]}
                                handleChoose={this.handleChange}
                                value={is_downtime ? 'stop' : 'notStop'}
                                dropdownName="isDowntime"
                                title="Es parada?"
                            />
                            {/* <FormControlLabel
                style={{width: 120, margin: 'auto', marginBottom: 5}}
                control={
                  <Checkbox
                    checked={is_downtime}
                    onChange={() => this.setState({is_downtime: !is_downtime})}
                    value="activeCB"
                  />
                }
                label="Es parada?"
              /> */}
                            <CustomButton onClick={this.handleChecking} width={'100%'}>
                                {this.state.id ? 'Guardar' : 'Crear'} tarea
                            </CustomButton>
                        </div>
                    </TableModal>
                    <TableComponent
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        service={TasksControlServices.getAll}
                        accessor={'tasks'}
                        createHook={TOTAL_ACCESS ? this.handleOpen : undefined}
                        getTableFetchHook={this.getTableHooks}
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: RowInfo) => ({
                                onClick: () => TOTAL_ACCESS && this.handleSelect(rowInfo.original),
                                style: {cursor: 'pointer'},
                            }),
                        }}
                    />
                </TableWrap>
            )
        );
    }

    getValuesDirect(itemDirect: number) {
        if (itemDirect === 1) {
            return 'Directa';
        }

        if (itemDirect === 0) {
            return 'Indirecta';
        }

        if (itemDirect === 2) {
            return 'Envasado';
        }

        return '';
    }
}

const TableWrap = styled.div`
    width: 45%;
    height: 100%;
    margin: auto;
`;
