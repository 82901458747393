import {Dump} from 'src/app/lanemanager/lane/store/types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import invoke from 'lodash/invoke';
import React, {FunctionComponent} from 'react';
import {List} from 'react-content-loader';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';

type Props = {dump: Dump};

export const DumpInfo: FunctionComponent<Props> = ({dump}) => {
    if (!dump || (!dump.dumped_at && !dump.total_kg && !dump.merchandise && !dump.provider)) return <div style={{fontSize: 14}}>No hay datos del origen ni del volcado...</div>;
    const {dumped_at, arrival_at, total_kg, merchandise, dumped_boxes, provider, allowed_customers, quality_protocols, batches} = dump;
    return (
        <div style={{padding: 15}}>
            {!arrival_at && (
                <>
                    <List style={{width: '80%', height: 120}} preserveAspectRatio={'none'} />
                </>
            )}
            {arrival_at && (
                <div style={{marginTop: '5%'}}>
                    <ThemedTypography style={{display: 'flex', alignItems: 'center'}}>
                        Origen:{' '}
                        {invoke(batches, 'map', (btch: Dump['batches'][0], idx: number) => (
                            <Typography component="span" style={{marginLeft: 'auto', fontSize: 30}} key={'batches' + idx}>
                                {' '}
                                {btch.erp_batch_code} ({btch.boxes})
                            </Typography>
                        ))}
                    </ThemedTypography>
                    <ThemedTypography dataName={'arrival-time'}>
                        Volcado:{' '}
                        <span style={{marginLeft: 'auto'}}>
                            {format(new Date(dumped_at), 'HH:mm - DD/MM/YYYY', {
                                locale: es,
                            })}
                        </span>
                    </ThemedTypography>
                    <ThemedTypography dataName={'arrival-time'}>
                        Origen llegado:{' '}
                        <span style={{marginLeft: 'auto'}}>
                            {format(new Date(arrival_at), 'HH:mm - DD/MM/YYYY', {
                                locale: es,
                            })}
                        </span>
                    </ThemedTypography>
                    <ThemedTypography>
                        Kilos totales: <span style={{marginLeft: 'auto'}}>{customFormatNumber(total_kg)}</span>
                    </ThemedTypography>
                    <ThemedTypography>
                        Cajas volcadas: <span style={{marginLeft: 'auto'}}>{customFormatNumber(dumped_boxes)}</span>
                    </ThemedTypography>
                    <ThemedTypography>Género:</ThemedTypography>
                    <ThemedTypography>
                        <span style={{margin: 'auto'}}>{merchandise && merchandise.name}</span>
                    </ThemedTypography>
                    <ThemedTypography>
                        Agricultor: <span style={{marginLeft: 'auto'}}>{provider && provider.name}</span>
                    </ThemedTypography>
                    <ThemedTypography>
                        Código agricultor: <span style={{marginLeft: 'auto'}}>{provider && provider.erp_code}</span>
                    </ThemedTypography>
                    {allowed_customers.length ? (
                        <ThemedTypography>
                            Válida para clientes:
                            {allowed_customers.map((ac, i) => (
                                <span key={i}> {ac}</span>
                            ))}
                        </ThemedTypography>
                    ) : null}
                    <ThemedTypography>
                        Protocolo de calidad: <span style={{marginLeft: 'auto'}}>{quality_protocols || 'No está definido'}</span>
                    </ThemedTypography>
                </div>
            )}
        </div>
    );
};

const stylesTypo = {
    typo: {
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: 40,
        color: 'grey',
        // borderBottom: '1px solid #DFDFDF',
        width: '100%',
        paddingLeft: 0,
        display: 'flex',
    },
};

const ThemedTypography = withStyles(stylesTypo)((props: any) => (
    <Typography data-name={props.dataName} className={props.classes.typo} style={{...props.style}}>
        {props.children}
    </Typography>
));
