import * as React from 'react';
import {Typography} from '@material-ui/core';
import {TableModal} from 'src/shared/components/Modal';
import {CustomButton} from 'src/shared/style';
//import {IsInRuleset} from 'src/global/authorization/grantSet';
//import {fetchWithFeedback} from 'src/utils/fetcherValidate';
//import {AlertServices} from './store/alert.services';
import {ActivitiesExcesiveInactiveTaskType} from './store/types';
import CheckIcon from '@material-ui/icons/CheckCircle';
import {CustomDialog} from 'src/shared/components/CustomDialog';

export interface Props {
    openAlertExcessiveInactiveTask: boolean;
    excesiveTasks: ActivitiesExcesiveInactiveTaskType[] | undefined;
    handleClose(): void;
    checkAlertTransitInactiveExcessive(idCheck: string): void;
}

type State = {
    showConfirmCheckAlert: boolean;
    idToCheck: string;
};

const initialState: Readonly<State> = {
    showConfirmCheckAlert: false,
    idToCheck: '',
};

const divStyleAlert = {
    padding: '20px',
    margin: '20px',
    backgroundColor: '#e4b9b9',
};

export class AlertExcessiveInactiveTaskModal extends React.Component<Props, State> {
    readonly state = initialState;

    render() {
        return (
            <>
                <CustomDialog
                    title="Confirmación"
                    contentText="¿Está seguro que quiere marcar como revisada la alerta?"
                    handleClose={() => this.setState({showConfirmCheckAlert: false})}
                    open={this.state.showConfirmCheckAlert}
                    handle={() => {
                        this.props.checkAlertTransitInactiveExcessive(this.state.idToCheck);
                        this.setState({showConfirmCheckAlert: false});
                    }}
                />
                <TableModal open={this.props.openAlertExcessiveInactiveTask} handleClose={() => this.props.handleClose()} title={'Inactividad excesiva'}>
                    <>
                        {this.props.excesiveTasks && this.props.excesiveTasks.length ? (
                            this.props.excesiveTasks.map((excessiveTask: any) => (
                                <div key={excessiveTask.id} style={divStyleAlert}>
                                    <p>
                                        {excessiveTask.name} {excessiveTask.surname} ( {excessiveTask.identification} ) ( {excessiveTask.code} )
                                    </p>
                                    <p>
                                        {excessiveTask.taskName} [ {excessiveTask.areaName} - {excessiveTask.centerName} ]
                                    </p>
                                    <p>
                                        Fecha fin de la última tarea: {excessiveTask.last_finished_at} ( {excessiveTask.duration_control} min )
                                    </p>
                                    <p>
                                        Biométrico entrada: {excessiveTask.last_checkin_finished_at} ( {excessiveTask.duration_erp} min )
                                    </p>
                                    <CustomButton
                                        onClick={() =>
                                            this.setState({
                                                showConfirmCheckAlert: true,
                                                idToCheck: excessiveTask.id,
                                            })
                                        }
                                        width={'100%'}
                                        margin="unset"
                                    >
                                        Visto <CheckIcon />
                                    </CustomButton>
                                </div>
                            ))
                        ) : (
                            <Typography variant="body1">No hay tareas excesivas</Typography>
                        )}
                    </>
                </TableModal>
            </>
        );
    }
}
