import * as React from 'react';
import {CustomDialog} from 'src/shared/components/CustomDialog';
import {DumpsServices} from './store/services';
import {notify} from 'src/utils/Notification';

type ReactivateBatchProps = {
    open: boolean;
    idDumpToReactivate: string;
    handleClose: () => void;
    handleRefresh: () => void;
};

const initialState = {
    batchesToReactivate: '',
    batchesToReset: '',
    idDumpToReactivate: '',
};

type State = Readonly<{
    batchesToReactivate: string;
    batchesToReset: string;
    idDumpToReactivate: string;
}>;

export class ReactivateBatchDialog extends React.Component<ReactivateBatchProps, State> {
    readonly state = initialState;

    componentWillReceiveProps(nextProps: any) {
        this.setState({batchesToReactivate: '', batchesToReset: ''});
        if (nextProps.open === true) {
            DumpsServices.reactivateBatchCheck(nextProps.idDumpToReactivate).then(batchesList => {
                if (batchesList.status === 404 && batchesList.data.message === 'INVALID_STATE_DUMP') {
                    notify({message: 'El volcado no está en un estado válido para reactivarlo.', status: 'error'});
                } else if (batchesList.status === 404) {
                    notify({message: 'Ha ocurrido un error.', status: 'error'});
                } else {
                    const batchesToReactivate = batchesList.data.batchesToReactivate.join('|');
                    const batchesToReset = batchesList.data.batchesToReset.join('|');

                    this.setState({batchesToReactivate, batchesToReset});
                }
            });
        }
    }

    private reactivateBatch = (idDumpToReactivate: string) => {
        DumpsServices.reactivateBatchExecute(idDumpToReactivate).then(res => {
            if (res.status === 404 && res.data.message === 'INVALID_STATE_DUMP') {
                notify({message: 'El volcado no está en un estado válido para reactivarlo.', status: 'error'});
            } else if (res.status === 404) {
                notify({message: 'Ha ocurrido un error.', status: 'error'});
            }
            this.props.handleRefresh();
        });
    };

    public render() {
        let listBatchesToReset = '';
        if (this.state.batchesToReset !== '') {
            listBatchesToReset = ' ( los orígenes [' + this.state.batchesToReset + '] pasarán a estado volcado) ';
        }
        const contentText = '¿Está seguro que quiere reactivar el origen [' + this.state.batchesToReactivate + ']? ' + listBatchesToReset;

        return (
            <>
                <CustomDialog
                    title="Confirmación"
                    contentText={contentText}
                    handleClose={() => this.props.handleClose()}
                    open={this.props.open && this.state.batchesToReactivate !== ''}
                    handle={() => {
                        this.reactivateBatch(this.props.idDumpToReactivate);
                        this.props.handleClose();
                    }}
                />
            </>
        );
    }
}
