import React, {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';

type Props = {
  icon?: JSX.Element;
  text: string;
  onClick?(...args: any[]): void;
  href?: string;
  target?: string;
};
export const PrimaryListItem: FunctionComponent<Props> = props => (
  <Link
    to={props.href || ''}
    href={props.href}
    style={{display: 'flex', cursor: 'pointer'}}>
    {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
    <Typography style={{alignSelf: 'center'}} onClick={props.onClick}>
      {props.text}
    </Typography>
  </Link>
);
