import {PaginatedServiceArgs} from 'src/types';
// import {fetcher} from 'src/global/fetcher';
import {guardedFetcher} from 'src/utils/guardedFetcher';

import {Box, BoxesResponse} from './types';
import {boxesValidate} from './validators/BoxesResponse';

export const BoxesServices = {
  getAll: async () => {
    try {
      const validate = await boxesValidate;
      const {data, status} = await guardedFetcher.get<BoxesResponse>(
        'production/info/boxes',
      );

      if (!validate(data)) {
        console.error('no he validado>> ', validate.errors, 'con data>> ', data);
      }

      if (status === 200) {
        return data;
      } else {
        console.error('no he validado>> ', validate.errors, 'con data>> ', data);
        return undefined;
      }
    } catch (error) {
      return undefined;
    }
  },

  getAllPaginated: ({params}: PaginatedServiceArgs) =>
    guardedFetcher.get<PaginateResponse<Box>>('production/info/boxes', {params}),
};
