export const boxScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['id', 'created_at', 'employee_uid'],
  properties: {
    id: {type: 'string'},
    created_at: {type: 'string'},
    employee_uid: {type: 'string'},
  },
};

// const boxWithTypeSchema = {};
