import React, {FunctionComponent} from 'react';

import {TaskByEmployeeData} from 'src/app/information/reports/store/types';
import {PersonalServices} from 'src/app/lanemanager/personal/store/personal.services';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {ParametersSelectors} from 'src/utils/Parameters/selectors';
import {ParameterCPN} from 'src/types/app_types';
import {connect} from 'react-redux';
import {AppStoreState} from 'src/store';

type SProps = {
    employeeCodeModeParam?: String;
};
type ComponentProps = SProps;

const EmployeesWorkingNowBase: FunctionComponent<ComponentProps> = props => {
    const {employeeCodeModeParam} = props;
    const showErpEmployeeCode = employeeCodeModeParam === 'ERP_MODE' ? true : false;
    const showInternalEmployeeCode = employeeCodeModeParam === 'INTERNAL_MODE' ? true : false;

    const columnFormat: ColumnType<TaskByEmployeeData> = [
        {
            Header: 'Área',
            headerClassName: 'header_cell_string',
            accessor: 'areaName',
            className: 'cell_string',
            filterable: true,
            sortable: true,
        },
        {
            Header: 'Centro',
            headerClassName: 'header_cell_string',
            accessor: 'centerName',
            className: 'cell_string',
            filterable: true,
            sortable: true,
        },
        {
            Header: 'EMP.Código',
            headerClassName: 'header_cell_string',
            accessor: 'code',
            show: showInternalEmployeeCode,
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'EMP.Código',
            headerClassName: 'header_cell_string',
            accessor: 'erp_employee_code',
            show: showErpEmployeeCode,
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'EMP.Foto',
            Cell: item =>
                item.original.profileUrl ? (
                    <img
                        style={{
                            height: 100,
                            margin: 0,
                            objectFit: 'scale-down',
                        }}
                        src={item.original.profileUrl}
                        alt={'img'}
                    />
                ) : null,
            centerCell: true,
            sortable: false,
        },
        {
            Header: 'EMP.Nombre',
            headerClassName: 'header_cell_string',
            accessor: 'name',
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'EMP.Apellidos',
            headerClassName: 'header_cell_string',
            accessor: 'surname',
            className: 'cell_string',
            filterable: true,
        },
        {
            Header: 'Tarea',
            headerClassName: 'header_cell_string',
            accessor: 'taskName',
            className: 'cell_string',
            filterable: true,
            sortable: true,
        },
        {
            Header: 'Num. de cajas',
            headerClassName: 'header_cell_number',
            accessor: 'boxesCount',
            Cell: item => <>{customFormatNumber(item.original.boxesCount)}</>,
            sortable: false,
            className: 'cell_number',
            width: 130,
        },
    ];
    return IsInRuleset('REPORTS_EMPLOYEES_WORKING_VIEW') ? (
        <PaginatedTableComponent defaultPageSize={10} columnFormat={columnFormat} service={PersonalServices.get} pdf={true} excel={true} loading={true} showSizeOptions={true} />
    ) : null;
};

export const EmployeesWorkingNow = connect<SProps, {}, {}, AppStoreState>(state => ({
    employeeCodeModeParam: ParametersSelectors.getValueOfParameter(state, ParameterCPN.EMPLOYEE_CODE_MODE),
}))(EmployeesWorkingNowBase);
