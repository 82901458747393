// import {palletScheme, palletWithMaketypeAndBoxesScheme} from './pallet';

export const ptableScheme = {
  additionalProperties: true,
  type: 'object',
  required: ['id', 'name', 'assigned_to', 'reserved_to', 'area_id'],
  properties: {
    id: {type: 'string'},
    name: {type: 'string'},
    assigned_to: {oneOf: [{type: 'string'}, {type: 'null'}]},
    reserved_to: {oneOf: [{type: 'string'}, {type: 'null'}]},
    area_id: {type: 'string'},
    // pallet: {
    //   oneOf: [palletWithMaketypeAndBoxesScheme, palletScheme, {type: 'null'}],
    // },
  },
};

// export const ptableWithPalletsScheme = {
//   ...ptableScheme,
//   required: ptableScheme.required.concat('pallet'),
//   properties: {
//     ...ptableScheme.properties,
//     pallet: {
//       oneOf: [palletWithMaketypeAndBoxesScheme, palletScheme, {type: 'null'}],
//     },
//   },
// };
