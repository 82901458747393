import {LaneRatiosType} from './types/index';
import {fetcher} from 'src/global/fetcher';
import {LanePerformanceHourType, LanePersonnelHourType} from 'src/app/lanemanager/charts/store/types';

const prefix = 'production/stats/report';

export const ChartsServices = {
    performanceHour: (areaId: string, workShift?: string) =>
        fetcher.get<LanePerformanceHourType>(`${prefix}/performance-hour/criteria`, {
            params: {
                area_id: areaId,
                work_shift: workShift,
            },
        }),
    personnelHour: (areaId: string, workShift?: string) =>
        fetcher.get<LanePersonnelHourType>(`${prefix}/personnel-hour/criteria`, {
            params: {
                area_id: areaId,
                work_shift: workShift,
            },
        }),
    ratiosDateWorkshift: (areaId: string, workShift?: string, toWorkShift?: string, areaShift?: string) =>
        fetcher.get<LaneRatiosType>(`${prefix}/ratios/criteria`, {
            params: {
                area_id: areaId,
                work_shift: workShift,
                to_work_shift: toWorkShift,
                area_shift: areaShift,
            },
        }),

    kgDumpedHour: (areaId: string, currentDate?: string) =>
        fetcher.get<any>(`${prefix}/kg-dumped-hour/criteria`, {
            params: {
                area_id: areaId,
                current_date: currentDate,
            },
        }),

    CostKgDumpedWeek: (areaId: string, workShift?: string) =>
        fetcher.get<any>(`${prefix}/cost-kg-dumped-week-mode2/criteria`, {
            params: {
                area_id: areaId,
                work_shift: workShift,
            },
        }),

    kgDumpedWeek: (areaId: string, workShift?: string) =>
        fetcher.get<any>(`${prefix}/kg-dumped-week-mode2/criteria`, {
            params: {
                area_id: areaId,
                work_shift: workShift,
            },
        }),

    PersonnelKgDumpedWeek: (areaId: string, workShift?: string) =>
        fetcher.get<any>(`${prefix}/personnel-hour-week-mode2/criteria`, {
            params: {
                area_id: areaId,
                work_shift: workShift,
            },
        }),

    totalWeek: (areaId: string, workShift?: string) =>
        fetcher.get<any>(`${prefix}/total-week-mode2/criteria`, {
            params: {
                area_id: areaId,
                work_shift: workShift,
            },
        }),

    getResumeDataByAreaAndWorkshift: (areaId: string, initialWorkshift?: string, finalWorkshift?: string) =>
        fetcher.get<any>(`${prefix}/resume-data/area/${areaId}/initialWorkshift/${initialWorkshift}/finalWorkshift/${finalWorkshift}`),
};
