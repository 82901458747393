import styled from 'styled-components';

const TableWrap = styled.div`
  margin: 30px auto;
  width: 95%;
  background-color: #eaeaea;
`;

const TerminalWrap = styled.div`
  margin: 100px auto;
  width: 400px;
  background-color: #eaeaea;
`;

export {TableWrap, TerminalWrap};
