import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

import {DowntimeRequestNew, DowntimeResponsePreflight, LaneDowntime} from './types';

const prefix = 'presence/downtimes';

export const DowntimeServices = {
  getDowntimes: ({params, args}: PaginatedServiceArgs) =>
    guardedFetcher.get<PaginateResponse<LaneDowntime>>(
      `${prefix}/area/${args || 'NO_EXISTE_LINEA'}`,
      {params},
    ),
  getPreflight: (areaId: string) =>
    guardedFetcher.get<DowntimeResponsePreflight>(`${prefix}/area/${areaId}/preflight`),
  new: (areaId: string, downtime: DowntimeRequestNew) =>
    guardedFetcher.post<LaneDowntime>(`${prefix}/area/${areaId}`, downtime),
  continue: (areaId: string, downtimeId: string) =>
    guardedFetcher.post<LaneDowntime>(`${prefix}/area/${areaId}/continue`, {
      downtime_id: downtimeId,
    }),
  current: (areaId: string) =>
    guardedFetcher.get<LaneDowntime>(`${prefix}/area/${areaId}/current`),
  newBreakDowntime: (areaId: string, downtime: DowntimeRequestNew) =>
    guardedFetcher.post<LaneDowntime>(`${prefix}/area/${areaId}/breakdowntime`, downtime),
};
