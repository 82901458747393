import 'babel-polyfill';
import 'react-table/react-table.css';
import './assets/fonts/fonts.scss';
import './assets/scss/App.scss';
import {Cancelable} from 'lodash';
import throttle from 'lodash/throttle';
import * as React from 'react';
import {Provider} from 'react-redux';
import {Store} from 'redux';
import {LoginSelectors} from './app/login/store/login.selectors';
import {Typography} from '@material-ui/core';
import {green, red, blue, purple} from '@material-ui/core/colors';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {GetGrantSet, RULESET} from 'src/global/authorization/grantSet';
import {AppWaiterWrapper, InjectGlobal} from 'src/global/styled';
import {history, store, AppStoreState} from 'src/store';
import {saveState} from 'src/utils/persisted-state';
import {ErrorHandler} from 'src/utils/ErrorHandler';
import {NotificationConnected} from 'src/utils/Notification';
import {Routes} from 'src/Routes';
import {dispatcher} from 'src/store/store';
import {notifyCloseWrapper} from 'src/utils/Notification/actions';
import {FetchSystemParameters, SYSTEM_PARAMETERS} from './global/systemParameters/systemParameters';
import {FetchInitialParameters, INITIAL_PARAMETERS, findInitialParameter} from './global/initalParameters/initialParameters';
import {ParameterCPN} from './types/app_types';
import {ModulesListType} from './app/menu/store/types';
import {generateUrlImage} from './global/assets';

const createThemeApp = (primaryColor: string, secondaryColor: string) => {
    return createMuiTheme({
        typography: {
            useNextVariants: true,
        },
        palette: {
            type: 'light',
            secondary: getColorEnv(secondaryColor || ''),
            primary: getColorEnv(primaryColor || ''),
        },
    });
};

type State = Readonly<{
    name: string;
    asyncStore: any;
    grantsets?: any[];
    systemParameters?: any[];
    initialParameters?: any[];
    modules?: ModulesListType;
}>;
const initialState: Readonly<State> = {name: '', asyncStore: false};

export let storeSavingThrottle: (() => void) & Cancelable;

class App extends React.Component<{}, State> {
    readonly state = initialState;
    private resolvedStore: Store<AppStoreState> = {} as any;
    private theme: any = null;

    componentDidMount() {
        store().then(async (asynkedStore: any) => {
            this.resolvedStore = asynkedStore;
            this.setState((prevState: State) => ({
                ...prevState,
                asyncStore: true,
            }));
            dispatcher(notifyCloseWrapper());
            //if (LoginSelectors.getUserData(asynkedStore.getState()).userId) {
            await this.awaitSystemParameters();
            //} else this.setState({systemParameters: []});
            await this.awaitInitialParameters();
            this.setCustomerData();
            if (LoginSelectors.getUserData(asynkedStore.getState()).userId) {
                this.awaitGrantsets();
            } else this.setState({grantsets: []});
            asynkedStore.subscribe(
                (storeSavingThrottle = throttle(() => {
                    saveState(asynkedStore.getState());
                }, 750)),
            );
        });
    }

    setCustomerData = () => {
        document.title = (findInitialParameter(ParameterCPN.CUSTOMER_NAME)?.value || '') + (process.env.NODE_ENV !== 'production' ? ' - DEV' : '');

        const favicon: any = document.getElementById('favicon');
        if (favicon) {
            favicon.href = generateUrlImage('favicon.ico');
        }

        this.theme = createThemeApp(
            findInitialParameter(ParameterCPN.FRONT_APP_PRIMARY_COLOR)?.value || '',
            findInitialParameter(ParameterCPN.FRONT_APP_SECUNDARY_COLOR)?.value || '',
        );
    };

    awaitInitialParameters = async () => {
        const done = await FetchInitialParameters();
        if (done) this.setState({initialParameters: INITIAL_PARAMETERS.parameters});
    };

    awaitSystemParameters = async () => {
        const done = await FetchSystemParameters();
        if (done) this.setState({systemParameters: SYSTEM_PARAMETERS.parameters});
    };

    awaitGrantsets = async () => {
        const done = await GetGrantSet();
        if (done) this.setState({grantsets: RULESET.grantsets});
    };

    render() {
        if (this.state.asyncStore && this.state.grantsets && this.state.initialParameters) {
            return (
                <>
                    <MuiThemeProvider theme={this.theme}>
                        <ErrorHandler>
                            <Provider store={this.resolvedStore}>
                                <>
                                    <NotificationConnected />
                                    <Routes history={history} />
                                </>
                            </Provider>
                        </ErrorHandler>
                    </MuiThemeProvider>
                    <InjectGlobal />
                </>
            );
        } else
            return (
                <AppWaiterWrapper>
                    <div
                        style={{
                            alignContent: 'center',
                            margin: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <CircularProgress style={{alignContent: 'center', margin: 'auto', marginBottom: 10}} />
                        <Typography>Accediendo a la aplicación....</Typography>
                    </div>
                </AppWaiterWrapper>
            );
    }
}

let Application: any;
Application = App;

function getColorEnv(variableColor: string) {
    switch (variableColor) {
        case 'green':
            return green;
        case 'red':
            return red;
        case 'blue':
            return blue;
        case 'purple':
            return purple;

        default:
            return green;
    }
}

export {Application};
