import * as React from 'react';
import {RouteComponentProps} from 'react-router';
import styled from 'styled-components';
import qs from 'qs';
import seyte from 'src/assets/seyte.svg';
import {OCard} from 'src/shared/style';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {GraphicsServices} from 'src/app/graphics/store/Graphics.services';
import {DumpInfo} from 'src/app/graphics/DumpInfo';
import {ProdPartsMesh} from 'src/app/graphics/ProdPartsMesh';
import {PtablesMesh} from 'src/app/graphics/PtablesMesh';
import {ProdPartGeneric} from 'src/app/lanemanager/lane/store/types';
import {PerformanceGraph} from 'src/app/graphics/PerformanceGraph';
import {Typography} from '@material-ui/core';
import {notify} from 'src/utils/Notification';
import {PersonnelTable} from 'src/app/graphics/PersonnelTable';
import {dispatcher} from 'src/store/store';
import {notifyCloseWrapper} from 'src/utils/Notification/actions';

type State = Readonly<{
  section: 1 | 2 | 3;
  lanes: string[];
  laneIndex: number;
  laneData: any;
}>;

const initialState: State = {section: 1, lanes: [], laneIndex: 0, laneData: {}};

type ComponentProps = RouteComponentProps;
export class Graphics extends React.Component<ComponentProps, State> {
  private timerSection: any;
  private timerLane: any;
  readonly state = initialState;

  componentDidMount() {
    const lanes = [...this.state.lanes];
    for (const [, value] of Object.entries<string>(
      qs.parse(this.props.location.search, {ignoreQueryPrefix: true}),
    )) {
      lanes.push(value);
    }

    this.timerSection = setInterval(() => {
      const nextSection =
        this.state.section === 3 ? 1 : ((this.state.section + 1) as any);
      this.setState({
        section: nextSection,
      });
    }, 10000);

    this.timerLane = setInterval(() => {
      if (lanes.length > 1)
        this.setState(
          {
            laneIndex:
              typeof this.state.laneIndex !== 'undefined' &&
              this.state.laneIndex < this.state.lanes.length - 1
                ? this.state.laneIndex + 1
                : 0,
            laneData: {},
          },
          () => this.fetchAreaData(),
        );
      else this.fetchAreaData();
    }, 30000);
    this.setState({lanes}, () => this.fetchAreaData());
  }

  componentWillUnmount() {
    clearInterval(this.timerSection);
    this.timerLane && clearInterval(this.timerLane);
  }

  private fetchAreaData = async () => {
    dispatcher(notifyCloseWrapper());

    if (this.state.lanes.length > 0) {
      const data = await fetchWithFeedback(
        GraphicsServices.getAllAreaData(this.state.lanes[this.state.laneIndex]),
      );
      data.prodparts = colorProdparts(data.prodparts.prodparts);
      data.ptables = colorPtables(data);
      this.setState({laneData: data});
    } else {
      notify({message: 'Hace falta elegir el área en la url.', status: 'error'});
    }
  };

  render() {
    const {section, laneData} = this.state;
    return (
      <>
        <img
          src={seyte}
          style={{position: 'fixed', height: 59, right: 15, top: 1}}
          alt={'img'}
        />
        <WrapDiv>
          <OCard
            width={'100%'}
            height={'5%'}
            contentHeight={'97.5%%'}
            marginBottom={'7px'}
            contentPadding={'0px'}>
            <Typography style={{textAlign: 'center', fontSize: 35}}>
              {laneData.currentDump
                ? laneData.currentDump.area.name
                : 'Línea - Obteniendo datos...'}
            </Typography>
          </OCard>
          {section === 1 ? (
            <>
              <OCard
                title="Volcado actual"
                titleFontSize={35}
                width={'49.45%'}
                height={'93.6%'}
                contentHeight={'97.5%%'}
                contentPadding={'0px'}>
                <DumpInfo
                  dump={
                    this.state.laneData && this.state.laneData.currentDump
                      ? this.state.laneData.currentDump.dump
                      : {}
                  }
                />
              </OCard>
              <OCard
                title="Personal de la línea"
                titleFontSize={35}
                width={'49.45%'}
                height={'93.6%'}
                contentHeight={'97.5%%'}
                contentPadding={'0px'}>
                <PersonnelTable
                  data={
                    this.state.laneData && this.state.laneData.currentEmployeesByTask
                      ? (this.state.laneData.currentEmployeesByTask as any[]).reduce(
                          (
                            acc: {data: number[]; labels: string[]},
                            curr: {taskName: string; totalEmployees: number},
                          ) => ({
                            labels: acc.labels.concat(curr.taskName),
                            data: acc.data.concat(curr.totalEmployees),
                          }),
                          {data: [], labels: []},
                        )
                      : {data: [], labels: []}
                  }
                />
              </OCard>
            </>
          ) : null}
          {section === 2 ? (
            <>
              <OCard
                title={'Kilos / hora'}
                titleFontSize={35}
                width={'100%'}
                height={'93.6%'}
                contentHeight={'94%'}
                contentPadding={'0px'}>
                <PerformanceGraph chartData={this.state.laneData.performance} />
              </OCard>
            </>
          ) : null}
          {section === 3 ? (
            <>
              <OCard
                title={'Partes de producción'}
                titleFontSize={35}
                heightTitle={'25px'}
                width={'49.45%'}
                height={'93.6%'}
                contentHeight={'99.87%'}
                contentPadding={'0px'}>
                <ProdPartsMesh
                  prodparts={
                    this.state.laneData && this.state.laneData.prodparts
                      ? this.state.laneData.prodparts
                      : []
                  }
                />
              </OCard>
              <OCard
                title={'Mesas de paletizado'}
                titleFontSize={35}
                heightTitle={'25px'}
                width={'49.45%'}
                height={'93.6%'}
                contentHeight={'100%'}
                contentPadding={'0'}>
                <PtablesMesh
                  ptables={
                    laneData && laneData.prodparts && laneData.ptables
                      ? laneData.ptables.map((pt: any) => {
                          return {
                            ...pt,
                            boxes_count: pt.pallet ? pt.pallet.boxes_count : undefined,
                            boxes_per_pallet: pt.pallet
                              ? pt.pallet.boxes_per_pallet
                              : undefined,
                          };
                        })
                      : []
                  }
                />
              </OCard>
            </>
          ) : null}
        </WrapDiv>
      </>
    );
  }
}

const WrapDiv = styled.div`
  padding-top: 5px;
  width: 100%;
  height: calc(100% - 5px);
  display: flex;
  flex-wrap: wrap;
`;

// const WrapVerticalLeftSideDiv = styled.div`
//   width: 49.8%;
//   height: 99.5%;
//   display: flex;
//   flex-direction: column;
// `;

export const rainbow = (numOfSteps: number, step: number) => {
  let r, g, b;
  const h = step / numOfSteps;
  const i = ~~(h * 6);
  const f = h * 6 - i;
  const q = 1 - f;
  switch (i % 6) {
    case 0:
      r = 1;
      g = f;
      b = 0;
      break;
    case 1:
      r = q;
      g = 1;
      b = 0;
      break;
    case 2:
      r = 0;
      g = 1;
      b = f;
      break;
    case 3:
      r = 0;
      g = q;
      b = 1;
      break;
    case 4:
      r = f;
      g = 0;
      b = 1;
      break;
    case 5:
      r = 1;
      g = 0;
      b = q;
      break;
  }
  const c =
    '#' +
    ('00' + (~~((r as number) * 255)).toString(16)).slice(-2) +
    ('00' + (~~((g as number) * 255)).toString(16)).slice(-2) +
    ('00' + (~~((b as number) * 255)).toString(16)).slice(-2);
  return c;
};

const lightOrDark = (color: any) => {
  let r, g, b, hsp;

  if (color.match(/^rgb/)) {
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  if (hsp > 150.5) {
    return 'light';
  } else {
    return 'dark';
  }
};

const colorProdparts = (prodparts: ProdPartGeneric[]) =>
  prodparts.map((prodpart: any, index: number, pps) => {
    prodpart.color = rainbow(pps.length + 1, index + 1);
    prodpart.darkLight = lightOrDark(prodpart.color);
    return prodpart;
  });

const colorPtables = (data: any) =>
  data.ptables.ptables.map((ptable: any) => {
    if (ptable.assigned_to) {
      const prodpartToTable = data.prodparts.find(
        (prodpart: ProdPartGeneric) => prodpart.id === ptable.assigned_to,
      );
      ptable.color = prodpartToTable ? prodpartToTable.color : '#eeeeee';
      ptable.darkLight = prodpartToTable.darkLight;
    } else if (ptable.reserved_to && !ptable.assigned_to) {
      const prodpartToTable = data.prodparts.find(
        (prodpart: ProdPartGeneric) => prodpart.id === ptable.reserved_to,
      );
      ptable.color = prodpartToTable ? prodpartToTable.color : '#eeeeee';
      ptable.darkLight = prodpartToTable.darkLight;
    } else {
      ptable.color = '#eeeeee';
    }
    return ptable;
  });
