import React, {useState, useEffect} from 'react';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import {connect} from 'react-redux';
import {AppStoreState} from 'src/store';
import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {FiltersKpi} from './components/FiltersKpi';
import {CenterControlServices} from 'src/app/administration/centerControl/store/centerControl.services';
import {ConfectionCalculation} from './components/ConfectionCalculation';
import {ULCalculation} from './components/ULCalculation';
import {HourCalculation} from './components/HourCalculation';
import {CostCalculation} from './components/CostCalculation';
import {DowntimeCalculation} from './components/DowntimeCalculation/DowntimeCalculation';
import {Paper} from "@material-ui/core";

type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
};
const KpisReportsBase: React.FunctionComponent<SProps> = () => {
    const [kpiSelected, setKpiSelected] = useState<string | undefined>({
        undefined,
    } as any);

    const [areaSelected, setAreaSelected] = useState<string>({
        undefined,
    } as any);

    const [centerSelected, setCenterSelected] = useState<string>({
        undefined,
    } as any);

    const [dateSelected, setDateSelected] = useState<string[]>({
        undefined,
    } as any);

    const [centers, setCenters] = useState<any>([] as any);

    useEffect(() => {
        CenterControlServices.get().then((res: any) => {
            setCenters([{id: '', name: ''}, ...res.data]);
        });
    }, []);

    return (
        <>
            <div style={{padding:'4px'}}>
                <Paper>
                    <FiltersKpi
                        handleSearch={handleSearch}
                        areas={[]}
                        centers={centers}
                        kpiTypes={[
                            {id: '', name: ''},
                            {id: 'confectionCalculation', name: 'Cálculo de la confección'},
                            {id: 'ulCalculation', name: 'Cálculo de origen'},
                            {id: 'hourCalculation', name: 'Cálculo de horas'},
                            {id: 'costCalculation', name: 'Cálculo de costes'},
                            {id: 'downtimeCalculation', name: 'Cálculo de paradas'},
                        ]}
                    />
                </Paper>
            </div>

            {kpiSelected === 'confectionCalculation' && (
                <>
                    <ConfectionCalculation
                        areaId={areaSelected}
                        centerId={centerSelected}
                        startDate={dateSelected[0]}
                        endDate={dateSelected[1]}
                    />
                </>
            )}
            {kpiSelected === 'ulCalculation' && (
                <>
                    <ULCalculation
                        areaId={areaSelected}
                        centerId={centerSelected}
                        startDate={dateSelected[0]}
                        endDate={dateSelected[1]}
                    />
                </>
            )}
            {kpiSelected === 'hourCalculation' && (
                <>
                    <HourCalculation
                        areaId={areaSelected}
                        centerId={centerSelected}
                        startDate={dateSelected[0]}
                        endDate={dateSelected[1]}
                    />
                </>
            )}
            {kpiSelected === 'costCalculation' && (
                <>
                    <CostCalculation
                        areaId={areaSelected}
                        centerId={centerSelected}
                        startDate={dateSelected[0]}
                        endDate={dateSelected[1]}
                    />
                </>
            )}
            {kpiSelected === 'downtimeCalculation' && (
                <>
                    <DowntimeCalculation
                        areaId={areaSelected}
                        centerId={centerSelected}
                        startDate={dateSelected[0]}
                        endDate={dateSelected[1]}
                    />
                </>
            )}
        </>
    );

    function handleSearch(
        kpiTypeId: string,
        centerId: string,
        areaId: string,
        date: Date[] | undefined,
    ) {
        setAreaSelected(areaId);
        setCenterSelected(centerId);
        setKpiSelected(kpiTypeId);

        let startDate = format(new Date(), 'YYYY-MM-DD', {
            locale: es,
        });
        let endDate = format(new Date(), 'YYYY-MM-DD', {
            locale: es,
        });
        if (date !== undefined) {
            startDate = format(date[0], 'YYYY-MM-DD', {
                locale: es,
            });
            endDate = format(date[1], 'YYYY-MM-DD', {
                locale: es,
            });
        }
        setDateSelected([startDate, endDate]);
    }
};

export const KpisReports = connect<SProps, {}, {}, AppStoreState>(state => ({
    lane: LaneSelectSelectors.getLane(state),
}))(KpisReportsBase);
