import localForage from 'localforage';
import {ParameterCPN} from 'src/types/app_types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {SharedServices} from 'src/shared/shared.services';
import {fillStorage} from 'src/utils/localforage';

export type SystemParameter = {
    name: ParameterCPN;
    value: string;
};

export type SystemParameters = {
    parameters: SystemParameter[];
};

export let SYSTEM_PARAMETERS: SystemParameters = {
    parameters: [],
};

export const FetchSystemParameters = async () => {
    let set;

    set = await fetchWithFeedback(SharedServices.getSystemParameters());
    await systemParametersInstance.clear();
    await fillStorage(systemParametersInstance, set);

    SYSTEM_PARAMETERS.parameters = [...set.systemParameters];

    return Array.isArray(set) ? false : true;
};

export const systemParametersInstance = localForage.createInstance({
    name: 'system-parameters-store',
    version: 1.0,
    storeName: 'system-parameters-store',
});

export const findSystemParameter = (parameterName: string): SystemParameter | undefined => {
    return SYSTEM_PARAMETERS.parameters.find((param: SystemParameter) => param.name === parameterName);
};
