import {ParametersActionType} from './action-types';

export const ParametersActions = {
  success: (parameters: any): any => ({
    type: ParametersActionType.success,
    payload: parameters,
  }),
  clear: (): any => ({
    type: ParametersActionType.error,
  }),
};
