import * as React from 'react';
import {OCard} from 'src/shared/style';
import {KgTotalChart} from 'src/app/information/kpis/charts/confectionCalculation/KgTotalChart';
import {KgTotalProductChart} from 'src/app/information/kpis/charts/confectionCalculation/KgTotalProductChart';
import {KgHourTotalChart} from 'src/app/information/kpis/charts/confectionCalculation/KgHourTotalChart';
import {KgHourTotalProductChart} from 'src/app/information/kpis/charts/confectionCalculation/KgHourTotalProductChart';
import {KgTotalType, KgTotalProductType, KgHourTotalType, KgHourTotalProductType, ProductInfoType} from 'src/app/information/kpis/store/types/chart-types';
import {KpiChartsServices} from 'src/app/information/kpis/store/services';

export interface ConfectionCalculationProps {
    areaId: string;
    centerId: string;
}
type State = {
    areaId: string | undefined;
    centerId: string | undefined;
    date: Date | Date[] | undefined;
    kgTotal: KgTotalType | undefined;
    kgTotalProduct: KgTotalProductType | undefined;
    kgHourTotal: KgHourTotalType | undefined;
    kgHourTotalProduct: KgHourTotalProductType | undefined;
    loadingKgTotalData: boolean;
    loadingKgTotalProductData: boolean;
    loadingKgHourTotalData: boolean;
    loadingKgHourTotalProductData: boolean;
};

const initialState = {
    areaId: undefined,
    centerId: undefined,
    date: undefined,
    kgTotal: {
        kgProduccion: '',
        kgByCentersValues: [],
        kgByCentersLabels: [],
        kgByAreasValues: [],
        kgByAreasLabels: [],
    },
    kgTotalProduct: {
        products: [],
        kg: [],
    },
    kgHourTotal: {
        hours: [],
        kg: [],
    },
    kgHourTotalProduct: {
        products: [],
        hours: [],
    },
    loadingKgTotalData: false,
    loadingKgTotalProductData: false,
    loadingKgHourTotalData: false,
    loadingKgHourTotalProductData: false,
};

type Props = {
    areaId: string;
    centerId: string;
    startDate: string;
    endDate: string;
};

export class ConfectionCalculation extends React.Component<Props, State> {
    state = initialState;

    componentDidMount() {
        this.showData(this.props);
    }

    componentWillReceiveProps(props: Props) {
        this.showData(props);
    }

    showData(props: Props) {
        const {centerId, areaId, startDate, endDate} = props;
        this.setState({loadingKgTotalData: true});
        this.setState({loadingKgTotalProductData: true});
        this.setState({loadingKgHourTotalData: true});
        this.setState({loadingKgHourTotalProductData: true});

        KpiChartsServices.kgTotal(centerId, areaId, startDate, endDate).then((res: any) => {
            const centersLabel = [];
            const centersValue = [];
            const areasLabel = [];
            const areasValue = [];

            if (res) {
                for (const index in res.data.kgByCenters) {
                    if (index) {
                        centersLabel.push(index);
                        centersValue.push(res.data.kgByCenters[index]);
                    }
                }
                for (const index in res.data.kgByAreas) {
                    if (index) {
                        areasLabel.push(index);
                        areasValue.push(res.data.kgByAreas[index]);
                    }
                }
            }

            const data = {
                kgProduccion: res.data.kgProduccion,
                kgByCentersValues: centersValue,
                kgByCentersLabels: centersLabel,
                kgByAreasValues: areasValue,
                kgByAreasLabels: areasLabel,
            };

            this.setState({kgTotal: data});
            this.setState({loadingKgTotalData: false});
        });

        KpiChartsServices.kgTotalProduct(centerId, areaId, startDate, endDate).then((res1: any) => {
            const products = [];
            const kg = [];
            const maxResult = 30;
            let numResult = 0;
            if (res1) {
                for (const index in res1.data) {
                    if (index) {
                        numResult++;
                        if (numResult > maxResult) {
                            break;
                        }
                        products.push(index);
                        kg.push(res1.data[index]);
                    }
                }
            }

            const data1 = {
                products: products,
                kg: kg,
            };

            this.setState({kgTotalProduct: data1});
            this.setState({loadingKgTotalProductData: false});
        });

        KpiChartsServices.kgHourTotal(centerId, areaId, startDate, endDate).then((res2: any) => {
            const hours = [];
            const kg = [];

            if (res2) {
                for (const index in res2.data) {
                    if (index) {
                        hours.push(index);
                        kg.push(res2.data[index]);
                    }
                }
            }

            const data2 = {
                hours: hours,
                kg: kg,
            };

            this.setState({kgHourTotal: data2});
            this.setState({loadingKgHourTotalData: false});
        });

        KpiChartsServices.kgHourTotalProduct(centerId, areaId, startDate, endDate).then((res3: any) => {
            const products = [];
            const hours = [];
            let addHours = true;

            if (res3) {
                for (const productName in res3.data) {
                    if (res3.data) {
                        const dataProduct = res3.data[productName];
                        const productInfo: ProductInfoType = {
                            name: productName,
                            kg: [],
                        };
                        const kgsHour: number[] = [];
                        for (const hour in dataProduct) {
                            if (dataProduct) {
                                kgsHour.push(dataProduct[hour]);
                                if (addHours) {
                                    hours.push(hour);
                                }
                            }
                        }
                        addHours = false;
                        productInfo.kg = kgsHour;
                        products.push(productInfo);
                    }
                }
            }

            const data3 = {
                products: products,
                hours: hours,
            };

            this.setState({kgHourTotalProduct: data3});
            this.setState({loadingKgHourTotalProductData: false});
        });
    }

    public render() {
        return (
            <>
                <h1 className="text-center">Cálculo de la confección</h1>
                <OCard title={'Kg totales'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'20px'}>
                    <KgTotalChart chartData={this.state.kgTotal} isLoading={this.state.loadingKgTotalData} />
                </OCard>
                <OCard title={'Kg por confección'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'20px'}>
                    {typeof this.state.kgTotalProduct !== 'undefined' &&
                        typeof this.state.kgTotalProduct.products !== 'undefined' &&
                        this.state.kgTotalProduct.products.length > 0 && (
                            <KgTotalProductChart chartData={this.state.kgTotalProduct} isLoading={this.state.loadingKgTotalProductData} />
                        )}
                </OCard>
                <OCard title={'Kg/tiempo totales'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'20px'}>
                    <KgHourTotalChart chartData={this.state.kgHourTotal} isLoading={this.state.loadingKgHourTotalData} />
                </OCard>
                <OCard title={'Kg/tiempo por confección'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'20px'}>
                    {typeof this.state.kgHourTotalProduct !== 'undefined' &&
                        typeof this.state.kgHourTotalProduct.hours !== 'undefined' &&
                        this.state.kgHourTotalProduct.hours.length > 0 && (
                            <KgHourTotalProductChart chartData={this.state.kgHourTotalProduct} isLoading={this.state.loadingKgHourTotalProductData} />
                        )}
                </OCard>
                {/* </WrapFullContainer> */}
            </>
        );
    }
}
