export const employeeScheme = {
  additionalProperties: true,
  type: 'object',
  required: ['id', 'name', 'surname', 'task_active'],
  properties: {
    id: {type: 'string'},
    name: {type: 'string'},
    surname: {type: 'string'},
    task_active: {type: 'string'},
  },
};
