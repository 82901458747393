import * as React from 'react';
import * as ReactDOM from 'react-dom';

import {ENV} from 'src/global/api';
import {initGuardedFetcher} from 'src/utils/guardedFetcher';
import 'src/global/styles/global.css';

import {Application} from './App';

const disableReactDevTools = (): void => {
  const noop = (): void => undefined;
  const DEV_TOOLS = window.__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === 'function' ? noop : null;
    }
  }
};

if (ENV === 'production') disableReactDevTools();

initGuardedFetcher();

ReactDOM.render(<Application />, document.getElementById('root'));
