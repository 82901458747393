import {TerminalActionType} from 'src/terminal/store/action-types';
import {
  ApiAllTasksResponse,
  ApiAllTasksUnit,
  ApiPersonalTasksResponse,
  TaskPayload,
  TerminalAction,
} from 'src/terminal/store/actions';

export interface Task {
  id: string;
  name: string;
  area_id?: number;
}

export interface User {
  id: string;
  name: string;
  surname: string;
  job?: string;
  lastVisit?: Date;
}

export interface TerminalStoreState {
  tasks: Task[];
  users: User[];
  usersTasks: UserTasks;
  authorized: string;
}

export interface UserTasks {
  [userId: string]: UserTask[];
}

export interface UserTask {
  taskId: string;
  state: 'finalized' | 'current' | 'pending';
  area_id: string;
  assignedTime?: Date;
  startedTime?: Date;
  finishedTime?: Date;
  middleTime?: Date;
}

const defaultTerminalStateX: TerminalStoreState = {
  tasks: [],
  users: [],
  usersTasks: {},
  authorized: '',
};

// const defaultTerminalState: TerminalStoreState = {
//   tasks: [
//     {id: 1, name: 'tarea buena'},
//     {id: 2, name: 'tarea mala'},
//     {id: 3, name: 'tarea masomenos'},
//     {id: 4, name: 'tarea interesante'},
//     {id: 5, name: 'otra va'},
//   ],
//   users: [
//     {
//       id: 1,
//       name: 'Pepito',
//       surname: 'Fabuloso',
//       job: 'limpieza',
//     },
//     {
//       id: 2,
//       name: 'Maria',
//       surname: 'Aquella',
//       job: 'confección',
//     },
//   ],
//   usersTasks: {
//     1: [
//       {
//         taskId: 1,
//         state: 'pending',
//       },
//       {
//         taskId: 2,
//         state: 'pending',
//       },
//       {
//         taskId: 4,
//         state: 'pending',
//       },
//     ],
//     2: [
//       {
//         taskId: 3,
//         state: 'pending',
//       },
//       {
//         taskId: 5,
//         state: 'pending',
//       },
//       {
//         taskId: 2,
//         state: 'pending',
//       },
//     ],
//   },
//   authorized: 0,
// };

export default (
  state: TerminalStoreState = defaultTerminalStateX,
  action: TerminalAction,
): TerminalStoreState => {
  switch (action.type) {
    case TerminalActionType.auth:
      return {
        ...state,
        authorized:
          state.users.find((element: User) => element.id === action.payload) === undefined
            ? 0
            : action.payload,
      } as TerminalStoreState;
    case TerminalActionType.choose:
      const actionChoosePayload = action.payload as TaskPayload;
      return {
        ...state,
        usersTasks: {
          ...state.usersTasks,
          [actionChoosePayload.userId]: state.usersTasks[actionChoosePayload.userId].map(
            (ut: UserTask) => {
              if (ut.taskId === actionChoosePayload.task) {
                return {
                  ...ut,
                  state: 'current',
                  startedTime: new Date().toISOString(),
                };
              }
              return ut;
            },
          ),
        },
      } as TerminalStoreState;
    case TerminalActionType.unchoose:
      const actionUnchoosePayload = action.payload as TaskPayload;
      return {
        ...state,
        usersTasks: {
          ...state.usersTasks,
          [actionUnchoosePayload.userId]: state.usersTasks[
            actionUnchoosePayload.userId
          ].map((ut: UserTask) => {
            if (ut.taskId === actionUnchoosePayload.task) {
              return {...ut, state: 'pending'};
            }
            return ut;
          }),
        },
      } as TerminalStoreState;
    case TerminalActionType.finalizeTask:
      const actionFinalizeTPayload = action.payload as TaskPayload;
      return {
        ...state,
        usersTasks: {
          ...state.usersTasks,
          [actionFinalizeTPayload.userId]: state.usersTasks[
            actionFinalizeTPayload.userId
          ].map((ut: UserTask) => {
            if (ut.taskId === actionFinalizeTPayload.task) {
              return {
                ...ut,
                state: 'finalized',
                finishedTime: new Date().toISOString(),
              };
            }
            return ut;
          }),
        },
      } as TerminalStoreState;
    case TerminalActionType.finalize:
      return {...state, authorized: ''};
    case TerminalActionType.load: {
      const loadTasksPayload = action.payload as ApiPersonalTasksResponse;
      const addTasks = () => {
        const newTasks = [...state.tasks];
        loadTasksPayload.tasks.forEach(t => {
          const exist = newTasks.find(tk => tk.id === t.id);
          if (!exist)
            newTasks.push({
              id: t.id,
              name: t.name,
            });
        });
        return newTasks;
      };
      const addUser = () => {
        const newUsers = [...state.users];
        const exist = newUsers.find(u => u.id === loadTasksPayload.employee.id);
        if (!exist)
          newUsers.push({
            id: loadTasksPayload.employee.id,
            name: loadTasksPayload.employee.name,
            surname: loadTasksPayload.employee.surname,
            lastVisit: loadTasksPayload.employee.last_visit,
          });
        return newUsers;
      };
      const addUsersTasks = (): UserTasks => {
        return {
          ...state.usersTasks,
          [loadTasksPayload.employee.id]: loadTasksPayload.tasks.map(t => {
            if (
              loadTasksPayload.active_task &&
              loadTasksPayload.active_task.id === t.id
            ) {
              return {
                taskId: t.id,
                state: loadTasksPayload.active_task.state,
                area_id: loadTasksPayload.active_task!.area_id!,
                assignedTime: loadTasksPayload.active_task.created_at,
                startedTime: t.updated_at,
                finishedTime: t.finished_at,
              };
            } else
              return {
                taskId: t.id,
                area_id: loadTasksPayload.active_task
                  ? loadTasksPayload.active_task!.area_id!
                  : '',
                state: t.state ? t.state : 'pending',
                assignedTime: t.created_at,
                startedTime: t.updated_at,
                finishedTime: t.finished_at,
              };
          }),
        };
      };
      return {
        authorized: state.authorized,
        tasks: addTasks(),
        users: addUser(),
        usersTasks: addUsersTasks(),
      } as TerminalStoreState;
    }
    case TerminalActionType.loadAll:
      const loadAllTasksPayload = action.payload as ApiAllTasksResponse;
      let tasks: Task[] = [];
      let users: User[] = [];
      const usersTasks: UserTasks = {};
      loadAllTasksPayload.all_tasks.forEach((elem: ApiAllTasksUnit) => {
        if (tasks.length === 0)
          tasks.push({
            id: elem.task.id,
            name: elem.task.name,
          });
        if (users.length === 0)
          users.push({
            id: elem.employee.id,
            name: elem.employee.name,
            surname: elem.employee.surname,
            lastVisit: elem.employee.last_visit,
          });
        if (Object.keys(usersTasks).length === 0)
          Object.defineProperty(usersTasks, elem.employee.id, {
            value: [
              {
                taskId: elem.task.id,
                state: elem.task.state ? elem.task.state : 'pending',
              },
            ],
          });
        const existTask = tasks.find(t => t.id !== elem.task.id);
        tasks = !existTask
          ? tasks.concat({
              id: elem.task.id,
              name: elem.task.name,
            } as Task)
          : tasks;
        const existUser = users.find(u => u.id !== elem.employee.id);
        users = !existUser
          ? users.concat({
              name: elem.employee.name,
              surname: elem.employee.surname,
              id: elem.employee.id,
              lastVisit: elem.employee.last_visit,
            })
          : users;
        Object.keys(usersTasks).forEach((uid: string) => {
          usersTasks[uid].push({
            taskId: elem.task.id,
            state: elem.task.state ? elem.task.state : 'pending',
            area_id: elem.task.area_id!,
            assignedTime: elem.task.created_at,
            startedTime: elem.task.updated_at,
            finishedTime: elem.task.finished_at,
          });
        });
      });
      return {
        authorized: state.authorized,
        users,
        tasks,
        usersTasks,
      } as TerminalStoreState;
    default:
      return state;
  }
};
