import {ajv} from 'src/utils/ajv';
import {areaScheme} from 'src/app/lanemanager/lane/store/validators/schemes/area';
import {downtimeCategoryScheme} from './schemes/downtimeCategory';
import {downtimeCauseScheme} from './schemes/downtimeCause';

const downtimePreflightScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['area', 'categories', 'causes'],
  properties: {
    area: areaScheme,
    categories: {
      type: 'array',
      items: {oneOf: [downtimeCategoryScheme, {type: 'null'}]},
    },
    causes: {
      type: 'array',
      items: {oneOf: [downtimeCauseScheme, {type: 'null'}]},
    },
  },
};

const precompile = ajv.compile(downtimePreflightScheme);
export const downtimePreflightResValidate = precompile;
