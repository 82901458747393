import {push} from 'connected-react-router';
import React from 'react';
import {connect} from 'react-redux';
import {Switch} from 'react-router';
import {StatusBar} from 'src/app/statusbar/StatusBar';
import {TabContainerNoTabs} from 'src/app/lanemanager/Wrap';
import {createStyles, withStyles, Theme} from '@material-ui/core/styles';
import {GuardSingleAccessRoute} from 'src/global/authorization/GuardAuthorizationRoute';
import {AppStoreState} from 'src/store';
import {RouteHelpers} from 'src/utils/routerHelpers';
import {FunctionalityControl} from './functionalitiesControl/FunctionalityControl';
import {MenuItemsControl} from "./menuItemsControl/MenuItemsControl";
import {ModulesControl} from './modulesControl/ModulesControl';

type SProps = {pathname: string};
type DProps = {push(path: string): void};
type Props = {
    classes: {
        root: string;
    };
};

type State = {
    routeTitle: string;
};
const initialState: State = {routeTitle: ''};

const styles = ({palette}: Theme) =>
    createStyles({
        root: {
            backgroundColor: palette.background.paper,
            padding: 0,
            height: 'calc(100% - 48px)',
            width: '100%',
        },
    });

export class SystemIndexPageBase extends React.Component<Props & SProps & DProps, State> {
    readonly state = initialState;

    componentDidMount() {
        this.findRoute();
    }
    componentWillReceiveProps(nextProps: SProps) {
        this.findRoute(nextProps.pathname);
    }

    render() {
        const {classes} = this.props;
        return (
            <>
                <StatusBar title={this.state.routeTitle} />
                <div className={classes.root}>
                    <TabContainerNoTabs>
                        <Switch>
                            <GuardSingleAccessRoute
                                accessSet={['SYSTEM']}
                                exact={true}
                                path="/system/functionalities"
                                component={FunctionalityControl}
                            />
                            <GuardSingleAccessRoute
                                accessSet={['SYSTEM']}
                                exact={true}
                                path="/system/menuItems"
                                component={MenuItemsControl}
                            />
                            <GuardSingleAccessRoute
                                accessSet={['SYSTEM']}
                                exact={true}
                                path="/system/modules"
                                component={ModulesControl}
                            />
                        </Switch>
                    </TabContainerNoTabs>
                </div>
            </>
        );
    }

    private findRoute = (pathname: string = this.props.pathname) => {
        const path = pathname.split('/')[2];
        const routeTitle = this.getTitle(path);
        this.setState({routeTitle});
    };

    private getTitle = (path: string) => {
        switch (path) {
            case 'functionalities':
                return 'Gestión de las funcionalidades del cliente';
            case 'menuItems':
                return 'Gestión de las opciones de menú del cliente';
            case 'modules':
                return 'Gestión de los módulos del cliente';
            default:
                return 'Sistema';
        }
    };
}

export const SystemIndexPage = connect<SProps, DProps, Props, AppStoreState>(
    state => ({
        pathname: RouteHelpers.getLocation(state),
    }),
    {push},
)(withStyles(styles)(SystemIndexPageBase));
