import React from 'react';
import isEqual from 'react-fast-compare';
import Select from 'react-select';
import animated from 'react-select/lib/animated';
import styled from 'styled-components';

import {CausesControlServices} from 'src/app/administration/causesControl/store/causesControl.services';
import {
  CausesControlResponse,
  CauseControlUnit,
} from 'src/app/administration/causesControl/store/causesControl.types';
import {WrapForm, WrapSaveButton} from 'src/app/administration/export/styled/Wrap';
import {TCTaskResponse} from 'src/app/administration/tasksControl/store/tasksControl.types';
import {Typography} from '@material-ui/core';
import {CustomDropdown} from 'src/shared/style';
import {CustomButton} from 'src/shared/style/CustomButton';
import {CustomTextField} from 'src/shared/style/CustomTextField';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notifyValidator} from 'src/utils/notifyValidator';

const initialState: State = {
  id: '',
  name: '',
  nameValid: true,
  categoryId: '',
  downtimeTaskId: '',
  tasksAffected: [],
  areasAffected: [],
};
type State = Readonly<{
  id: string;
  name: string;
  nameValid: boolean;
  categoryId: string;
  downtimeTaskId: string;
  tasksAffected: Array<{value: string; label: string}>;
  areasAffected: Array<{value: string; label: string}>;
}>;

type Props = {
  preflight: CausesControlResponse;
  cause?: CauseControlUnit;
  areasList: Array<{id: string; name: string}>;
  tasksList: TCTaskResponse['tasks'];
  handleClose(): void;
  updateDevice(): void;
};

export class CauseForm extends React.Component<Props, State> {
  readonly state = initialState;

  inputRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    if (this.props.cause) {
      const {
        id,
        name,
        category_id,
        tasksAffected,
        areasAffected,
        downtime_task_id,
      } = this.props.cause;
      this.setState({
        id,
        name,
        downtimeTaskId: downtime_task_id,
        areasAffected: this.transformToPicker(areasAffected),
        tasksAffected: this.transformToPicker(tasksAffected),
        categoryId: category_id,
      });
    } else this.setState(initialState);
  }

  private transformToPicker = (arr: any[]) => {
    return arr.map(el => ({value: el.id, label: el.name}));
  };

  render() {
    const {
      id,
      name,
      areasAffected,
      tasksAffected,
      downtimeTaskId,
      categoryId,
      nameValid,
    } = this.state;
    const {areasList, preflight, tasksList} = this.props;
    return (
      <WrapForm margin={'5px 10px'}>
        <CustomTextField
          margin={'5px auto 0 auto'}
          label={'Nombre'}
          variant="outlined"
          error={!nameValid}
          width={'50%'}
          onChange={this.handleInputs('name')}
          value={name}
        />
        <DataDiv>
          <div style={{width: '47%'}}>
            <CustomDropdown
              nullEntry={true}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 350,
                  },
                },
              }}
              dropdownName="downtimeTask"
              value={downtimeTaskId}
              title="Tarea de la parada"
              data={
                tasksList && tasksList.length ? tasksList.filter(t => t.is_downtime) : []
              }
              handleChoose={e => this.setState({downtimeTaskId: e.target.value})}
            />
          </div>
          <div style={{flexGrow: 1}} />
          <div style={{width: '47%'}}>
            <CustomDropdown
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
              dropdownName="category"
              value={categoryId}
              title="Categoría"
              data={preflight ? preflight.categories : []}
              handleChoose={e => this.setState({categoryId: e.target.value})}
            />
          </div>
        </DataDiv>
        {id && (
          <Typography style={{margin: '0 0 5px 0'}}>
            <label>Áreas afectadas</label>
            <Select
              backspaceRemovesValue={false}
              menuPlacement="top"
              value={areasAffected}
              styles={affectedAreasSelect}
              isMulti={true}
              closeMenuOnSelect={false}
              isClearable={false}
              // @ts-ignore
              components={animated()}
              options={this.transformToPicker(areasList)}
              onChange={async sel => {
                const cast = sel as Array<{
                  value: string;
                  label: string;
                }>;

                try {
                  const newAssigned = cast.filter(
                    c => !areasAffected.find(ajt => isEqual(c, ajt)),
                  );
                  if (newAssigned.length) {
                    await fetchWithFeedback(
                      CausesControlServices.toggleArea({
                        downtimeCauseId: id,
                        areaId: newAssigned[0].value,
                      }),
                      {
                        successMessage: 'Se ha añadido el área correctamente',
                        errorMessage: 'No se ha podido añadir el área a esta causa',
                      },
                    );
                  } else {
                    const newUnassigned = areasAffected.filter(
                      ajt => !cast.find(c => isEqual(c, ajt)),
                    );
                    if (newUnassigned.length) {
                      await fetchWithFeedback(
                        CausesControlServices.toggleArea({
                          downtimeCauseId: id,
                          areaId: newUnassigned[0].value,
                        }),
                        {
                          successMessage: 'Se ha quitado el área correctamente',
                          errorMessage: 'No se ha podido quitar el área de esta causa',
                        },
                      );
                    }
                  }
                  this.setState({areasAffected: sel as any});
                } catch (error) {
                  console.error(error);
                }
              }}
            />
            <div style={{margin: 5}} />
            <label>Tareas afectadas</label>
            <Select
              backspaceRemovesValue={false}
              value={tasksAffected}
              menuPlacement="top"
              isMulti={true}
              closeMenuOnSelect={false}
              isClearable={false}
              styles={affectedTasksSelect}
              // @ts-ignore
              components={animated()}
              options={this.transformToPicker(tasksList)}
              onChange={async sel => {
                const cast = sel as Array<{
                  value: string;
                  label: string;
                }>;
                try {
                  const newAssigned = cast.filter(
                    c => !tasksAffected.find(ajt => isEqual(c, ajt)),
                  );
                  if (newAssigned.length) {
                    await fetchWithFeedback(
                      CausesControlServices.toggleTask({
                        downtimeCauseId: id,
                        taskId: newAssigned[0].value,
                      }),
                      {
                        successMessage: 'Se ha añadido la tarea correctamente',
                        errorMessage: 'No se ha podido añadir la tarea a esta causa',
                      },
                    );
                  } else {
                    const newUnassigned = tasksAffected.filter(
                      ajt => !cast.find(c => isEqual(c, ajt)),
                    );
                    if (newUnassigned.length) {
                      await fetchWithFeedback(
                        CausesControlServices.toggleTask({
                          downtimeCauseId: id,
                          taskId: newUnassigned[0].value,
                        }),
                        {
                          successMessage: 'Se ha quitado la tarea correctamente',
                          errorMessage: 'No se ha podido quitar la tarea de esta causa',
                        },
                      );
                    }
                  }
                  this.setState({tasksAffected: sel as any});
                } catch (error) {
                  console.error(error);
                }
              }}
            />
          </Typography>
        )}
        <WrapSaveButton>
          <CustomButton onClick={this.handleSend} width={'100%'} margin="unset">
            {id ? 'Guardar' : 'Crear'} causa
          </CustomButton>
        </WrapSaveButton>
      </WrapForm>
    );
  }

  private handleSend = async () => {
    const {id, name, categoryId, downtimeTaskId} = this.state;
    const validator = notifyValidator(this);
    if (id) {
      const res = await fetchWithFeedback(
        CausesControlServices.update(id, {
          name,
          downtime_category_id: categoryId,
          downtime_task_id: downtimeTaskId,
        }),
        {returnConfirmation: true, notifyValidator: validator, showMessage: true},
      );
      if (res) {
        this.props.updateDevice();
        this.props.handleClose();
      }
    } else {
      const res = await fetchWithFeedback(
        CausesControlServices.create({
          name,
          downtime_category_id: categoryId,
          downtime_task_id: downtimeTaskId,
        }),
        {returnConfirmation: true, notifyValidator: validator, showMessage: true},
      );
      if (res) {
        this.setState(initialState);
        this.props.updateDevice();
      }
    }
  };

  private handleInputs: HandleNamedChange<State> = name => e => {
    this.setState({[name]: e.target.value, [`${name}Valid`]: true} as Pick<
      State,
      keyof State
    >);
  };

  // private handleErrors = (errors: {[k: string]: boolean}) => {
  //   const errorsObject = {
  //     codeValid: true,
  //     nameValid: true,
  //     surnameValid: true,
  //     identificationValid: true,
  //   };
  //   const keys = Object.keys(errors);
  //   for (const error of keys) {
  //     if (error === 'code') {
  //       errorsObject.codeValid = false;
  //     } else if (error === 'name') {
  //       errorsObject.nameValid = false;
  //     } else if (error === 'surname') {
  //       errorsObject.surnameValid = false;
  //     } else if (error === 'identification') {
  //       errorsObject.identificationValid = false;
  //     }
  //   }
  //   // this.setState(errorsObject as State);
  // };
}

const DataDiv = styled.div`
  /* min-height: 350px; */
  width: 500px;
  display: flex;
  flex-direction: row;
`;

const affectedTasksSelect = {
  menu: (provided: any) => ({
    ...provided,
    height: 200,
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: 200,
  }),
};

const affectedAreasSelect = {
  menu: (provided: any) => ({
    ...provided,
    height: 160,
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: 160,
  }),
};
