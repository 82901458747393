import {Action, Reducer} from 'redux';

import {LaneActionTypes, LaneCurrentDumpState} from '../types';

export const defaultState: LaneCurrentDumpState = {fetchState: 'waiting'};

export const laneCurrentDumpReducer: Reducer<LaneCurrentDumpState, Action> = (
  state = defaultState,
  {type},
) => {
  switch (type) {
    case LaneActionTypes.dumpFetchSuccess:
      return {fetchState: 'success'};
    case LaneActionTypes.dumpFetchFail:
      return {fetchState: 'fail'};
    case LaneActionTypes.dumpFetchWaiting:
      return {fetchState: 'waiting'};
    default:
      return state;
  }
};
