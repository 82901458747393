import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {AppStoreState} from 'src/store/interface';
import {notifyServerError, notify} from 'src/utils/Notification';

import {LaneProdPartActions} from '../actions/prodpart.actions';
import {LanePtableActions} from '../actions/ptable.actions';
import {AreaSelectors} from '../selectors';
import {PtableServices} from '../services';
import {LaneActionInterface, LanePtableState} from '../types';
// import {getAreaAllBatches} from './getAreaAllBatches';
import {getAreaAllProdparts} from './getAreaAllProdparts';

export type CPA = {
  ptable: LanePtableState;
  newAssign?: string;
  newReserve?: string;
  newTransfer?: string;
  callback?: () => void;
};
export const changePtableAssign = ({
  ptable,
  newAssign,
  newReserve,
  newTransfer,
  callback,
}: CPA) => async (
  dispatch: (action: any) => LaneActionInterface,
  getState: () => AppStoreState,
) => {
  try {
    const {assigned_to, ptable_id, reserved_to} = ptable;
    const lane = LaneSelectSelectors.getLane(getState());
    const area = lane ? lane.laneId : 'NO_EXISTE_LINEA';

    let request: any;
    if (!newAssign && !newReserve && !newTransfer) {
      if (assigned_to && !reserved_to)
        request = await PtableServices.unassignProdPartPtable(
          area,
          assigned_to,
          ptable_id,
        );
      else if (reserved_to && !assigned_to)
        request = await PtableServices.unreserveProdPartPtable(
          area,
          reserved_to,
          ptable_id,
        );
    }
    if (!ptable.assigned_to && newAssign && !newReserve && !newTransfer) {
      request = await PtableServices.assignProdPartPtable(area, newAssign, ptable_id);
    }
    if (!ptable.reserved_to && !newAssign && newReserve) {
      request = await PtableServices.reserveProdPartPtable(area, newReserve, ptable_id);
    }
    if (ptable.assigned_to && !newAssign && !newReserve && newTransfer) {
      request = await PtableServices.transferProdPartPtable(area, newTransfer, ptable_id);
    }
    // ! disabled without API
    if (request.status === 400) {
      notify({
        message: 'No se pueden realizar operaciones con un parte de producción completo',
        status: 'notify',
      });
    } else if (request.status === 405) {
      notify({
        message:
          'No se puede cambiar a un parte de producción con menos cajas que las ya paletizadas',
        status: 'notify',
      });
    } else if (request.status === 406) {
      notify({
        message: 'El parte actual y el nuevo no tienen el mismo producto',
        status: 'notify',
      });
    } else if (request.status === 200) {
      const state = getState();
      const {data} = request;
      const gotPtable = AreaSelectors.getPtableById(state, ptable.ptable_id);
      const gotProdPart = AreaSelectors.getProdPartById(
        state,
        newAssign! ||
          newReserve! ||
          newTransfer! ||
          ptable.assigned_to! ||
          ptable.reserved_to!,
      );
      if (gotPtable && gotProdPart) {
        if (!newAssign && !newReserve && !newTransfer) {
          dispatch(
            LanePtableActions.update({
              ...gotPtable,
              ...data,
            }),
          );
          dispatch(
            LaneProdPartActions.update({
              ...gotProdPart,
              ptables: gotProdPart.ptables.filter(pt => pt !== ptable.ptable_id),
            }),
          );
        } else {
          dispatch(
            LanePtableActions.update({
              ...gotPtable,
              ...data,
            }),
          );
          dispatch(
            LaneProdPartActions.update({
              ...gotProdPart,
              ptables: gotProdPart.ptables.find(id => id === ptable.ptable_id)
                ? gotProdPart.ptables
                : gotProdPart.ptables.concat(ptable.ptable_id),
            }),
          );
        }
      }
      callback && callback();
      !callback && dispatch(getAreaAllProdparts());
    } else {
      dispatch(
        notifyServerError(
          request.data.message === 'PRODPART_PTABLE_AREA_DONT_HAVE_SAME_AREAID'
            ? 'Mesa de paletizado no pertenece a esta línea'
            : undefined,
        ),
      );
    }
  } catch (err) {
    console.error(err);
    dispatch(notifyServerError());
    throw Error(err.message);
  }
};
