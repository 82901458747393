import {
  PaginatedTableActionAdd,
  PaginatedTableActionClear,
  PaginatedTableActionTypes,
} from 'src/shared/components/store/paginatedTable.action-types';

export const PaginatedTableActions = {
  add: (payload: {id: string; payload: any}): PaginatedTableActionAdd => ({
    type: PaginatedTableActionTypes.addPaginationData,

    payload,
  }),
  clear: (payload: {id: string}): PaginatedTableActionClear => ({
    type: PaginatedTableActionTypes.clearPaginationData,
    payload,
  }),
};
