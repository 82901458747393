import React, {FormEvent, ChangeEvent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Radio from '@material-ui/core/Radio';
import ListItemText from '@material-ui/core/ListItemText';
import {User} from 'src/terminal/store/reducer';

interface CardProps {
  classes: {
    card: string;
    bullet: string;
    title: string;
    pos: string;
    input: string;
    content: string;
    actionCard: string;
    button: string;
    list: string;
  };
  isInput: boolean;
  titleText?: string;
  inputLabelText?: string;
  buttonText?: string;
  inputValue?: string;
  mainText?: string;
  isList: boolean;
  uTasks?: any[];
  uData?: User;
  handleInputChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  handleButtonClick?: () => void;
  handleFinishTask?: () => void;
  handleToggle?: any;
}

const SimpleCardBase = (props: CardProps) => {
  const {classes} = props;
  let currentTask: any;
  if (props.isList) {
    currentTask = props.uTasks!.find((task: any) => task.state === 'current');
  }
  return (
    <Card className={classes.card}>
      <CardContent>
        {props.titleText && (
          <Typography className={classes.title} color="textSecondary">
            {props.titleText}
          </Typography>
        )}
        {props.mainText && (
          <Typography className={classes.pos} component="p">
            {props.mainText}
          </Typography>
        )}
        {props.isList && (
          <div>
            <Typography>
              Nombre: {props.uData!.name} {props.uData!.surname}
              <br />
              Tarea actual:
              {(currentTask && ` ${currentTask.taskName}`) || ' no hay tarea activa'}
              <br />
              Área de la tarea: {currentTask && currentTask.area_id}
              <br />
              Fecha actual: {new Date().toLocaleString()}
              <br />
              Fecha de la tarea:
              {currentTask && ` ${new Date(currentTask.assignedTime).toLocaleString()}`}
            </Typography>
            <List className={classes.list}>
              {props.uTasks!.map((task: any, index: number) => (
                <ListItem
                  key={index}
                  role={undefined}
                  button={true}
                  onClick={props.handleToggle(index)}>
                  <Radio
                    checked={task.state === 'current'}
                    tabIndex={-1}
                    disableRipple={true}
                    disabled={task.state === 'finalized'}
                  />
                  <ListItemText primary={task.taskName} secondary={task.stateEsp} />
                </ListItem>
              ))}
            </List>
            <Button onClick={props.handleButtonClick}>Salir del terminal</Button>
            <Button onClick={props.handleFinishTask}>Finalizar la tarea en curso</Button>
          </div>
        )}
      </CardContent>
      <CardActions>
        {props.isInput && (
          <form className={classes.actionCard} onSubmit={props.handleSubmit}>
            <InputLabel>{props.inputLabelText}</InputLabel>
            <Input
              className={classes.input}
              value={props.inputValue}
              onChange={props.handleInputChange}
            />
            <Button className={classes.button} type="submit" size="small">
              {props.buttonText}
            </Button>
          </form>
        )}
      </CardActions>
    </Card>
  );
};

export const CardStyled = withStyles({
  card: {
    width: 400,
    minHeight: 600,
    backgroundColor: '#eaeaea',
  },
  content: {
    width: 400,
    maxHeight: 600,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    marginTop: 15,
    marginBottom: 40,
    fontSize: 20,
  },
  pos: {
    marginTop: 40,
    marginBottom: 40,
  },
  input: {
    width: 350,
  },
  actionCard: {
    marginTop: 150,
    maxHeight: 80,
  },
  button: {
    marginTop: 10,
  },
  list: {
    width: '100%',
    maxWidth: 330,
    position: 'relative',
    overflow: 'auto',
    height: 300,
  },
})(SimpleCardBase);
