import * as React from 'react';
import styled from 'styled-components';
import {TableComponent} from 'src/shared/components/TableComponent';
import {ColumnType} from 'src/types';
import ReactTable, {RowInfo} from 'react-table';
import {CenterControlServices} from 'src/app/administration/centerControl/store/centerControl.services';
import {TasksControlServices} from 'src/app/administration/tasksControl/store/tasksControl.services';
import {NonProductiveTimeData, NonProductiveTypeResponse} from 'src/app/administration/nonProductiveTimesControl/store/nonProductiveTimes.types';
import {NonProductiveTimesControlServices} from './store/nonProductiveTimesControl.services';
//import {TableModal} from 'src/shared/components/Modal';
//import {InputLabel} from '@material-ui/core';
//import {CustomButton, CustomDropdown, CustomTextField} from 'src/shared/style';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
// import {notifyValidator} from 'src/utils/notifyValidator';
import {notify} from 'src/utils/Notification';
import {NonProductivityModal} from './NonProductiveModal';

const NOASIGNADA = 'noAsignada'; // Constante para cuando no se asigna tarea

type State = {
    open: boolean;
    nonProductiveTimeList: NonProductiveTimeData[];
    tasksList: Array<{
        id: any;
        name: any;
    }>;
    typeList: NonProductiveTypeResponse[];
    center_id: string;
    center_name: string;
    non_productive_time_id: string;
    name: string;
    start_at: string;
    startValid: boolean;
    duration: number;
    durationValid: boolean;
    task_id: string;
    non_productive_types_id: string;
};

const initialState: State = {
    open: false,
    nonProductiveTimeList: [],
    tasksList: [],
    typeList: [],
    center_id: '',
    center_name: '',
    non_productive_time_id: '',
    name: '',
    start_at: '',
    startValid: true,
    duration: 0,
    durationValid: true,
    task_id: '',
    non_productive_types_id: '',
};

export class NonProductiveTimesControl extends React.Component<{}, State> {
    readonly state = initialState;

    // configurar columnas de tablas
    private columns: ColumnType<{id: string; name: string}> = [
        {
            Header: 'Centro',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'name',
            className: 'cell_string',
        },
    ];

    private columnsSubtable = [
        {
            Header: 'Nombre',
            headerClassName: 'subheader_cell_string',
            accessor: 'name',
            className: 'subcell_string',
        },
        {
            Header: 'Hora Inicio',
            headerClassName: 'subheader_cell_string',
            accessor: 'start_at',
            className: 'subcell_string',
        },
        {
            Header: 'Duración',
            headerClassName: 'subheader_cell_string',
            accessor: 'duration',
            className: 'subcell_string',
        },
        {
            Header: 'Tarea',
            headerClassName: 'subheader_cell_string',
            accessor: 'task_name',
            className: 'subcell_string',
        },
        {
            Header: 'Tipo',
            headerClassName: 'subheader_cell_string',
            accessor: 'non_productive_types_name',
            className: 'subcell_string',
        },
    ];

    // Expandir fila
    private handleSelect = (rowInfo: any) => {
        this.setState({center_id: rowInfo.id, center_name: rowInfo.name}, this.fetchNonProductiveTimesByCenterId);
    };

    private fetchNonProductiveTimesByCenterId = async () => {
        this.setState({nonProductiveTimeList: []});
        const {center_id} = this.state;
        if (center_id) {
            const nonProductiveTimeList = await fetchWithFeedback(NonProductiveTimesControlServices.getAllTimesFromCenter(center_id), {accessor: 'nonProductiveTimes'});
            if (nonProductiveTimeList) {
                this.setState({nonProductiveTimeList});
            }
        }
    };

    // modal

    // cargar listados
    componentDidMount() {
        this.fetchTasks();
        this.fetchTypes();
    }

    private fetchTasks = async () => {
        const fetchedList = await fetchWithFeedback(TasksControlServices.getAll());
        const taskList = [{id: NOASIGNADA, name: 'Sin Tarea Asignada'}].concat(fetchedList.tasks);
        this.setState({tasksList: taskList});
    };

    private fetchTypes = async () => {
        const typeList = await fetchWithFeedback(NonProductiveTimesControlServices.getTypes());
        this.setState({typeList});
    };

    private handleSelectSubrow = (rowInfo: any) => {
        console.log(rowInfo);

        this.setState({
            open: true,
            center_id: rowInfo.center_id,
            non_productive_time_id: rowInfo.non_productive_time_id,
            name: rowInfo.name,
            start_at: rowInfo.start_at,
            duration: rowInfo.duration,
            task_id: rowInfo.task_id != null ? rowInfo.task_id : 'noAsignada',
            non_productive_types_id: rowInfo.non_productive_types_id,
        });
    };

    private createNonProductiveTime = (centerId: string, name: string, startAt: string, duration: number, taskId: string, nonProductiveTypesId: string) => {
        fetchWithFeedback(
            NonProductiveTimesControlServices.create({
                centerId,
                name,
                startAt,
                duration,
                taskId,
                nonProductiveTypesId,
            }),
            {
                showMessage: false,
            },
        )
            .then((res: any) => {
                if (res) {
                    notify({message: 'Tiempo no productivo creado', status: 'success'});
                    this.fetchNonProductiveTimesByCenterId();
                }
                this.handleClose();
            })
            .catch(() => {
                notify({message: 'Error al crear tiempo no productivo', status: 'error'});
            });
    };

    private handleClose = () => {
        this.setState({
            open: false,
            non_productive_time_id: '',
            name: '',
            start_at: '',
            duration: 0,
            task_id: '',
            non_productive_types_id: '',
        });
    };

    render() {
        const {
            open,
            nonProductiveTimeList,
            tasksList,
            typeList,
            center_id,
            center_name,
            name,
            start_at,
            startValid,
            duration,
            durationValid,
            task_id,
            non_productive_types_id,
        } = this.state;

        return (
            <TableWrap>
                <NonProductivityModal
                    open={open}
                    tasksList={tasksList}
                    typeList={typeList}
                    center_id={center_id}
                    center_name={center_name}
                    name={name}
                    start_at={start_at}
                    startValid={startValid}
                    duration={duration}
                    durationValid={durationValid}
                    task_id={task_id}
                    non_productive_types_id={non_productive_types_id}
                    hideModal={this.handleClose}
                    createNonProductiveTime={this.createNonProductiveTime}
                />
                <TableComponent
                    defaultPageSize={20}
                    columnFormat={this.columns}
                    service={CenterControlServices.get}
                    propsToOwnTable={{
                        getTrProps: (_: any, rowInfo: RowInfo) => ({
                            onClick: () => {
                                this.handleSelect(rowInfo.original);
                            },
                        }),
                        SubComponent: () => {
                            return (
                                <div style={{display: 'felx'}}>
                                    <ReactTable
                                        style={{
                                            width: '100%',
                                            borderBottom: 3,
                                            borderBottomStyle: 'solid',
                                            borderBottomColor: 'green',
                                            cursor: 'pointer',
                                        }}
                                        columns={this.columnsSubtable}
                                        data={nonProductiveTimeList}
                                        showPagination={false}
                                        minRows={0}
                                        resizable={false}
                                        filterable={false}
                                        getNoDataProps={() => {
                                            if (!nonProductiveTimeList.length) return {style: {display: 'none'}};
                                            return {};
                                        }}
                                        getTrProps={(_: any, rowInfo: any) => {
                                            return {
                                                onClick: (__: any, handleOriginal: any) => {
                                                    this.handleSelectSubrow(rowInfo.original);
                                                    // IMPORTANT! React-Table uses onClick internally to trigger
                                                    // events like expanding SubComponents and pivots.
                                                    // By default a custom 'onClick' handler will override this functionality.
                                                    // If you want to fire the original onClick handler, call the
                                                    // 'handleOriginal' function.
                                                    if (handleOriginal) {
                                                        handleOriginal();
                                                    }
                                                },
                                            };
                                        }}
                                    />
                                </div>
                            );
                        },
                    }}
                />
            </TableWrap>
        );
    }
}

const TableWrap = styled.div`
    width: 95%;
    height: 100%;
    margin: auto;
`;
