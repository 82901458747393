import {ParametersActions} from './../../../utils/Parameters/actions';
import {push} from 'connected-react-router';
import Cookies from 'js-cookie';
import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {LoginActions} from 'src/app/login/store/login.actions';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {LoginServices} from 'src/app/login/store/login.services';
import {currentDumpInstance} from 'src/app/lanemanager/lane/store/storage';
import {prodPartsInstance} from 'src/app/lanemanager/prodparts/store/prodParts.storage';
import {ENV} from 'src/global/api';
import {
    resetRuleset,
    rulesetInstance,
    GetGrantSet,
    IsInRuleset,
} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {persistentInstance} from 'src/utils/localforage';
import {storeSavingThrottle} from 'src/App';
import {DynamicMenuListAction, ModulesListAction} from "../../menu/store/actions";
import {ModulesService} from "../../menu/store/service";

const login = (
    data: {
        username: string;
        password: string;
    },
    cb: () => void,
): ThunkAction<Promise<void>, AppStoreState, null, AnyAction> => async dispatch => {
    const res = await fetchWithFeedback(LoginServices.login(data), {
        returnUrl: true,
        errorMessage: 'Inicio de sesíon incorrecto',
    });
    if (res && res.data && res.data.token) {
        await persistentInstance.clear();
        await currentDumpInstance.clear();
        await prodPartsInstance.clear();
        await rulesetInstance.clear();
        const base64Url = res.data.token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        const {username = 'username_unknown', employeeId = 'eID_uknown', name = 'name_unknown', surname = 'surname_unknown', userId = 'uID_unknown'} = JSON.parse(
            window.atob(base64),
        );
        if (ENV === 'development') Cookies.set('auth', res.data.token, {domain: res.url.split('/')[2], path: '/'});
        const timeout = setTimeout(async () => {
            const gotGrants = await GetGrantSet(res.data);
            if (gotGrants) {
                dispatch(ParametersActions.success({parameters: res.data.parameters}));
                dispatch(LoginActions.login({username, employeeId, name, surname, userId}));


                await fetchWithFeedback(ModulesService.getUserModules()).then(data => {
                    if(data) {
                        dispatch(ModulesListAction(data.modules));
                        dispatch(DynamicMenuListAction({
                            openModule: '',
                            selectedItem: undefined,
                        }));
                    }
                });


                if (IsInRuleset('BLUEPRINT_VIEW') && !IsInRuleset('SHOW_MENU')) {
                    dispatch(push('/blueprints'));
                } else {
                    dispatch(push('/'));
                }
            }
            clearTimeout(timeout);
        }, 1500);
    } else cb();
};

const logout = (): ThunkAction<Promise<void>, AppStoreState, null, AnyAction> => async (dispatch, getState) => {
    // const username = LoginSelectors.getUserData(getState()).username;
    dispatch(push('/login'));
    storeSavingThrottle.cancel();
    const timeout = setTimeout(async () => {
        await persistentInstance.clear();
        await currentDumpInstance.clear();
        await prodPartsInstance.clear();
        await rulesetInstance.clear();
        if (ENV === 'development') Cookies.remove('auth', {path: '/'});
        clearTimeout(timeout);
    }, 1000);
    dispatch(LoginActions.logout());
    resetRuleset();
    if (LoginSelectors.getUserData(getState()).username) await fetchWithFeedback(LoginServices.logout());
};

export const LoginThunks = {
    login,
    logout,
};
