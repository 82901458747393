import {ChartData, ChartOptions} from 'chart.js';
import React from 'react';
import {LaneChartDataset} from '../store/types';
import {fetchChart} from '../store/services';
import {Bar} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {Spinner} from 'src/app/global/spinner';
import Typography from '@material-ui/core/Typography';

/***
 * CHART OPTIONS
 */
const options: ChartOptions = {
    legend: {
        display: true,
        position: 'top',
    },
    responsive: true,
    title: {
        display: false,
        text: 'Costes y kilos trabajados en los últimos 7 días con datos',
    },
    tooltips: {
        mode: 'nearest',
        intersect: false,
    },
    maintainAspectRatio: false,
    hover: {
        mode: 'nearest',
        intersect: true,
    },
    scales: {
        xAxes: [
            {
                display: true,
                scaleLabel: {
                    display: true,
                    // labelString: 'Month',
                },
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
        yAxes: [
            {
                id: 'cost',
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: '€/Kg',
                },
                ticks: {
                    beginAtZero: true,
                    callback(value: number) {
                        return customFormatNumber(value);
                    },
                },
            },
            {
                id: 'volume',
                display: true,
                //id: 'kg',
                position: 'right',
                scaleLabel: {
                    display: true,
                    labelString: 'Kg',
                },
                ticks: {
                    beginAtZero: true,
                    callback(value: number) {
                        return customFormatNumber(value);
                    },
                },
            },
        ],
    },
    plugins: {
        datalabels: {
            formatter: (value: any) => {
                return customFormatNumber(value);
            },
        },
    },
};

type State = {
    chart?: ChartData;
    options: ChartOptions;
    isLoading: boolean;
};

const initialState = {
    chart: undefined,
    options: options,
    isLoading: true,
};

type Props = {
    laneId: string;
    workshift: string;
    daysBefore: string;
};

export class DailyLaneCostsChart extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidMount() {
        this.setState({
            isLoading: true,
        });
        this.fetchChart(this.props.workshift);
    }

    componentWillReceiveProps(newProps: any) {
        if (newProps.workshift !== this.props.workshift) {
            this.setState({
                isLoading: true,
            });
            this.fetchChart(newProps.workshift);
        }
    }

    fetchChart(workshift: string) {
        console.log('this.props.workshift', workshift);
        fetchChart(this.props.laneId, workshift, this.props.daysBefore).then((res: any) => {
            try {
                this.createDataset(res.data);
            } catch (e) {
                console.error(e);
            } finally {
                this.setState({
                    isLoading: false,
                });
            }
        });
    }

    createDataset(data: LaneChartDataset) {
        if (data.labels.length) {
            const datasets = [
                {
                    yAxisID: 'cost',
                    label: 'Coste',
                    borderColor: '#FBCC9A',
                    data: data.costValues,
                    fill: false,
                    type: 'line',
                },
                {
                    yAxisID: 'volume',
                    label: 'Kg trabajados',
                    backgroundColor: '#99ff99',
                    data: data.workedKgValues,
                    fill: false,
                },
            ] as any;

            const chartData = {
                labels: data.labels,
                datasets: datasets,
            };

            this.setState({
                chart: chartData,
            });
        }
    }

    render() {
        const chart = this.state.chart;

        if (this.state.isLoading) {
            return (
                <div style={{height: '400px'}}>
                    <Spinner isLoading={this.state.isLoading} position={'relative'} />
                </div>
            );
        }
        if (chart !== undefined) {
            return (
                <div style={{height: '400px'}}>
                    <Bar data={chart} options={this.state.options} />
                </div>
            );
        } else {
            return <Typography variant="h6">No hay datos</Typography>;
        }
    }
}
