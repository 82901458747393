import {ChartData, ChartOptions} from 'chart.js';
import React from 'react';
import {CostTotalMakingType} from 'src/app/information/kpis/store/types/chart-types';
import styled from 'styled-components';
import {Bar} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {Spinner} from 'src/app/global/spinner';
//import {Bar} from 'react-chartjs-2';
//const initialState = {chart};

const Heading = styled.div<{fontSize?: number}>`
    display: flex;
    flex-direction: column;
    margin: 0px 4px;
    span {
        text-align: center;
    }
    & > span:nth-child(1) {
        text-transform: uppercase;
        font-weight: bold;
        font-size: ${({fontSize}) => fontSize || '1.7'}rem;
    }
`;

const SubHeading = styled.div<{fontSize?: number}>`
    display: flex;
    flex-direction: column;
    margin: 0px 4px;
    span {
        text-align: center;
    }
    & > span:nth-child(1) {
        text-transform: uppercase;
        font-size: ${({fontSize}) => fontSize || '0.7'}rem;
    }
`;

const chart: ChartData = {
    labels: [],
    datasets: [],
};

/***
 * CHART OPTIONS
 */
const options: ChartOptions = {
    legend: {
        display: true,
        position: 'top',
    },
    responsive: true,
    title: {
        display: false,
        text: 'Tendencia de palés/hora',
    },
    tooltips: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
            label: (tooltipItem: any) => {
                return tooltipItem && tooltipItem.yLabel ? customFormatNumber(Number(tooltipItem.yLabel)) : '';
            },
        },
    },
    maintainAspectRatio: false,
    hover: {
        mode: 'nearest',
        intersect: true,
    },
    scales: {
        xAxes: [
            {
                display: true,
                scaleLabel: {
                    display: true,
                    // labelString: 'Month',
                },
                ticks: {
                    beginAtZero: true,
                },
            },
        ],
        yAxes: [
            {
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: '€/kg',
                },
                ticks: {
                    beginAtZero: true,
                    callback(value: string) {
                        return customFormatNumber(Number(value));
                    },
                },
            },
            {
                display: true,
                id: 'kg',
                position: 'right',
                scaleLabel: {
                    display: true,
                    labelString: 'kg/h',
                },
                ticks: {
                    callback: value => {
                        // you can add your own method here (just an example)
                        return customFormatNumber(Number(value));
                    },
                },
            },
        ],
    },
    plugins: {
        datalabels: {
            formatter: (value: any) => {
                return customFormatNumber(value);
            },
        },
    },
};

const initialState = {chart, options};

type State = {
    chart: ChartData;
    options: ChartOptions;
};

type Props = {
    chartData: CostTotalMakingType;
    isLoading: boolean;
};

export class CostTotalMakingChart extends React.Component<Props, State> {
    readonly state = initialState;
    static getDerivedStateFromProps(props: Props) {
        let dataInfo: number[] = [];
        let labelInfo: string[] = [];
        let dataHourInfo: number[] = [];

        if (props.chartData.costByCentersValues && props.chartData.costByCentersValues.length > 0) {
            dataInfo = props.chartData.costByCentersValues;
            labelInfo = props.chartData.costByCentersLabels;
        }

        if (props.chartData.costByAreasValues && props.chartData.costByAreasValues.length > 0) {
            dataInfo = props.chartData.costByAreasValues;
            labelInfo = props.chartData.costByAreasLabels;
        }

        if (props.chartData.kgPerHourCentersValues && props.chartData.kgPerHourCentersValues.length > 0) {
            dataHourInfo = props.chartData.kgPerHourCentersValues;
        }

        if (props.chartData.kgPerHourAreasValues && props.chartData.kgPerHourAreasValues.length > 0) {
            dataHourInfo = props.chartData.kgPerHourAreasValues;
        }

        const datasets = [
            {
                label: '€/kg',
                backgroundColor: '#FBCC9A',
                borderColor: '#FBCC9A',
                data: dataInfo,
                fill: false,
                type: 'line',
            },
            {
                yAxisID: 'kg',
                label: 'kg/h',
                backgroundColor: '#99ff99',
                borderColor: '#99ff99',
                data: dataHourInfo,
                fill: true,
                type: 'bar',
            },
        ] as any;

        const chartShow = {
            labels: labelInfo,
            datasets,
        };

        return {
            chart: chartShow,
        };
    }

    //readonly state = initialState;
    render() {
        if (this.props.isLoading) {
            return <Spinner isLoading={this.props.isLoading} position={'relative'} />;
        }
        if (this.props.chartData.costProduccion) {
            //let indirectCostByKg = ' [ €/kg ind (CASI AEROPUERTO: 0,163 | CASI SAN ISIDRO: 0,121 | CASI SAN ISIDRO ECOLOGICO: 0,192) ]';
            let indirectCostByKg = ' [ €/kg ind (';

            if (this.props.chartData.indirectCostByKgValues) {
                for (const index in this.props.chartData.indirectCostByKgValues) {
                    if (index !== '0') {
                        indirectCostByKg += ' | ';
                    }

                    indirectCostByKg += this.props.chartData.indirectCostByKgLabels[index] + ': ' + this.props.chartData.indirectCostByKgValues[index];
                }
            }

            indirectCostByKg += ') ]';

            return (
                <>
                    <Heading>
                        <span>{customFormatNumber(Number(this.props.chartData.costProduccion))} Euros / Kg</span>
                    </Heading>
                    <SubHeading>
                        <span>{indirectCostByKg}</span>
                    </SubHeading>
                    <Bar data={this.state.chart} options={this.state.options} />
                </>
            );
        } else {
            return (
                <Heading>
                    <span>-</span>
                </Heading>
            );
        }
    }
}
