import React from 'react';

import {WrapForm} from 'src/app/administration/export/styled/Wrap';
import {CustomButton} from 'src/shared/style/CustomButton';
import {CustomTextField} from 'src/shared/style/CustomTextField';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {CalculateCostExecutionControlData} from './store/types';
import {CenterControlServices} from '../centerControl/store/centerControl.services';
import { CalculateCostExecutionsControlServices } from './store/calculateCostExecutionsControl.services';
import { notify } from 'src/utils/Notification';

type State = Readonly<{
    id: string;
    center_name: string;
    center_id: string;
    workShift: string | undefined;
    description: string;
    centersList: Array<{id: string; name: string}>;
}>;

const initialState: Readonly<State> = {
    id: '',
    center_name: '',
    center_id: '',
    workShift: undefined,
    description: '',
    centersList: [],
};

type Props = {
    calculateCostExecution?: CalculateCostExecutionControlData;
    handleClose(): void;
    update(): void;
};

export class CalculateCostExecutionForm extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidMount() {
        this.fetchCenters();
        if (this.props.calculateCostExecution) {
            const {id, workShift} = this.props.calculateCostExecution;
            this.setState({
                id,
                workShift,
            });
        } else this.setState(initialState);
    }

    private fetchCenters = async () => {
        let centersList = await fetchWithFeedback(CenterControlServices.get());
        centersList = [{id: undefined, name: ''}].concat(centersList);
        this.setState({centersList});
    };

    render() {
        const {workShift} = this.state;
        return (
            <WrapForm margin={'5px 10px'}>

                        <CustomTextField
                            margin={'15px 5px 10px 0'}
                            width={'100%'}
                            label="Fecha"
                            error={false}
                            value={workShift}
                            name="workShift"
                            variant={'outlined'}
                            type="date"
                            shrink={true}
                            onChange={e => this.setState({workShift: e.target.value})}
                        />


                        <CustomButton margin={'10px 0px 0px auto'} onClick={this.handleSend}>
                        {'Lanzar cálculo'}
                        </CustomButton>


            </WrapForm>
        );
    }

    private handleSend = async () => {
        const {workShift} = this.state;
        if (workShift) {


            CalculateCostExecutionsControlServices.create(workShift).then(res =>
            {
                if(res.status === 404 && res.data.message === 'CALCULATE_COST_IN_PROGRESS') {
                    notify({message: 'No se puede calcular coste porque hay algún cálculo en ejecución', status: 'error'});
                }else if(res.status === 404 && res.data.message === 'INVALID_DAY') {
                    notify({message: 'Día no válido', status: 'error'});
                }else if(res.status === 404 && res.data.message === 'ACTIVITIES_NOT_FINISHED') {
                    notify({message: 'Hay tareas abiertas en el día seleccionado', status: 'error'});
                }else if(res.status === 404 && res.data.message === 'AREASHIFTS_NOT_FINISHED') {
                    notify({message: 'Hay turnos abiertos en el día seleccionado', status: 'error'});
                }else{
                    notify({message: 'Iniciado recálculo', status: 'success'});
                    
                    this.props.update();
                    this.props.handleClose();
                }
            });
        }
    };
}

