import * as React from 'react';
import {connect} from 'react-redux';
import ReactTable, {RowInfo} from 'react-table';
import styled from 'styled-components';

import {AreaControlServices} from 'src/app/administration/areaControl/store/services';
import {CausesControlServices} from 'src/app/administration/causesControl/store/causesControl.services';
import {CausesControlResponse, CauseControlUnit} from 'src/app/administration/causesControl/store/causesControl.types';
import {CauseForm} from 'src/app/administration/causesControl/CauseForm';
import {DeviceControlUnitNoAutomatas} from 'src/app/administration/devicesControl/store/devicesControl.types';
import {TasksControlServices} from 'src/app/administration/tasksControl/store/tasksControl.services';
import {TCTaskResponse} from 'src/app/administration/tasksControl/store/tasksControl.types';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

type SProps = {
    userData: ReturnType<typeof LoginSelectors.getUserData>;
};

type State = Readonly<{
    open: boolean;
    selectedUrl: string;
    selectedCause?: CauseControlUnit;
    areasList: Array<{id: string; name: string}>;
    tasksList: TCTaskResponse['tasks'];
    preflight?: CausesControlResponse;
}>;

const initialState: State = {
    open: false,
    selectedUrl: '',
    selectedCause: undefined,
    areasList: [],
    tasksList: [],
};

// const TOTAL_ACCESS =
//   typeof IsInRuleset === 'function' ? IsInRuleset('DOWNTIMECAUSES_TOTAL') : false;
// const VIEW_ACCESS =
//   typeof IsInRuleset === 'function' ? IsInRuleset('DOWNTIMECAUSES_VIEW') : false;
// const Table = TableComponent<CausesControlResponse>();

export class CausesControlBase extends React.Component<SProps, State> {
    readonly state = initialState;
    componentDidMount() {
        this.fetchAreas();
        IsInRuleset('DOWNTIMECAUSES_TOTAL') && this.fetchPreflight();
        this.fetchTasks();
    }
    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    private handleClose = () => {
        this.setState({open: false, selectedCause: undefined});
        this.useTableHook();
    };
    private handleOpenNewCause = () => this.setState({open: true});
    private handleOpenCause = (selectedCause?: CauseControlUnit) => this.setState({open: true, selectedCause});

    private handleUpdate = () => this.useTableHook();

    private fetchAreas = async () => {
        const areasList = await fetchWithFeedback(AreaControlServices.getAllAreas(), {
            accessor: 'areas',
        });
        this.setState({areasList});
    };

    private fetchTasks = async () => {
        const tasksList = await fetchWithFeedback(TasksControlServices.getAll(), {
            accessor: 'tasks',
        });
        this.setState({tasksList: tasksList /*.filter((t: any) => t.direct === true)*/});
    };

    // !!! FIXME: we cant make double call for the data...
    private fetchPreflight = async () => {
        const preflight = await fetchWithFeedback(CausesControlServices.getAllInTable());
        this.setState({preflight});
    };

    private columns: ColumnType<DeviceControlUnitNoAutomatas> = [
        {
            Header: 'Nombre de la causa',
            headerClassName: 'header_cell_string',
            accessor: 'name',
            filterable: true,
            className: 'cell_string',
        },
        {
            Header: 'Categoría de la causa',
            headerClassName: 'header_cell_string',
            accessor: 'category_name',
            filterable: true,
            className: 'cell_string',
        },
        {
            Header: 'Áreas y Tareas',
            width: 120,
            sortable: false,
            expander: true,
            style: {
                cursor: 'pointer',
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
            },
        },
    ];

    render() {
        const TOTAL_ACCESS = IsInRuleset('DOWNTIMECAUSES_TOTAL');
        const {open, selectedCause, areasList, preflight, tasksList} = this.state;
        return (
            IsInRuleset(['DOWNTIMECAUSES_TOTAL', 'DOWNTIMECAUSES_VIEW']) && (
                <TableWrap>
                    <TableModal title={'Gestión de la causa'} open={open} handleClose={this.handleClose}>
                        <CauseForm
                            areasList={areasList}
                            tasksList={tasksList && tasksList.length ? tasksList : []}
                            cause={selectedCause}
                            preflight={preflight!}
                            handleClose={this.handleClose}
                            updateDevice={this.handleUpdate}
                        />
                    </TableModal>
                    <TableComponent
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        service={CausesControlServices.getAllInTable}
                        getTableFetchHook={this.getTableHook}
                        accessor={'causes'}
                        createHook={TOTAL_ACCESS ? this.handleOpenNewCause : undefined}
                        // dataHook={this.getDataHook}
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: RowInfo) => ({
                                onClick: (evt: any) => {
                                    if (!evt.target.className.includes('expand')) TOTAL_ACCESS && this.handleOpenCause(rowInfo.original);
                                },
                                style: {cursor: 'pointer'},
                            }),
                            SubComponent: (info: RowInfo) => {
                                const data = info.original as CauseControlUnit;
                                return (
                                    <div style={{display: 'flex'}}>
                                        <ReactTable
                                            style={{
                                                width: '50%',
                                                borderBottom: 3,
                                                borderBottomStyle: 'solid',
                                                borderBottomColor: 'green',
                                            }}
                                            data={data.areasAffected}
                                            showPagination={false}
                                            minRows={0}
                                            pageSize={data.areasAffected.length}
                                            resizable={false}
                                            sortable={false}
                                            filterable={false}
                                            getNoDataProps={() => {
                                                if (!data.areasAffected.length) return {style: {display: 'none'}};
                                                return {};
                                            }}
                                            columns={[
                                                {
                                                    Header: 'Áreas afectadas',
                                                    headerClassName: 'subheader_title',
                                                    columns: [
                                                        {
                                                            Header: 'Nombre',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'name',
                                                            className: 'subcell_string',
                                                        },
                                                        {
                                                            Header: 'Es línea?',
                                                            headerClassName: 'subheader_cell_string',
                                                            Cell: item => <>{!!item.original.manufaturing ? 'Sí' : 'No'}</>,
                                                            className: 'subcell_string',
                                                            width: 70,
                                                        },
                                                        {
                                                            Header: 'Pertenece al Centro',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'center_name',
                                                            width: 140,
                                                            className: 'subcell_string',
                                                        },
                                                    ],
                                                },
                                            ]}
                                        />
                                        <ReactTable
                                            style={{
                                                width: '50%',
                                                borderBottom: 3,
                                                borderBottomStyle: 'solid',
                                                borderBottomColor: 'green',
                                            }}
                                            data={data.tasksAffected}
                                            showPagination={false}
                                            minRows={0}
                                            pageSize={data.tasksAffected.length}
                                            resizable={false}
                                            sortable={false}
                                            filterable={false}
                                            getNoDataProps={() => {
                                                if (!data.tasksAffected.length) return {style: {display: 'none'}};
                                                return {};
                                            }}
                                            columns={[
                                                {
                                                    Header: 'Tareas afectadas',
                                                    headerClassName: 'subheader_title',
                                                    columns: [
                                                        {
                                                            Header: 'Nombre',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'name',
                                                            className: 'subcell_string',
                                                        },
                                                        {
                                                            Header: 'Directa?',
                                                            headerClassName: 'subheader_cell_string',
                                                            Cell: item => <>{item.original.direct ? 'Sí' : 'No'}</>,
                                                            width: 140,
                                                            className: 'subcell_string',
                                                        },
                                                    ],
                                                },
                                            ]}
                                        />
                                    </div>
                                );
                            },
                        }}
                    />
                </TableWrap>
            )
        );
    }
}

export const CausesControl = connect<SProps, {}, {}, AppStoreState>(state => ({
    userData: LoginSelectors.getUserData(state),
}))(CausesControlBase);

const TableWrap = styled.div`
    width: 60%;
    height: 100%;
    margin: auto;
`;
