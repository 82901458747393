import * as React from 'react';
import isEqual from 'react-fast-compare';
import Select from 'react-select';
import animated from 'react-select/lib/animated';
import ReactTable, {RowInfo} from 'react-table';
import styled from 'styled-components';

import {AreasControlServices} from 'src/app/administration/areasControl/store/areasControl.services';
import {AreasControlUnit} from 'src/app/administration/areaControl/store/types';
import {CenterControlServices} from 'src/app/administration/centerControl/store/centerControl.services';
import {TaskGroupsControlServices} from 'src/app/administration/taskGroupsControl/store/taskGroupsControl.services';
import {TCTaskgroupData} from 'src/app/administration/taskGroupsControl/store/taskGroupsControl.types';
import {Checkbox, FormControlLabel, Typography, FormLabel, RadioGroup, Radio} from '@material-ui/core';
import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {CustomButton, CustomDropdown, CustomTextField} from 'src/shared/style';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notifyValidator} from 'src/utils/notifyValidator';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {Advertisement} from 'src/shared/components/Advertisement';
import {DowntimeCauseUnit} from '../causesControl/store/causesControl.types';
import {CausesControlServices} from '../causesControl/store/causesControl.services';
import {AreasetControlServices} from '../areasetControl/store/areasetControl.services';
import {NotifyActionType} from 'src/utils/Notification/action-types';
import {ParameterCPN} from 'src/types/app_types';
import {findSystemParameter} from 'src/global/systemParameters/systemParameters';

type State = {
    open: boolean;
    id: string;
    name: string;
    nameValid: boolean;
    manufacturing: boolean;
    lane_dump_delay: string;
    max_capacity: string;
    max_capacityValid: boolean;
    lane_dump_delayValid: boolean;
    dump_barcode: string;
    dump_barcodeValid: boolean;
    dump_modify_barcode: string;
    dump_modify_barcodeValid: boolean;
    center_id: string;
    center_idValid: boolean;
    centersList: Array<{id: string; name: string}>;
    areaset_id: string;
    areaset_idValid: boolean;
    areasetsList: Array<{id: string; name: string}>;
    max_employees: string;
    max_employeesValid: boolean;
    taskgroupsList: TCTaskgroupData[];
    areaTaskgroupsList: Array<{
        value: any;
        label: any;
    }>;
    areaTaskgroupsListRAW: TCTaskgroupData[];
    causesList: DowntimeCauseUnit[];
    areaCausesList: Array<{
        value: any;
        label: any;
    }>;
    // areaCausesListRAW: CauseCategoryUnit[];
    dump_box: boolean;
    auto_palletise: boolean;
    showAd: boolean;
    messageAd: string;
};

const initialState: State = {
    open: false,
    id: '',
    name: '',
    center_id: '',
    centersList: [],
    areaset_id: '',
    areasetsList: [],
    manufacturing: false,
    max_employees: '',
    nameValid: true,
    max_capacity: '',
    max_capacityValid: true,
    lane_dump_delay: '',
    lane_dump_delayValid: true,
    dump_modify_barcode: '',
    dump_barcode: '',
    taskgroupsList: [],
    areaTaskgroupsList: [],
    areaTaskgroupsListRAW: [],
    causesList: [],
    areaCausesList: [],
    // areaCausesListRAW: [],
    dump_barcodeValid: true,
    dump_modify_barcodeValid: true,
    max_employeesValid: true,
    center_idValid: true,
    areaset_idValid: true,
    dump_box: false,
    auto_palletise: false,
    showAd: false,
    messageAd: '',
};

// console.log(IsInRuleset);
// const TOTAL_ACCESS =
//   typeof IsInRuleset === 'function' ? IsInRuleset('AREAS_TOTAL') : false;
// const VIEW_ACCESS = typeof IsInRuleset === 'function' ? IsInRuleset('AREAS_VIEW') : false;

export class AreasControl extends React.Component<{}, State> {
    readonly state = initialState;
    private tableHook: () => void = () => ({});
    private getTableHooks = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    componentDidMount() {
        this.fetchTaskgroups();
        this.fetchCenters();
        this.fetchAreasets();
        this.fetchCauses();
    }

    private fetchTaskgroups = async () => {
        const taskgroupsList = await fetchWithFeedback(TaskGroupsControlServices.get(), {
            accessor: 'taskgroups',
        });
        this.setState({taskgroupsList});
    };

    private fetchCenters = async () => {
        const centersList = await fetchWithFeedback(CenterControlServices.get());
        this.setState({centersList});
    };

    private fetchAreasets = async () => {
        let areasetsList = await fetchWithFeedback(AreasetControlServices.get());
        areasetsList = [{id: undefined, name: ''}].concat(areasetsList);
        this.setState({areasetsList});
    };

    private fetchCauses = async () => {
        const causesList = await fetchWithFeedback(CausesControlServices.getAll());
        this.setState({causesList});
    };

    private fetchTaskgroupsAndCausesById = async () => {
        const {id} = this.state;
        if (id) {
            const areaTaskgroupsList = await fetchWithFeedback(TaskGroupsControlServices.getAllTaskGroupsFromArea(id), {accessor: 'taskgroups'});
            const areaCausesList = await fetchWithFeedback(CausesControlServices.getAllCausesFromArea(id), {accessor: 'causes'});

            this.setState({
                areaTaskgroupsList: this.transformToPicker(areaTaskgroupsList),
                areaTaskgroupsListRAW: areaTaskgroupsList,
                areaCausesList: this.transformToPicker(areaCausesList),
            });
        }
    };

    private transformToPicker = (arr: any[]) => arr.map(el => ({value: el.id, label: el.name}));

    private handleChange: HandleNamedChange<State> = name => e => this.setState({[name]: e.target.value} as Pick<State, keyof State>);

    private handleClose = () => {
        this.setState({
            open: false,
            id: '',
            name: '',
            manufacturing: false,
            dump_barcodeValid: true,
            dump_modify_barcodeValid: true,
            lane_dump_delayValid: true,
            max_employeesValid: true,
            center_idValid: true,
            areaset_idValid: true,
            max_employees: '',
            areaTaskgroupsList: [],
            areaCausesList: [],
            center_id: '',
            areaset_id: '',
            dump_barcode: '',
            dump_modify_barcode: '',
            max_capacity: '',
            dump_box: false,
            auto_palletise: false,
        });
    };

    private handleOpen = () => {
        this.setState({open: true});
    };

    private handleSelectAndOpen = ({
        id,
        name,
        manufacturing,
        max_employees,
        center_id,
        areaset_id,
        lane_dump_delay,
        dump_barcode,
        dump_modify_barcode,
        max_capacity,
        dump_box,
        auto_palletise,
    }: AreasControlUnit) =>
        this.setState(
            () => ({
                open: true,
                id,
                name,
                lane_dump_delay,
                dump_barcode,
                dump_modify_barcode,
                dump_box,
                max_capacity: max_capacity ? String(max_capacity) : '',
                max_capacityValid: true,
                nameValid: true,
                dump_barcodeValid: true,
                dump_modify_barcodeValid: true,
                lane_dump_delayValid: true,
                max_employeesValid: true,
                center_idValid: true,
                areaset_idValid: true,
                manufacturing,
                max_employees: max_employees ? String(max_employees) : '',
                center_id,
                areaset_id,
                auto_palletise,
            }),
            this.fetchTaskgroupsAndCausesById,
        );
    private handleSelect = ({
        id,
        name,
        manufacturing,
        lane_dump_delay,
        max_employees,
        dump_barcode,
        dump_modify_barcode,
        center_id,
        areaset_id,
        max_capacity,
        dump_box,
        auto_palletise,
    }: AreasControlUnit) =>
        this.setState(
            () => ({
                id,
                dump_box,
                name,
                dump_barcode,
                dump_modify_barcode,
                lane_dump_delay,
                nameValid: true,
                max_capacity: max_capacity ? String(max_capacity) : '',
                manufacturing,
                max_capacityValid: true,
                dump_barcodeValid: true,
                dump_modify_barcodeValid: true,
                lane_dump_delayValid: true,
                max_employeesValid: true,
                center_idValid: true,
                areaset_idValid: true,
                max_employees: max_employees ? String(max_employees) : '',
                center_id,
                areaset_id,
                auto_palletise,
            }),
            this.fetchTaskgroupsAndCausesById,
        );

    private handleSending = async () => {
        const {
            name,
            id,
            nameValid,
            max_employees,
            manufacturing,
            dump_barcode,
            max_capacity,
            dump_modify_barcode,
            lane_dump_delay,
            center_id,
            areaset_id,
            dump_box,
            auto_palletise,
        } = this.state;
        const validator = notifyValidator(this);
        if (nameValid) {
            if (id) {
                const res = await fetchWithFeedback(
                    AreasControlServices.update({
                        id,
                        dump_box,
                        name,
                        dump_barcode,
                        lane_dump_delay,
                        dump_modify_barcode,
                        max_employees: +max_employees,
                        manufacturing,
                        center_id,
                        areaset_id,
                        max_capacity: +max_capacity,
                        auto_palletise,
                    }),
                    {showMessage: true, notifyValidator: validator},
                );

                if (res && res.notificationWeb) {
                    this.setState({showAd: true});
                    this.setState({messageAd: res.notificationWeb});
                }

                this.handleClose();
            } else {
                const res = await fetchWithFeedback(
                    AreasControlServices.create({
                        dump_box,
                        name: name ? name : null,
                        max_employees: +max_employees,
                        manufacturing,
                        lane_dump_delay,
                        dump_barcode,
                        dump_modify_barcode,
                        center_id,
                        areaset_id,
                        max_capacity: +max_capacity,
                        auto_palletise,
                    }),
                    {showMessage: true, notifyValidator: validator},
                );

                if (res && res.notificationWeb) {
                    this.setState({showAd: true});
                    this.setState({messageAd: res.notificationWeb});
                }

                if (res && res.area && res.area.id)
                    this.setState({
                        id: '',
                        name: '',
                        dump_barcode: '',
                        lane_dump_delay: '',
                        dump_modify_barcode: '',
                        max_employees: '',
                        manufacturing: false,
                        center_id: '',
                        areaset_id: '',
                        max_capacityValid: true,
                        nameValid: true,
                        dump_barcodeValid: true,
                        dump_modify_barcodeValid: true,
                        lane_dump_delayValid: true,
                        max_employeesValid: true,
                        center_idValid: true,
                        areaset_idValid: true,
                        max_capacity: '',
                        dump_box: false,
                        auto_palletise: false,
                    });
            }
        }
        this.useTableHook();
    };

    private hideAd = () => {
        this.setState({
            showAd: false,
            messageAd: '',
        });
    };

    private handleChecking = () => {
        const {name} = this.state;
        this.setState({nameValid: !!name}, this.handleSending);
    };

    private handleBindAllDowntimeCauses = async () => {
        const allCauses = this.transformToPicker(this.state.causesList);
        const notAssignedCauses: Array<string> = [];

        allCauses.forEach(c => {
            if (!this.state.areaCausesList.find(f => isEqual(c, f))) {
                notAssignedCauses.push(c.value);
            }
        }, notAssignedCauses);

        if (notAssignedCauses && notAssignedCauses.length) {
            const params = {causesIds: notAssignedCauses, areaId: this.state.id};
            await fetchWithFeedback(CausesControlServices.bindAllCausesWithArea(params), {
                successMessage: 'Se han añadido todas las causas de parada correctamente',
                errorMessage: 'No se han podido añadir las causas de parada a este área',
            }).then(() => {
                this.setState({areaCausesList: allCauses});
            });
        }
    };

    private handleUnbindAllDowntimeCauses = async () => {
        if (this.state.areaCausesList.length) {
            const params = {areaId: this.state.id};
            await fetchWithFeedback(CausesControlServices.unbindAllCausesWithArea(params), {
                successMessage: 'Se han eliminado todas las causas de parada correctamente',
                errorMessage: 'No se han podido eliminar las causas de parada en este área',
            }).then(() => {
                this.setState({areaCausesList: []});
            });
        }
    };

    private columns: ColumnType<{id: string; name: string}> = [
        {
            Header: 'Nombre',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'name',
            className: 'cell_string',
        },
        {
            Header: 'Centro',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'center_name',
            className: 'cell_string',
        },
        {
            Header: 'Código de volcado',
            headerClassName: 'header_cell_string',
            filterable: true,
            width: 150,
            accessor: 'dump_barcode',
            className: 'cell_string',
        },
        {
            Header: 'Código de modificación',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'dump_modify_barcode',
            className: 'cell_string',
        },
        {
            Header: 'Es linea?',
            sortable: false,
            width: 80,
            Cell: item => <Checkbox checked={item.original.manufacturing} />,
            centerCell: true,
        },
        {
            Header: 'Cambio origen',
            headerClassName: 'header_cell_string',
            sortable: false,
            width: 130,
            className: 'cell_string',
            Cell: item => <>{item.original.dump_box ? 'Manual' : 'Por tiempo'}</>,
        },
        {
            Header: 'Capacidad máxima',
            headerClassName: 'header_cell_number',
            sortable: false,
            accessor: 'max_capacity',
            Cell: item => <>{customFormatNumber(item.original.max_capacity)}</>,
            className: 'cell_number',
        },
        {
            Header: 'Grupos',
            width: 70,
            sortable: false,
            centerCell: true,
            expander: true,
            style: {
                cursor: 'pointer',
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
            },
        },
    ];

    private handleCheckbox: HandleChange = e => {
        const {value} = e.target;
        if (value === 'manual') this.setState({dump_box: true});
        if (value === 'time') this.setState({dump_box: false});
    };

    render() {
        const {
            id,
            name,
            open,
            manufacturing,
            max_employees,
            nameValid,
            areaTaskgroupsList,
            taskgroupsList,
            areaCausesList,
            causesList,
            center_id,
            areaset_id,
            lane_dump_delay,
            dump_barcode,
            dump_modify_barcode,
            centersList,
            areasetsList,
            areaTaskgroupsListRAW,
            //areaCausesListRAW,
            dump_barcodeValid,
            dump_modify_barcodeValid,
            lane_dump_delayValid,
            max_employeesValid,
            center_idValid,
            areaset_idValid,
            max_capacityValid,
            max_capacity,
            dump_box,
            auto_palletise,
        } = this.state;
        return IsInRuleset(['AREAS_TOTAL', 'AREAS_VIEW']) ? (
            <TableWrap>
                <Advertisement open={this.state.showAd} hide={this.hideAd} message={this.state.messageAd} type={NotifyActionType.notify} autoHideDuration={300000} />
                <TableModal title={'Gestión de área'} open={open} handleClose={this.handleClose} maxWidth={'false'}>
                    <Typography
                        component="div"
                        style={{
                            width: 1200,
                        }}
                    >
                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div style={{flexGrow: 4}}>
                                <CustomTextField
                                    margin={'0 auto 10px auto'}
                                    width={'300px'}
                                    // style={{zIndex: 20, marginTop: 5}}
                                    label="Nombre"
                                    error={!nameValid}
                                    value={name}
                                    name="name"
                                    variant={'outlined'}
                                    onChange={this.handleChange('name')}
                                />
                            </div>
                            <div style={{flexGrow: 4}}>
                                <CustomTextField
                                    margin={'0 auto 10px auto'}
                                    width={'150px'}
                                    placeholder={`${findSystemParameter(ParameterCPN.AREA_BARCODE_PREFIX)?.value || ''}...`}
                                    // style={{zIndex: 20, marginTop: 5}}
                                    label="Código de volcado"
                                    error={!dump_barcodeValid}
                                    value={dump_barcode}
                                    name="dump_barcode"
                                    variant={'outlined'}
                                    onChange={e => {
                                        const {value} = e.target;
                                        if (/^[1-9][0-9]{0,12}$/.test(value) || value === '') this.setState({dump_barcode: value});
                                    }}
                                />
                            </div>
                            <div style={{flexGrow: 4}}>
                                <CustomTextField
                                    margin={'0 auto 10px auto'}
                                    width={'150px'}
                                    // style={{zIndex: 20, marginTop: 5}}
                                    label="Código de modificación"
                                    placeholder={`${findSystemParameter(ParameterCPN.AREA_MODIFY_BARCODE_PREFIX)?.value || ''}...`}
                                    error={!dump_modify_barcodeValid}
                                    value={dump_modify_barcode}
                                    name="dump_modify_barcode"
                                    variant={'outlined'}
                                    onChange={e => {
                                        const {value} = e.target;
                                        if (/^[1-9][0-9]{0,12}$/.test(value) || value === '') this.setState({dump_modify_barcode: value});
                                    }}
                                />
                            </div>
                            <div style={{flexGrow: 4}}>
                                <CustomTextField
                                    margin={'0 auto 10px auto'}
                                    width={'100px'}
                                    // style={{zIndex: 20, marginTop: 5}}
                                    label="Retraso de género (seg)"
                                    error={!lane_dump_delayValid}
                                    value={lane_dump_delay}
                                    name="lane_dump_delay"
                                    variant={'outlined'}
                                    onChange={e => {
                                        const {value} = e.target;
                                        if (/^\d+$/.test(value) || value === '') this.setState({lane_dump_delay: value});
                                    }}
                                />
                            </div>
                            <div style={{flexGrow: 4}}>
                                <CustomTextField
                                    margin={'0 auto 10px auto'}
                                    width={'100px'}
                                    label="Capacidad máxima (kg)"
                                    error={!max_capacityValid}
                                    value={max_capacity}
                                    name="max_capacity"
                                    variant={'outlined'}
                                    onChange={e => {
                                        const {value} = e.target;
                                        if (/^\d+$/.test(value) || value === '') this.setState({max_capacity: value});
                                    }}
                                />
                            </div>
                            <div style={{flexGrow: 4}}>
                                <CustomTextField
                                    margin={'0 auto 10px auto'}
                                    width={'100px'}
                                    label="Numero de empleados"
                                    error={!max_employeesValid}
                                    value={max_employees}
                                    name="max_employees"
                                    variant={'outlined'}
                                    onChange={e => {
                                        const {value} = e.target;
                                        if (/^\d+$/.test(value) || value === '') this.setState({max_employees: value});
                                    }}
                                />
                            </div>
                        </Typography>
                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div style={{flexGrow: 4}}>
                                <FormControlLabel
                                    style={{width: 120, marginTop: 5}}
                                    control={<Checkbox checked={manufacturing} onChange={() => this.setState({manufacturing: !manufacturing})} />}
                                    label="Es línea?"
                                />
                            </div>
                            <div style={{flexGrow: 4}}>
                                <FormControlLabel
                                    style={{width: 120, marginTop: 5}}
                                    control={<Checkbox checked={auto_palletise} onChange={() => this.setState({auto_palletise: !auto_palletise})} />}
                                    label="Auto paletizado?"
                                />
                            </div>
                            <div style={{flexGrow: 4}}>
                                <FormLabel component="section" style={{marginTop: 10}}>
                                    Control del volcado:
                                </FormLabel>
                                <RadioGroup style={{marginTop: 5}} name="nfcDevices" value={dump_box ? 'manual' : 'time'} onChange={this.handleCheckbox}>
                                    <FormControlLabel value="time" style={{height: 25}} control={<Radio />} label="Por tiempo" />
                                    <FormControlLabel value="manual" style={{height: 25}} control={<Radio />} label="Manual" />
                                </RadioGroup>
                            </div>
                            <div style={{flexGrow: 4}}>
                                <Typography
                                    component="div"
                                    style={{
                                        width: '90%',
                                    }}
                                >
                                    <CustomDropdown
                                        error={!center_idValid}
                                        title={'Centro'}
                                        margin={'5px 0'}
                                        data={centersList}
                                        dropdownName={'center'}
                                        value={center_id}
                                        handleChoose={e => this.setState({center_id: e.target.value})}
                                    />
                                </Typography>
                            </div>
                            <div style={{flexGrow: 4}}>
                                <CustomDropdown
                                    error={!areaset_idValid}
                                    title={'Grupo de área'}
                                    margin={'5px 0'}
                                    data={areasetsList}
                                    dropdownName={'center'}
                                    value={areaset_id}
                                    handleChoose={e => this.setState({areaset_id: e.target.value})}
                                />
                            </div>
                        </Typography>
                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div style={{flexGrow: 4, maxHeight: 200, height: 200, width: '45%', marginRight: '10px'}}>
                                {id && (
                                    <>
                                        <div style={{margin: '0 auto'}}>
                                            <label>Paradas admitidas en la línea</label>
                                            <Select
                                                backspaceRemovesValue={false}
                                                value={areaCausesList}
                                                isMulti={true}
                                                styles={customStyles}
                                                closeMenuOnSelect={false}
                                                isClearable={false}
                                                menuPlacement="top"
                                                placeholder={''}
                                                // @ts-ignore
                                                components={animated()}
                                                options={this.transformToPicker(causesList)}
                                                onChange={async sel => {
                                                    const cast = sel as Array<{
                                                        value: string;
                                                        label: string;
                                                    }>;
                                                    try {
                                                        const newAssigned = cast.filter(c => !areaCausesList.find(tgl => isEqual(c, tgl)));
                                                        if (newAssigned.length) {
                                                            await fetchWithFeedback(CausesControlServices.toggleArea({areaId: id, downtimeCauseId: newAssigned[0].value}), {
                                                                successMessage: 'Se ha añadido la causa de parada correctamente',
                                                                errorMessage: 'No se ha podido añadir la causa de parada a este área',
                                                            });
                                                        } else {
                                                            const newUnassigned = areaCausesList.filter(tgl => !cast.find(c => isEqual(c, tgl)));
                                                            if (newUnassigned.length) {
                                                                await fetchWithFeedback(CausesControlServices.toggleArea({areaId: id, downtimeCauseId: newUnassigned[0].value}), {
                                                                    successMessage: 'Se ha quitado la causa de parada correctamente',
                                                                    errorMessage: 'No se ha podido quitar la causa de parada de este área',
                                                                });
                                                            }
                                                        }
                                                        this.setState({areaCausesList: cast});
                                                    } catch (error) {
                                                        console.error(error);
                                                    }
                                                }}
                                            />
                                        </div>
                                        <Typography
                                            component="div"
                                            style={{
                                                marginBottom: 25,
                                                marginTop: 15,
                                                width: 500,
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <CustomButton width={'49%'} height={'32px'} onClick={this.handleBindAllDowntimeCauses} variant={'outlined'} variantColor={'primary'}>
                                                Añadir todas
                                            </CustomButton>
                                            <div style={{flexGrow: 1}} />
                                            <CustomButton
                                                width={'49%'}
                                                height={'32px'}
                                                onClick={this.handleUnbindAllDowntimeCauses}
                                                variant={'outlined'}
                                                variantColor={'secondary'}
                                            >
                                                Eliminar todas
                                            </CustomButton>
                                        </Typography>
                                    </>
                                )}
                            </div>
                            <div style={{flexGrow: 4, maxHeight: 200, minHeight: 200, width: '45%', marginLeft: '10px'}}>
                                {id && (
                                    <div style={{margin: '0 auto 5px auto'}}>
                                        <label>Grupos de tareas admitidas en la línea</label>
                                        <Select
                                            backspaceRemovesValue={false}
                                            value={areaTaskgroupsList}
                                            isMulti={true}
                                            styles={customStyles}
                                            closeMenuOnSelect={false}
                                            isClearable={false}
                                            maxMenuHeight={150}
                                            placeholder={''}
                                            // @ts-ignore
                                            components={animated()}
                                            options={this.transformToPicker(taskgroupsList)}
                                            // onChange={sub => this.setState({tasksAffected: sub as any})}
                                            onChange={async sel => {
                                                const cast = sel as Array<{
                                                    value: string;
                                                    label: string;
                                                }>;
                                                try {
                                                    const newAssigned = cast.filter(c => !areaTaskgroupsList.find(tgl => isEqual(c, tgl)));
                                                    if (newAssigned.length) {
                                                        await fetchWithFeedback(AreasControlServices.toggleAreaToTaskgroup(id, newAssigned[0].value), {
                                                            successMessage: 'Se ha elegido la tarea correctamente',
                                                            errorMessage: 'No se ha podido elegir la tarea a este área',
                                                        });
                                                    } else {
                                                        const newUnassigned = areaTaskgroupsList.filter(tgl => !cast.find(c => isEqual(c, tgl)));
                                                        if (newUnassigned.length) {
                                                            await fetchWithFeedback(AreasControlServices.toggleAreaToTaskgroup(id, newUnassigned[0].value), {
                                                                successMessage: 'Se ha quitado la tarea correctamente',
                                                                errorMessage: 'No se ha podido quitar la tarea de este área',
                                                            });
                                                        }
                                                    }
                                                    this.setState({areaTaskgroupsList: cast});
                                                } catch (error) {
                                                    console.error(error);
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        </Typography>
                        <div style={{display: 'flex'}}>
                            <CustomButton margin={'0px 0px 0px auto'} onClick={this.handleChecking} width={'25%'}>
                                {this.state.id ? 'Guardar' : 'Crear'}
                            </CustomButton>
                        </div>
                    </Typography>
                </TableModal>
                <TableComponent
                    defaultPageSize={20}
                    columnFormat={this.columns}
                    service={AreasControlServices.get}
                    accessor={'areas'}
                    createHook={IsInRuleset('AREAS_TOTAL') ? this.handleOpen : undefined}
                    getTableFetchHook={this.getTableHooks}
                    propsToOwnTable={{
                        getTrProps: (_: any, rowInfo: RowInfo) => ({
                            onClick: (evt: any) => {
                                if (!IsInRuleset('AREAS_TOTAL')) return;
                                if (evt.target.className.includes('expand')) this.handleSelect(rowInfo.original);
                                else this.handleSelectAndOpen(rowInfo.original);
                            },
                            style: {cursor: 'pointer'},
                        }),
                        SubComponent: () => {
                            return (
                                <div style={{display: 'flex'}}>
                                    <ReactTable
                                        style={{
                                            width: '100%',
                                            borderBottom: 3,
                                            borderBottomStyle: 'solid',
                                            borderBottomColor: 'green',
                                        }}
                                        data={areaTaskgroupsListRAW}
                                        showPagination={false}
                                        minRows={0}
                                        onFetchData={this.fetchTaskgroupsAndCausesById}
                                        pageSize={areaTaskgroupsListRAW.length}
                                        resizable={false}
                                        sortable={false}
                                        filterable={false}
                                        getNoDataProps={() => {
                                            if (!areaTaskgroupsListRAW.length) return {style: {display: 'none'}};
                                            return {};
                                        }}
                                        columns={[
                                            {
                                                Header: 'Grupos de tareas del área',
                                                headerClassName: 'subheader_title',
                                                columns: [
                                                    {
                                                        Header: '',
                                                        headerClassName: 'subheader_cell_string',
                                                        accessor: 'white-space',
                                                        className: 'subcell_string',
                                                        width: 800,
                                                    },
                                                    {
                                                        Header: 'Nombre',
                                                        accessor: 'name',
                                                        headerClassName: 'subheader_cell_string',
                                                        className: 'cell_string',
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                </div>
                            );
                        },
                    }}
                />
            </TableWrap>
        ) : null;
    }
}

const TableWrap = styled.div`
    width: 95%;
    height: 100%;
    margin: auto;
`;

const customStyles = {
    menu: (provided: any) => ({
        ...provided,
        height: 150,
        minHeight: 150,
        alignItems: 'top',
    }),
    menuList: (provided: any) => ({
        ...provided,
        height: 150,
        minHeight: 150,
        alignItems: 'top',
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        maxHeight: 150,
        overflowX: 'hidden',
        overflowY: 'auto',
        minHeight: 150,
        alignItems: 'top',
    }),
};
