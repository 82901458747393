import differenceInMinutes from 'date-fns/difference_in_minutes';

import {AppStoreState} from 'src/store';
import {Task, User, UserTask} from 'src/terminal/store/reducer';

interface CTasksArgs {
  userId?: number;
  self?: true;
  computeTime: boolean;
}

/**
 * Get authorized userId.
 *
 * @param {AppStoreState} state
 * @returns {number}
 */
export const getAuthorizedUser = (state: AppStoreState) => state.terminal.authorized;

/**
 * Get authorized user data.
 *
 * @param {AppStoreState} state
 * @returns
 */
export const getAuthorizedUserData = (state: AppStoreState) => {
  const {authorized, users} = state.terminal;
  return users.find((user: User) => authorized === user.id)!;
};

/**
 * Get authorized user tasks.
 *
 * @param {AppStoreState} state
 * @returns
 */
export const getAuthorizedUserTasks = (state: AppStoreState) => {
  return computeTasks(state, {self: true, computeTime: false});
};

/**
 * Get all users tasks mapping.
 *
 * @param {AppStoreState} state
 * @returns
 */
export const getAllUsersTasksMap = (state: AppStoreState) => {
  return computeTasks(state, {computeTime: false});
};

/**
 * Get all users computed tasks mapping with computed simple times.
 *
 * @param {AppStoreState} state
 */
export const getAllUsersTasksTimedMap = (state: AppStoreState) => {
  return computeTasks(state, {computeTime: true});
};

/**
 *
 * Get all uncomputed tasks of a given userId.
 *
 * @param {AppStoreState} state
 * @param {number} userId
 * @returns
 */
export const getUniqueUserTasks = (state: AppStoreState, userId: number) => {
  return computeTasks(state, {userId, computeTime: false});
};

/**
 * Get all users array.
 *
 * @param {AppStoreState} state
 * @returns {User[]}
 */
export const getAllUsers = (state: AppStoreState): User[] => {
  return state.terminal.users;
};

export interface ActiveUsersTasks extends User {
  activeTask: UserTaskExtended | null;
}

export interface UserTaskExtended extends UserTask {
  taskName: string;
}
/**
 * Get active tasks per user data.
 *
 * @param {AppStoreState} state
 * @returns
 */
export const getAllUsersActiveTasks = (state: AppStoreState): ActiveUsersTasks[] => {
  const {tasks, users, usersTasks} = state.terminal;
  return users.map((u: User) => {
    let activeComputed: UserTaskExtended | null = null;
    const active = usersTasks[u.id].find((ut: UserTask) => ut.state === 'current');
    if (active !== undefined) {
      const taskName = tasks.find((t: Task) => t.id === active!.taskId);
      activeComputed = {
        ...active,
        taskName: taskName!.name,
        startedTime: active.startedTime,
      };
    }
    return {
      ...u,
      activeTask: activeComputed,
    };
  });
};

/**
 * Computes tasks for a single user or all of them if no arg provided
 *
 * @param {AppStoreState} state
 * @param {(number | true)} [arg]
 * @returns
 */
const computeTasks = (state: AppStoreState, args: CTasksArgs) => {
  const {users, tasks, usersTasks, authorized} = state.terminal;
  let userTasks: UserTask[] | undefined;
  if (args!.self === true) userTasks = usersTasks[authorized];
  if (args!.userId) userTasks = usersTasks[args!.userId!];

  let computed;
  if (userTasks) computed = computeSingleUTasks(userTasks, tasks, args.computeTime);
  else {
    computed = users.map((u: User) => {
      const computedUTasks = usersTasks[u.id];
      return {
        ...u,
        computedTasks: computeSingleUTasks(computedUTasks, tasks, args.computeTime),
      };
    });
  }
  return computed;
};

const computeSingleUTasks = (
  userTasks: UserTask[],
  tasks: Task[],
  computeTime: boolean,
) => {
  return userTasks.map((ut: UserTask) => {
    const unique = tasks.find((t: Task) => t.id === ut.taskId);
    let stateEsp;
    if (ut.state === 'pending') stateEsp = 'pendiente';
    if (ut.state === 'current') stateEsp = 'en curso';
    if (ut.state === 'finalized') stateEsp = 'finalizada';
    if (computeTime && ut.startedTime) {
      return {
        ...ut,
        taskName: unique!.name,
        stateEsp,
        totalTime: differenceInMinutes(
          ut.finishedTime !== undefined ? ut.finishedTime : Date.now(),
          ut.startedTime,
        ),
      };
    }
    return {...ut, taskName: unique!.name, stateEsp};
  });
};
