import * as React from 'react';

import {
    Checkbox,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary, Grid, /*IconButton,*/ InputLabel, ListItemText, MenuItem, Select,
    //InputLabel, ListItemText, MenuItem, Select,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {FunctionalityType, MenuItemType, ModuleType, StatusKey} from "./store/menuItemsControl.types";
import {StatusType} from "./MenuItemsControl";
import LinkIcon from "@material-ui/icons/Link";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {Link} from "react-router-dom";

type State = {
    selectedFunctionalities: Map<string, string[]>
    cbEnabled : boolean;
}
const initialState: Readonly<State> = {
    selectedFunctionalities: new Map<string, string[]>(),
    cbEnabled : false
};

type Props = {
    expanded: string | boolean;
    change(...args: any[]): any;
    moduleId: string;
    module: ModuleType;
    functionalities: FunctionalityType;
    modifiedItems : Map<StatusKey, StatusType>;
    enableSave() : void;
    push(loc: string): void;
};

// type DProps = {
//     push(loc: string): void
// };
//
// type AllProps = Props & DProps;

class MenuItemsControlPanelComponent extends React.Component<Props, State> {

    readonly state = initialState;

    componentDidMount() {
        this.fillSelectedFunctionalities();
        this.setState({cbEnabled : this.isModuleCheckBoxEnable()});
    }


    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.module !== prevProps.module) {
            this.fillSelectedFunctionalities();
            this.forceUpdate();
        }
    }

    private isModuleCheckBoxEnable = ()=> {
        return Object.values(this.props.module.menuItems).some(item => item.enabled);
    }

    private fillSelectedFunctionalities = ()=> {
        Object.entries(this.props.module.menuItems).forEach(([itemId, item]) => {
            const itemSelectedFunctionalities : string[] = [];
            item.functionalities.forEach(fId => {
                itemSelectedFunctionalities.push(this.props.functionalities[fId]);
            });
            this.state.selectedFunctionalities.set(itemId, itemSelectedFunctionalities);
        });
    }

    private handleCheckModule = (e : any, module : ModuleType) => {

        e.stopPropagation();
        const checked = e.target.checked;
        module.enabled = checked;
        this.props.modifiedItems.set({moduleId:this.props.moduleId,menuItemId:null,functionalityId:null}, {type: "module", enabled: checked});
        this.props.enableSave();

        this.forceUpdate();
    }

    private handleCheckItem = (e : React.ChangeEvent<{checked : boolean}>, itemId: string, item : MenuItemType) => {

        const checked = e.target.checked;
        item.enabled = checked
        this.props.modifiedItems.set({moduleId:this.props.moduleId,menuItemId:itemId,functionalityId:null}, {type: "menu_item", enabled: checked});
        this.props.enableSave();

        const cbEnabled = this.isModuleCheckBoxEnable();
        //this.props.module.enabled = this.props.module.enabled && cbEnabled;
        this.setState({cbEnabled});
    }

    private handleSelectFunctionality = (e : React.ChangeEvent<{checked : boolean}>, functionalityId : string , itemId : string) => {

        const funcName = this.props.functionalities[functionalityId];
        let selectedFunctionalities = this.state.selectedFunctionalities.get(itemId);
        if (!selectedFunctionalities) {
            selectedFunctionalities = [];
            this.state.selectedFunctionalities.set(itemId, selectedFunctionalities);
        }
        const item = this.props.module.menuItems[itemId];
        const itemFunctionalities = item.functionalities;

        if (e.target.checked) {
            itemFunctionalities.push(functionalityId);
            selectedFunctionalities.push(funcName);
        }
        else {
            const index = itemFunctionalities.indexOf(functionalityId);
            itemFunctionalities.splice(index, 1);
            const sIndex = selectedFunctionalities.indexOf(funcName);
            sIndex !== -1 && selectedFunctionalities.splice(sIndex, 1);

            if (selectedFunctionalities.length === 0) item.enabled = false;
        }

        const cbEnabled = this.isModuleCheckBoxEnable();
        //this.props.module.enabled = this.props.module.enabled && cbEnabled;

        this.props.modifiedItems.set({moduleId:this.props.moduleId,menuItemId:itemId, functionalityId}, {type: "functionality", enabled: e.target.checked});
        this.props.enableSave();

        this.setState({cbEnabled});
    }

    // private navigateTo = (link : string) => {
    //     this.props.push(link);
    // }

    render() {
        const {expanded, module, functionalities, change} = this.props;

        return (
            <ExpansionPanel
                expanded={expanded === module.name}
                onChange={(_: any, expanded: boolean) => change(module.name, expanded)}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Checkbox
                            disabled={!this.state.cbEnabled}
                            checked={module.enabled}
                            disableRipple
                            color="default"
                            id={module.name + "_checkBox"}
                            inputProps={{ 'aria-label': 'Checkbox A' }}
                            // onClick={(e) => this.handleCheckModule(e, module)}
                            onClick={(e) => this.handleCheckModule(e, module)}
                        />
                        <Typography variant="h6" style={{color: this.state.cbEnabled ? 'black' : 'grey'}}>
                            {module.name}
                        </Typography>
                    </div>

                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display: 'block', paddingLeft: '32px'}}>
                    {Object.entries(module.menuItems).map(([itemId, item]) => (
                        <Grid container key={item.name} spacing={16} alignItems="center">
                            <Grid item xs={5}>
                                <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
                                    <Checkbox
                                        checked={item.enabled}
                                        disableRipple
                                        disabled={item.functionalities.length === 0}
                                        color="default"
                                        id={item.name + "_checkBox"}
                                        onChange={(e) => this.handleCheckItem(e, itemId, item)}
                                    />
                                    <Typography variant="body1" style={{color: (item.functionalities.length) ? 'black' : 'grey'}}>
                                        {item.name}
                                    </Typography>
                                    <Link
                                        aria-label="enlace"
                                        to={item.link}
                                        target="_blank"
                                        onClick={(e) => e.preventDefault}
                                    >
                                        <LinkIcon fontSize="large" color="primary"/>
                                    </Link>
                                </div>
                            </Grid>

                            <Grid item xs={7}>
                                <InputLabel id={itemId + '_label'}
                                >
                                    Funcionalidades
                                </InputLabel>
                                <Select style={{width:'100%'}}
                                        //disabled={!this.isModuleChecked(functionalityId, moduleId)}
                                        aria-label={item.name + '_label'}
                                        id={itemId + "_select"}
                                        multiple
                                        renderValue={(selected) => (selected as string[]).join(', ')}
                                        value={(this.state.selectedFunctionalities.get(itemId) ?? [])}
                                >
                                    {
                                        Object.entries(functionalities).map(([functionalityId, functionalityName]) => (
                                            <MenuItem value={functionalityName} key={functionalityId + itemId}>
                                                <Checkbox
                                                    disableRipple
                                                    checked = {item.functionalities.indexOf(functionalityId) !== -1}
                                                    onChange = {
                                                        (e) => this.handleSelectFunctionality(e, functionalityId, itemId)
                                                    }
                                                />
                                                <ListItemText primary={functionalityName} />
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </Grid>


                        </Grid>
                    ))}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        push: (e: any) => dispatch(push(e))
    };
}

export const MenuItemsControlPanel = connect(null, mapDispatchToProps)(MenuItemsControlPanelComponent);

