import * as React from 'react';
import {Redirect, Route} from 'react-router-dom';

import {IsInRuleset} from 'src/global/authorization/grantSet';
import {GrantGroupsRanks} from 'src/global/authorization/ranking';
import {notify} from 'src/utils/Notification';

// import {RULESET} from 'src/global/authorization/grantSet';

interface RedirectRouteProps {
  path: string;
  [key: string]: any;
}
export const GuardSingleAccessRoute: React.FunctionComponent<
  RedirectRouteProps & {
    accessSet?: Array<keyof typeof GrantGroupsRanks>;
  }
> = props => {
  let access = false;
  if (!props.accessSet) return IsInRuleset() ? <Route {...props} /> : <Redirect to="/" />;
  for (const acc of props.accessSet) {
    access = IsInRuleset(acc);
    if (access === true) {
      return <Route {...props} />;
    }
  }
  console.log('OBJ:',props.accessSet);
  notify({message: 'No tiene acceso a esta información', status: 'error'});
  return <Redirect to="/" />;
};

export const GuardAuthorizationRoute: React.FunctionComponent<
  RedirectRouteProps
> = props => {
  if (props.userData && props.userData.userId) {
    if (props.path === '/lanemanager') return <GuardLanemanagerRoute {...props} />;
    return <Route {...props} />;
  }
  return <Redirect to="/login" />;
};

const GuardLanemanagerRoute: React.FunctionComponent<RedirectRouteProps> = props => {
  if (props.lane && props.lane.laneId)
    return (
      <GuardSingleAccessRoute
        {...props}
        accessSet={[
          'LANEMANAGER_COMMON_VIEW',
          'LANEMANAGER_COMMON_TOTAL',
          'LANEMANAGER_STATE_TOTAL',
          'LANEMANAGER_STATE_VIEW',
          'LANEMANAGER_PRODPARTS_VIEW',
          'LANEMANAGER_PRODPARTS_TOTAL',
          'LANEMANAGER_AREASHIFTS_TOTAL',
          'LANEMANAGER_AREASHIFTS_VIEW',
          'LANEMANAGER_DOWNTIMES_TOTAL',
          'LANEMANAGER_DOWNTIMES_VIEW',
          'LANEMANAGER_PALLETS_VIEW',
          'LANEMANAGER_DUMPS_VIEW',
          'LANEMANAGER_PERSONNEL_TOTAL',
          'LANEMANAGER_PERSONNEL_VIEW',
          'LANEMANAGER_PERFORMANCE_VIEW',
        ]}
      />
    );
  return <Redirect to="/" />;
};
