import {AppStoreState} from 'src/store';

export const MenuListSelectors = {
    getState: (state: AppStoreState) => state.menuListState
}

export const DynamicMenuListSelectors = {
    getState: (state: AppStoreState) => state.dynamicMenuListState
}

export const ModulesListSelectors = {
    getState: (state: AppStoreState) => state.modules
}
