import {minipalletScheme} from './minipallet';

export const batchScheme = {
  additionalProperties: false,
  type: 'object',
  required: [
    'id',
    'batch_code',
    'merchandise_name',
    'provider_info',
    'total_kg_logisticunits',
    'total_kg_dumped',
    'total_kg_boxes',
    'pallets',
  ],
  properties: {
    id: {type: 'string'},
    batch_code: {type: 'string'},
    merchandise_name: {type: 'string'},
    provider_info: {type: 'string'},
    started_at: {type: 'string'},
    total_kg_logisticunits: {type: 'number'},
    total_kg_dumped: {type: 'number'},
    total_kg_boxes: {type: 'number'},
    pallets: {
      oneOf: [{type: 'array', items: minipalletScheme}, {type: 'null'}],
    },
  },
};
