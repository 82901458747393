import * as React from 'react';

import {
    Checkbox,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary, Grid,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {MenuItemType, ModuleType} from "./store/modulesControl.types";
import {StatusType} from "./ModulesControl";

type Props = {
    expanded: string | boolean;
    change(...args: any[]): any;
    moduleId: string;
    module: ModuleType;
    modifiedItems : Map<string, StatusType>;
    enableSave() : void;
};


export class  ModulesControlPanel extends React.Component<Props> {

    private handleCheckModule = (e : any, module : ModuleType) => {

        e.stopPropagation();
        const checked = e.target.checked;
        module.enabled = checked;
        this.props.modifiedItems.set(this.props.moduleId, {type: "module", enabled: checked});
        this.props.enableSave();

        this.forceUpdate();
    }

    private handleCheckItem = (e : React.ChangeEvent<{checked : boolean}>, itemId: string, item : MenuItemType) => {

        const checked = e.target.checked;
        item.enabled = checked
        this.props.modifiedItems.set(itemId, {type: "menu_item", enabled: checked});
        this.props.enableSave();

        this.forceUpdate();
    }

    render() {
        const {expanded, module, change} = this.props;

        return (
            <ExpansionPanel
                expanded={expanded === module.name}
                onChange={(_: any, expanded: boolean) => change(module.name, expanded)}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <Checkbox
                            checked={module.enabled}
                            disableRipple
                            color="default"
                            id={module.name + "_checkBox"}
                            inputProps={{ 'aria-label': 'Checkbox A' }}
                            // onClick={(e) => this.handleCheckModule(e, module)}
                            onClick={(e) => this.handleCheckModule(e, module)}
                        />
                        <Typography variant="h6">
                            {module.name}
                        </Typography>
                    </div>

                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display: 'block'}}>
                    {Object.entries(module.items).map(([itemId, item]) => (
                        <Grid container key={item.name} spacing={16} alignItems="center">
                            <Grid item xs={1}/>
                            <Grid item xs={8}>
                                <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
                                    <Checkbox
                                        checked={item.enabled}
                                        disableRipple
                                        disabled={!module.enabled}
                                        color="default"
                                        id={item.name + "_checkBox"}
                                        onChange={(e) => this.handleCheckItem(e, itemId, item)}
                                    />
                                    <Typography variant="body1" style={{color: module.enabled ? 'black' : 'grey'}}>
                                        {item.name}
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    ))}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

