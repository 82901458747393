import {ENV} from 'src/global/api';
import {persistentInstance} from 'src/utils/localforage';

export const saveState = (state: {}) => {
  persistentInstance
    .setItem('state', state)
    .then(() => {
      if (ENV === 'development') console.log('Saved state');
    })
    .catch(err => {
      if (ENV === 'development') console.log('!!!!!Didnt save the state', err);
    });
};

export const loadState = async (): Promise<{} | undefined> => {
  try {
    return await persistentInstance.getItem('state');
  } catch (err) {
    throw new Error(err);
  }
};
