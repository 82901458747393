import {customerScheme} from './customer';
// import {makingTypeScheme} from './makingtype';
import {merchandiseScheme} from './merchandise';
import {palletWithBatchesScheme} from './pallet';
import {ptableScheme} from './ptable';

export const prodPartScheme = {
  additionalProperties: false,
  type: 'object',
  required: [
    'id',
    'pallets_total',
    'pallets_count',
    'pallets',
    'ptables',
    // 'making_type',
    'area_name',
    'packaging',
    'erp_shipping_date',
    'boxes_count',
    'boxes_total',
    'order_id',
    'order_reference',
    'order_state',
    'customer',
    'erp_prodpart_code',
    'boxes_per_pallet',
    'pallet_name',
  ],
  properties: {
    area_name: {type: 'string'},
    id: {type: 'string'},
    pallets_total: {type: 'number'},
    pallets_count: {type: 'number'},
    erp_shipping_date: {oneOf: [{type: 'string'}, {type: 'null'}]},
    order_id: {type: 'string'},
    order_reference: {oneOf: [{type: 'string'}, {type: 'null'}]},
    order_state: {type: 'string'},
    packaging: {type: 'string'},
    boxes_total: {type: 'number'},
    boxes_count: {type: 'number'},
    customer: customerScheme,
    pallets: {
      oneOf: [
        {type: 'array', items: palletWithBatchesScheme, minItems: 0},
        {type: 'null'},
      ],
    },
    ptables: {
      oneOf: [{type: 'array', items: ptableScheme, minItems: 0}, {type: 'null'}],
    },
    // making_type: makingTypeScheme,
    merchandise: merchandiseScheme,
    erp_prodpart_code: {type: 'string'},
    boxes_per_pallet: {oneOf: [{type: 'number'}, {type: 'null'}]},
    pallet_name: {oneOf: [{type: 'string'}, {type: 'null'}]},
  },
};
