export const downtimeCauseScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['id', 'name', 'category_id'],
  properties: {
    id: {type: 'string'},
    name: {type: 'string'},
    category_id: {type: 'string'},
  },
};
