import {LaneActionTypes} from '../types/lane.action-types';
import {AreaEmployeesRes, LaneActionInterface} from '../types';

export const LaneEmployeeActions = {
  set: (payload: AreaEmployeesRes): LaneActionInterface<AreaEmployeesRes> => ({
    type: LaneActionTypes.setEmployees,
    payload,
  }),
  clear: (): LaneActionInterface => ({type: LaneActionTypes.clearEmployees}),
};
