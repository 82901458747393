import {
    PalletDefinitionCstmFieldCreateParams,
    PalletDefinitionCstmFieldsControlResponse,
} from 'src/app/administration/palletDefinitionCstmFieldsControl/store/palletDefinitionCstmFieldsControl.types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'pallet-definition-cstm-fields';

export const PalletDefinitionCstmFieldsControlServices = {
    getAll: () => guardedFetcher.get<PalletDefinitionCstmFieldsControlResponse>(`${prefix}`),
    create: (params: PalletDefinitionCstmFieldCreateParams) => guardedFetcher.post(`${prefix}`, params),
    update: (id: string, params: Partial<PalletDefinitionCstmFieldCreateParams>) => guardedFetcher.patch(`${prefix}/${id}`, params),
};
