import * as React from 'react';
import VirtualList from 'react-tiny-virtual-list';
import styled from 'styled-components';

import {
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {NormalizedGrantOfSet} from 'src/global/authorization/normalizeGrants';

// import AdminIcon from '@material-ui/icons/Gavel';
// import ProductionInfoIcon from '@material-ui/icons/MultilineChart';
// import PresenceIcon from '@material-ui/icons/People';
// import ProductionControlIcon from '@material-ui/icons/ControlCamera';
// import EmployeesIcon from '@material-ui/icons/MultilineChart';

type Props = {
  expanded: string | null;
  // name: string;
  change(...args: any[]): any;
  category: string;
  grants: NormalizedGrantOfSet[];
  selectedGrants: string[];
  chooseGrant(id: string | null): void;
};

export const GrantsCategoryPanel: React.FunctionComponent<Props> = props => {
  const LENGTH = props.grants.length < 3 ? 1 : props.grants.length / 3 + 1;
  const MAGIC = LENGTH * 44;
  const height = MAGIC > 300 ? 300 : MAGIC;
  let count = 0;
  props.grants.forEach(el => {
    if (props.selectedGrants.find(sg => sg === el.key)) count++;
  });
  return (
    <ExpansionPanel
      expanded={props.expanded === props.category}
      onChange={(_: any, expanded: boolean) => props.change(props.category, expanded)}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" style={{width: 250}}>
          {props.category}
        </Typography>
        <Typography style={{alignSelf: 'center', marginLeft: 250}}>
          + seleccionados: <span style={{fontSize: 14}}>{count}</span>
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <DetailsWrap>
          <VirtualList
            width="99%"
            height={height}
            itemSize={40}
            itemCount={LENGTH}
            renderItem={({index, style}) => {
              let grants: NormalizedGrantOfSet[] = [];
              if (index === 0) grants = props.grants.slice(0, 3);
              else grants = props.grants.slice(index * 3, index * 3 + 3);
              if (!(props.expanded === props.category)) return null;
              return (
                <LaneWrap key={index} style={style}>
                  {grants.map(gr => (
                    <FormControlLabel
                      key={gr.key}
                      style={{width: 450, marginLeft: 45}}
                      control={
                        <Checkbox
                          checked={
                            !!(props.expanded === props.category) &&
                            !!props.selectedGrants.find(sg => sg === gr.key)
                          }
                          // disabled={!GET}
                          onChange={() => props.chooseGrant(gr.key)}
                        />
                      }
                      label={gr.name}
                    />
                  ))}
                </LaneWrap>
              );
            }}
          />
        </DetailsWrap>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const DetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LaneWrap = styled.div`
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* margin: 5px; */
  width: 100%;
`;
