import {TCTaskResponse} from 'src/app/administration/tasksControl/store/tasksControl.types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/tasks';

type Options = {
  name: string;
  id: string;
  direct: number;
  is_downtime: boolean;
};

type OptionsPresence = {
  areaId: string;
  employeeId: string;
};

export const TasksControlServices = {
  getAll: (params?: {[k: string]: any}) =>
    guardedFetcher.get<TCTaskResponse>(prefix, {params}),
  create: ({name, direct, is_downtime}: Partial<Options>) =>
    guardedFetcher.post(prefix, {name, direct, is_downtime}),
  update: ({id, name, direct, is_downtime}: Options) =>
    guardedFetcher.patch(`${prefix}/${id}`, {name, direct, is_downtime}),
  delete: ({id}: Pick<Options, keyof Options>) =>
    guardedFetcher.delete(`${prefix}/${id}`),
  getTaskAvailables: ({areaId, employeeId}: Partial<OptionsPresence>) =>
    guardedFetcher.post(`lanemanager/task-allowed`, {
      area_id: areaId,
      employee_id: employeeId,
    }),
};
