import {batchSimpleScheme} from './batch';
import {boxScheme} from './box';
import {makingTypeScheme} from './makingtype';

export const palletScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['id', 'boxes_count', 'boxes_total', 'started_at', 'finished_at'],
  properties: {
    id: {type: 'string'},
    boxes_count: {type: 'number'},
    boxes_total: {type: 'number'},
    started_at: {oneOf: [{type: 'string'}, {type: 'null'}]},
    finished_at: {oneOf: [{type: 'string'}, {type: 'null'}]},
  },
};

export const palletWithBoxesScheme = {
  ...palletScheme,
  required: palletScheme.required.concat('boxes'),
  properties: {
    ...palletScheme.properties,
    boxes: {oneOf: [{type: 'array', items: boxScheme}, {type: 'null'}]},
  },
};
export const palletWithBatchesScheme = {
  ...palletScheme,
  required: palletScheme.required.concat('batches'),
  properties: {
    ...palletScheme.properties,
    batches: {
      oneOf: [{type: 'array', items: batchSimpleScheme}, {type: 'null'}],
    },
  },
};

export const palletWithMaketypeAndBoxesScheme = {
  ...palletWithBoxesScheme,
  required: palletScheme.required.concat('making_type'),
  properties: {
    ...palletWithBoxesScheme.properties,
    making_type: makingTypeScheme,
  },
};

// state: { type: 'string', oneOf: ['active', 'inactive', 'reserved'] },
