import * as React from 'react';
import {WrapFullContainer, OCard} from 'src/shared/style';
import {HourTotalType, EmployeesTotalTasksType, HourPieGroupedTotalType, HourPieTaskType} from 'src/app/information/kpis/store/types/chart-types';
import {KpiChartsServices} from 'src/app/information/kpis/store/services';
import {HourTotalChart} from 'src/app/information/kpis/charts/HourCalculation/HourTotalChart';
import {EmployeesTotalTasksChart} from 'src/app/information/kpis/charts/HourCalculation/EmployeesTotalTasksChart';
import {HourPieGroupedTotalChart} from '../../charts/HourCalculation/HourPieGroupedTotalChart';
import {HourPieTaskChart} from '../../charts/HourCalculation/HourPieTaskChart';

export interface ConfectionCalculationProps {
    areaId: string;
    centerId: string;
}
type State = {
    areaId: string | undefined;
    centerId: string | undefined;
    date: Date | Date[] | undefined;
    hourTotal: HourTotalType | undefined;
    hourPieGroupedTotal: HourPieGroupedTotalType | undefined;
    hourPieGroupedComparativeTotal: HourPieGroupedTotalType | undefined;
    hourPieIndirect: HourPieTaskType | undefined;
    hourPieDirect: HourPieTaskType | undefined;
    employeesTotalTasks: EmployeesTotalTasksType | undefined;
    loadingHourPieGroupedTotalData: boolean;
    loadingHourPieIndirectData: boolean;
    loadingHourTotalData: boolean;
    loadingEmployeesTotalTasksData: boolean;
};

const initialState = {
    areaId: undefined,
    centerId: undefined,
    date: undefined,
    hourPieGroupedTotal: {
        hourByGroupedValues: [],
        hourByGroupedLabels: [],
    },
    hourPieGroupedComparativeTotal: {
        hourByGroupedValues: [],
        hourByGroupedLabels: [],
    },
    hourPieIndirect: {
        hourByTasksTypeValues: [],
        hourByTasksTypeLabels: [],
    },
    hourPieDirect: {
        hourByTasksTypeValues: [],
        hourByTasksTypeLabels: [],
    },
    hourTotal: {
        hourProduccion: '',
        hourByTasksValues: [],
        hourByTasksLabels: [],
    },
    employeesTotalTasks: {
        numEmployees: '',
        employeesByTasksValues: [],
        employeesByTasksLabels: [],
    },
    loadingHourPieGroupedTotalData: false,
    loadingHourPieIndirectData: false,
    loadingHourTotalData: false,
    loadingEmployeesTotalTasksData: false,
};

type Props = {
    areaId: string;
    centerId: string;
    startDate: string;
    endDate: string;
};

export class HourCalculation extends React.Component<Props, State> {
    state = initialState;

    componentDidMount() {
        this.showData(this.props);
    }

    componentWillReceiveProps(props: Props) {
        this.showData(props);
    }

    showData(props: Props) {
        const {centerId, areaId, startDate, endDate} = props;

        this.setState({loadingHourPieGroupedTotalData: true});
        this.setState({loadingHourPieIndirectData: true});
        this.setState({loadingHourTotalData: true});
        this.setState({loadingEmployeesTotalTasksData: true});

        KpiChartsServices.hourPieGroupedTotal(centerId, areaId, startDate, endDate).then((res: any) => {
            const tasksLabel = [];
            const tasksValue = [];

            if (res) {
                for (const index in res.data.hourByGroupedValues) {
                    if (index) {
                        tasksLabel.push(index);
                        tasksValue.push(res.data.hourByGroupedValues[index]);
                    }
                }
            }

            const data = {
                hourByGroupedValues: tasksValue,
                hourByGroupedLabels: tasksLabel,
            };

            const tasksComparativeLabel = [];
            const tasksComparativeValue = [];

            if (res) {
                for (const index in res.data.hourByGroupedProductivesValues) {
                    if (index) {
                        tasksComparativeLabel.push(index);
                        tasksComparativeValue.push(res.data.hourByGroupedProductivesValues[index]);
                    }
                }
            }

            const dataComparative = {
                hourByGroupedValues: tasksComparativeValue,
                hourByGroupedLabels: tasksComparativeLabel,
            };

            this.setState({hourPieGroupedTotal: data});
            this.setState({hourPieGroupedComparativeTotal: dataComparative});
            this.setState({loadingHourPieGroupedTotalData: false});
        });

        KpiChartsServices.hourPieIndirectAndDirect(centerId, areaId, startDate, endDate).then((res: any) => {
            const IndirectTasksLabel = [];
            const IndirectTasksValue = [];

            if (res) {
                for (const index in res.data.hourIndirectByTasksTypeValues) {
                    if (index) {
                        IndirectTasksLabel.push(index);
                        IndirectTasksValue.push(res.data.hourIndirectByTasksTypeValues[index]);
                    }
                }
            }

            const dataIndirect = {
                hourByTasksTypeValues: IndirectTasksValue,
                hourByTasksTypeLabels: IndirectTasksLabel,
            };

            const DirectTasksLabel = [];
            const DirectTasksValue = [];

            if (res) {
                for (const index in res.data.hourDirectByTasksTypeValues) {
                    if (index) {
                        DirectTasksLabel.push(index);
                        DirectTasksValue.push(res.data.hourDirectByTasksTypeValues[index]);
                    }
                }
            }

            const dataDirect = {
                hourByTasksTypeValues: DirectTasksValue,
                hourByTasksTypeLabels: DirectTasksLabel,
            };

            this.setState({hourPieIndirect: dataIndirect});
            this.setState({hourPieDirect: dataDirect});
            this.setState({loadingHourPieIndirectData: false});
        });

        KpiChartsServices.hourTotal(centerId, areaId, startDate, endDate).then((res: any) => {
            const tasksLabel = [];
            const tasksValue = [];

            if (res) {
                for (const index in res.data.hourByTasks) {
                    if (index) {
                        tasksLabel.push(index);
                        tasksValue.push(res.data.hourByTasks[index]);
                    }
                }
            }

            const data = {
                hourProduccion: res.data.hourProduccion,
                hourByTasksValues: tasksValue,
                hourByTasksLabels: tasksLabel,
            };

            this.setState({hourTotal: data});
            this.setState({loadingHourTotalData: false});
        });

        KpiChartsServices.employeesTotalTasks(centerId, areaId, startDate, endDate).then((res1: any) => {
            const employeesByTasksLabel = [];
            const employeesByTasksValue = [];

            if (res1) {
                for (const index in res1.data.employeesByTasks) {
                    if (index) {
                        employeesByTasksLabel.push(index);
                        employeesByTasksValue.push(res1.data.employeesByTasks[index]);
                    }
                }
            }

            const data = {
                numEmployees: res1.data.numEmployees,
                employeesByTasksValues: employeesByTasksValue,
                employeesByTasksLabels: employeesByTasksLabel,
            };

            this.setState({employeesTotalTasks: data});
            this.setState({loadingEmployeesTotalTasksData: false});
        });
    }

    public render() {
        return (
            <>
                <h1 className="text-center">Cálculo de horas</h1>
                <WrapFullContainer>
                    <OCard
                        title={'Horas totales agrupadas'}
                        heightTitle={'30'}
                        width={'47%'}
                        height={'600px'}
                        contentHeight={'500px'}
                        contentPadding={'5px'}
                        margin={'2% 1% 2% 2%'}
                    >
                        <HourPieGroupedTotalChart chartData={this.state.hourPieGroupedTotal} isLoading={this.state.loadingHourPieGroupedTotalData} />
                    </OCard>
                    <OCard
                        title={'Comparativa H. productivas / H. no productivas (paradas y tránsitos)'}
                        heightTitle={'30'}
                        width={'47%'}
                        height={'600px'}
                        contentHeight={'500px'}
                        contentPadding={'5px'}
                        margin={'2% 1% 2% 2%'}
                    >
                        <HourPieGroupedTotalChart chartData={this.state.hourPieGroupedComparativeTotal} isLoading={this.state.loadingHourPieGroupedTotalData} />
                    </OCard>
                    <OCard title={'Horas Indirectas'} heightTitle={'30'} width={'47%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'2% 1% 2% 2%'}>
                        <HourPieTaskChart chartData={this.state.hourPieIndirect} isLoading={this.state.loadingHourPieIndirectData} />
                    </OCard>
                    <OCard title={'Horas Directas'} heightTitle={'30'} width={'47%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'2% 1% 2% 2%'}>
                        <HourPieTaskChart chartData={this.state.hourPieDirect} isLoading={this.state.loadingHourPieIndirectData} />
                    </OCard>
                    <OCard title={'Horas totales'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'2%'}>
                        <HourTotalChart chartData={this.state.hourTotal} isLoading={this.state.loadingHourTotalData} />
                    </OCard>
                    <OCard title={'Número de empleados'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'2%'}>
                        <EmployeesTotalTasksChart chartData={this.state.employeesTotalTasks} isLoading={this.state.loadingEmployeesTotalTasksData} />
                    </OCard>
                </WrapFullContainer>
            </>
        );
    }
}
