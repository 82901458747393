import * as React from 'react';
import styled from 'styled-components';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {FunctionalityControlServices} from "./store/functionalityControl.services";
import {FunctionalityControlPanel} from "./FunctionalityControlPanel";
import {
    FunctionalitiesTreeType,
    ModulesTreeType, FunctionalityCategoryType, ExchangeFunctionalityType, FunctionalityType, ExchangeBindModulesType
} from "./store/functionalityControl.types";
import {
    Button,
    Checkbox,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import {TableModal} from "../../../shared/components/Modal";
import ReloadIcon from "@material-ui/icons/Refresh";


type BindModulesType = {
    [moduleId: string] : BindModuleItemType[]
};

type BindModuleItemType = {
    itemId: string,
    itemName: string,
}

type State = {
    openModal : boolean;
    enableSave : boolean;
    enableBind : boolean;
    expanded: string | boolean;
    allModules: ModulesTreeType;
    allCategories: FunctionalityCategoryType;
    allFunctionalities: FunctionalitiesTreeType;
    functionalityId? : string;
    updatedFunctionalities: Map<string, number>;
    bindedModules : Map<string, BindModulesType>;
};

const initialState: Readonly<State> = {
    openModal : false,
    enableSave: false,
    enableBind : false,
    expanded: false,
    allModules: {},
    allCategories: {},
    allFunctionalities: {},
    functionalityId: undefined,
    updatedFunctionalities: new Map<string, number>(),
    bindedModules : new Map<string, BindModulesType>()
};

export class FunctionalityControl extends React.Component<{}, State> {
    readonly state = initialState;

    componentDidMount() {
        this.fetchFunctionalities().then(data => {

            this.setState({
                allModules : data.allModules,
                allCategories : data.categories,
                allFunctionalities : data.functionalities,
            })

            //this.fillBindedModules();
        });
    }

    // private fillBindedModules = () => {
    //     Object.keys(this.state.allFunctionalities).forEach(functionalityId => this.fillFunctionality(functionalityId));
    // }

    private fillFunctionality = (functionalityId : string) => {
        const functionality : FunctionalityType = this.state.allFunctionalities[functionalityId];
        const modules : BindModulesType = {};

        Object.entries(functionality.modules).forEach(([moduleId, menuItems]) => {
            if (this.state.allModules[moduleId]) {
                const items : BindModuleItemType[] = [];
                menuItems.forEach(itemId => {
                    const item = this.state.allModules[moduleId].menuItems[itemId];
                    item && items.push({itemId, itemName: item.name});
                });

                modules[moduleId] = items;
            }
        });

        this.state.bindedModules.set(functionalityId, modules);
    }

    private fetchFunctionalities = async () => {
        return await fetchWithFeedback(FunctionalityControlServices.get(), {
            accessor: 'data'
        });
    };

    private reload = () => {
        this.handleCloseModal();

        this.state.updatedFunctionalities.clear();

        this.fetchFunctionalities().then(data => {
            this.setState({
                allModules : data.allModules,
                allCategories : data.categories,
                allFunctionalities : data.functionalities,
                enableSave: false
            });
        });
    }

    private handleSave = () => {
        const updatedFunctionalities : ExchangeFunctionalityType[] = [];

        this.setState({enableSave: false});

        this.state.updatedFunctionalities.forEach((value, key) => {
            updatedFunctionalities.push({
               id: key,
               status: value
            });
        });

        fetchWithFeedback(
            FunctionalityControlServices.save({updatedFunctionalities : updatedFunctionalities}),
            {
                showMessage: true,
                successMessage: 'Se han actualizado las funcionalidades de la aplicación',
                errorMessage: 'No se han podido actualizar las funcionalidades. Vuelva a intentarlo.'
            },
        ).then((r) => {
            if (r && r.result === 'OK') {
                this.setState({
                    allFunctionalities: r.functionalities
                })
                this.state.updatedFunctionalities.clear();
            } else {
                this.setState({enableSave: true});
            }
        });
    }

    private handleChangeModal = (panel: string, expanded: boolean) =>
        this.setState({expanded: expanded ? panel : false});

    private handleOpenModal = (functionalityId : string) => {

        this.fillFunctionality(functionalityId);
        this.setState({
            functionalityId : functionalityId,
            openModal : true
        });
    }

    private handleCloseModal = () => {
        this.setState({
            functionalityId : undefined,
            openModal: false,
            enableBind: false
        });

        this.state.bindedModules.clear();
    }

    private handleChangeModule = (e : React.ChangeEvent<{checked : boolean}>, moduleId : string) => {

        const {functionalityId, bindedModules} = this.state;

        if (functionalityId) {
            const bindedModule = bindedModules.get(functionalityId);

            if (e.target.checked) {
                bindedModule && (bindedModule[moduleId] = []);
            } else {
                bindedModule && delete bindedModule[moduleId];
            }
            this.forceUpdate();
            this.setState({enableBind: true});
        }
    }

    private handleSelectMenuItem = (e : React.ChangeEvent<{checked : boolean}>, moduleId : string , itemId : string, itemName : string) => {

        const {functionalityId, bindedModules} = this.state;

        if (functionalityId) {
            const bFunc = bindedModules.get(functionalityId);
            const bModule = bFunc && bFunc[moduleId];
            if (bModule) {
                if (e.target.checked) bModule.push({itemId, itemName});
                else {
                    bModule.some((item, index) => {
                        if (item.itemId === itemId) {
                            bModule.splice(index, 1);
                            return true;
                        }
                        return false;
                    });
                }
                this.forceUpdate();
                this.setState({enableBind: true});
            }
        }
    }

    private handleBindModules = () => {
        const functionalityId = this.state.functionalityId;
        if (functionalityId) {

            this.setState({enableBind: false});

            const functionality = this.state.allFunctionalities[functionalityId];
            const bindedModules = this.state.bindedModules.get(functionalityId);
            const functionalityStatus = functionality.status;
            const isUpdated = this.state.updatedFunctionalities.has(functionalityId);
            const category = functionality.category;



            if (bindedModules) {
                const toDelete : ExchangeBindModulesType[] = [];
                const toAdd : ExchangeBindModulesType[] = [];
                Object.entries(bindedModules).forEach(([moduleId, menuItems])=> {
                    if (menuItems.length === 0 && functionality.modules[moduleId] === undefined) {
                        toAdd.push({moduleId, menuItemId : null});
                    }
                    else {
                        menuItems.forEach(item => {
                            if (!functionality.modules[moduleId] || functionality.modules[moduleId].indexOf(item.itemId) < 0) {
                                toAdd.push({moduleId, menuItemId : item.itemId});
                            }
                        });
                    }
                });

                Object.entries(functionality.modules).forEach(([moduleId, menuItems])=> {
                    if (!menuItems[0]) {
                        if (!bindedModules[moduleId]) toDelete.push({moduleId, menuItemId : null});
                    }
                    else {
                        menuItems.forEach(menuItemId => {
                            const hasItem = bindedModules[moduleId] && bindedModules[moduleId].some(item => item.itemId === menuItemId);
                            if (!hasItem) {
                                toDelete.push({moduleId, menuItemId});
                            }
                        });
                    }
                });

                fetchWithFeedback(
                    FunctionalityControlServices.bindModules(
                        {modules : {functionalityId, functionalityStatus, isUpdated, category, toAdd, toDelete}}),
                    {
                        showMessage: true,
                        successMessage: 'Se han asociado los módulos a la funcionalidad',
                        errorMessage: 'No se han podido asociar los módulos. Vuelva a intentarlo.'
                    },
                ).then(r => {
                    if (r && r.result === 'OK') {
                        this.setState({
                            allFunctionalities : r.functionalities,
                            enableSave : false
                        });
                        this.state.updatedFunctionalities.clear();
                        this.fillFunctionality(functionalityId);
                        // const fcp = this.refs[category] as FunctionalityControlPanel;
                        // fcp.updateModulesCount();
                        //
                        // this.handleCloseModal();
                    } else {
                        this.setState({enableBind: true});
                    }
                });
            }
        }
    }

    private getSelectedItems(functionalityId: string | undefined, moduleId: string) : string[] {
        const bFunc = functionalityId && this.state.bindedModules.get(functionalityId);
        const items = bFunc && bFunc[moduleId];
        const itemNames = items && items.map(i => i.itemName);

        if (itemNames !== undefined && itemNames !== "") return itemNames;
        return [];
    }

    private isModuleChecked(functionalityId : string | undefined, moduleId : string) : boolean {
        if (functionalityId) {
            const bModule = this.state.bindedModules.get(functionalityId);
            if (bModule && bModule.hasOwnProperty(moduleId)) return true;
        }
        return false;
    }

    private isMenuItemChecked(functionalityId : string | undefined, moduleId : string, itemId : string) : boolean {
        if (functionalityId) {
            const bFunc = this.state.bindedModules.get(functionalityId);
            if (bFunc) {
                return bFunc[moduleId] && bFunc[moduleId].some(item => item.itemId === itemId);
            }
        }
        return false;
    }

    private enableSave = () => {
        this.setState({enableSave: true});
    }

    render() {
        const {
            expanded,
            enableSave,
            enableBind,
            openModal,
            functionalityId,
            allCategories,
            allFunctionalities,
            allModules,
            updatedFunctionalities
        } = this.state;
        const functionality = typeof functionalityId === 'string' ? allFunctionalities[functionalityId] : undefined;

        return (
            <WrappingDiv>
                <Grid container alignContent="flex-start">
                <Grid item xs={1}/>
                <Grid item xs={10}>
                {
                    functionality && (
                        <TableModal title={`Asociar módulos a ${functionality.category} > ${functionality.name}`}
                                    open={openModal}
                                    handleClose={this.handleCloseModal}
                                    fullWidth={true}
                                    maxWidth={'md'}
                        >
                            {
                                Object.entries(allModules).map(([moduleId , module ]) => (
                                        <Grid container spacing={16}
                                              alignItems="center"
                                              key={functionalityId + '_' + moduleId}
                                        >
                                            <Grid item xs={5}>
                                                <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
                                                    <Checkbox
                                                        disableRipple
                                                        checked = {this.isModuleChecked(functionalityId, moduleId)}
                                                        onChange = {(e) => this.handleChangeModule(e, moduleId)}
                                                    />
                                                    <Typography variant="h6">
                                                        {module.name}
                                                    </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <InputLabel id={functionalityId + '_' + moduleId + '_label'}
                                                >
                                                    Opciones de menú
                                                </InputLabel>
                                                <Select style={{width:'100%'}}
                                                        disabled={!this.isModuleChecked(functionalityId, moduleId)}
                                                        aria-label={functionalityId + '_' + moduleId + '_label'}
                                                        id={functionalityId + '_' + moduleId + "_select"}
                                                        multiple
                                                        renderValue={(selected) => (selected as string[]).join(', ')}
                                                        value={this.getSelectedItems(functionalityId, moduleId)}
                                                >
                                                    {
                                                        Object.entries(module.menuItems).map(([itemId, item]) => (
                                                            <MenuItem value={item.name} key={functionalityId + '_' + moduleId + '_' + itemId}>
                                                                <Checkbox
                                                                    disableRipple
                                                                    checked = {this.isMenuItemChecked(functionalityId, moduleId, itemId)}
                                                                    onChange = {
                                                                        (e) => this.handleSelectMenuItem(e, moduleId, itemId, item.name)
                                                                    }
                                                                />
                                                                <ListItemText primary={item.name} />
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    )
                                )}

                            <div style={{textAlign: 'right'}}>
                                <Button
                                    disabled={!enableBind}
                                    style={{
                                        marginTop: '25px',
                                        marginBottom: '25px',
                                        width: 'auto',
                                        height: '4.1%',
                                        padding: '4 15',
                                        fontWeight: 900,
                                    }}
                                    color="secondary"
                                    variant="contained"
                                    onClick={this.handleBindModules}
                                >
                                    GUARDAR
                                </Button>
                            </div>

                        </TableModal>
                    )
                }

                { allCategories && Object.entries(allCategories).map(([categoryName, functionalitiesIds]) => (
                    <FunctionalityControlPanel
                        updatedFunctionalities={updatedFunctionalities}
                        ref={categoryName}
                        key={categoryName}
                        expanded={expanded}
                        functionalities={allFunctionalities}
                        categoryName={categoryName}
                        functionalitiesIds={functionalitiesIds}
                        openModuleModal={this.handleOpenModal}
                        change={this.handleChangeModal}
                        enableSave={this.enableSave}
                    />
                ))
                }
                <div style={{textAlign: 'center'}}>
                    <Button
                        disabled={!enableSave}
                        style={{
                            marginTop: '25px',
                            marginBottom: '25px',
                            width: 'auto',
                            height: '4.1%',
                            padding: '4 15',
                            fontWeight: 900,
                        }}
                        color="secondary"
                        variant="contained"
                        onClick={this.handleSave}>
                        GUARDAR
                    </Button>
                </div>
                </Grid>
                <Grid item xs={1}>
                    <IconButton aria-label="reload">
                        <ReloadIcon fontSize="large" onClick={this.reload}/>
                    </IconButton>
                </Grid>


            </Grid>
            </WrappingDiv>
        );
    }
}

const WrappingDiv = styled.div`
  /*width: calc(75%);*/
  margin: auto;
  min-height: calc(100% - 7px);
  background-color: #efefef;
  /* min-height: calc(100% - 5px); */
  /* margin-bottom: 5px; */
  padding: 5px;
  /* margin-bottom: 5px; */
`;
