import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

// import {AppStoreState} from 'src/store';
import {CardControlActions} from 'src/app/administration/cardControl/store/actions/cardcontrol.actions';
import {CardControlServices} from 'src/app/administration/cardControl/store/services';
import {AppStoreState} from 'src/store';
import {notifyServerError} from 'src/utils/Notification';

// import {AnyAction} from 'redux';

export const getDeviceState = (
  deviceId: string,
): ThunkAction<Promise<void>, AppStoreState, null, AnyAction> => async dispatch => {
  try {
    const res = await CardControlServices.getDeviceState(deviceId);
    if (res.status === 200) {
      if (+res.data.active > 0) dispatch(CardControlActions.setActive(res.data.id));
      else if (+res.data.active < 1)
        dispatch(CardControlActions.setInactive(res.data.id));
    } else dispatch(notifyServerError());
  } catch (error) {
    console.error('GET DEVICE STATUS ERROR>> ', error);
    dispatch(CardControlActions.setInactive());
  }
};
