import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'messages';
type SendOptions = {
  employeeIds: string[];
  // jobtypeIds: string[];
  admin_employee_id: string;
  subject: string;
  body: string;
  sender: string;
};
export const MessagesServices = {
  read: (messageId: string) => guardedFetcher.patch(`${prefix}/read/${messageId}`),
  send: (args: SendOptions) => guardedFetcher.post(`${prefix}`, args),
  getInboxAdmin: ({params}: PaginatedServiceArgs) =>
    guardedFetcher.get(`${prefix}`, {params}),
  getInbox: ({params, args}: PaginatedServiceArgs) =>
    guardedFetcher.get(`${prefix}/employee/${args ? args[0] : 'NO_HAY_EMPLOYEE_ID'}`, {
      params,
    }),
  // getMessage: (messageId: string) => guardedFetcher.get(`${prefix}/${messageId}`),
  getNew: (employeeId?: string) =>
    guardedFetcher.get(
      `${prefix}/employee/${employeeId ||
        'NO_HAY_EMPLOYEE_ID'}?page-size=3&page=1&filtered=%7B%22viewed%22:%22false%22%7D`,
    ),
};
