import {taskgroupScheme} from './taskgroup';

export const areaScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['id', 'name', 'taskgroup'],
  properties: {
    id: {type: 'string'},
    name: {type: 'string'},
    taskgroup: {type: 'array', items: taskgroupScheme},
  },
};
