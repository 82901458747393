import {PaginateParams} from 'src/types';
// import {Grantset} from 'src/global/authorization/grantSet';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'auth/rulegroups';

type Options = {id?: string; name?: string; grants?: string[]};
export const RulegroupsControlServices = {
  get: (params?: PaginateParams) =>
    guardedFetcher.get<{rulegroups: [{id: string; name: string}]}>(`${prefix}`, {
      params,
    }),
  update: ({id, name}: Options) => guardedFetcher.patch(`${prefix}/${id}`, {name}),
  create: ({name}: Options) => guardedFetcher.post(`${prefix}`, {name}),
  delete: ({id}: Options) => guardedFetcher.delete(`${prefix}/${id}`),
  getUsers: ({id}: Options) => guardedFetcher.get(`${prefix}/${id}/users`),
  getGrantset: ({id}: Options) =>
    guardedFetcher.get<{grantsets: any}>(`${prefix}/${id}/grantsets`),
  updateGrants: ({id, grants}: Options) =>
    guardedFetcher.patch(`${prefix}/${id}/update-grants`, grants),
};
