import styled from 'styled-components';

export const WrapFullContainer = styled.div`
  width: 100%;
  height: 99.5%;
  display: flex;
  flex-wrap: wrap;
`;

export const WrapFullContainerNowrap = styled.div`
  width: 100%;
  height: 99.5%;
  display: flex;
`;

export const WrapCreateButton = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
`;

export const WrapTwoCardsToColumn = styled('div')<{width?: string}>`
  display: flex;
  flex-wrap: wrap;
  width: ${({width}) => width || '100%'};
`;

export const WrapCardsToRow = styled('div')<{height?: string}>`
  width: 100%;
  height: ${({height}) => height || '50%'};
  display: flex;
`;

export const WrapCreate = styled.div`
  width: 100%;
`;
export const WrapFilter = styled.div`
  width: 100%;
  /* margin-top: 15px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const WrapCustomDropdown = styled('div')<{
  width?: string;
  margin?: string;
}>`
  width: ${({width}) => width || '100%'};
  margin-bottom: 20px;
  margin-top: 15px;
  ${({margin}) => (margin ? `margin: ${margin}` : '')}
`;

export const WrapHeader = styled('div')<{height?: string}>`
  display: flex;
  height: ${({height}) => `${height}px`};
  width: 100%;
  border-bottom: 1px solid #dfdfdf;
  font-size: 10px !important;
`;
