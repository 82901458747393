import {Reducer, Action} from 'redux';
import {ProdPartsState} from '../types';
import {ProdPartsActionTypes} from '../actions/action-types';

export const defaultState: ProdPartsState = {fetchState: 'waiting'};

export const prodPartsReducer: Reducer<ProdPartsState, Action> = (
  state = defaultState,
  {type},
) => {
  switch (type) {
    case ProdPartsActionTypes.success:
      return {fetchState: 'success'};
    case ProdPartsActionTypes.fail:
      return {fetchState: 'fail'};
    case ProdPartsActionTypes.waiting:
      return {fetchState: 'waiting'};
    default:
      return state;
  }
};
