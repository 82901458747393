// import {push} from 'connected-react-router';
import * as React from 'react';
// import {Redirect} from 'react-router';
import styled from 'styled-components';

import {Button} from '@material-ui/core';
// import ExpansionPanel from '@material-ui/core/ExpansionPanel';
// import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
// import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import {SharedServices} from 'src/shared/shared.services';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

// import {dispatcher} from 'src/store/store';

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

type Props = {
  error: Error;
  info: React.ErrorInfo;
};

export const ErrorMessage: React.FunctionComponent<Props> = props => {
  const {error, info} = props;
  fetchWithFeedback(
    SharedServices.sendError({
      category: 'Frontend',
      description: 'Error en la aplicación',
      incidence: false,
      exception: error.stack ? error.stack.substring(0, 499) : undefined,
      endpoint: info.componentStack.substring(0, 99),
    }),
  );
  // readonly state: State = {fireRedirect: false};

  // fire = () => {
  //   console.log('firing state to redirect');
  //   this.setState({fireRedirect: true});
  // };

  // render() {
  // if (!this.state.fireRedirect)
  return (
    <Div>
      <div
        style={{display: 'flex', flexDirection: 'column', height: 100, margin: 'auto'}}>
        <Typography variant="h3" style={{textAlign: 'center', marginBottom: 10}}>
          Ha sucedido un error.
        </Typography>
        <Button variant="outlined" component="a" href={'/'}>
          Volver a la página de inicio
        </Button>
      </div>
    </Div>
  );
  // else return <Redirect to="/acceso" />;
  // }
};

const Div = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  margin: auto;
  width: 900px;
  height: 500px;
`;
