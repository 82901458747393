import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {AppStoreState} from 'src/store/interface';
import {getAllUsersActiveTasks, ActiveUsersTasks} from 'src/terminal/store/selectors';

interface TasksAllProps {
  classes: {
    root: string;
    table: string;
  };
  uTasksMap: any[];
}
const TasksAll = (props: TasksAllProps) => {
  const {classes} = props;
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Área</TableCell>
            <TableCell>Nombre Empleado</TableCell>
            <TableCell>Tarea</TableCell>
            <TableCell>F/H Inicio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.uTasksMap.map((ut: ActiveUsersTasks) => {
            if (ut.activeTask)
              return (
                <TableRow key={ut.id}>
                  <TableCell component="th" scope="row">
                    Area X
                  </TableCell>
                  <TableCell>{`${ut.name} ${ut.surname}`}</TableCell>
                  <TableCell>{ut.activeTask.taskName}</TableCell>
                  <TableCell>
                    {new Date(ut.activeTask.startedTime!).toLocaleString()}
                  </TableCell>
                </TableRow>
              );
            return undefined;
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

const TableStyled = withStyles({
  root: {
    width: '100%',
    // marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    backgroundColor: '#eaeaea',
    minWidth: 700,
  },
})(TasksAll);

const mapStateToProps = (state: AppStoreState) => ({
  uTasksMap: getAllUsersActiveTasks(state),
});
// const mapDispatchToProps = (dispatch: any) => {};

export const TableConnected = connect(mapStateToProps)(TableStyled);
