import {Reducer} from 'redux';

import {
  EmployeeTaskSelectActions,
  EmployeeTaskSelectActionTypes,
} from 'src/app/presencemanagement/store/employeeTaskSelect.action-types';

export type EmployeeTaskSelectStoreState = {
  employeeId: string;
  workshift: string;
  employeeFullname: string;
  centerId: string;
};
const initialState: EmployeeTaskSelectStoreState = {
  employeeId: '',
  workshift: '',
  employeeFullname: '',
  centerId: '',
};
export const EmployeeTaskSelectReducer: Reducer<
  EmployeeTaskSelectStoreState,
  EmployeeTaskSelectActions
> = (state = initialState, action) => {
  switch (action.type) {
    case EmployeeTaskSelectActionTypes.select:
      return {
        employeeId: action.payload.employeeId,
        workshift: action.payload.workshift,
        employeeFullname: action.payload.employeeFullname,
        centerId: action.payload.centerId,
      };
    case EmployeeTaskSelectActionTypes.clear:
      return {employeeId: '', workshift: '', employeeFullname: '', centerId: ''};
    default:
      return state;
  }
};
