import 'react-quill/dist/quill.snow.css'; // ES6

import invoke from 'lodash/invoke';
import * as React from 'react';
import isEqual from 'react-fast-compare';
import {connect} from 'react-redux';
import styled from 'styled-components';

import {EmployeesControlServices} from 'src/app/administration/employeesControl/store/employeesControl.services';
import {EmployeesControlResponseData, JobtypeResponseData} from 'src/app/administration/employeesControl/store/types';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {MessagesServices} from 'src/app/messages/store/messages.services';
import {withStyles, Badge, Checkbox} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import {PaginatedTableSelectors} from 'src/shared/components/store/paginatedTable.selectors';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {CustomTextField, OCard} from 'src/shared/style';
import {AppStoreState} from 'src/store';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';
import {ParametersSelectors} from 'src/utils/Parameters/selectors';
import {ParameterCPN} from 'src/types/app_types';

type SProps = {
    tableFilters: ReturnType<typeof PaginatedTableSelectors.getFilters>;
    userData: ReturnType<typeof LoginSelectors.getUserData>;
    employeeCodeModeParam?: String;
};
type State = Readonly<{
    subject: string;
    message: string;
    sender: string;
    massSelect: boolean;
    allJobtypes?: JobtypeResponseData[];
    selectedEmployees: any[];
}>;
const initialState: State = {
    massSelect: false,
    sender: '',
    subject: '',
    message: '',
    allJobtypes: undefined,
    selectedEmployees: [],
};
type ComponentProps = SProps;
class MessageCreateBase extends React.Component<ComponentProps, State> {
    readonly state = initialState;

    componentDidMount() {
        this.fetchJobtypes();
    }
    componentWillReceiveProps(nextProps: SProps) {
        if (!isEqual(nextProps.tableFilters, this.props.tableFilters)) this.setState({massSelect: false});
    }

    private fetchJobtypes = async () => {
        const jobtypes = (await fetchWithFeedback(EmployeesControlServices.getAllJobtypes(), {
            accessor: 'jobtypes',
        })) as JobtypeResponseData[];
        this.setState({allJobtypes: jobtypes || []});
    };
    // private modules = {
    //   toolbar: [
    //     [{header: [1, 2, false]}],
    //     ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    //     [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
    //   ],
    // };

    private handleSelect = (employee: EmployeesControlResponseData) => {
        if (!employee) return;
        const {id} = employee;
        const {selectedEmployees} = this.state;
        const exist = selectedEmployees.find(it => it.employeeId === id);
        if (exist) {
            this.setState({
                selectedEmployees: selectedEmployees.filter(it => it.employeeId !== id),
                massSelect: false,
            });
        } else {
            this.setState({
                selectedEmployees: selectedEmployees.concat(this.parseEmployee(employee)),
                massSelect: false,
            });
        }
    };
    private parseEmployee = (
        employee: EmployeesControlResponseData,
    ): {
        employeeId: string;
        jobtypes: string[];
    } => ({
        employeeId: employee.id,
        jobtypes: employee.jobtypes.map(jt => jt.id),
    });
    private selectAllFiltered = async () => {
        if (!this.state.massSelect) {
            const selected = await fetchWithFeedback(
                EmployeesControlServices.getAllEmployees({
                    params: {...(this.props.tableFilters as any), 'page-size': 999999},
                    args: [{idOnly: true}],
                }),
            );
            const mapForState = selected.data.map((s: any) => ({employeeId: s.id}));
            this.setState({selectedEmployees: mapForState, massSelect: true});
        } else this.setState({selectedEmployees: [], massSelect: false});
    };
    private printRolesForTable = (jobtypes: Array<{id: string; name: string}>) =>
        invoke(jobtypes, 'map', (item: {id: string; name: string}) => (
            <span key={item.id}>
                {item.name}
                <br />
            </span>
        ));

    render() {
        const {subject, message, allJobtypes, sender} = this.state;
        const {employeeCodeModeParam} = this.props;
        const showErpEmployeeCode = employeeCodeModeParam === 'ERP_MODE' ? true : false;
        const showInternalEmployeeCode = employeeCodeModeParam === 'INTERNAL_MODE' ? true : false;

        return (
            <OCard width={'calc(100% - 10px)'} height={'calc(100% - 5px)'} contentHeight={'98%'} contentPadding={0}>
                <SelectionContainer>
                    {allJobtypes && (
                        <PaginatedTableComponent
                            defaultPageSize={2}
                            showSizeOptions={false}
                            toStore={'message_create'}
                            additionalButtons={[
                                {
                                    icon: <SendIcon />,
                                    tooltipText: 'Enviar',
                                    onClick: () => this.sendMessage(),
                                },
                            ]}
                            columnFormat={[
                                {
                                    Header: 'Selección',
                                    filterable: true,
                                    Filter: () => (
                                        <div style={{textAlign: 'left'}}>
                                            <CustomBadge
                                                display={true}
                                                color="primary"
                                                // title="Cantidad seleccionados"
                                                content={'' + this.state.selectedEmployees.length}
                                            >
                                                <Checkbox value="activeCB" checked={this.state.massSelect} style={{padding: 2}} onClick={() => this.selectAllFiltered()} />
                                            </CustomBadge>
                                        </div>
                                    ),
                                    Cell: info => (
                                        <>
                                            <Checkbox
                                                checked={!!this.state.selectedEmployees.find(it => it.employeeId === info.original.id)}
                                                // color="primary"
                                                value="activeCB"
                                                style={{padding: 10}}
                                            />
                                        </>
                                    ),
                                    sortable: false,
                                    width: 85,
                                    style: {textAlign: 'center', whiteSpace: 'normal', alignSelf: 'center'},
                                },
                                {
                                    Header: 'Código empleado',
                                    accessor: 'code',
                                    show: showInternalEmployeeCode,
                                    sortable: true,
                                    filterable: true,
                                    width: 140,
                                    style: {textAlign: 'center', whiteSpace: 'normal', alignSelf: 'center'},
                                },
                                {
                                    Header: 'Código empleado',
                                    accessor: 'erp_employee_code',
                                    show: showErpEmployeeCode,
                                    sortable: true,
                                    filterable: true,
                                    width: 140,
                                    style: {textAlign: 'center', whiteSpace: 'normal', alignSelf: 'center'},
                                },
                                {
                                    Header: 'Nombre',
                                    accessor: 'name',
                                    style: {textAlign: 'center', whiteSpace: 'normal', alignSelf: 'center'},
                                    sortable: true,
                                    filterable: true,
                                },
                                {
                                    Header: 'Apellidos',
                                    accessor: 'surname',
                                    style: {textAlign: 'center', whiteSpace: 'normal', alignSelf: 'center'},
                                    sortable: true,
                                    filterable: true,
                                },

                                {
                                    Header: 'Roles',
                                    sortable: false,
                                    accessor: 'jobtypeFilter',
                                    dropdownFilter: true,
                                    dropdownFilterData: this.state.allJobtypes,
                                    Cell: item => <>{this.printRolesForTable(item.original.jobtypes)}</>,
                                    style: {textAlign: 'center', whiteSpace: 'normal', alignSelf: 'center'},
                                },
                            ]}
                            service={EmployeesControlServices.getAllEmployees}
                            propsToOwnTable={{
                                getTrProps: (_: any, rowInfo: any) => ({
                                    onClick: () => this.handleSelect(rowInfo.original),
                                    style: {cursor: 'pointer'},
                                }),
                            }}
                        />
                    )}
                </SelectionContainer>
                <BodyContainer>
                    <CustomTextField label={'Remitente'} value={sender} onChange={this.handleChange('sender')} margin={'5px 0 10px 0'} />
                    <CustomTextField label={'Asunto'} value={subject} onChange={this.handleChange('subject')} margin={'0 0 10px 0'} />
                    <CustomTextField multiline={true} label={'Cuerpo del mensaje'} value={message} onChange={this.handleChange('message')} margin={'0 0 10px 0'} />
                </BodyContainer>
            </OCard>
        );
    }

    private sendMessage = async () => {
        if (!this.props.userData.employeeId) return notify({message: 'Su usuario no tiene empleado asociado.', status: 'error'});
        const {message, subject, sender, selectedEmployees} = this.state;
        if (message && subject && sender && selectedEmployees.length) {
            const res = await fetchWithFeedback(
                MessagesServices.send({
                    body: message.trim(),
                    sender: sender.trim(),
                    subject: subject.trim(),
                    employeeIds: selectedEmployees.map(se => se.employeeId),
                    admin_employee_id: this.props.userData.employeeId,
                }),
                {
                    accessor: 'message',
                    successMessage: 'Mensaje enviado',
                    errorMessage: 'Mensaje no enviado, error del servidor',
                },
            );
            if (!res)
                this.setState({
                    massSelect: false,
                    sender: '',
                    subject: '',
                    message: '',
                    selectedEmployees: [],
                });
        } else {
            notify({message: 'Revisar los errores de los campos', status: 'error'});
        }
    };

    private handleChange: HandleNamedChange<State> = name => e => this.setState({[name]: e.target.value} as Pick<State, keyof State>);
}

export const MessageCreate = connect<SProps, {}, {}, AppStoreState>(state => ({
    userData: LoginSelectors.getUserData(state),
    tableFilters: PaginatedTableSelectors.getFilters('message_create', state),
    employeeCodeModeParam: ParametersSelectors.getValueOfParameter(state, ParameterCPN.EMPLOYEE_CODE_MODE),
}))(MessageCreateBase);

const SelectionContainer = styled.div`
    height: 45%;
    width: 100%;
    margin: 0px;
    margin-top: 5px;
    padding: 0px;
`;
const BodyContainer = styled.div`
    height: 55%;
    margin: 10px;
    /* display: flex;
  flex-direction: column; */
`;

/****  BADGE */

type BadgeProps = {
    display: boolean;
    content?: string;
    margin?: string;
    classes?: any;
    color?: 'primary' | 'secondary' | 'default' | 'error';
    style?: {[k: string]: any};
};

const styles = {
    badge: {
        top: 14,
        padding: 3,
        right: -22,
        width: 20,
        height: 20,
    },
};

const CustomBadgeBase: React.FunctionComponent<BadgeProps> = props => {
    return (
        <div
            style={{
                margin: props.margin,
            }}
        >
            <Badge
                component={'div'}
                title="Selección TOTAL, selecciona todos los empleados con los criteros de los filtros"
                // style={props.style}
                classes={{badge: props.classes.badge}}
                invisible={!props.display}
                badgeContent={props.content || ''}
                color={props.color || 'primary'}
            >
                {props.children}
            </Badge>
        </div>
    );
};

const CustomBadge = withStyles(styles)(CustomBadgeBase);
