import {ParametersReducer} from './../utils/Parameters/reducer';
import {EmployeeTaskSelectReducer} from './../app/presencemanagement/store/employeeTaskSelect.reducer';
import {connectRouter} from 'connected-react-router';
import {History} from 'history';
import {combineReducers, Reducer} from 'redux';

import {administrationReducers} from 'src/app/administration/export/store/combine';
import {LaneSelectReducer} from 'src/app/laneSelect/store';
import {LoginActionTypes} from 'src/app/login/store/login.action-types';
import {LoginReducer} from 'src/app/login/store/login.reducer';
// import {MessagesReducer} from 'src/app/messages/store/messages.reducer';
import {PaginatedTableReducer} from 'src/shared/components/store/paginatedTable.reducer';
import {lanemanagerReducers} from 'src/app/lanemanager/export/store/combine';
import terminalReducer from 'src/terminal/store/reducer';
import {notifyReducer} from 'src/utils/Notification/reducer';
import mapReducer from 'src/app/map/store/reduce';
import {DynamicMenuListReducer, MenuListReducer, ModulesListReducer} from 'src/app/menu/store/reducer';

export const reducers = (history: History): Reducer => {
    const appReducers: Reducer = (state, action) => {
        if (action.type === LoginActionTypes.logout) state = undefined;
        const combi = combineReducers({
            router: connectRouter(history) as any,
            terminal: terminalReducer,
            notify: notifyReducer as any,
            lanemanagement: lanemanagerReducers,
            administration: administrationReducers,
            login: LoginReducer,
            laneSelect: LaneSelectReducer,
            employeeTaskSelect: EmployeeTaskSelectReducer,
            parameters: ParametersReducer,
            // messages: MessagesReducer as any,
            paginatedTable: PaginatedTableReducer,
            maps: mapReducer,
            menuListState: MenuListReducer,
            dynamicMenuListState: DynamicMenuListReducer,
            modules: ModulesListReducer
        });
        return combi(state, action);
    };
    return (state, action) => appReducers(state, action);
};
