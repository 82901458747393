import * as React from 'react';
import styled from 'styled-components';

import {BackupControlServices} from 'src/app/administration/backupControl/store/backupControl.services';
import {TableComponent} from 'src/shared/components/TableComponent';
import {API_URL} from 'src/global/api';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';

export class BackupControl extends React.Component {
  private columns: ColumnType<{id: string; name: string}> = [
    {
      Header: 'Tablas a elegir',
      filterable: true,
      sortable: false,
      id: 'tables',
      accessor: a => a,
    },
  ];

  render() {
    return (
      IsInRuleset('BACKUP') && (
        <TableWrap>
          <TableComponent
            defaultPageSize={20}
            columnFormat={this.columns}
            accessor={'nameTables'}
            service={BackupControlServices.get}
            propsToOwnTable={{
              getTrProps: (_: any, rowInfo: any) => ({
                onClick: () => {
                  if (rowInfo.original)
                    window.open(API_URL() + '/backup/table/' + rowInfo.original);
                },
                style: {
                  cursor: 'pointer',
                },
              }),
            }}
          />
        </TableWrap>
      )
    );
  }
}

const TableWrap = styled.div`
  width: 30%;
  height: 100%;
  margin: auto;
`;
