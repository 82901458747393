import styled from 'styled-components';

export const WrapTable = styled.div`
  width: 100%;
  height: 100%;
`;

export const WrapActive = styled.span`
  color: ${({color}) => color};
`;
