import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {AppStoreState} from 'src/store';
import {fillStorage} from 'src/utils/localforage';
import {notifyConnect, notifyServerError} from 'src/utils/Notification';

import {LaneDumpActions} from '../actions';
import {AreaServices} from '../services';
import {currentDumpInstance} from '../storage';
import {LaneActionInterface} from '../types';
import {areaDumpSchemeResValidate} from '../validators/AreaDumpRes';

export const getCurrentDump = () => async (dispatch: (action: any) => LaneActionInterface, getState: () => AppStoreState) => {
    try {
        const lane = LaneSelectSelectors.getLane(getState());
        const req = await AreaServices.getCurrentDump(lane ? lane.laneId : 'NO_EXISTE_LINEA');
        const {data, status} = req;
        if (status < 400) {
            const validate = await areaDumpSchemeResValidate;
            const valid = await validate(data);

            if (!valid) {
                console.error('NO HE VALIDADO> ', validate.errors); // ! POR CONSOLA???
                //dispatch(LaneDumpActions.fail());
                //dispatch(notifyServerError());

                //throw new Error('NO HE VALIDADO> ');
            }

            // dispatch(LanePtableActions.set(data));
            if (data.dump === null) {
                await currentDumpInstance.clear();
            } else {
                await currentDumpInstance.clear();
                await fillStorage(currentDumpInstance, data.dump);
            }

            dispatch(LaneDumpActions.success());
        } else dispatch(notifyServerError());
    } catch (error) {
        dispatch(notifyConnect());
        console.error(error);
        throw new Error(error.message);
    }
};
