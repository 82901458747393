import React from 'react';
import styled from 'styled-components';

const NotFoundContainer = styled.div`
  * {
    margin-right: auto;
    margin-left: auto;
  }
`;

export const NotFound: React.FunctionComponent<{}> = () => {
  return <NotFoundContainer>Page do not fount</NotFoundContainer>;
};
