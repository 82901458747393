import {NotifyAction, NotifyActionType} from './action-types';

export interface NotificationStoreState {
  open: boolean;
  type: NotifyActionType;
  message?: string;
  timeout?: number;
}

const defaultNotificationState = {
  // open: false,
  // type: NotifyActionType.success,
  // message: 'Example notification',
};

export const notifyReducer = (
  state: NotificationStoreState = defaultNotificationState as any,
  action: NotifyAction,
) => {
  const {payload} = action;
  switch (action.type) {
    case NotifyActionType.success:
      return {
        ...state,
        open: true,
        message: payload!.message,
        type: NotifyActionType.success,
        timeout: payload!.timeout,
      };
    case NotifyActionType.notify:
      return {
        ...state,
        open: true,
        message: payload!.message,
        type: NotifyActionType.notify,
        timeout: payload!.timeout,
      };
    case NotifyActionType.warning:
      return {
        ...state,
        open: true,
        message: payload!.message,
        type: NotifyActionType.warning,
        timeout: payload!.timeout,
      };
    case NotifyActionType.error:
      return {
        ...state,
        open: true,
        message: payload!.message,
        type: NotifyActionType.error,
        timeout: payload!.timeout,
      };
    case NotifyActionType.close:
      return defaultNotificationState;
    default:
      return state;
  }
};
