import {PaginateParams} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/centers';

type Options = {id?: string; name?: string};
export const CenterControlServices = {
  get: (params?: PaginateParams) => guardedFetcher.get(`${prefix}`, {params}),
  update: ({id, name}: Options) => guardedFetcher.patch(`${prefix}/${id}`, {name}),
  create: ({name}: Options) => guardedFetcher.post(`${prefix}`, {name}),
  delete: ({id}: Options) => guardedFetcher.delete(`${prefix}/${id}`),
};
