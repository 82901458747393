import invoke from 'lodash/invoke';
import * as React from 'react';
import {Link} from 'react-router-dom';

import {Message} from 'src/app/messages/store/messages.action-types';
import {MessageResponseUnit} from 'src/app/messages/store/messages.types';
import {withStyles} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

type Props = {
  messages: Message[];
  classes: {[k: string]: any};
  clickHook?(): void;
};
export const MessagesPopupBase: React.FunctionComponent<Props> = ({
  messages,
  classes,
  clickHook,
}) => {
  let sliced: Message[] = [];
  if (messages) {
    if (messages.length > 3) sliced = messages.slice(0, 2);
    else sliced = messages;
  }
  const clickFn = typeof clickHook === 'function' ? clickHook : undefined;
  return (
    <Card className={classes.card}>
      <List component="nav">
        {invoke(sliced, 'map', (msg: MessageResponseUnit) => {
          return (
            <Link
              key={msg.id}
              to={`/messages/box/${msg.id}`}
              href={`/messages/box/${msg.id}`}
              style={{textDecoration: 'inherit', color: 'inherit'}}
              onClick={clickFn}>
              <ListItem key={msg.id} button={true}>
                <ListItemText
                  primary={msg.subject}
                  secondary={
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          msg.body.length > 100
                            ? msg.body.substring(0, 97) + '...'
                            : msg.body,
                      }}
                    />
                  }
                />
              </ListItem>
            </Link>
          );
        })}
        <Link
          to="/messages/box"
          href="/messages/box"
          style={{textDecoration: 'inherit', color: 'inherit'}}
          onClick={clickFn}>
          <ListItem button={true}>
            <ListItemText primary="Ver más..." />
          </ListItem>
        </Link>
      </List>
    </Card>
  );
};

export const MessagesPopup = withStyles({
  card: {
    width: 300,
    zIndex: 50,
    position: 'absolute',
    right: 140,
    top: 45,
  },
})(MessagesPopupBase);
