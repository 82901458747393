import {Reducer} from 'redux';

import {ParametersActions, ParametersActionType} from './action-types';

export type ParametersStoreState = {
  parameters: any[];
};
const initialState: ParametersStoreState = {
  parameters: [],
};
export const ParametersReducer: Reducer<ParametersStoreState, ParametersActions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case ParametersActionType.success:
      return {
        parameters: action.payload ? action.payload.parameters : [],
      };
    default:
      return state;
  }
};
