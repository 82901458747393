import * as React from 'react';
import {CustomDialog} from 'src/shared/components/CustomDialog';
import {DumpsServices} from './store/services';
import {notify} from 'src/utils/Notification';

type CancelProps = {
    open: boolean;
    idDumpToCancel: string;
    handleClose: () => void;
    handleRefresh: () => void;
};

export class CancelDumpDialog extends React.Component<CancelProps> {
    private cancelDump = (idDumpToCancel: string) => {
        DumpsServices.cancelDump(idDumpToCancel).then(res => {
            if (res.status === 404 && res.data.message === 'INVALID_STATE_DUMP') {
                notify({message: 'El volcado no está en un estado válido para cancelarlo.', status: 'error'});
            } else if (res.status === 404) {
                notify({message: 'Ha ocurrido un error.', status: 'error'});
            }
            this.props.handleRefresh();
        });
    };

    public render() {
        const contentText = '¿Está seguro que quiere cancelar el volcado?';

        return (
            <>
                <CustomDialog
                    title="Confirmación"
                    contentText={contentText}
                    handleClose={() => this.props.handleClose()}
                    open={this.props.open && this.props.idDumpToCancel !== ''}
                    handle={() => {
                        this.cancelDump(this.props.idDumpToCancel);
                        this.props.handleClose();
                    }}
                />
            </>
        );
    }
}
