import {
  LoginActionsLogIn,
  LoginActionsLogOut,
  LoginActionTypes,
  LogInPayload,
} from 'src/app/login/store/login.action-types';

export const LoginActions = {
  login: (payload: LogInPayload): LoginActionsLogIn => ({
    type: LoginActionTypes.login,
    payload,
  }),
  logout: (): LoginActionsLogOut => ({type: LoginActionTypes.logout}),
};
