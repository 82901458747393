import {guardedFetcher} from 'src/utils/guardedFetcher';
import {LaneKPI, LaneChartDataset} from './types';

export const fetchChart = (laneId: string, workshift: string, daysBefore: string) => {
    return guardedFetcher.get<LaneChartDataset>(`kpis/lane/chart/${laneId}/workshift/${workshift}/${daysBefore}`);
};

export const fetchKPIs = (laneId: string, dateSearch: string, daysBefore: string) => {
    return guardedFetcher.get<LaneKPI>(`kpis/lane/${laneId}/workshift/${dateSearch}/${daysBefore}`);
};

export const fechtDaysBefore = () => {
    return guardedFetcher.get<number>(`kpis/lane/days-before`);
};
