import React, {ChangeEvent} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {connect} from 'react-redux';
import {TableWrap} from 'src/terminal/components';
import Typography from '@material-ui/core/Typography';
import {createStyles, Theme, withStyles} from '@material-ui/core/styles';
import EmployeesTasksTable from 'src/terminal/containers/EmployeesTasksTable';
import EmployeesPerformanceTable from 'src/terminal/containers/EmployeesPerformanceTable';

interface EmployeeStatsState {
  tab: number;
}

interface EmployeeStatsProps {
  classes: {
    root: string;
  };
}

const TabContainer = (props: any) => {
  return (
    <Typography component="div" style={{padding: 8 * 3}}>
      {props.children}
    </Typography>
  );
};
const styles = ({palette}: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: palette.background.paper,
    },
  });

class EmployeeStats extends React.Component<EmployeeStatsProps, EmployeeStatsState> {
  state = {
    tab: 0,
  };
  handleChange = (event: ChangeEvent<{}>, tab: number) => {
    // tslint:disable-next-line:no-unused-expression
    console.log(event);
    this.setState({tab});
  };
  render() {
    const {classes} = this.props;
    const {tab} = this.state;
    return (
      <TableWrap>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs value={tab} onChange={this.handleChange}>
              <Tab label="Estadísticas de Tareas" />
              <Tab label="Estadísticas de Trabajadores" />
            </Tabs>
          </AppBar>
          {tab === 0 && (
            <TabContainer>
              <EmployeesTasksTable />
            </TabContainer>
          )}
          {tab === 1 && (
            <TabContainer>
              <EmployeesPerformanceTable />
            </TabContainer>
          )}
        </div>
      </TableWrap>
    );
  }
}

const EmployeeStatsStyled = withStyles(styles)(EmployeeStats);

// const mapStateToProps = (state: AppStoreState) => {};
// const mapDispatchToProps = (dispatch: any) => {};

export default connect()(EmployeeStatsStyled);
