import {guardedFetcher} from 'src/utils/guardedFetcher';

export const fetchMaps = () => {
    return guardedFetcher.get(`blueprints`);
};
export const fetchMap = (mapId: string) => {
    return guardedFetcher.get('blueprints/' + mapId);
};
export const fetchAlertInactive = (centerId: string) => {
    return guardedFetcher.get(`alert/center/${centerId}/isAdmin/1/excessive-inactive-task-center`);
};
export const fetchKPIs = () => {
    return guardedFetcher.get('blueprints/kpis');
}
