import React, {MouseEvent} from 'react';

import Button, {ButtonProps} from '@material-ui/core/Button';

type Props = {
    className?:any;
    classes?: any;
    children?: any;
    height?: string;
    margin?: string;
    minHeight?: string;
    width?: string;
    minWidth?: string;
    bgcolor?: string;
    variantColor?: 'primary' | 'secondary' | 'inherit' | 'default';
    grow?: number;
    variant?: ButtonProps['variant'];
    onClick(e: MouseEvent<any>): void;
    color?: string;
    disabled?: boolean;
    padding?: string;
};
export const CustomButton = (props: Props) => (
  <Button
    classes={props.classes}
    className={props.className}
    color={props.variantColor || 'secondary'}
    variant={props.variant || 'contained'}
    style={{
      padding: props.padding,
      height: props.height || 52,
      minHeight: props.height,
      minWidth: props.minWidth || 64,
      width: props.width || 250,
      margin: props.margin || '0 auto',
      flexGrow: props.grow,
      backgroundColor: props.bgcolor,
      color: props.color,
    }}
    disabled={props.disabled}
    onClick={props.onClick}>
    {props.children}
  </Button>
);
