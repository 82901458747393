import {PaginatedServiceArgs} from 'src/types';
import {CalculateCostExecutionControlData} from 'src/app/administration/calculateCostExecutionsControl/store/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'calculate-cost-executions';

export const CalculateCostExecutionsControlServices = {
    get: ({params}: PaginatedServiceArgs) => guardedFetcher.get<PaginateResponse<CalculateCostExecutionControlData>>(`${prefix}`, {params}),
    recalculate: (id: string) => guardedFetcher.patch(`${prefix}/${id}`),
    create: (workshift: string) => guardedFetcher.post(`${prefix}/${workshift}`),
};
