// import {ChartData} from 'chart.js';
import 'chartjs-plugin-datalabels';
import React from 'react';
// import {Polar} from 'react-chartjs-2';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// const chart: ChartData | null = {
//   labels: ['Jefe de Línea', 'Cuentas', 'Remontado', 'Envasado', 'Control de Calidad'],
//   datasets: [
//     {
//       label: 'Personas',
//       data: [1, 3, 7, 15, 4],
//       backgroundColor: ['red', 'blue', 'green', 'magenta'],

//       fill: true,
//     },
//   ],
// };
// const initialState = {chart};

type State = {
  data: Array<{
    label: string;
    data: string;
  }>;
};

type Props = {
  data?: {labels: string[]; data: string[]};
};
export class PersonnelTable extends React.Component<Props, State> {
  readonly state: State = {data: []};
  static getDerivedStateFromProps(props: Props) {
    if (props.data) {
      return {
        data: props.data.labels.map((lbl, index) => ({
          label: lbl,
          data: props.data!.data[index],
        })),
      };
    } else return null;
  }

  render() {
    const {data} = this.state;
    return (
      <div
        style={{
          marginTop: 25,
          marginLeft: 'auto',
          marginRight: 'auto',
          justifyContent: 'center',
          display: 'flex',
        }}>
        <div style={{height: 820, width: 900}}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{fontSize: 40, width: '50%'}} align="right">
                  TAREA
                </TableCell>
                <TableCell style={{fontSize: 40}} align="left">
                  TRABAJADORES
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length
                ? data.map((row, idx) => (
                    <TableRow key={idx} style={{height: 70}}>
                      <TableCell
                        style={{fontSize: 35}}
                        align="right"
                        component="th"
                        scope="row">
                        {row.label ? row.label.toUpperCase() : ''}
                      </TableCell>
                      <TableCell style={{fontSize: 35}} align="left">
                        {row.data ? String(row.data).toUpperCase() : ''}
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}
