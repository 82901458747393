import {LoggingUnit} from 'src/app/logging/store/logging.types';
import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'error-logs';

export const LoggingServices = {
  getErrors: ({params}: PaginatedServiceArgs) =>
    guardedFetcher.get<PaginateResponse<LoggingUnit>>(prefix, {params}),
  getIncidences: ({params}: PaginatedServiceArgs) =>
    guardedFetcher.get<PaginateResponse<LoggingUnit>>(`${prefix}/incidences`, {params}),
};
