import {PaginateParams} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/areasets';

type Options = {id?: string; name?: string};
export const AreasetControlServices = {
    get: (params?: PaginateParams) => guardedFetcher.get(`${prefix}`, {params}),
    update: ({id, name}: Options) => guardedFetcher.patch(`${prefix}/${id}`, {name}),
    create: ({name}: Options) => guardedFetcher.post(`${prefix}`, {name}),
};
