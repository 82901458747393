import {LaneSelectStoreState} from 'src/app/laneSelect/store/laneSelect.reducer';
import {Action, ActionRich} from 'src/types';

export enum LaneSelectActionTypes {
  select = '[SelectLane] Selecting lane',
  clear = '[SelectLane] Clear selected lane',
}

export type LaneSelectActions = LaneSelectActionSelect | LaneSelectActionClear;

export type LaneSelectActionSelect = ActionRich<
  LaneSelectActionTypes.select,
  LaneSelectStoreState
>;

export type LaneSelectActionClear = Action<LaneSelectActionTypes.clear>;
