import {AppStoreState} from 'src/store';

export const LoginSelectors = {
  getUserData: (state: AppStoreState) => ({
    userId: state.login.userId,
    employeeId: state.login.employeeId,
    username: state.login.username,
    name: state.login.name,
    surname: state.login.surname,
  }),
  // getUserRules: (state: AppStoreState) => ({
  //   rules: state.login.rules,
  //   rulegroups: state.login.rulegroups,
  // }),
};
