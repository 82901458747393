import * as React from 'react';

import {withStyles} from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import {WrapHeader} from './Wrap';

const styles = {
    container: {
        witdh: '33%'
    },
    card: {
        // width: '33%',
        // minHeight: '300px',
        marginLeft: 5,
        marginRight: 5,
        padding: 0,
    },
    button: {
        marginLeft: 'auto',
        marginRight: 10,
        marginTop: 5,
        height: 40,
        width: 180,
    },
    content: {
        height: '100%',
        display: 'flex',
    },
    header: {
        width: '75%',
    },
};

interface CardProps {
    title?: string | React.ReactNode;
    linkToHandler?: () => void;
    buttonText?: string;
    headerComponent?: React.ReactNode;
    width?: string;
    height?: string;
    contentHeight?: string;
    contentPadding?: string | number;
    overflowX?: any;
    marginBottom?: string;
    margin?: string;
    heightTitle?: string;
    titleFontSize?: number;
}

interface CSSProps {
    classes: {
        card: any;
        button: any;
        content: any;
        header: any;
        container: any;
    };
}

const OCardBase: React.FunctionComponent<CardProps & CSSProps> = props => {
    const {classes} = props;

    const cardStyles = {
        // width: '100%',
        height: props.height,
        margin: props.margin,
        marginBottom: props.marginBottom,
    };

    return (
        <div className={classes.container} style={{width: props.width}}>
            <Card className={classes.card} style={cardStyles}>
                {props.title && (
                    <WrapHeader height={props.heightTitle || '50'}>
                        <CardHeader
                            titleTypographyProps={{style: {fontSize: props.titleFontSize || 15}}}
                            className={props.classes.header}
                            title={props.title}
                            style={{fontSize: 10, maxHeight: props.heightTitle}}
                        />
                        {props.headerComponent}
                    </WrapHeader>
                )}
                <CardContent
                    style={{
                        padding: props.contentPadding,
                        height: props.contentHeight || 315,
                        overflowX: props.overflowX,
                    }}
                >
                    {props.children}
                </CardContent>
            </Card>
        </div>

    );
};

export const OCard = withStyles(styles)(OCardBase);
