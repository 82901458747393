import {DynamicMenuListState, MenuListState, ModulesListType} from './types'

export const MENU_LIST_ACTION = 'MENU_LIST_ACTION';
export const DYNAMIC_MENU_LIST_ACTION = 'DYNAMIC_MENU_LIST_ACTION';
export const MODULE_LIST_ACTION = 'MODULE_LIST_ACTION';

export const MenuListAction = (state : MenuListState) => {
    return {
        type: MENU_LIST_ACTION,
        menuListState: state
    }
};

export const DynamicMenuListAction = (state : DynamicMenuListState) => {
    return {
        type: DYNAMIC_MENU_LIST_ACTION,
        dynamicMenuListState: state
    }
};

export const ModulesListAction = (state : ModulesListType) => {
    return {
        type: MODULE_LIST_ACTION,
        modules: state ?? null
    }
};


