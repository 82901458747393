import ajv from 'ajv';
import {AxiosPromise, AxiosRequestConfig} from 'axios';
/**
 * Method for fetching information for NOT paginated services.
 *
 * @template T
 * @param {AxiosPromise<T>} fetchInstance
 * @param {{accessor?: keyof T; dispatch?: Dispatch; returnError?: boolean}} [options]
 * @returns
 */
import isEqual from 'react-fast-compare';
import {AnyAction} from 'redux';

import {LoginThunks} from 'src/app/login/store/login.thunks';
import {SharedServices} from 'src/shared/shared.services';
import {dispatcher} from 'src/store/store';
import {fillStorage} from 'src/utils/localforage';
import {
    notify,
    notifyConnect,
    notifyServerError,
    // notifyValidationError,
    notifyCloseWrapper,
} from 'src/utils/Notification/actions';
import {RULESET} from 'src/global/authorization/grantSet';

export const fetchAndValidate = async <T>(
    fetchInstance: AxiosPromise<T>,
    accessor: keyof T,
    validator: ajv.ValidateFunction,
    storageInstance?: LocalForage,
    actions?: ActionsMap,
) => {
    let data: T,
        status: number,
        config: AxiosRequestConfig = {};
    try {
        const res = await fetchInstance;
        data = res.data;
        status = res.status;
        config = res.config;
        if (status === 200) {
            const valid = await validator(data);

            if (!valid) {
                console.error('no he validado>> ', validator.errors, ' DATA>> ', data);
                //dispatcher(notifyValidationError());
                //actions && actions.toFail && executeActions(actions.toFail);
            }

            storageInstance && (await fillStorage(storageInstance, data[accessor]));
            actions && actions.toSuccess && executeActions(actions.toSuccess);
        } else {
            dispatcher(notifyServerError());
            actions && actions.toFail && executeActions(actions.toFail);
        }
        return data ? data[accessor] : undefined;
    } catch (error) {
        if (error !== 'NO_ACCESS') {
            dispatcher(notifyConnect());
            SharedServices.sendError({
                category: 'Frontend',
                description: 'Error en el fetch',
                incidence: false,
                exception: error,
                endpoint: config
                    ? `${config.method ? config.method.toLocaleUpperCase() : 'NO_METHOD'} - ${
                        config.url
                    }`
                    : 'NO_HAY_ENDP',
                body_request: `${config.data}`,
            });
        }
        console.error('error fetch> ', error);
        actions && actions.toFail && executeActions(actions.toFail);
        return undefined;
    }
};

const executeActions = (actionsArr: AnyAction[]) => {
    for (const action of actionsArr) dispatcher(action);
};

export type ActionsMap = {
    toSuccess?: AnyAction[];
    toFail?: AnyAction[];
};

/**
 * Method for fetching through axios, returns internal type of the fetching instance.
 *
 * @template T
 * @param {AxiosPromise<T>} fetchInstance
 * @param {{accessor?: keyof T; dispatch?: Dispatch; returnError?: boolean}} [options]
 * @returns
 */
export const fetchWithFeedback = async <T, K extends keyof T>(
    fetchInstance: AxiosPromise<T>,
    options?: {
        accessor?: K;
        errorAccessor?: K;
        showMessage?: boolean;
        errorMessage?: string;
        successMessage?: string;
        returnConfirmation?: boolean;
        returnUrl?: boolean;
        errorMessageMap?: Map<string, string>;
        notifyValidator?: (errors: LaravelExplicitError[]) => void;
    },
): Promise<
    | (K extends undefined ? T : T[K])
    | {data: K extends undefined ? T : T[K]; url?: string}
    | null
    | true
    | any
    > => {
    if (!options) options = {};
    const {
        accessor,
        errorAccessor,
        showMessage = false,
        errorMessage = false,
        successMessage = false,
        errorMessageMap = false,
        returnConfirmation = false,
        returnUrl = false,
        notifyValidator,
    } = options;
    let data: T,
        status: number,
        config: AxiosRequestConfig = {},
        backError: LaravelError | undefined;
    try {
        // console.log('FETCHING WITH FW>>>');
        if (showMessage || successMessage) dispatcher(notifyCloseWrapper());
        const res = await fetchInstance;
        data = res.data;
        status = res.status;
        config = res.config;
        if (status === 401) dispatcher(LoginThunks.logout());
        // console.log('status>>>', status, data);
        if (status >= 200 && status < 400) {
            (showMessage || successMessage) &&
            notify({
                status: 'success',
                message: successMessage || 'La operación se ha realizado con éxito',
            });
            if (returnConfirmation) return true;
            const accessedData = typeof accessor === 'undefined' ? data : data[accessor];
            if (returnUrl) {
                return {
                    data: accessedData,
                    url: config.url,
                };
            } else return accessedData;
        } else {
            const isValidationError = isLaravelValidationError(data);
            if (isValidationError && notifyValidator) {
                notifyValidator(data as any);
                return null;
            }
            const cast = (data as unknown) as Array<{message: string; errors: string}>;

            let resData = typeof errorAccessor === 'undefined' ? data : data[errorAccessor];
            const resMsg = resData instanceof Array ? resData[0] : resData;
            let errMsg = errorMessageMap && errorMessageMap.get(resMsg) ? errorMessageMap.get(resMsg) : errorMessage;

            (showMessage || errMsg) &&
            dispatcher(
                notifyServerError(
                    errMsg
                        ? errMsg
                        : Array.isArray(cast)
                        ? cast[0].message
                        : undefined,
                ),
            );

            if (
                isEqual(
                    Object.keys(data).sort(),
                    ['exception', 'line', 'message', 'file', 'trace'].sort(),
                ) &&
                status === 500
            ) {
                dispatcher(notifyServerError());
                backError = (data as unknown) as LaravelError;
            } else {
                !(showMessage || errorMessage) &&
                dispatcher(
                    notifyServerError(Array.isArray(cast) ? cast[0].message : undefined),
                );
                return null;
            }
            return null;
        }
    } catch (error) {
        if (error !== 'NO_ACCESS') dispatcher(notifyConnect());
        return null;
    } finally {
        if (typeof backError !== 'undefined' && RULESET && RULESET.grants.length > 0)
            SharedServices.sendError({
                category: 'Frontend',
                description: 'Error en el fetch',
                incidence: false,
                exception: `${backError.message}`,
                endpoint: config
                    ? `${config.method ? config.method.toLocaleUpperCase() : 'NO_METHOD'} - ${
                        config.url
                    }`
                    : 'NO_HAY_ENDP',
                body_request: `${config.data}`,
            });
    }
};

export const fetchPaginateHelper = async <T extends PaginateResponse>(
    fetchInstance: AxiosPromise<T>,
    options?: {
        returnError?: boolean;
        returnUrl?: boolean;
    },
): Promise<{data: T; url?: string} | null> => {
    // console.log('FETCHING WITH FW>>>');

    if (!options) options = {};
    const {returnError = false, returnUrl = false} = options;
    let data: T,
        status: number,
        config: AxiosRequestConfig = {},
        backError: LaravelError | undefined;
    try {
        const res = await fetchInstance;
        data = res.data;
        status = res.status;
        config = res.config;
        // console.log('FETCHING WITH FETCHER HELPER>>>');
        if (status === 401) dispatcher(LoginThunks.logout());
        if (status === 200) {
            return returnUrl ? {data, url: config.url} : {data};
        } else {
            if (
                isEqual(
                    Object.keys(data).sort(),
                    ['exception', 'line', 'message', 'file', 'trace'].sort(),
                ) &&
                status === 500
            ) {
                dispatcher(notifyServerError());
                backError = (data as unknown) as LaravelError;
            } else {
                const cast = data as any;
                dispatcher(notifyServerError(Array.isArray(cast) ? cast[0].message : undefined));
                return returnError ? cast : null;
            }
            return null;
        }
    } catch (error) {
        if (error !== 'NO_ACCESS') dispatcher(notifyConnect());
        console.error('error fetch> ', error);
        return null;
    } finally {
        if (typeof backError !== 'undefined' && RULESET && RULESET.grants.length > 0)
            SharedServices.sendError({
                category: 'Frontend',
                description: 'Error en el fetch',
                incidence: false,
                exception: `${backError.message}`,
                endpoint: config
                    ? `${config.method ? config.method.toLocaleUpperCase() : 'NO_METHOD'} - ${
                        config.url
                    }`
                    : 'NO_HAY_ENDP',
                body_request: `${config.data}`,
            });
    }
};

/**
 * Method for fetching information services.
 *
 * @template T
 * @param {AxiosPromise<T>} fetchInstance
 * @param {{accessor?: keyof T; dispatch?: Dispatch; returnError?: boolean}} [options]
 * @returns
 */
export const fetchHelperNotPaginated = async <
  T extends {[k: string]: any},
  K extends keyof T
>(
  fetchInstance: Promise<T>,
  //fetchInstance: AxiosPromise<T>,
  options?: {
    accessor?: K;
    returnError?: boolean;
    returnUrl?: boolean;
    returnData?: boolean;
  },
): Promise<{data: T | T[K]; url?: string; allData?: T} | null> => {
    if (!options) options = {};
    const {accessor, returnError = false, returnUrl = false, returnData = false} = options;
    let data: T,
        status: number,
        config: AxiosRequestConfig = {},
        backError: LaravelError | undefined;
    try {
        const res = await fetchInstance;
        data = res.data;
        status = res.status;
        config = res.config;
        if (status === 200) {
            return {
                data: typeof accessor !== 'undefined' ? data[accessor] : data,
                url: returnUrl ? config.url : undefined,
                allData: returnData ? data : undefined,
            };
        } else {
            if (
                isEqual(
                    Object.keys(data).sort(),
                    ['exception', 'line', 'message', 'file', 'trace'].sort(),
                ) &&
                status === 500
            ) {
                dispatcher(notifyServerError());
                backError = (data as unknown) as LaravelError;
            } else {
                const cast = data as any;
                dispatcher(notifyServerError(Array.isArray(cast) ? cast[0].message : undefined));
                return returnError ? cast : null;
            }
            return null;
        }
    } catch (error) {
        if (error !== 'NO_ACCESS') dispatcher(notifyConnect());
        console.error('error fetch> ', error);
        return null;
    } finally {
        if (typeof backError !== 'undefined' && RULESET && RULESET.grants.length > 0)
            SharedServices.sendError({
                category: 'Frontend',
                description: 'Error en el fetch',
                incidence: false,
                exception: `${backError.message}`,
                endpoint: config
                    ? `${config.method ? config.method.toLocaleUpperCase() : 'NO_METHOD'} - ${
                        config.url
                    }`
                    : 'NO_HAY_ENDP',
                body_request: `${config.data}`,
            });
    }
};

const isLaravelValidationError = (data: any): boolean => {
    if (!data.length || !Array.isArray(data)) return false;
    const extracted = data[0];
    if (!extracted.message) return false;
    if (typeof extracted.errors !== 'object') return false;
    for (const [, value] of Object.entries<string[]>(extracted.errors)) {
        if (!value.length || !Array.isArray(value)) return false;
    }
    return true;
};
