export enum DowntimeActionType {
  set = '[Lane] [Downtime] Set downtimes list',
  add = '[Lane] [Downtime] Set single downtime',
  updateDowntime = '[Lane] [Downtime] Update downtime',
  clearDowntimes = '[Lane] [Downtime] Clear downtimes',
  clearCauses = '[Lane] [Downtime] Clear causes',
  clearCategories = '[Lane] [Downtime] Clear categories',
  setCauses = '[Lane] [Downtime] Set downtime causes',
  setCategories = '[Lane] [Downtime] Set downtime categories',
  setFilterActive = '[Lane] [Downtime] Set table filter to active',
  setFilterInactive = '[Lane] [Downtime] Set table filter to inactive',
  clearFilter = '[Lane] [Downtime] Clear table filter',
}
