import {ActionRich} from 'src/types';

export enum PaginatedTableActionTypes {
  addPaginationData = '[PaginatedTable] Add paginate data',
  clearPaginationData = '[PaginatedTable] Clear pagination data',
}

export type PaginatedTableActionAdd = ActionRich<
  typeof PaginatedTableActionTypes.addPaginationData,
  {id: string; payload: any}
>;

export type PaginatedTableActionClear = ActionRich<
  typeof PaginatedTableActionTypes.clearPaginationData,
  {id: string}
>;
export type PaginatedTableActionsList =
  | PaginatedTableActionAdd
  | PaginatedTableActionClear;
