import localForage from 'localforage';

export const batchesInstance = localForage.createInstance({
  name: 'batches-store',
  version: 1.0,
  storeName: 'batches-store',
});

export const ptablesInstance = localForage.createInstance({
  name: 'ptables-store',
  version: 1.0,
  storeName: 'ptables-store',
});

export const employeesInstance = localForage.createInstance({
  name: 'employees-store',
  version: 1.0,
  storeName: 'employees-store',
});

export const currentDumpInstance = localForage.createInstance({
  name: 'current-dump-store',
  version: 1.0,
  storeName: 'current-dump-store',
});
