import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import * as React from 'react';
import styled from 'styled-components';

import {LoggingServices} from 'src/app/logging/store/logging.services';
import {LoggingUnit} from 'src/app/logging/store/logging.types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {ColumnType} from 'src/types';
import {IsInRuleset} from 'src/global/authorization/grantSet';

export const LoggingIncidences: React.FunctionComponent = () => {
    const columns: ColumnType<LoggingUnit> = [
        {
            Header: 'Categoría',
            headerClassName: 'header_cell_string',
            filterable: true,
            sortable: false,
            accessor: 'category',
            className: 'cell_string',
            width: 200,
        },
        {
            Header: 'Descripción',
            headerClassName: 'header_cell_string',
            filterable: true,
            sortable: false,
            accessor: 'description',
            className: 'cell_string',
        },
        {
            Header: 'Excepción',
            headerClassName: 'header_cell_string',
            filterable: true,
            sortable: false,
            accessor: 'exception',
            className: 'cell_string',
        },
        {
            Header: 'Fecha',
            headerClassName: 'header_cell_string',
            filterable: true,
            sortable: false,
            accessor: 'created_at',
            Cell: item => (
                <>
                    {item.original.created_at &&
                        format(new Date(item.original.created_at), 'DD/MM/YYYY HH:mm:ss', {
                            locale: es,
                        })}
                </>
            ),
            className: 'cell_string',
            width: 145,
        },
    ];

    return (
        <>
            {IsInRuleset() && (
                <TableWrap>
                    <PaginatedTableComponent defaultPageSize={20} columnFormat={columns} service={LoggingServices.getIncidences} />
                </TableWrap>
            )}
        </>
    );
};

const TableWrap = styled.div`
    width: 90%;
    height: 100%;
    margin: auto;
`;
