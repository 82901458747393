import {
    ExchangeBindModulesType,
    ExchangeFunctionalityType, FunctionalitiesTreeType, FunctionalityCategoryType, ModulesTreeType
} from './functionalityControl.types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'system/functionalities';

export const FunctionalityControlServices = {

    get: () => guardedFetcher.get<{
        data : {
            categories: FunctionalityCategoryType,
            functionalities: FunctionalitiesTreeType,
            allModules: ModulesTreeType
        }
    }>(prefix),

    save: (enabledFunctionalities: {updatedFunctionalities: ExchangeFunctionalityType[]}) =>
        guardedFetcher.patch(prefix, enabledFunctionalities),

    // bindModules: (bindModules: {functionality: {id : string, modules : {[id : string] : string[]}}}) => {
    //     console.log(bindModules);
    //     return guardedFetcher.patch(prefix + '/bindModules', bindModules);
    // }

    bindModules: (bindModules: {modules: {
        functionalityId: string,
            functionalityStatus: number,
            isUpdated: boolean,
            category: string,
            toAdd : ExchangeBindModulesType[],
            toDelete : ExchangeBindModulesType[]
        }}) => guardedFetcher.patch(prefix + '/bindModules', bindModules)

};
