import {ajv} from 'src/utils/ajv';
import {areaScheme} from './schemes/area';
import {employeeScheme} from './schemes/employee';

const areaEmployeesResScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['area', 'employees'],
  properties: {
    area: areaScheme,
    employees: {type: 'array', items: employeeScheme},
  },
};

const precompile = ajv.compile(areaEmployeesResScheme);
export const areaEmployeesResSchemeValidate = precompile;
