import {SystemParameter} from './../global/systemParameters/systemParameters';
import {Grantset} from 'src/global/authorization/grantSet';
import {fetcher} from 'src/global/fetcher';
import {InitialParameter} from 'src/global/initalParameters/initialParameters';

type ErrorLogPrimary = {
    category: string;
    description: string;
};

type ErrorLogSecondary = {
    incidence: boolean;
    exception: string;
    endpoint: string;
    body_request: string;
    body_response: string;
};

type ErrorLog = ErrorLogPrimary & Partial<ErrorLogSecondary>;

export const SharedServices = {
    getGrants: () => fetcher.get<{grants: Array<{url: string; method: string}>; grantsets: Grantset[]}>('auth/mygrants'),
    sendError: (errorLog: ErrorLog) => fetcher.post('error-logs', errorLog),
    getInitialParameters: () => fetcher.get<{initialParameters: Array<InitialParameter>}>('initial_parameters'),
    getSystemParameters: () => fetcher.get<{initialParameters: Array<SystemParameter>}>('system_parameters'),
};
