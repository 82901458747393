import {ChartData, ChartOptions} from 'chart.js';
import React from 'react';
import {Bar} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {LaneKgDumpedWeekType} from 'src/app/lanemanager/charts/store/types';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {Spinner} from 'src/app/global/spinner';

const chart: ChartData = {
    labels: [],
    datasets: [],
};
const initialState = {chart};

type State = {
    chart: ChartData;
};
type Props = {
    chartData: LaneKgDumpedWeekType;
    isLoading: boolean;
};
export class KgDumpedWeekChart extends React.Component<Props, State> {
    readonly state = initialState;
    static getDerivedStateFromProps(props: Props) {
        const datasets = [
            /*{
        yAxisID: 'kg',
        label: 'KG/H por persona',
        borderColor: '#f44e42',
        backgroundColor: '#f44e42',
        data: props.chartData.kgsDumpedPerson,
        fill: false,
        // Changes this dataset to become a line
        type: 'line',
      },
      {
        label: 'KG/H',
        borderColor: '#82a1bf',
        backgroundColor: '#82a1bf',
        data: props.chartData.kgsDumped,
        fill: false,
      },*/
            {
                label: 'Turno mañana',
                borderColor: '#4f81bd',
                backgroundColor: '#4f81bd',
                data: props.chartData.kgsDumpedDays1,
                fill: false,
            },
            {
                label: 'Turno tarde',
                borderColor: '#eb4034',
                backgroundColor: '#eb4034',
                data: props.chartData.kgsDumpedDays2,
                fill: false,
            },
        ] as any;

        return {
            chart: {
                labels: props.chartData.days,
                datasets,
            },
        };
    }
    render() {
        if (this.props.isLoading) {
            return <Spinner isLoading={this.props.isLoading} position={'relative'} />;
        }
        return <Bar data={this.state.chart} options={options} />;
    }
}

/***
 * CHART OPTIONS
 */
const options: ChartOptions = {
    responsive: true,
    title: {
        display: false,
        text: 'Personal trabajando en personas / hora',
    },
    tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
            label: (tooltipItem: any) => {
                return tooltipItem && tooltipItem.yLabel ? customFormatNumber(Number(tooltipItem.yLabel)) : '';
            },
        },
    },
    hover: {
        mode: 'nearest',
        intersect: true,
    },
    maintainAspectRatio: false,
    scales: {
        yAxes: [
            {
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'KG',
                },
                ticks: {
                    beginAtZero: true,
                    callback: value => {
                        // you can add your own method here (just an example)
                        return customFormatNumber(Number(value));
                    },
                    //min: 0,
                    //max: 100
                },
            },
            /*{
        display: true,
        id: 'kg',
        position: 'right',
        scaleLabel: {
          display: true,
          labelString: 'KG/H por persona',
        },
        ticks: {
          beginAtZero: true,
          //min: 0,
          //max: 100
        },
        // ticks: {
        //   reverse: true,
        // },
      },*/
        ],
    },
    plugins: {
        datalabels: {
            font: {
                size: 0, // Para que no muestre los valores en la gráfica
            },
        },
    },
};
