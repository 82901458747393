import * as React from 'react';

import {withStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

const textFieldStyles = {
  root: {
    width: '100%',
  },
};
export type CustomTextFieldType = {
  children?: any;
  classes: any;
  value: string | undefined;
  handleText: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>,
  ) => void;
  label?: string;
  name?: string;
};

export const CustomTextField = withStyles(textFieldStyles)(
  (props: CustomTextFieldType) => (
    <>
      <InputLabel style={{marginBottom: 0}}>{props.label}</InputLabel>
      <TextField
        style={{marginTop: 0}}
        className={props.classes.root}
        margin="normal"
        variant="outlined"
        multiline={true}
        rowsMax="6"
        value={props.value}
        name={props.name ? props.name : 'description'}
        onChange={props.handleText}
      />
    </>
  ),
);
