import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import * as React from 'react';
import styled from 'styled-components';

import {LoggingServices} from 'src/app/logging/store/logging.services';
import {LoggingUnit} from 'src/app/logging/store/logging.types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {ColumnType} from 'src/types';
import {IsInRuleset} from 'src/global/authorization/grantSet';

export const LoggingErrors: React.FunctionComponent = () => {
    const columns: ColumnType<LoggingUnit> = [
        {
            Header: 'Categoría',
            filterable: true,
            sortable: false,
            accessor: 'category',
            centerCell: true,
            width: 100,
        },
        {
            Header: 'Descripción',
            filterable: true,
            sortable: false,
            accessor: 'description',
            centerCell: true,
            width: 250,
        },
        {
            Header: 'Endpoint',
            filterable: true,
            sortable: false,
            accessor: 'endpoint',
            // centerCell: true,
            width: 250,
            style: {
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                alignSelf: 'center',
                textAlign: 'center',
            },
        },
        {
            Header: 'Excepción',
            filterable: true,
            sortable: false,
            accessor: 'exception',
            style: {
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                alignSelf: 'center',
                textAlign: 'center',
            },
            // width: 400,
        },
        {
            Header: 'Request_body',
            filterable: true,
            sortable: false,
            accessor: 'body_request',
            Cell: item => {
                let json;
                try {
                    json = JSON.parse(item.original.body_request);
                    //tslint:disable-next-line
                } catch (error) {}
                return <>{json ? JSON.stringify(json, null, 2) : item.original.body_request}</>;
            },
            centerCell: true,
            width: 250,
        },
        {
            Header: 'Fecha',
            filterable: true,
            sortable: false,
            accessor: 'created_at',
            Cell: item => (
                <>
                    {item.original.created_at &&
                        format(new Date(item.original.created_at), 'DD/MM/YYYY HH:mm:ss', {
                            locale: es,
                        })}
                </>
            ),
            centerCell: true,
            width: 145,
        },
    ];

    return (
        <>
            {IsInRuleset('SYSTEM') && (
                <TableWrap>
                    <PaginatedTableComponent defaultPageSize={20} columnFormat={columns} service={LoggingServices.getErrors} />
                </TableWrap>
            )}
        </>
    );
};

const TableWrap = styled.div`
    width: 100%;
    height: 100%;
    margin: auto;
`;
