import {guardedFetcher} from 'src/utils/guardedFetcher';
import {CenterTreeType, ExchangeCentersType} from "./usersByCenter.types";

const prefix = 'alert/config';

export const UsersByCenterServices = {
    get: (alertType : string) => guardedFetcher.get<{centers: CenterTreeType, users: string[]}>(prefix + '/getAlertMails/' + alertType),

    save: (modules: {exchangeCenters: ExchangeCentersType}) =>
        guardedFetcher.post(prefix + '/setAlertMails', modules),
};
