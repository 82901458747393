import {CausesControlResponse} from 'src/app/administration/causesControl/store/causesControl.types';
// import {fetcher} from 'src/global';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/downtime-causes';

type Options = {
    name: string;
    downtime_task_id?: string;
    downtime_category_id: string;
};
export const CausesControlServices = {
    getAll: () => guardedFetcher.get<CausesControlResponse>(`${prefix}/causes`),
    getAllInTable: () => guardedFetcher.get<CausesControlResponse>(`${prefix}`),

    getPreflight: () => guardedFetcher.get<CausesControlResponse>(`${prefix}/preflight`),

    create: (params: Options) => guardedFetcher.post(prefix, params),

    update: (id: string, params: Partial<Options>) => guardedFetcher.patch(`${prefix}/${id}`, params),

    delete: (id: string) => guardedFetcher.delete(`${prefix}/${id}`),

    toggleTask: (params: {downtimeCauseId: string; taskId: string}) => guardedFetcher.post(`${prefix}/toggle-relation/task`, params),

    toggleArea: (params: {downtimeCauseId: string; areaId: string}) => guardedFetcher.post(`${prefix}/toggle-relation/area`, params),

    getAllCausesFromArea: (areaId: string) => guardedFetcher.get<CausesControlResponse>(`${prefix}/area/${areaId}`),

    bindAllCausesWithArea: (params: {causesIds: Array<string>; areaId: string}) => guardedFetcher.post<CausesControlResponse>(`${prefix}/bindArea`, params),

    unbindAllCausesWithArea: (params: {areaId: string}) => guardedFetcher.post<CausesControlResponse>(`${prefix}/unbindArea`, params),
};
