import {ChartData, ChartOptions} from 'chart.js';
import React from 'react';
import {HourPieTaskType} from 'src/app/information/kpis/store/types/chart-types';

import {Pie} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {Spinner} from 'src/app/global/spinner';
//const initialState = {chart};

const chart: ChartData = {
    labels: [],
    datasets: [],
};

/***
 * CHART OPTIONS
 */
const options: ChartOptions = {
    legend: {
        display: true,
        position: 'bottom',
    },
    responsive: true,
    title: {
        display: false,
        text: 'Tendencia de palés/hora',
    },
    tooltips: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
            label: (tooltipItem: any, data: any) => {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const meta = dataset._meta[Object.keys(dataset._meta)[0]];
                const total = meta.total;
                const currentValue = dataset.data[tooltipItem.index];
                const percentage = parseFloat(((currentValue / total) * 100).toFixed(1));
                return customFormatNumber(currentValue) + ' (' + customFormatNumber(percentage) + '%)';
            },
            title: (tooltipItem: any, data: any) => {
                return data.labels[tooltipItem[0].index];
            },
        },
    },
    maintainAspectRatio: false,
    hover: {
        mode: 'nearest',
        intersect: true,
    },
    plugins: {
        datalabels: {
            display: true,
            formatter: (value: any, ctx: any) => {
                //return customFormatNumber(value);
                let sum = 0;
                const dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data: any) => {
                    sum += data;
                    return sum;
                });
                const percentage = Math.round((value * 100) / sum);
                if (percentage > 5) {
                    return customFormatNumber(value);
                }
                return '';
            },
        },
    },
};

const initialState = {chart, options};

type State = {
    chart: ChartData;
    options: ChartOptions;
};

type Props = {
    chartData: HourPieTaskType;
    isLoading: boolean;
};

export class HourPieTaskChart extends React.Component<Props, State> {
    readonly state = initialState;
    static getDerivedStateFromProps(props: Props) {
        let dataInfo: number[] = [];
        let labelInfo: string[] = [];

        if (props.chartData.hourByTasksTypeValues && props.chartData.hourByTasksTypeValues.length > 0) {
            dataInfo = props.chartData.hourByTasksTypeValues;
            labelInfo = props.chartData.hourByTasksTypeLabels;
        }

        const randomBackgroundColors: string[] = [];
        let i = 0;
        while (i < dataInfo.length) {
            randomBackgroundColors.push(HourPieTaskChart.getRandomColor());
            i++;
        }

        const datasets = [
            {
                label: 'Horas',
                //backgroundColor: ['#99ff99', '#f8c1bb', '#99AADA9', '#f33D3b', '#9FFCD9', '#FFDDDD'],
                backgroundColor: randomBackgroundColors,
                data: dataInfo,
                fill: false,
            },
        ] as any;

        const chartShow = {
            labels: labelInfo,
            datasets,
        };

        return {
            chart: chartShow,
        };
    }

    static getRandomColor() {
        const letters = '6789ABCDEF'.split('');
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 10)];
        }
        return color;
    }

    //readonly state = initialState;
    render() {
        if (this.props.isLoading) {
            return <Spinner isLoading={this.props.isLoading} position={'relative'} />;
        }

        return (
            <>
                <Pie data={this.state.chart} options={this.state.options} />
            </>
        );
    }
}
