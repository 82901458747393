import * as React from 'react';
import {RowInfo} from 'react-table';
import styled from 'styled-components';

import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {CustomButton, CustomTextField} from 'src/shared/style';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';

import {DowntimeCategoriesControlServices} from './store/downtimeCategoriesControl.services';

type State = {
    open: boolean;
    id: string;
    name: string;
    nameValid: boolean;
};
const initialState: State = {
    open: false,
    id: '',
    name: '',
    nameValid: true,
};

// const TOTAL_ACCESS =
//   typeof IsInRuleset === 'function' ? IsInRuleset('DOWNTIMECATEGORIES_TOTAL') : false;
// const VIEW_ACCESS =
//   typeof IsInRuleset === 'function' ? IsInRuleset('DOWNTIMECATEGORIES_VIEW') : false;
export class DowntimeCategoriesControl extends React.Component<{}, State> {
    readonly state = initialState;
    private tableHook: () => void = () => ({});
    private getTableHooks = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    private columns: ColumnType<{id: string; name: string}> = [
        {
            Header: 'Nombre',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'name',
            className: 'cell_string',
        },
    ];

    private handleChange: HandleNamedChange<State> = name => e => {
        this.setState({[name]: e.target.value} as Pick<State, keyof State>);
    };

    private handleClose = () => {
        this.setState({
            open: false,
            id: '',
            name: '',
        });
    };

    private handleOpen = () => {
        this.setState({open: true});
    };

    private handleSelect = ({id, name}: {id: string; name: string}) => {
        this.setState({
            open: true,
            id,
            name,
        });
    };

    private handleSending = async () => {
        const {name, id, nameValid} = this.state;
        if (nameValid) {
            if (id) {
                await fetchWithFeedback(DowntimeCategoriesControlServices.update({id, name}), {
                    showMessage: true,
                });
                this.handleClose();
            } else {
                await fetchWithFeedback(DowntimeCategoriesControlServices.create({name}), {
                    showMessage: true,
                });
                this.setState({name: ''});
            }
            this.useTableHook();
        } else notify({message: 'Campo nombre no puede ir vacio.', status: 'error'});
    };

    private handleChecking = () => {
        const {name} = this.state;
        this.setState({nameValid: !!name}, this.handleSending);
    };

    render() {
        const {name, open, nameValid} = this.state;
        return (
            IsInRuleset(['DOWNTIMECATEGORIES_TOTAL', 'DOWNTIMECATEGORIES_VIEW']) && (
                <TableWrap>
                    <TableModal title={'Gestión de las categorías'} open={open} handleClose={this.handleClose}>
                        <div
                            style={{
                                maxWidth: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <CustomTextField
                                margin={'5px auto 10px auto'}
                                width={'100%'}
                                // style={{zIndex: 20, marginTop: 5}}
                                label="Nombre"
                                error={!nameValid}
                                value={name}
                                name="name"
                                variant="outlined"
                                onChange={this.handleChange('name')}
                            />
                            <CustomButton onClick={this.handleChecking} width={'100%'}>
                                {this.state.id ? 'Guardar' : 'Crear'} categoría
                            </CustomButton>
                        </div>
                    </TableModal>
                    <TableComponent
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        service={DowntimeCategoriesControlServices.get}
                        accessor={'categories'}
                        createHook={IsInRuleset('DOWNTIMECATEGORIES_TOTAL') ? this.handleOpen : undefined}
                        getTableFetchHook={this.getTableHooks}
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: RowInfo) => ({
                                onClick: () => IsInRuleset('DOWNTIMECATEGORIES_TOTAL') && this.handleSelect(rowInfo.original),
                                style: {cursor: 'pointer'},
                            }),
                        }}
                    />
                </TableWrap>
            )
        );
    }
}

const TableWrap = styled.div`
    width: 30%;
    height: 100%;
    margin: auto;
`;
