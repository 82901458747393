import {LaneActionTypes} from '../types/lane.action-types';
import {AreaProdPartsRes, LaneActionInterface, LaneProdPartState} from '../types';

export const LaneProdPartActions = {
  set: (payload: AreaProdPartsRes): LaneActionInterface<AreaProdPartsRes> => ({
    type: LaneActionTypes.setProdParts,
    payload,
  }),
  update: (payload: LaneProdPartState): LaneActionInterface<LaneProdPartState> => ({
    type: LaneActionTypes.updateProdPart,
    payload,
  }),
  clear: (): LaneActionInterface => ({type: LaneActionTypes.clearProdParts}),
};
