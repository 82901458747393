import {Action} from "redux";

export enum ModuleKey {
    none = 'NONE',
    system = 'KEY_SYSTEM',
    settings = 'KEY_SETTING',
    connectedFactory = 'KEY_CONNECTED_FACTORY',
    employees = 'KEY_EMPLOYEES',
    traceability = 'KEY_TRACEABILITY',
    messages = 'KEY_MESSAGES',
    plannig = 'KEY_PLANNIG',
    costsManagement = 'KEY_COSTS_MANAGEMENT',
    kpis = 'KEY_KPIS',
    visualFactory = 'KEY_VISUAL_FACTORY'
}

export interface MenuListActionType extends Action {
    menuListState: MenuListState;
}

export interface DynamicMenuListActionType extends Action {
    dynamicMenuListState: DynamicMenuListState;
}

export type MenuListState = {
    openModule: ModuleKey;
    selectedItem?: string;
};

export type DynamicMenuListState = {
    openModule: string;
    selectedItem?: string;
};

export interface ModulesListActionType extends Action {
    modules: ModulesListType;
}

export type ModulesListType = {
    [id: string] : ModuleType
}

export type ModuleType = {
    name: string,
    id: string,
    items: MenuItemListType
}

export type MenuItemListType = {
    [id: string] : MenuItemType
}

export type MenuItemType = {
    name: string,
    id: string,
    link: string
}
