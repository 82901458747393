import {DowntimeActionType} from '../types/downtime.action-types';
import {DowntimeActionInterface, DowntimeResponsePreflight} from '../types';

export const PreflightActions = {
  setCategories: (
    payload: DowntimeResponsePreflight,
  ): DowntimeActionInterface<DowntimeResponsePreflight> => ({
    type: DowntimeActionType.setCategories,
    payload,
  }),
  setCauses: (
    payload: DowntimeResponsePreflight,
  ): DowntimeActionInterface<DowntimeResponsePreflight> => ({
    type: DowntimeActionType.setCauses,
    payload,
  }),
  clearCategories: (): DowntimeActionInterface => ({
    type: DowntimeActionType.clearCategories,
  }),
  clearCauses: (): DowntimeActionInterface => ({
    type: DowntimeActionType.clearCauses,
  }),
};
