import {Reducer} from 'redux';

import {ChartsActions, ChartsState} from 'src/app/lanemanager/charts/store/types';
import {ChartsActionTypes} from 'src/app/lanemanager/charts/store/types/chart.action-types';

const initialState: ChartsState = {
  test: {options: ['minuto', 'hora'], selected: 'minuto'},
};

export const chartsReducer: Reducer<ChartsState, ChartsActions> = (
  state = initialState,
  action,
) => {
  if (!state) return state;
  switch (action.type) {
    case ChartsActionTypes.setOptions: {
      const {chartId, options} = action.payload;
      return {
        ...state,
        [chartId]: {
          ...state[chartId],
          options,
        },
      };
    }
    case ChartsActionTypes.setSelected: {
      const {chartId, selected} = action.payload;
      return {
        ...state,
        [chartId]: {
          ...state[chartId],
          selected,
        },
      };
    }
    default:
      return state;
  }
};
