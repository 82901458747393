import {guardedFetcher} from 'src/utils/guardedFetcher';
import {
  NonProductiveTimeResponse,
  NonProductiveTypeResponse,
  NonProductiveTimeData,
  NonProductiveTimeDataForSend,
} from 'src/app/administration/nonProductiveTimesControl/store/nonProductiveTimes.types';

const nonProductiveTimesPrefix = 'crud/non-productive-times';

export const NonProductiveTimesControlServices = {
  getAllTimesFromCenter: (centerId: string) => {
    const response = guardedFetcher.get<NonProductiveTimeResponse>(
      `${nonProductiveTimesPrefix}/center/${centerId}`,
    );
    return response;
  },
  getTypes: () => {
    const response = guardedFetcher.get<NonProductiveTypeResponse>(
      `${nonProductiveTimesPrefix}/types`,
    );
    return response;
  },

  create: ({
    centerId,
    name,
    startAt,
    duration,
    taskId,
    nonProductiveTypesId,
  }: Partial<NonProductiveTimeData>) => {
    return guardedFetcher.post<NonProductiveTimeDataForSend>(nonProductiveTimesPrefix, {
      centerId,
      name,
      startAt,
      duration,
      taskId,
      nonProductiveTypesId,
    });
  },
};
