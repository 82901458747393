import {Reducer} from 'redux';

import {CardControlAction, CardControlState} from '../types';
import {CardControlActionTypes} from '../types/cardcontrol.action-types';

export const defaultState: CardControlState = {formState: 'inactive', id: '0'};

export const cardControlReducer: Reducer<CardControlState, CardControlAction> = (
  state = defaultState,
  {type, payload},
) => {
  switch (type) {
    case CardControlActionTypes.setActive:
      return {formState: 'active', id: payload};
    case CardControlActionTypes.setInactive:
      return {formState: 'inactive', id: payload};
    default:
      return state;
  }
};
