import {BatchesResponse} from './../../../lanemanager/batches/store/types/index';
import {PalletsResponse} from './../../../lanemanager/pallets/store/types/index';
import {TaskByEmployeeData, TaskTimeByAreaData, TimeByWorkshiftData} from 'src/app/information/reports/store/types';
import {PaginatedServiceArgs, PaginateParams} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'presence/stats';
const prefixProduction = 'production/stats';

export const ReportsServices = {
    getTasksByEmployeeRange: (areaId: string, taskId: string, dateStart: string, dateEnd: string, params?: PaginateParams) =>
        guardedFetcher.get<PaginateResponse<TaskByEmployeeData>>(`${prefix}/area/${areaId}/task/${taskId}/${dateStart}/${dateEnd}`, {params}),
    getTasksByEmployee: ({params}: PaginatedServiceArgs) => guardedFetcher.get<PaginateResponse<TaskByEmployeeData>>(`${prefix}/report/tasks-by-employee`, {params}),
    getTimeByWorkshift: ({params}: PaginatedServiceArgs) => guardedFetcher.get<PaginateResponse<TimeByWorkshiftData>>(`${prefix}/report/times-workshift`, {params}),
    getTasksTimeByArea: ({params}: PaginatedServiceArgs) => guardedFetcher.get<PaginateResponse<TaskTimeByAreaData>>(`${prefix}/report/times-area-task`, {params}),
    getTimeByArea: ({params}: PaginatedServiceArgs) => guardedFetcher.get<PaginateResponse>(`${prefix}/report/times-area`, {params}),
    getPalletsAllPaginated: ({params, args}: PaginatedServiceArgs) =>
        guardedFetcher.get<PaginateResponse<PalletsResponse>>(`${prefixProduction}/report/pallets-info`, {
            params: args ? {...params, filtered: {area_id: args, ...params!.filtered}} : {...params},
        }),
    getAllPaginated: ({params, args}: PaginatedServiceArgs) =>
        guardedFetcher.get<PaginateResponse<BatchesResponse>>(`${prefixProduction}/report/batches-info`, {
            params: args ? {...params, filtered: {area_id: args, ...params!.filtered}} : {...params},
        }),
};
