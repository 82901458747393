import {Reducer} from 'redux';

import {AreaEmployeesRes, LaneActionInterface, LaneEmployeesState} from '../types';
import {LaneActionTypes} from '../types/lane.action-types';

export const defaultState: LaneEmployeesState[] = [
  // {
  //   id: '1',
  //   task_active: 'limpieza de linea',
  //   name: 'Jaime',
  //   surname: 'Sanz',
  //   created_at: new Date().toLocaleString(),
  // },
  // {
  //   id: '2',
  //   task_active: 'paletizado',
  //   name: 'Pedro',
  //   surname: 'Amigo',
  //   created_at: new Date().toLocaleString(),
  // },
];

export const laneEmployeesReducer: Reducer<LaneEmployeesState[], LaneActionInterface> = (
  state = defaultState,
  {type, payload},
): LaneEmployeesState[] => {
  if (!state) return state;
  if (!type) return state;
  switch (type) {
    case LaneActionTypes.setEmployees: {
      const {employees} = payload as AreaEmployeesRes;
      const transform = employees.map(
        e =>
          ({
            id: e.id,
            name: e.name,
            surname: e.surname,
            task_active: e.task_active,
            created_at: e.created_at,
          } as LaneEmployeesState),
      );
      return transform;
    }
    case LaneActionTypes.clearEmployees: {
      return [];
    }
    default:
      return state;
  }
};
