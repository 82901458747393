import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

import {EmployeeReportUnit} from './types';

const prefix = 'presence/stats/report/registered-tasks';
export const EmployeesServices = {
    get: () => {
        return guardedFetcher.get<EmployeeReportUnit>(prefix);
    },

    getPaginated: ({params}: PaginatedServiceArgs) => guardedFetcher.get<PaginateResponse<EmployeeReportUnit>>(prefix, {params}),
    getGroupedPaginated: ({params}: PaginatedServiceArgs) => guardedFetcher.get<PaginateResponse<EmployeeReportUnit>>(prefix+'/grouped', {params}),
};
