import {guardedFetcher} from 'src/utils/guardedFetcher';

import {
    AreaBatchesRes,
    AreaDumpRes,
    AreaEmployeesRes,
    AreaProdPartsRes,
    AreaPtablesRes,
} from './types';
import {fetcher} from 'src/global/fetcher';

const prefix = 'production/info';
const prefixControl = 'production/control';

/**
 * Batches API calls Service
 */
export const BatchServices = {
    getById: (batchId: string) => guardedFetcher.get(`${prefix}/batch/${batchId}`),
    getAll: () => guardedFetcher.get(`${prefix}/batches`),
    finishCurrentBachInArea: (areaId: string) =>
        fetcher.post(`${prefixControl}/area/${areaId}/finish-batch`),
};

/**
 * ProdParts API calls Service
 */
export const ProdPartServices = {
    getAll: () => guardedFetcher.get(`${prefix}/prodparts`),
    getById: (prodpartId: string) => guardedFetcher.get(`${prefix}/prodpart/${prodpartId}`),
    getPtables: (prodpartId: string) =>
        guardedFetcher.get(`${prefix}/prodpart/${prodpartId}/ptables`),
    importProdpart: (erp_code: string, prodpart_id: string) =>
        guardedFetcher.post(`${prefix}/prodpart/import`, {erp_code, prodpart_id})
};

/**
 * Areas API calls Service
 */
export const AreaServices = {
    getAll: () => guardedFetcher.get(`${prefix}/areas`),
    getById: (areaId: string) => guardedFetcher.get(`${prefix}/area/${areaId}`),
    getAllBatches: (areaId: string) =>
        guardedFetcher.get<AreaBatchesRes>(`${prefix}/area/${areaId}/batches`),
    getCurrentDump: (areaId: string) =>
        guardedFetcher.get<AreaDumpRes>(`${prefix}/area/${areaId}/dumps/current`),
    getAllEmployees: (areaId: string) =>
        guardedFetcher.get<AreaEmployeesRes>(`${prefix}/area/${areaId}/employees`),
    getAllPtables: (areaId: string) =>
        guardedFetcher.get<AreaPtablesRes>(`${prefix}/area/${areaId}/ptables`),
    getAllProdparts: (areaId: string) =>
        guardedFetcher.get<AreaProdPartsRes>(`${prefix}/area/${areaId}/prodparts`),
};

/**
 * Ptables API calls Service
 */
export const PtableServices = {
    assignProdPartPtable: (areaId: string, prodpartId: string, ptableId: string) =>
        guardedFetcher.post(
            `${prefixControl}/area/${areaId}/prodpart/${prodpartId}/ptable/${ptableId}/assign`,
        ),
    reserveProdPartPtable: (areaId: string, prodpartId: string, ptableId: string) =>
        guardedFetcher.post(
            `${prefixControl}/area/${areaId}/prodpart/${prodpartId}/ptable/${ptableId}/reserve`,
        ),
    transferProdPartPtable: (areaId: string, prodpartId: string, ptableId: string) =>
        guardedFetcher.post(
            `${prefixControl}/area/${areaId}/prodpart/${prodpartId}/ptable/${ptableId}/transfer`,
        ),
    unassignProdPartPtable: (areaId: string, prodpartId: string, ptableId: string) =>
        guardedFetcher.post(
            `${prefixControl}/area/${areaId}/prodpart/${prodpartId}/ptable/${ptableId}/unassign`,
        ),
    unreserveProdPartPtable: (areaId: string, prodpartId: string, ptableId: string) =>
        guardedFetcher.post(
            `${prefixControl}/area/${areaId}/prodpart/${prodpartId}/ptable/${ptableId}/unreserve`,
        ),
};
