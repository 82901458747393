import * as React from 'react';
import styled from 'styled-components';

import {ParameterControlUnit} from 'src/app/administration/parametersControl/store/parametersControl.types';
import {TCTaskResponse} from 'src/app/administration/tasksControl/store/tasksControl.types';
import {Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, TextField, Typography} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {CustomDropdown} from 'src/shared/style';

type Props = {
    editable: boolean;
    expanded: string | boolean;
    change(...args: any[]): any;
    parameter: ParameterControlUnit;
    tasksList?: TCTaskResponse['tasks'];
    handleChangeParameterValue(param: {parameterId: string; value: string}): void;
    handleParameterSave(parameterId: string): void;
};

export const ParameterControlPanel: React.FunctionComponent<Props> = props => {
    const inputValue = props.tasksList ? props.tasksList.find(tl => tl.id === props.parameter.value) : undefined;
    if (props.parameter)
        return (
            <ExpansionPanel expanded={props.expanded === props.parameter.name} onChange={(_: any, expanded: boolean) => props.change(props.parameter.name, expanded)}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6" style={{width: '80%'}}>
                        {props.parameter.description}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <DetailsWrap>
                        <Typography style={{alignSelf: 'center'}}>
                            Valor: <span style={{fontSize: 14}}>{}</span>
                        </Typography>
                        {!props.tasksList ? (
                            <TextField
                                variant="outlined"
                                disabled={!props.editable}
                                inputProps={{style: {padding: '8.5px 14px'}}}
                                style={{width: 500, height: 35, marginLeft: 15, marginRight: 15}}
                                value={props.parameter.value}
                                onChange={e =>
                                    props.handleChangeParameterValue({
                                        parameterId: props.parameter.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        ) : (
                            <CustomDropdown
                                margin={'0 15px 0 15px'}
                                containerWidth={'500px'}
                                dropdownName="Tasks Dropdown"
                                value={inputValue ? inputValue.id : undefined}
                                data={props.tasksList}
                                handleChoose={e =>
                                    props.handleChangeParameterValue({
                                        parameterId: props.parameter.id,
                                        value: e.target.value,
                                    })
                                }
                            />
                        )}
                        {props.editable && (
                            <Button onClick={() => props.handleParameterSave(props.parameter.id)} variant="outlined">
                                Guardar
                            </Button>
                        )}
                    </DetailsWrap>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    else return null;
};

const DetailsWrap = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

// const LaneWrap = styled.div`
//   display: flex;
//   flex-direction: row;
//   /* justify-content: space-between; */
//   /* margin: 5px; */
//   width: 100%;
// `;
