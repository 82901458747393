import differenceInMinutes from 'date-fns/difference_in_minutes';
import React from 'react';

import {ReportsServices} from 'src/app/information/reports/store/services';
import {TimeByWorkshiftData} from 'src/app/information/reports/store/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import { fetchWithFeedback } from 'src/utils/fetcherValidate';
import { ShiftsMapService } from 'src/shared/components/services';

type State = {
    shifts : Array<{
        id:string;
        name:string;
    }>;
    shiftsMap : Map<string, string>;
    shiftsLoaded : boolean;
}

const initialState : State = {
    shifts: [],
    shiftsMap: new Map<string, string>(),
    shiftsLoaded : false
};

export class TimeByWorkshift extends React.Component<{}, State> {
    readonly state = initialState;
    columns : ColumnType<TimeByWorkshiftData> = [];

    private fetchShiftsMap = async () => {
        const shifts : Array<{
            id:string;
            name:string;
        }> = await fetchWithFeedback(ShiftsMapService(), {accessor: 'shifts'});
        this.setState({shifts});
        shifts.forEach(v => this.state.shiftsMap.set(v.id, v.name));
    }

    componentDidMount() {
        this.fetchShiftsMap().then(() => {
            this.columns = this.getColumnFormat();
            this.setState({shiftsLoaded : true});
        });
    }

    private getColumnFormat = () : ColumnType<TimeByWorkshiftData> => {
        return [
            {
                Header: 'Jornada',
                headerClassName: 'header_cell_string',
                accessor: 'workShift',
                daterange: true,
                dateFormat: true,
                filterable: true,
                width: 260,
                className: 'cell_string',
            },
            {
                Header: 'Turno',
                headerClassName: 'header_cell_string',
                Cell: item => <>{this.state.shiftsMap.get(item.original.area_shift)}</>,
                dropdownFilter: true,
                dropdownFilterData: this.state.shifts,
                id: 'area_shift',
                className: 'cell_string',
            },
            {
                Header: 'EMP.Nombre',
                headerClassName: 'header_cell_string',
                accessor: 'employee_name',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'EMP.Apellidos',
                headerClassName: 'header_cell_string',
                accessor: 'employee_surname',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'EMP.Código',
                headerClassName: 'header_cell_string',
                accessor: 'code',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'Tiempo dedicado',
                headerClassName: 'header_cell_number',
                accessor: 'duration',
                Cell: item => {
                    const startDate = new Date(item.original.created_at);
                    const now = new Date();
                    const time = differenceInMinutes(now, startDate);
                    const coerce = item.original.duration !== null ? +item.original.duration : undefined;
                    return <>{typeof coerce === 'number' ? customFormatNumber(Number((coerce / 60).toFixed(0))) : customFormatNumber(time)} m</>;
                },
                className: 'cell_number',
            },
            {
                Header: 'Inicio trabajo',
                headerClassName: 'header_cell_string',
                accessor: 'created_at',
                dateTimeFormat: true,
                className: 'cell_string',
            },
            {
                Header: 'Final trabajo',
                headerClassName: 'header_cell_string',
                accessor: 'finished_at',
                dateTimeFormat: true,
                className: 'cell_string',
            },
            {
                Header: 'Coste',
                headerClassName: 'header_cell_number',
                id: 'cost',
                Cell: item => {
                    const {cost} = item.original;
                    const costConverted = cost ? (typeof cost === 'number' ? cost : +cost) : undefined;
                    return <>{costConverted ? `${customFormatNumber(Number(costConverted.toFixed(2)))} € ` : 'no hay datos'}</>;
                },
                className: 'cell_number',
            },
        ];
    }

    render() {

         return IsInRuleset(['REPORTS_WORKSHIFT_TIMES_EXCEL', 'REPORTS_WORKSHIFT_TIMES_VIEW']) ?
            this.state.shiftsLoaded && (<PaginatedTableComponent
                columnFormat={this.columns}
                defaultPageSize={20}
                service={ReportsServices.getTimeByWorkshift}
                showSizeOptions={true}
                twoWeeksFilter={true}
                loading={true}
                excel={IsInRuleset('REPORTS_WORKSHIFT_TIMES_EXCEL')}
            />) : null;
    }



}
