import * as React from 'react';
import styled from 'styled-components';

import {CenterControlServices} from 'src/app/administration/centerControl/store/centerControl.services';
import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {CustomButton, CustomTextField} from 'src/shared/style';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

type State = {
    id: string;
    name: string;
    selectedId: string;
    idValid: boolean;
    nameValid: boolean;
    open: boolean;
};
const initialState: State = {
    id: '',
    name: '',
    selectedId: '',
    open: false,
    idValid: true,
    nameValid: true,
};

// const TOTAL_ACCESS =
//   typeof IsInRuleset === 'function' ? IsInRuleset('CENTERS_TOTAL') : false;
// const VIEW_ACCESS =
//   typeof IsInRuleset === 'function' ? IsInRuleset('CENTERS_VIEW') : false;

export class CentersControl extends React.Component<{}, State> {
    readonly state = initialState;
    private tableHook: () => void = () => ({});
    private columns: ColumnType<{id: string; name: string}> = [
        {
            Header: 'Centros',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'name',
            className: 'cell_string',
        },
    ];

    private handleChange: HandleNamedChange<State> = name => e => {
        this.setState({[name]: e.target.value} as Pick<State, keyof State>);
    };

    private handleClose = () =>
        this.setState({
            selectedId: '',
            open: false,
            id: '',
            idValid: true,
            name: '',
            nameValid: true,
        });

    private handleOpen = () => {
        this.setState({
            open: true,
            id: '',
            idValid: true,
            name: '',
            nameValid: true,
        });
    };

    private handleSelect = (original: {id: string; name: string}) =>
        this.setState({
            open: true,
            selectedId: '' + original.id,
            id: original.id,
            name: original.name,
        });

    private handleSending = async () => {
        const {name, selectedId, nameValid} = this.state;
        // we update name if we have selec
        if (name && nameValid && selectedId) {
            await fetchWithFeedback(CenterControlServices.update({id: selectedId, name}), {
                showMessage: true,
            });
            this.handleClose();
        } else if (name && nameValid && !selectedId) {
            await fetchWithFeedback(CenterControlServices.create({name}), {showMessage: true});
            this.setState({id: '', name: ''});
        }
        this.useTableHook();
    };

    private getTableHooks = (fn: () => void) => {
        this.tableHook = fn;
    };

    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    render() {
        const {selectedId, name, nameValid, open} = this.state;
        return (
            IsInRuleset(['CENTERS_TOTAL', 'CENTERS_VIEW']) && (
                <TableWrap>
                    <TableModal title={'Gestión del centro'} open={open} handleClose={this.handleClose}>
                        <div style={{width: 260}}>
                            <CustomTextField
                                label="Nombre del centro"
                                error={!nameValid}
                                value={name}
                                name="name"
                                variant={'outlined'}
                                width={'100%'}
                                color="secondary"
                                onChange={this.handleChange('name')}
                                margin={'10px 0 15px 0'}
                            />
                            <CustomButton onClick={this.handleSending} width={'100%'}>
                                {this.state.selectedId ? 'Guardar' : 'Crear'} centro
                            </CustomButton>
                        </div>
                    </TableModal>
                    <TableComponent
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        service={CenterControlServices.get}
                        createHook={IsInRuleset('CENTERS_TOTAL') ? this.handleOpen : undefined}
                        getTableFetchHook={this.getTableHooks}
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: any) => ({
                                onClick: () => {
                                    if (IsInRuleset('CENTERS_TOTAL')) {
                                        if (!selectedId) this.handleSelect(rowInfo.original);
                                        else this.handleClose();
                                    }
                                },
                                style: {
                                    cursor: 'pointer',
                                },
                            }),
                        }}
                    />
                </TableWrap>
            )
        );
    }
}

const TableWrap = styled.div`
    width: 30%;
    height: 100%;
    margin: auto;
`;
