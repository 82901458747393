import {AppStoreState} from 'src/store';

export const PaginatedTableSelectors = {
  getFilters: (
    id: string,
    state: AppStoreState,
  ):
    | {
        page: number;
        pageSize: number;
        sorted: any[];
        filtered: any[];
      }
    | undefined => state.paginatedTable[id],
};
