import {routerMiddleware} from 'connected-react-router';
import createHistory from 'history/createBrowserHistory';
// import createRavenMiddleware from 'raven-for-redux';
// import Raven from 'raven-js';
import {applyMiddleware, compose, createStore, DeepPartial} from 'redux';
import thunk from 'redux-thunk';

import {ENV} from 'src/global/api';
import {loadState} from 'src/utils/persisted-state';

/*** reducers */
import {reducers} from './reducers';

export const history = createHistory();

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const middlewares = [thunk, routerMiddleware(history)];
export let dispatcher = (_: any) => _;
export const makeDispatcher = (dispatch: any) => {
  if (dispatch && typeof dispatch === 'function') dispatcher = dispatch;
  else dispatcher = (...args: any) => console.error('NO_DISPATCH_INSTANTIATED', args);
};

export const store = async () => {
  const persistentStore = (await loadState()) as DeepPartial<{}>;
  let logger;
  if (ENV !== 'production') {
    const {createLogger} = await import('redux-logger');
    logger = createLogger({collapsed: true});
  }
  if (logger) middlewares.push(logger);
  const newStore = createStore(
    reducers(history) as any,
    persistentStore !== null ? persistentStore : {},
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  makeDispatcher(newStore.dispatch);
  return newStore;
};
