import {dispatcher} from 'src/store/store';

import {NotifyAction, NotifyActionType, NotifyPayload} from './action-types';

const payloadToType = (payload: NotifyPayload) => {
  const {status} = payload;
  if (status === 'error') return NotifyActionType.error;
  if (status === 'success') return NotifyActionType.success;
  if (status === 'notify') return NotifyActionType.notify;
  return NotifyActionType.warning;
};

export const notify = (payload: NotifyPayload) =>
  dispatcher(notifyActionWrapper(payload));

/**
 *  Notification action, payload is composed by message, status and timeout.
 *
 * @param {NotifyPayload} payload | {message, status, timeout}
 * @returns {NotifyAction}
 */
export const notifyActionWrapper = (payload: NotifyPayload): NotifyAction => ({
  type: payloadToType(payload),
  payload,
});

export const notifyCloseWrapper = () => ({type: NotifyActionType.close});
/**
 * Predefined message overridable with the custom message.
 *
 * SERVER ERROR 400/500
 *
 * @param {string} [message]
 */
export const notifyServerError = (message?: string) =>
  notifyActionWrapper({
    message: message || 'No se ha podido obtener datos del servidor',
    status: 'error',
  });

/**
 * Predefined message about NO CONNECTIVITY.
 *
 */
export const notifyConnect = () =>
  notifyActionWrapper({
    message: 'No se ha podido obtener datos, error de conexión.',
    status: 'error',
  });

/**
 * Predefined message overridable with the custom message.
 *
 * DATA VALIDATION ERROR 400/500
 *
 * @param {string} [message]
 */
export const notifyValidationError = (message?: string) =>
  notifyActionWrapper({
    message: message || 'No se ha podido validar los datos obtenidos del servidor',
    status: 'error',
  });
