import {guardedFetcher} from 'src/utils/guardedFetcher';
import {ExcesiveInactiveTaskType, ExceededKgBatchType, TaskRegisteredBetweenTimeInterval} from './types';

const prefix = 'alert';
export const AlertServices = {
    excessiveInactiveTask: (areaId: string, isAdmin: number) => guardedFetcher.get<ExcesiveInactiveTaskType>(`${prefix}/area/${areaId}/isAdmin/${isAdmin}/excessive-inactive-task`),

    taskRegisteredBetweenTimeInterval: (areaId: string, isAdmin: number) => guardedFetcher.get<TaskRegisteredBetweenTimeInterval>(`${prefix}/area/${areaId}/isAdmin/${isAdmin}/task-registered-between-time-interval`),

    exceededKgBatch: (areaId: string, isAdmin: number) => guardedFetcher.get<ExceededKgBatchType>(`${prefix}/area/${areaId}/isAdmin/${isAdmin}/exceeded-kg-batch`),

    checkedExcessiveInactiveTask: (idCheck: string, employeeId: string) => guardedFetcher.post<any>(`${prefix}/checked-excessive-inactive-task/${idCheck}`, {employee_id: employeeId}),

    checkedTaskRegisteredBetweenTimeInterval: (idCheck: string, employeeId: string) => guardedFetcher.post<any>(`${prefix}/checked-task-registered-between-time-interval/${idCheck}`, {employee_id: employeeId}),
};
