import * as React from 'react';

import {
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {CenterType} from "./store/usersByCenter.types";
import {Creatable}  from "react-select";
import animated from "react-select/lib/animated";
import _ from 'lodash';

export type Props = {
    expanded: string | boolean;
    change(...args: any[]): any;
    centerId: string;
    center: CenterType;
    hasWriteGrants: boolean;
    totalUsers: Array<{
        value: string;
        label: string;
    }>;
    checkIfHasSave() : void;
    updateTotalUsers(value : {value: string, label: string}) : void;
};

type State = {
    selectedUsers: Array<{
        value: string;
        label: string;
    }>;
};


export class UsersByCenterPanel extends React.Component<Props, State> {
    componentDidMount() {
        //const totalUsers = this.props.users.map(v => ({value: v, label: v}));
        const selectedUsers = this.props.center.users.map(v => ({value: v, label: v}));
        this.setState({selectedUsers});
    }

    updateSelectedUsers = () => {
        this.props.center.users = this.state.selectedUsers.map( v  => (v.value));
    }

    getSelectedUsers = () => {
        return this.state.selectedUsers;
    }

    isChange = () => {
        const selected = _.sortBy(this.state.selectedUsers.map(v => v.value));
        return !_.isEqual(this.props.center.users, selected);
    }

    private handleChange = (selectedUsers : any) => {
        this.setState({selectedUsers}, this.props.checkIfHasSave);
    }

    private handleCreateUser = (value : any) => {
        const newSelect = {value:value, label:value};
        //this.props.totalUsers.push(newSelect);
        this.state.selectedUsers.push(newSelect);
        this.props.updateTotalUsers(newSelect);
    }



    render() {
        const {expanded, center, change, hasWriteGrants, centerId} = this.props;

        return this.state && (
            <ExpansionPanel
                expanded={expanded === center.name}
                onChange={(_: any, expanded: boolean) => change(center.name, expanded)}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">
                        {center.name}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{display: 'block'}}>
                    <label>Emails</label>
                    <Creatable
                        key={centerId}
                        isDisabled={!hasWriteGrants}
                        backspaceRemovesValue={false}
                        value={this.state.selectedUsers}
                        isMulti={true}
                        styles={affectedTgSelect}
                        closeMenuOnSelect={false}
                        isClearable={false}
                        components={animated()}
                        options={this.props.totalUsers}
                        onChange={this.handleChange}
                        onCreateOption={this.handleCreateUser}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

const affectedTgSelect = {
    menu: (provided: any) => ({
        ...provided,
        height: 200,
    }),
    menuList: (provided: any) => ({
        ...provided,
        height: 200,
    }),
    multiValueRemove: (base: any, state : any) => {
        return state.isDisabled ? { ...base, display: 'none' } : base;
    },
    multiValue: (base: any, state : any) => {
        return state.isDisabled ? { ...base, backgroundColor: 'white' } : base;
    },
};

