import styled from 'styled-components';

export const StyledDatePicker = styled.div`
  input {
    min-width: 0.54em !important;
    height: 100% !important;
    padding: 0 1px !important;
    border: 0 !important;
    background: none !important;
    font: inherit !important;
    box-sizing: content-box !important;
  }

  .react-daterange-picker__calendar {
    z-index: 20;
  }

  .react-daterange-picker__wrapper {
    z-index: 200;
  }
`;
