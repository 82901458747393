import React from 'react';
import {TableModal} from 'src/shared/components/Modal';
import { BrowserBarcodeReader } from '@zxing/library';
import LinearProgress from '@material-ui/core/LinearProgress';

export interface Props {
    open: boolean;
    hide: () => void;
    onReadedCode: (code: string) => void;
    // width: number;
    // height: number;
}

type State = {
    zxing: BrowserBarcodeReader,
    showSpinner: boolean,
    timerId: number,
};

const initialState: Readonly<State> = {
    zxing: new BrowserBarcodeReader(),
    showSpinner: true,
    timerId: 0,
};

export class BarcodeScanner extends React.Component<Props, State> {

    readonly state = initialState;

    private hideModalTable = () => {
        clearTimeout(this.state.timerId);
        this.state.zxing.reset();
        this.props.hide();
    };

    public init = () => {
        const timerId = setTimeout(this.hideModalTable, 15000);
        this.setState({
            showSpinner : true,
            timerId : timerId,
        });
        this.scanBarcode();
        this.checkVideoIsReady();
    };

    private checkVideoIsReady = () => {
        const video = document.querySelector('video');

        if (!video || !video.srcObject) {
            setTimeout(this.checkVideoIsReady, 100);
        } else {
            this.setState({
                showSpinner : false
            });
        }
    };

    private scanBarcode = () => {
        const zxing = this.state.zxing;

        zxing.decodeOnceFromVideoDevice(undefined, 'barcodeVideo')
            .then(result => {
                this.handleResult(result);
            })
            .catch(err => {
                console.error(err.message);
                this.hideModalTable();
            });
    };

    private handleResult(result : any) {
        this.props.onReadedCode(result.text);
        this.hideModalTable();
    }

    render() {

        return (
            <TableModal open={this.props.open} handleClose={this.hideModalTable}>
                <div id="container">
                    <video
                        autoPlay
                        muted
                        id="barcodeVideo"
                        style={{width:'100%', height:'100%'}}
                    >
                    </video>
                </div>
                <div
                    id="spinner"
                    style={{ display: this.state.showSpinner ? "block" : "none" }}
                >
                    <LinearProgress />
                </div>
            </TableModal>
        );
    }
}



