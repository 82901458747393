import {ProdPartGeneric} from 'src/app/lanemanager/lane/store/types';
import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

import {ProdPartsResponse, PtablesResponse} from './types';

// import axios from 'axios';
const prefix = 'production/info';
export const ProdPartsServices = {
  getProdparts: () => guardedFetcher.get<ProdPartsResponse>(`${prefix}/prodparts`),
  getPtables: () => guardedFetcher.get<PtablesResponse>(`${prefix}/ptables`),
  getPtablesByArea: (areaId: string) => guardedFetcher.get<PtablesResponse>(`${prefix}/area/${areaId}/ptables`),
  getProdPartsPaginate: ({params, args}: PaginatedServiceArgs) =>
    guardedFetcher.get<PaginateResponse<ProdPartGeneric>>(
      `${prefix}/prodparts-paginate`,
      {
        params: {
          ...params,
          filtered: args ? {...params!.filtered, area_id: args} : params!.filtered,
        },
      },
    ),
  updateProdParts: () => guardedFetcher.get('production/command/updateProdparts'),
}; // axios.get<ProdPartsResponse>(prefix), //
