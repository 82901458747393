import {Reducer} from 'redux';
import {DYNAMIC_MENU_LIST_ACTION, MENU_LIST_ACTION, MODULE_LIST_ACTION} from './actions';
import {
    MenuListActionType,
    MenuListState,
    ModuleKey,
    ModulesListType,
    ModulesListActionType,
    DynamicMenuListState, DynamicMenuListActionType
} from './types';


const initialState: MenuListState = {
    openModule: ModuleKey.none,
};

const dynamicMenuInitialState: DynamicMenuListState = {
    openModule: ''
}

export const MenuListReducer: Reducer<MenuListState, MenuListActionType> = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case MENU_LIST_ACTION:
            return action.menuListState;
    }
    return state;
};

export const DynamicMenuListReducer: Reducer<DynamicMenuListState, DynamicMenuListActionType> = (
    state = dynamicMenuInitialState,
    action
) => {
    switch (action.type) {
        case DYNAMIC_MENU_LIST_ACTION:
            return action.dynamicMenuListState;
    }
    return state;
};

export const ModulesListReducer: Reducer<ModulesListType | null, ModulesListActionType> = (
    state = null,
    action
) => {
    switch (action.type) {
        case MODULE_LIST_ACTION:
            return action.modules;
    }
    return state;
}
