import {ajv} from 'src/utils/ajv';
import {areaScheme} from './schemes/area';
import {batchScheme} from './schemes/batch';

const areaBatchesResScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['area', 'batches'],
  properties: {
    area: areaScheme,
    batches: {type: 'array', items: batchScheme},
  },
};

const precompile = ajv.compile(areaBatchesResScheme);
export const areaBatchesSchemeResValidate = precompile;
