import React from 'react';
import styled from 'styled-components';

import {WrapForm} from 'src/app/administration/export/styled/Wrap';
import {Divider} from '@material-ui/core';
import {CustomButton} from 'src/shared/style/CustomButton';
import {CustomTextField} from 'src/shared/style/CustomTextField';
//import {notifyValidator} from 'src/utils/notifyValidator';
import {EmployeeCostsControlData} from './store/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {EmployeeCostsControlServices} from 'src/app/administration/employeeCostsControl/store/employeeCostsControl.services';

type State = Readonly<{
    id: string;
    employee_name: string;
    employee_surname: string;
    employee_code: string;
    employee_identification: string;
    hours: number | undefined;
    ordinary_rate: number | undefined;
    ordinary_rate_special: number | undefined;
    festive_rate: number | undefined;
    festive_rate_special: number | undefined;
}>;

const initialState: Readonly<State> = {
    id: '',
    employee_name: '',
    employee_surname: '',
    employee_code: '',
    employee_identification: '',
    hours: 0,
    ordinary_rate: 0,
    ordinary_rate_special: 0,
    festive_rate: 0,
    festive_rate_special: 0,
};

type Props = {
    employeeCost?: EmployeeCostsControlData;
    handleClose(): void;
    update(): void;
};

export class EmployeeCostForm extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidMount() {
        if (this.props.employeeCost) {
            const {
                id,
                employee_name,
                employee_surname,
                employee_code,
                employee_identification,
                hours,
                ordinary_rate,
                ordinary_rate_special,
                festive_rate,
                festive_rate_special,
            } = this.props.employeeCost;
            this.setState({
                id,
                employee_name,
                employee_surname,
                employee_code,
                employee_identification,
                hours,
                ordinary_rate,
                ordinary_rate_special,
                festive_rate,
                festive_rate_special,
            });
        } else this.setState(initialState);
    }

    render() {
        const {
            id,
            employee_name,
            employee_surname,
            employee_code,
            employee_identification,
            hours,
            ordinary_rate,
            ordinary_rate_special,
            festive_rate,
            festive_rate_special,
        } = this.state;
        return (
            <WrapForm margin={'5px 10px'}>
                <div style={{fontSize: '20px'}}>
                    {employee_name} {employee_surname} ({employee_identification}) ({employee_code})
                </div>
                <Divider style={{margin: '15px 0 15px 0'}} />
                <DataDiv>
                    <LeftPart>
                        <CustomTextField
                            margin={'10px 0 5px 0'}
                            label={'Tarifa normal'}
                            variant="outlined"
                            width={'200px'}
                            onChange={this.handleInputs('ordinary_rate')}
                            value={ordinary_rate || ''}
                        />
                        <CustomTextField
                            margin={'10px 0 5px 0'}
                            label={'Tarifa extra'}
                            variant="outlined"
                            width={'200px'}
                            onChange={this.handleInputs('ordinary_rate_special')}
                            value={ordinary_rate_special || ''}
                        />
                    </LeftPart>
                    <RightPart>
                        <CustomTextField
                            margin={'10px 0 5px 0'}
                            label={'Tarifa festivo normal'}
                            variant="outlined"
                            width={'200px'}
                            onChange={this.handleInputs('festive_rate')}
                            value={festive_rate || ''}
                        />
                        <CustomTextField
                            margin={'10px 0 5px 0'}
                            label={'Tarifa festivo extra'}
                            variant="outlined"
                            width={'200px'}
                            onChange={this.handleInputs('festive_rate_special')}
                            value={festive_rate_special || ''}
                        />
                    </RightPart>
                </DataDiv>
                <CustomTextField margin={'10px 0 10px 0'} label={'Horas'} variant="outlined" width={'200px'} onChange={this.handleInputs('hours')} value={hours || ''} />

                <div style={{display: 'flex'}}>
                    <CustomButton margin={'0px 0px 0px auto'} onClick={this.handleSend} width={'25%'}>
                        {id ? 'Guardar' : 'Crear'}
                    </CustomButton>
                </div>
            </WrapForm>
        );
    }

    private handleSend = async () => {
        const {id, hours, ordinary_rate, ordinary_rate_special, festive_rate, festive_rate_special} = this.state;

        if (id) {
            let hoursFormated;
            let ordinaryRateFormated;
            let ordinaryRateSpecialFormated;
            let festiveRateFormated;
            let festiveRateSpecialFormated;
            if (hours) {
                hoursFormated = parseFloat(hours.toString().replace(',', '.'));
            }
            if (ordinary_rate) {
                ordinaryRateFormated = parseFloat(ordinary_rate.toString().replace(',', '.'));
            }
            if (ordinary_rate_special) {
                ordinaryRateSpecialFormated = parseFloat(ordinary_rate_special.toString().replace(',', '.'));
            }
            if (festive_rate) {
                festiveRateFormated = parseFloat(festive_rate.toString().replace(',', '.'));
            }
            if (festive_rate_special) {
                festiveRateSpecialFormated = parseFloat(festive_rate_special.toString().replace(',', '.'));
            }

            const res = await fetchWithFeedback(
                EmployeeCostsControlServices.update(id, {
                    hours: hoursFormated,
                    ordinary_rate: ordinaryRateFormated,
                    ordinary_rate_special: ordinaryRateSpecialFormated,
                    festive_rate: festiveRateFormated,
                    festive_rate_special: festiveRateSpecialFormated,
                }),
                {showMessage: true},
            );
            if (res) {
                this.props.update();
                this.props.handleClose();
            }
        }
    };

    private handleInputs: HandleNamedChange<State> = name => e => {
        this.setState({[name]: e.target.value, [`${name}Valid`]: true} as Pick<State, keyof State>);
    };
}

export const LeftPart = styled.div`
    width: 45%;
`;
export const RightPart = styled.div`
    width: 45%;
    display: flex;
    flex-direction: column;
`;
export const DataDiv = styled.div`
    display: flex;
`;
