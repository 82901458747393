import React, {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DefaultIcon from "@material-ui/icons/RadioButtonUnchecked";
import DefaultSelectedIcon from "@material-ui/icons/RadioButtonChecked";
import Typography from '@material-ui/core/Typography';
import {connect} from "react-redux";
import {AppStoreState} from "../../../store";
import {DynamicMenuListSelectors} from "../store/selectors";

type Props = {
    text: string;
    icon?: JSX.Element;
    onClick(id: string): void;
    href?: string;
    target?: string;
    id: string;
};



const MenuListItemComponent: FunctionComponent<Props & SProps> = props => {
    const weight : 'bold' | 'normal' = props.id === props.storedId ? 'bold' : 'normal';
    const typoStyle = {
        margin: 0,
        alignSelf: 'left',
        fontWeight: weight,
    };

    return (
        <ListItem button
                  style={{
                      marginLeft: 24,
                      padding: 8,
                  }}
                  href={props.href}
                  target={props.target}
        >

            <ListItemIcon style={{
                margin: 0
            }}>
                {props.icon ?
                    props.icon : props.id === props.storedId ?
                        (
                            <DefaultSelectedIcon style={{transform:'scale(0.40)', translate: 16}}/>
                        ) : (
                            <DefaultIcon style={{transform:'scale(0.40)', translate: 16}}/>
                        )
                }
            </ListItemIcon>

            <ListItemText
                primary={
                    <Link to={props.href || ''} href={props.href} style={{display: 'flex'}}>

                        <Typography
                            style={typoStyle}
                            variant='body2'
                        >
                            {props.text}
                        </Typography>
                    </Link>
                }
                onClick={() => props.onClick(props.id)}
            />
        </ListItem>
    );
}


type SProps = {
    storedId: string | undefined;
};

export const DynamicMenuListItem = connect<SProps, {}, Props, AppStoreState>(
    state => ({
        storedId : DynamicMenuListSelectors.getState(state).selectedItem
    })
)(MenuListItemComponent);
