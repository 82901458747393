import * as React from 'react';
import {WrapFullContainer, OCard} from 'src/shared/style';
import {PalletTotalType, PalletTotalProductType, PalletHourTotalType, PalletHourTotalProductType, ProductPalletInfoType} from 'src/app/information/kpis/store/types/chart-types';
import {KpiChartsServices} from 'src/app/information/kpis/store/services';
import {PalletTotalChart} from 'src/app/information/kpis/charts/ULCalculation/PalletTotalChart';
import {PalletTotalProductChart} from 'src/app/information/kpis/charts/ULCalculation/PalletTotalProductChart';
import {PalletHourTotalProductChart} from 'src/app/information/kpis/charts/ULCalculation/PalletHourTotalProductChart';
import {PalletHourTotalChart} from 'src/app/information/kpis/charts/ULCalculation/PalletHourTotalChart';

export interface ConfectionCalculationProps {
    areaId: string;
    centerId: string;
}
type State = {
    areaId: string | undefined;
    centerId: string | undefined;
    date: Date | Date[] | undefined;
    palletTotal: PalletTotalType | undefined;
    palletTotalProduct: PalletTotalProductType | undefined;
    palletHourTotal: PalletHourTotalType | undefined;
    palletHourTotalProduct: PalletHourTotalProductType | undefined;
    loadingPalletTotalData: boolean;
    loadingPalletTotalProductData: boolean;
    loadingPalletHourTotalData: boolean;
    loadingPalletHourTotalProductData: boolean;
};

const initialState = {
    areaId: undefined,
    centerId: undefined,
    date: undefined,
    palletTotal: {
        palletProduccion: '',
        palletByCentersValues: [],
        palletByCentersLabels: [],
        palletByAreasValues: [],
        palletByAreasLabels: [],
    },
    palletTotalProduct: {
        products: [],
        pallet: [],
    },
    palletHourTotal: {
        hours: [],
        pallet: [],
    },
    palletHourTotalProduct: {
        products: [],
        hours: [],
    },
    loadingPalletTotalData: false,
    loadingPalletTotalProductData: false,
    loadingPalletHourTotalData: false,
    loadingPalletHourTotalProductData: false,
};

type Props = {
    areaId: string;
    centerId: string;
    startDate: string;
    endDate: string;
};

export class ULCalculation extends React.Component<Props, State> {
    state = initialState;

    componentDidMount() {
        this.showData(this.props);
    }

    componentWillReceiveProps(props: Props) {
        this.showData(props);
    }

    showData(props: Props) {
        const {centerId, areaId, startDate, endDate} = props;
        this.setState({loadingPalletTotalData: true});
        this.setState({loadingPalletTotalProductData: true});
        this.setState({loadingPalletHourTotalData: true});
        this.setState({loadingPalletHourTotalProductData: true});

        KpiChartsServices.palletTotal(centerId, areaId, startDate, endDate).then((res: any) => {
            const centersLabel = [];
            const centersValue = [];
            const areasLabel = [];
            const areasValue = [];

            if (res) {
                for (const index in res.data.palletByCenters) {
                    if (index) {
                        centersLabel.push(index);
                        centersValue.push(res.data.palletByCenters[index]);
                    }
                }
                for (const index in res.data.palletByAreas) {
                    if (index) {
                        areasLabel.push(index);
                        areasValue.push(res.data.palletByAreas[index]);
                    }
                }
            }

            const data = {
                palletProduccion: res.data.palletProduccion,
                palletByCentersValues: centersValue,
                palletByCentersLabels: centersLabel,
                palletByAreasValues: areasValue,
                palletByAreasLabels: areasLabel,
            };

            this.setState({palletTotal: data});
            this.setState({loadingPalletTotalData: false});
        });

        KpiChartsServices.palletTotalProduct(centerId, areaId, startDate, endDate).then((res1: any) => {
            const products = [];
            const pallet = [];
            const maxResult = 30;
            let numResult = 0;

            if (res1) {
                for (const index in res1.data) {
                    if (index) {
                        numResult++;
                        if (numResult > maxResult) {
                            break;
                        }
                        products.push(index);
                        pallet.push(res1.data[index]);
                    }
                }
            }

            const data1 = {
                products: products,
                pallet: pallet,
            };

            this.setState({palletTotalProduct: data1});
            this.setState({loadingPalletTotalProductData: false});
        });

        KpiChartsServices.palletHourTotal(centerId, areaId, startDate, endDate).then((res2: any) => {
            const hours = [];
            const pallet = [];

            if (res2) {
                for (const index in res2.data) {
                    if (index) {
                        hours.push(index);
                        pallet.push(res2.data[index]);
                    }
                }
            }

            const data2 = {
                hours: hours,
                pallet: pallet,
            };

            this.setState({palletHourTotal: data2});
            this.setState({loadingPalletHourTotalData: false});
        });

        KpiChartsServices.palletHourTotalProduct(centerId, areaId, startDate, endDate).then((res3: any) => {
            const products = [];
            const hours = [];
            let addHours = true;

            if (res3) {
                for (const productName in res3.data) {
                    if (res3.data) {
                        const dataProduct = res3.data[productName];
                        const productInfo: ProductPalletInfoType = {
                            name: productName,
                            pallet: [],
                        };
                        const palletsHour: number[] = [];
                        for (const hour in dataProduct) {
                            if (dataProduct) {
                                palletsHour.push(dataProduct[hour]);
                                if (addHours) {
                                    hours.push(hour);
                                }
                            }
                        }
                        addHours = false;
                        productInfo.pallet = palletsHour;
                        products.push(productInfo);
                    }
                }
            }

            const data3 = {
                products: products,
                hours: hours,
            };

            this.setState({palletHourTotalProduct: data3});
            this.setState({loadingPalletHourTotalProductData: false});
        });
    }

    public render() {
        return (
            <>
                <h1 className="text-center">Cálculo de origen</h1>
                <WrapFullContainer>
                    <OCard title={'Palés totales'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'20px'}>
                        <PalletTotalChart chartData={this.state.palletTotal} isLoading={this.state.loadingPalletTotalData} />
                    </OCard>
                    <OCard title={'Palés por confección'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'20px'}>
                        {typeof this.state.palletTotalProduct !== 'undefined' &&
                            typeof this.state.palletTotalProduct.products !== 'undefined' &&
                            this.state.palletTotalProduct.products.length > 0 && (
                                <PalletTotalProductChart chartData={this.state.palletTotalProduct} isLoading={this.state.loadingPalletTotalProductData} />
                            )}
                    </OCard>
                    <OCard title={'Palés/tiempo totales'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'20px'}>
                        <PalletHourTotalChart chartData={this.state.palletHourTotal} isLoading={this.state.loadingPalletHourTotalData} />
                    </OCard>
                    <OCard title={'Palés/tiempo por confección'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'20px'}>
                        {typeof this.state.palletHourTotalProduct !== 'undefined' &&
                            typeof this.state.palletHourTotalProduct.hours !== 'undefined' &&
                            this.state.palletHourTotalProduct.hours.length > 0 && (
                                <PalletHourTotalProductChart chartData={this.state.palletHourTotalProduct} isLoading={this.state.loadingPalletHourTotalProductData} />
                            )}
                    </OCard>
                </WrapFullContainer>
            </>
        );
    }
}
