import {ajv} from 'src/utils/ajv';

import {batchScheme} from './schemes/batch';

const batchesResScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['batches'],
  properties: {
    batches: {oneOf: [{type: 'array', items: batchScheme}, {type: 'null'}]},
  },
};

const precompile = ajv.compile(batchesResScheme);
export const batchesValidate = precompile;
