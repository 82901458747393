import * as React from 'react';
import styled from 'styled-components';

import {FestivesControlServices} from 'src/app/administration/festivesControl/store/festivesControl.services';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {TableModal} from 'src/shared/components/Modal';
import {FestiveForm} from './FestiveForm';
import {FestiveControlData} from './store/types';
import {OCard} from 'src/shared/style';
import {ParametersSelectors} from 'src/utils/Parameters/selectors';
import {AppStoreState} from 'src/store';
import {connect} from 'react-redux';

type State = {
    open: boolean;
    selectedFestive: FestiveControlData | undefined;
};

type SProps = {
    sundaysFestive: ReturnType<typeof ParametersSelectors.getSundaysFestive>;
    saturdaysFestive: ReturnType<typeof ParametersSelectors.getSaturdaysFestive>;
};

const initialState: State = {
    open: false,
    selectedFestive: undefined,
};

export class FestivesControlBase extends React.Component<SProps, State> {
    readonly state = initialState;

    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    private handleOpen = () => {
        this.setState({
            open: true,
            selectedFestive: undefined,
        });
    };

    private handleClose = () => {
        this.setState({open: false, selectedFestive: undefined});
    };

    private handleSelect = (festive: FestiveControlData) => {
        this.setState({
            selectedFestive: festive,
            open: true,
        });
    };

    private handleUpdateFestive = () => {
        // Recargar tabla
        this.setState({open: false, selectedFestive: undefined});
        this.useTableHook();
    };

    private columns: ColumnType<{id: string; name: string}> = [
        {
            Header: 'Jornada',
            headerClassName: 'header_cell_string',
            daterange: true,
            dateFormat: true,
            filterable: true,
            width: 260,
            accessor: 'workShift',
            className: 'cell_string',
        },
        {
            Header: 'Centro',
            headerClassName: 'header_cell_string',
            filterable: true,
            width: 260,
            accessor: 'center_name',
            className: 'cell_string',
        },
        {
            Header: 'Descripción',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'description',
            className: 'cell_string',
        },
    ];

    render() {
        const TOTAL_ACCESS = typeof IsInRuleset === 'function' ? IsInRuleset('FESTIVES_TOTAL') : false;
        const {selectedFestive, open} = this.state;
        return IsInRuleset(['FESTIVES_TOTAL', 'FESTIVES_VIEW']) ? (
            <TableWrap>
                <OCard width={'100%'} height={'100%'} contentHeight={'100%'} contentPadding={0} margin={'5px 5px 0 5px'} marginBottom={'10px'}>
                    <ul>
                        <li>Los días festivos sin centro aplica a todos los centros</li>
                        {this.props.saturdaysFestive === '1' && <li>Todos los sábados se consideran festivos</li>}
                        {this.props.sundaysFestive === '1' && <li>Todos los domingos se consideran festivos</li>}
                    </ul>
                </OCard>

                <TableModal title={'Gestión del festivo'} open={open} handleClose={this.handleClose}>
                    <FestiveForm festive={selectedFestive} handleClose={this.handleClose} update={this.handleUpdateFestive} />
                </TableModal>
                <TableComponentWrap>
                    <PaginatedTableComponent
                        columnFormat={this.columns}
                        defaultPageSize={20}
                        service={FestivesControlServices.get}
                        showSizeOptions={true}
                        twoWeeksFilter={true}
                        loading={true}
                        getTableFetchHook={this.getTableHook}
                        createHook={IsInRuleset('FESTIVES_TOTAL') ? this.handleOpen : undefined}
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: any) => ({
                                onClick: () => {
                                    if (TOTAL_ACCESS) {
                                        this.handleSelect(rowInfo.original);
                                    }
                                },
                                style: {cursor: 'pointer'},
                            }),
                        }}
                    />
                </TableComponentWrap>
            </TableWrap>
        ) : null;
    }
}

const TableWrap = styled.div`
    width: 95%;
    height: 100%;
    margin: auto;
`;

const TableComponentWrap = styled.div`
    height: calc(100% - 100px);
`;

export const FestivesControl = connect<SProps, {}, {}, AppStoreState>(
    state => ({
        sundaysFestive: ParametersSelectors.getSundaysFestive(state),
        saturdaysFestive: ParametersSelectors.getSaturdaysFestive(state),
    }),
    {},
)(FestivesControlBase);
