import {guardedFetcher} from 'src/utils/guardedFetcher';

// import {PaginateParams} from 'src/types';

const prefix = 'crud/downtime-categories';

type Options = {id?: string; name?: string};
export const DowntimeCategoriesControlServices = {
  get: () => guardedFetcher.get(`${prefix}`),
  update: ({id, name}: Options) => guardedFetcher.patch(`${prefix}/${id}`, {name}),
  create: ({name}: Options) => guardedFetcher.post(`${prefix}`, {name}),
  delete: ({id}: Options) => guardedFetcher.delete(`${prefix}/${id}`),
};
