export const boxScheme = {
  additionalProperties: false,
  type: 'object',
  required: [
    'id',
    'barcode',
    'finished_at',
    'palletized_at',
    'pallet_id',
    'logisticunit_id',
    'dump_id',
    'area_id',
  ],
  properties: {
    id: {type: 'string'},
    barcode: {type: 'string'},
    finished_at: {type: 'string'},
    palletized_at: {
      oneOf: [{type: 'string'}, {type: 'null'}],
    },
    pallet_id: {type: 'string'},
    logisticunit_id: {type: 'string'},
    dump_id: {type: 'string'},
    area_id: {type: 'string'},
  },
};
