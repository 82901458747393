import {TCTaskResponse} from 'src/app/administration/tasksControl/store/tasksControl.types';
import {
    TCTaskgroupData,
    TCTaskgroupResponse,
} from 'src/app/administration/taskGroupsControl/store/taskGroupsControl.types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/taskgroups';
const taskPrefix = 'crud/tasks';

type Options = {
    id: string;
    name: string;
};
export const TaskGroupsControlServices = {
    get: () => guardedFetcher.get<TCTaskgroupResponse>(prefix),
    create: ({name}: Partial<Options>) =>
        guardedFetcher.post<TCTaskgroupData>(prefix, {name}),
    update: ({id, name}: Options) =>
        guardedFetcher.patch<TCTaskgroupData>(`${prefix}/${id}`, {
            name,
        }),
    delete: ({id}: Partial<Options>) => guardedFetcher.delete(`${prefix}/${id}`),
    toggleTaskgroup: ({taskId, taskgroupId}: {taskId: string; taskgroupId: string}) =>
        guardedFetcher.post(`${prefix}/toggle-relation/task`, {
            taskId,
            taskgroupId,
        }),
    getAllTasksFromTaskgroup: (taskgroupId: string) =>
        guardedFetcher.get<TCTaskResponse>(`${taskPrefix}/taskgroup/${taskgroupId}`),

    // ! selector part
    getAllTaskGroupsFromArea: (areaId: string) =>
        guardedFetcher.get<TCTaskgroupResponse>(`${prefix}/area/${areaId}`),
};
