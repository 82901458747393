import {combineReducers, ReducersMapObject} from 'redux';

// tslint:disable-next-line
// import {taskControlReducer} from 'src/app/administration/taskControl/store/reducers/taskControl.reducer';
// import {areaControlReducer} from 'src/app/administration/areaControl/store/reducers/areaControl.reducer';
import {cardControlReducer} from 'src/app/administration/cardControl/store/reducers';
// import {employeesControlReducer} from 'src/app/administration/employeesControl/store/reducers/employeesControl.reducer';
import {AdministrationStoreState} from 'src/app/administration/export/store/interface';

const reducers: ReducersMapObject<AdministrationStoreState> = {
  cardControl: cardControlReducer,
  // employeesControl: employeesControlReducer,
  // taskControl: taskControlReducer,
  // areaControl: areaControlReducer,
};

export const administrationReducers = combineReducers(reducers);
