import {ChartData, ChartOptions} from 'chart.js';
import React from 'react';
import {Line} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {LaneCostKgDumpedWeekType} from 'src/app/lanemanager/charts/store/types';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {Spinner} from 'src/app/global/spinner';

const chart: ChartData = {
    labels: [],
    datasets: [],
};
const initialState = {chart};

type State = {
    chart: ChartData;
};
type Props = {
    chartData: LaneCostKgDumpedWeekType;
    isLoading: boolean;
};
export class CostKgDumpedChart extends React.Component<Props, State> {
    readonly state = initialState;
    static getDerivedStateFromProps(props: Props) {
        const datasets = [
            {
                label: 'Turno mañana',
                borderColor: '#4f81bd',
                backgroundColor: '#4f81bd',
                data: props.chartData.costKgsDumpedDays1,
                fill: false,
            },
            {
                label: 'Turno tarde',
                borderColor: '#eb4034',
                backgroundColor: '#eb4034',
                data: props.chartData.costKgsDumpedDays2,
                fill: false,
            },
        ] as any;

        return {
            chart: {
                labels: props.chartData.days,
                datasets,
            },
        };
    }
    render() {
        if (this.props.isLoading) {
            return <Spinner isLoading={this.props.isLoading} position={'relative'} />;
        }
        return <Line data={this.state.chart} options={options} />;
    }
}

/***
 * CHART OPTIONS
 */
const options: ChartOptions = {
    responsive: true,
    title: {
        display: false,
        text: 'Euros / kg volcados (semanal)',
    },
    tooltips: {
        mode: 'index',
        intersect: false,
        callbacks: {
            label: (tooltipItem: any) => {
                return tooltipItem && tooltipItem.yLabel ? customFormatNumber(Number(tooltipItem.yLabel)) : '';
            },
        },
    },
    hover: {
        mode: 'nearest',
        intersect: true,
    },
    maintainAspectRatio: false,
    scales: {
        yAxes: [
            {
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: '€/kg volcados',
                },
                ticks: {
                    beginAtZero: true,
                    callback: value => {
                        // you can add your own method here (just an example)
                        return customFormatNumber(Number(value));
                    },
                    //min: 0,
                    //max: 100
                },
            },
        ],
    },
    plugins: {
        datalabels: {
            font: {
                size: 0, // Para que no muestre los valores en la gráfica
            },
        },
    },
};
