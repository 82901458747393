import React from 'react';
import isEqual from 'react-fast-compare';
import Select from 'react-select';
import animated from 'react-select/lib/animated';

import {EmployeesControlServices} from 'src/app/administration/employeesControl/store/employeesControl.services';
import {JobtypeResponseData} from 'src/app/administration/employeesControl/store/types';
import {WrapForm, WrapSaveButton} from 'src/app/administration/export/styled/Wrap';
import {Typography} from '@material-ui/core';
import {CustomButton} from 'src/shared/style';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

const initialState: State = {
  employeesIds: [],
  commonJobtypes: [],
  jobtypes: [],
  idsToAssign: [],
  idsToUnassign: [],
  startingCommonJobtypes: [],
};

type State = Readonly<{
  employeesIds: string[];
  jobtypes: Array<{value: string; label: string}>;
  commonJobtypes: Array<{value: string; label: string}>;
  startingCommonJobtypes: Array<{value: string; label: string}>;
  idsToAssign: string[];
  idsToUnassign: string[];
}>;

type Props = {
  employees: Array<{employeeId: string; jobtypes: string[]}>;
  handleClose(): void;
  handleUpdate(): void;
  allJobtypes: JobtypeResponseData[];
};

export class MassJobtypesForm extends React.Component<Props, State> {
  readonly state = initialState;

  componentDidMount() {
    this.parseJobtypes();
  }

  private parseJobtypes = () => {
    const {allJobtypes, employees} = this.props;
    if (!allJobtypes || !employees) return;
    const mapped = allJobtypes.map(jt => ({value: jt.id, label: jt.name}));
    this.setState(() => ({jobtypes: mapped}), this.mapCommonJobtypes);
  };

  private mapCommonJobtypes = () => {
    if (this.props.employees.length) {
      const commonJobtypes = this.state.jobtypes.filter(jt =>
        this.props.employees.every(
          e => e.jobtypes && e.jobtypes.length > 0 && e.jobtypes.includes(jt.value),
        ),
      );
      this.setState({startingCommonJobtypes: commonJobtypes, commonJobtypes});
    }
  };

  render() {
    const {jobtypes, commonJobtypes} = this.state;
    return (
      <WrapForm margin={'5px 10px'}>
        <Typography
          component="div"
          style={{overflow: 'inherit', minHeight: 250, width: 300}}>
          <Select
            backspaceRemovesValue={false}
            isMulti={true}
            closeMenuOnSelect={false}
            isClearable={false}
            // @ts-ignore
            components={animated()}
            options={jobtypes}
            placeholder={'Roles comunes de empleados...'}
            value={commonJobtypes}
            onChange={sel => this.setState({commonJobtypes: sel as any})}
          />
        </Typography>
        <WrapSaveButton>
          <CustomButton
            // bgcolor={'#34baa2'}
            onClick={this.handleSend}
            width={'80%'}
            margin="auto">
            Guardar selección
          </CustomButton>
        </WrapSaveButton>
      </WrapForm>
    );
  }

  private handleSend = async () => {
    if (!this.props.employees.length) return;
    const employeesIds = this.props.employees.map(e => e.employeeId);
    const {commonJobtypes, startingCommonJobtypes} = this.state;
    const newAssigned = commonJobtypes.filter(
      c => !startingCommonJobtypes.find(ajt => isEqual(c, ajt)),
    );
    const newUnassigned = startingCommonJobtypes.filter(
      ajt => !commonJobtypes.find(c => isEqual(c, ajt)),
    );
    if (newAssigned.length) {
      await fetchWithFeedback(
        EmployeesControlServices.mAssignJobtypes({
          employeeIds: employeesIds,
          jobtypeIds: newAssigned.map(ea => ea.value),
        }),
        {showMessage: true},
      );
    }
    if (newUnassigned.length) {
      await fetchWithFeedback(
        EmployeesControlServices.mDesassignJobtypes({
          employeeIds: employeesIds,
          jobtypeIds: newUnassigned.map(ea => ea.value),
        }),
        {showMessage: true},
      );
    }
    this.props.handleUpdate();
  };
}
