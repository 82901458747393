import {Action, ActionRich} from 'src/types';

export enum LoginActionTypes {
  login = '[Login] Log in',
  logout = '[Login] Logging out',
}

export type LoginActionsLogIn = ActionRich<typeof LoginActionTypes.login, LogInPayload>;

export type LoginActionsLogOut = Action<typeof LoginActionTypes.logout>;

export type LoginActions = LoginActionsLogIn | LoginActionsLogOut;

export type LogInPayload = {
  username: string;
  name: string;
  surname: string;
  userId: string;
  employeeId: string;
};
