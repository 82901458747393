import * as React from 'react';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {AlertServices} from './store/alert.services';
import {BatchesExceededKgBatchType} from './store/types';
import {AlertExceededKgBatchModal} from './AlertExceededKgBatchModal';

export interface Props {
    laneId: string;
    employeeId: string;
}

type State = {
    openAlertExceededKgBatch: boolean;
    exceededBatches: BatchesExceededKgBatchType[] | undefined;
};

const initialState: Readonly<State> = {
    openAlertExceededKgBatch: false,
    exceededBatches: undefined,
};

export class AlertExceededKgBatch extends React.Component<Props, State> {
    readonly state = initialState;

    async componentDidMount() {
        this.fetchAlertExceededKgBatches();
        setInterval(() => {
            if (this.state.openAlertExceededKgBatch === false) {
                this.fetchAlertExceededKgBatches();
            }
        }, 120000);
    }

    private fetchAlertExceededKgBatches = async () => {
        const isAdmin = IsInRuleset(['ADMIN']) ? 1 : 0;
        const isAllowed = IsInRuleset(['ALERTS_EXCEEDED_KG_BATCHES']) ? 1 : 0;

        if (this.props.laneId && (isAdmin || isAllowed)) {
            const alertExceededKgBatches = await fetchWithFeedback(AlertServices.exceededKgBatch(this.props.laneId, isAdmin));

            if (alertExceededKgBatches) {
                this.setState({
                    exceededBatches: alertExceededKgBatches.batchesAlerts,
                });

                if (alertExceededKgBatches.batchesAlerts.length !== 0 && !this.state.openAlertExceededKgBatch) this.toggleAlertExceededKgBatches();
            }
        }
    };

    private toggleAlertExceededKgBatches = () => {
        this.setState({
            openAlertExceededKgBatch: !this.state.openAlertExceededKgBatch,
        });
    };

    render() {
        return (
            <>
                <AlertExceededKgBatchModal
                    openAlertExceededKgBatch={this.state.openAlertExceededKgBatch}
                    exceededBatches={this.state.exceededBatches}
                    handleClose={() => this.setState({openAlertExceededKgBatch: false})}
                />
            </>
        );
    }
}
