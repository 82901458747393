import * as React from 'react';
import {connect} from 'react-redux';

import {TableModal} from 'src/shared/components/Modal';
import {CustomButton, CustomTextField, CustomDropdown} from 'src/shared/style';
import {NonProductiveTypeResponse} from './store/nonProductiveTimes.types';
import {InputLabel} from '@material-ui/core';

const NOASIGNADA = 'noAsignada'; // Constante para cuando no se asigna tarea

export interface Props {
  open: boolean;
  tasksList: Array<{
    id: any;
    name: any;
  }>;
  typeList: NonProductiveTypeResponse[];
  center_id: string;
  center_name: string;
  name: string;
  start_at: string;
  startValid: boolean;
  duration: number;
  durationValid: boolean;
  task_id: string;
  non_productive_types_id: string;
  hideModal: () => void;
  createNonProductiveTime: (
    centerId: string,
    name: string,
    startAt: string,
    duration: number,
    taskId: string,
    nonProductiveTypesId: string,
  ) => void;
}

type State = {
  open: boolean;
  tasksList: Array<{
    id: any;
    name: any;
  }>;
  typeList: NonProductiveTypeResponse[];
  center_id: string;
  center_name: string;
  non_productive_time_id: string;
  name: string;
  start_at: string;
  startValid: boolean;
  duration: number;
  durationValid: boolean;
  task_id: string;
  non_productive_types_id: string;
};

const initialState: State = {
  open: false,
  tasksList: [],
  typeList: [],
  center_id: '',
  center_name: '',
  non_productive_time_id: '',
  name: '',
  start_at: '',
  startValid: true,
  duration: 0,
  durationValid: true,
  task_id: '',
  non_productive_types_id: '',
};

export class NonProductivityModal extends React.Component<Props, State> {
  readonly state = initialState;

  private handleChange: HandleNamedChange<State> = name => e => {
    let value = e.target.value;

    if (name === 'duration' && value < 0) value = 0;
    this.setState({[name]: value} as Pick<State, keyof State>);
  };

  componentWillReceiveProps(nextProps: any) {
    this.setState({open: nextProps.open});
    this.setState({center_id: nextProps.center_id});
    this.setState({center_name: nextProps.center_name});
    this.setState({non_productive_time_id: nextProps.non_productive_time_id});
    this.setState({name: nextProps.name});
    this.setState({start_at: nextProps.start_at});
    this.setState({startValid: nextProps.startValid});
    this.setState({duration: nextProps.duration});
    this.setState({durationValid: nextProps.name});
    this.setState({task_id: nextProps.task_id});
    this.setState({non_productive_types_id: nextProps.non_productive_types_id});
  }

  private handleChecking = (
    centerId: string,
    name: string,
    startAt: string,
    duration: number,
    taskId: string,
    nonProductiveTypesId: string,
  ) => {
    this.setState({
      startValid: !!startAt,
      durationValid: !!duration,
    });

    if (this.state.startValid && this.state.durationValid) {
      this.props.createNonProductiveTime(
        centerId,
        name,
        startAt,
        duration,
        taskId,
        nonProductiveTypesId,
      );
    }
  };

  render() {
    const {
      open,
      center_name,
      name,
      start_at,
      startValid,
      duration,
      durationValid,
      task_id,
      non_productive_types_id,
    } = this.state;

    return (
      <>
        <TableModal
          title={`${center_name} : ${name}`}
          open={open}
          handleClose={this.props.hideModal}>
          <div
            style={{
              maxWidth: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <InputLabel htmlFor="start_at">Hora de Inicio</InputLabel>
            <CustomTextField
              margin={'0 auto 10px auto'}
              width={'100%'}
              value={start_at}
              error={!startValid}
              name="start_at"
              variant={'outlined'}
              type="time"
              onChange={this.handleChange('start_at')}
            />
            <CustomTextField
              margin={'0 auto 10px auto'}
              width={'100%'}
              value={duration}
              error={!durationValid}
              label="Duración en minutos"
              name="duration"
              variant={'outlined'}
              type="number"
              onChange={this.handleChange('duration')}
            />
            <CustomDropdown
              title={'Tarea'}
              margin={'5px 0'}
              data={this.props.tasksList}
              value={task_id ? task_id : NOASIGNADA}
              dropdownName={'task'}
              handleChoose={e => this.setState({task_id: e.target.value})}
            />
            <CustomDropdown
              title={'Tipo'}
              margin={'5px 0'}
              data={this.props.typeList}
              value={non_productive_types_id}
              dropdownName={'type'}
              handleChoose={e => this.setState({non_productive_types_id: e.target.value})}
            />
          </div>
          <CustomButton
            width={'100%'}
            onClick={() => {
              this.handleChecking(
                this.state.center_id,
                this.state.name,
                this.state.start_at,
                this.state.duration,
                this.state.task_id,
                non_productive_types_id,
              );
            }}>
            Guardar Tiempo no productivo
          </CustomButton>
        </TableModal>
      </>
    );
  }
}

export const NonProductivityModalConnected = connect(
  null,
  null,
)(NonProductivityModal);
