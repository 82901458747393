import {fetcher} from 'src/global/fetcher';
import {
    KgTotalType,
    KgTotalProductType,
    KgHourTotalType,
    KgHourTotalProductType,
    PalletTotalType,
    PalletTotalProductType,
    PalletHourTotalType,
    PalletHourTotalProductType,
    HourTotalType,
    EmployeesTotalTasksType,
    HourPieGroupedTotalType,
} from './types/chart-types';

const prefix = 'kpis';

export const KpiChartsServices = {
    kg: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<KgTotalType>(`${prefix}/kg`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),

    kgTotal: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<KgTotalType>(`${prefix}/kg/total`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),

    kgTotalProduct: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<KgTotalProductType>(`${prefix}/kg/total-product`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),
    kgHourTotal: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<KgHourTotalType>(`${prefix}/kg/total-hour`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),
    kgHourTotalProduct: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<KgHourTotalProductType>(`${prefix}/kg/total-product-hour`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),

    //cálculo de origen
    palletTotal: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<PalletTotalType>(`${prefix}/pallet/total`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),
    palletTotalProduct: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<PalletTotalProductType>(`${prefix}/pallet/total-product`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),
    palletHourTotal: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<PalletHourTotalType>(`${prefix}/pallet/total-hour`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),
    palletHourTotalProduct: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<PalletHourTotalProductType>(`${prefix}/pallet/total-product-hour`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),

    hourPieGroupedTotal: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<HourPieGroupedTotalType>(`${prefix}/hour/pie-grouped-total`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),

    hourPieIndirectAndDirect: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<HourPieGroupedTotalType>(`${prefix}/hour/pie-indirect-and-direct`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),

    hourTotal: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<HourTotalType>(`${prefix}/hour/total`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),

    employeesTotalTasks: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<EmployeesTotalTasksType>(`${prefix}/hour/employees`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),

    //cálculo de costes
    costTotalMaking: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<PalletTotalType>(`${prefix}/cost/total-making`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),

    costTotalExpedition: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<PalletTotalType>(`${prefix}/cost/total-expedition`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),

    costTotalHour: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<PalletTotalType>(`${prefix}/cost/total-hour`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),

    hourTotalDowntime: (centerId: string, areaId: string, startDate: string, endDate: string) =>
        fetcher.get<HourTotalType>(`${prefix}/hour/total-downtime`, {
            params: {
                center_id: centerId,
                area_id: areaId,
                start_day: startDate,
                finish_day: endDate,
            },
        }),
};
