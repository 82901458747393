import axios from 'axios';

// import Fetch from 'src/utils/fetch';
import {API_URL} from 'src/global/api';

const prefix = '/presence';

// export interface GenericResponse {
//   status: number;
//   resHeaders: object;
//   resBody: any;
// }
export const logInDeviceService = (cardId: string) => {
  return axios.post(API_URL(`${prefix}/identify`), {
    device_id: '17142D40-0BD5-11E9-89BB-C93872DB163C',
    cardcode: cardId,
  });
};

export const changeTaskService = (userId: string, taskId: string) =>
  axios.post(API_URL(`${prefix}/register-task`), {
    device_id: '17142D40-0BD5-11E9-89BB-C93872DB163C',
    employee_id: userId,
    task_id: taskId,
  });

export const getAllTasksService = () => axios.get(API_URL(`${prefix}/registered-tasks`));
