import {ChartData, ChartOptions} from 'chart.js';
import React from 'react';
import {Bar} from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {Spinner} from 'src/app/global/spinner';

// import {fetchWithFeedback} from 'src/global/fetcher';
// import {PerformanceServices} from 'src/app/lanemanager/performance/store/services';

const chart: ChartData = {
    labels: [],
    datasets: [],
};
const initialState = {chart};

type State = {
    chart: ChartData;
};

type Props = {
    chartData: any;
    isLoading: boolean;
};

export class PalletHourTotalProductChart extends React.Component<Props, State> {
    readonly state = initialState;
    static getDerivedStateFromProps(props: Props) {
        const datasets: any = [];
        for (const index in props.chartData.products) {
            if (props.chartData.products[index]) {
                //const newKg = Object.assign([], props.chartData.products[index].kg, { [index]: replacementItem });
                const newPallet = props.chartData.products[index].pallet.map((i: number) => (i === 0 ? '' : i));
                datasets.push({
                    label: props.chartData.products[index].name,
                    //borderColor: this.getRandomColor(),
                    backgroundColor: PalletHourTotalProductChart.getRandomColor(),
                    data: newPallet,
                    fill: true,
                });
            }
        }

        const chartShow = {
            labels: props.chartData.hours,
            datasets,
        };

        return {
            chart: chartShow,
        };
    }

    static getRandomColor() {
        const letters = '0123456789ABCDEF'.split('');
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    render() {
        if (this.props.isLoading) {
            return <Spinner isLoading={this.props.isLoading} position={'relative'} />;
        }
        return <Bar data={this.state.chart} options={options} />;
    }
}

/***
 * CHART OPTIONS
 */
const options: ChartOptions = {
    legend: {
        display: false,
    },
    responsive: true,
    title: {
        display: false,
        text: 'Tendencia de palés/hora',
    },
    tooltips: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
            label: (tooltipItem: any, data: any) => {
                return tooltipItem && tooltipItem.yLabel ? data.datasets[tooltipItem.datasetIndex].label + ':' + customFormatNumber(Number(tooltipItem.yLabel)) : '';
            },
        },
    },
    hover: {
        mode: 'nearest',
        intersect: true,
    },
    maintainAspectRatio: false,
    scales: {
        xAxes: [
            {
                stacked: true,
                display: true,
                scaleLabel: {
                    display: true,
                    // labelString: 'Month',
                },
            },
        ],
        yAxes: [
            {
                stacked: true,
                display: true,
                scaleLabel: {
                    display: true,
                    // labelString: 'Value',
                },
            },
        ],
    },
    plugins: {
        datalabels: {
            display: false,
            //formatter: (value: any) => {
            //    return customFormatNumber(value);
            //},
        },
    },
};
