import {push} from 'connected-react-router';
import React from 'react';
import {connect} from 'react-redux';
import {Switch} from 'react-router';
import styled from 'styled-components';

import {TasksByEmployee} from 'src/app/information/reports/TasksByEmployee';
import {TasksTimeByArea} from 'src/app/information/reports/TasksTimeByArea';
import {TimeByWorkshift} from 'src/app/information/reports/TimeByWorkshift';
import {KpisReports} from 'src/app/information/kpis/KpisReports';
import {StatusBar} from 'src/app/statusbar/StatusBar';
import {AreaShifts} from 'src/app/information/areashifts/AreaShifts';
import {Boxes} from 'src/app/information/boxes/Boxes';
import {Employees} from 'src/app/information/employees/Employees';
import {PalletsList} from 'src/app/information/pallets/PalletsList';
import {EmployeesWorkingNow} from 'src/app/information/reports/EmployeesWorkingTow';
import {TabContainerNoTabs} from 'src/app/lanemanager/Wrap';
import {createStyles, withStyles, Theme} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TimeIcon from '@material-ui/icons/AccessTime';
import PalletIcon from '@material-ui/icons/Dashboard';
import EmployeeTaskIcon from '@material-ui/icons/HowToReg';
import AreaShiftsIcon from '@material-ui/icons/Layers';
import WorkingNowIcon from '@material-ui/icons/PeopleOutline';
import TasksListIcon from '@material-ui/icons/ViewList';
import BoxIcon from '@material-ui/icons/WebAsset';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {GuardSingleAccessRoute} from 'src/global/authorization/GuardAuthorizationRoute';
import {AppStoreState} from 'src/store';
import {RouteHelpers} from 'src/utils/routerHelpers';
import {ProdPartsList} from 'src/app/information/prodparts/ProdpartsList';
import {TimeByArea} from 'src/app/information/reports/TimeByArea';
import {PalletsInfo} from './reports/PalletsInfo';
import {BatchesInfo} from './reports/BatchesInfo';

type SProps = {pathname: string};
type DProps = {push(path: string): void};
type Props = {
    classes: {
        root: string;
    };
};

type State = {
    routeTitle: string;
    generic: boolean;
};
const initialState: State = {routeTitle: '', generic: true};

const styles = ({palette}: Theme) =>
    createStyles({
        root: {
            backgroundColor: palette.background.paper,
            padding: 0,
            height: 'calc(100% - 47px)',
            width: '100%',
        },
    });

export class InformationIndexPageBase extends React.Component<Props & SProps & DProps, State> {
    readonly state = initialState;
    componentDidMount() {
        this.findRoute();
    }

    componentWillReceiveProps(nextProps: SProps) {
        this.findRoute(nextProps.pathname);
    }

    render() {
        const {classes} = this.props;
        const {generic} = this.state;
        return (
            <>
                <StatusBar title={this.state.routeTitle} />
                <div className={classes.root}>
                    <TabContainerNoTabs>
                        <Switch>
                            <GuardSingleAccessRoute
                                accessSet={['KPIS_CONTROL_TOTAL', 'KPIS_VIEW']}
                                exact={true}
                                path="/information/kpis"
                                component={KpisReports}
                            />
                            <GuardSingleAccessRoute accessSet={['REPORTS_EMPLOYEES_WORKING_VIEW']} exact={true} path="/information/working-now" component={EmployeesWorkingNow} />
                            <GuardSingleAccessRoute
                                accessSet={['REPORTS_REGISTERED_TASKS_EXCEL', 'REPORTS_REGISTERED_TASKS_VIEW']}
                                exact={true}
                                path="/information/personnel"
                                component={Employees}
                            />
                            <GuardSingleAccessRoute
                                accessSet={['REPORTS_TASKS_BY_EMPLOYEE_EXCEL', 'REPORTS_TASKS_BY_EMPLOYEE_VIEW']}
                                exact={true}
                                path="/information/tasks-employee"
                                component={TasksByEmployee}
                            />
                            <GuardSingleAccessRoute
                                accessSet={['REPORTS_WORKSHIFT_TIMES_EXCEL', 'REPORTS_WORKSHIFT_TIMES_VIEW']}
                                exact={true}
                                path="/information/workshift-times"
                                component={TimeByWorkshift}
                            />
                            <GuardSingleAccessRoute
                                accessSet={['REPORTS_TASKS_TIME_VIEW']}
                                exact={true}
                                path="/information/area-times"
                                component={TimeByArea}
                            />
                            <GuardSingleAccessRoute
                                accessSet={['REPORTS_TASKS_TIME_PER_AREA_EXCEL', 'REPORTS_TASKS_TIME_PER_AREA_VIEW']}
                                exact={true}
                                path="/information/workshift-task-area"
                                component={TasksTimeByArea}
                            />

                            <GuardSingleAccessRoute
                                accessSet={['REPORTS_AREASHIFTS_EXCEL', 'REPORTS_AREASHIFTS_VIEW']}
                                exact={true}
                                path="/information/areashifts"
                                component={AreaShifts}
                            />
                            <GuardSingleAccessRoute
                                accessSet={['REPORTS_PALLETS_VIEW', 'REPORTS_PALLETS_VIEW_EXCEL']}
                                exact={true}
                                path="/information/pallets"
                                component={PalletsList}
                            />
                            <GuardSingleAccessRoute accessSet={['REPORTS_BOXES_VIEW']} exact={true} path="/information/boxes" component={Boxes} />
                            <GuardSingleAccessRoute accessSet={['REPORTS_PRODPARTS_VIEW']} exact={true} path="/information/prodparts" component={ProdPartsList} />
                            <GuardSingleAccessRoute
                                accessSet={['REPORTS_PALLETS_INFO_EXCEL', 'REPORTS_PALLETS_INFO_VIEW']}
                                exact={true}
                                path="/information/pallets-info"
                                component={PalletsInfo}
                            />
                            <GuardSingleAccessRoute
                                accessSet={['REPORTS_BATCHES_INFO_EXCEL', 'REPORTS_BATCHES_INFO_VIEW']}
                                exact={true}
                                path="/information/batches-info"
                                component={BatchesInfo}
                            />
                        </Switch>
                        {generic && (
                            <WrapList>
                                <List component="nav">
                                    {IsInRuleset('REPORTS_EMPLOYEES_WORKING_VIEW') && (
                                        <ListItem
                                            button={true}
                                            component={'a'}
                                            onClick={e => {
                                                this.props.push('/information/working-now');
                                                e.preventDefault();
                                            }}
                                            href="/information/working-now"
                                        >
                                            <ListItemIcon>
                                                <WorkingNowIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Trabajando actualmente" />
                                        </ListItem>
                                    )}
                                    {IsInRuleset(['REPORTS_REGISTERED_TASKS_EXCEL', 'REPORTS_REGISTERED_TASKS_VIEW']) && (
                                        <ListItem
                                            button={true}
                                            component={'a'}
                                            onClick={e => {
                                                this.props.push('/information/personnel');
                                                e.preventDefault();
                                            }}
                                            href="/information/personnel"
                                        >
                                            <ListItemIcon>
                                                <TasksListIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Registro de tareas" />
                                        </ListItem>
                                    )}
                                    {IsInRuleset(['REPORTS_TASKS_BY_EMPLOYEE_EXCEL', 'REPORTS_TASKS_BY_EMPLOYEE_VIEW']) && (
                                        <ListItem
                                            button={true}
                                            component={'a'}
                                            onClick={e => {
                                                this.props.push('/information/tasks-employee');
                                                e.preventDefault();
                                            }}
                                            href="/information/tasks-employee"
                                        >
                                            <ListItemIcon>
                                                <EmployeeTaskIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Tareas por empleado" />
                                        </ListItem>
                                    )}
                                    {IsInRuleset(['REPORTS_WORKSHIFT_TIMES_EXCEL', 'REPORTS_WORKSHIFT_TIMES_VIEW']) && (
                                        <ListItem
                                            button={true}
                                            component={'a'}
                                            onClick={e => {
                                                this.props.push('/information/workshift-times');
                                                e.preventDefault();
                                            }}
                                            href="/information/workshift-times"
                                        >
                                            <ListItemIcon>
                                                <TimeIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Tiempos por turno" />
                                        </ListItem>
                                    )}
                                    {IsInRuleset(['REPORTS_TASKS_TIME_PER_AREA_EXCEL', 'REPORTS_TASKS_TIME_PER_AREA_VIEW']) && (
                                        <ListItem
                                            button={true}
                                            component={'a'}
                                            onClick={e => {
                                                this.props.push('/information/workshift-task-area');
                                                e.preventDefault();
                                            }}
                                            href="/information/workshift-task-area"
                                        >
                                            <ListItemIcon>
                                                <TimeIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Tiempos por area y tarea" />
                                        </ListItem>
                                    )}
                                    {IsInRuleset(['REPORTS_AREASHIFTS_EXCEL', 'REPORTS_AREASHIFTS_VIEW']) && (
                                        <ListItem
                                            button={true}
                                            component={'a'}
                                            onClick={e => {
                                                this.props.push('/information/areashifts');
                                                e.preventDefault();
                                            }}
                                            href="/information/areashifts"
                                        >
                                            <ListItemIcon>
                                                <AreaShiftsIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Todos los turnos" />
                                        </ListItem>
                                    )}
                                    {IsInRuleset() && (
                                        <ListItem
                                            button={true}
                                            component={'a'}
                                            onClick={e => {
                                                this.props.push('/information/pallets');
                                                e.preventDefault();
                                            }}
                                            href="/information/pallets"
                                        >
                                            <ListItemIcon>
                                                <PalletIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Palés registrados" />
                                        </ListItem>
                                    )}
                                    {IsInRuleset('REPORTS_BOXES_VIEW') && (
                                        <ListItem
                                            button={true}
                                            component={'a'}
                                            onClick={e => {
                                                this.props.push('/information/boxes');
                                                e.preventDefault();
                                            }}
                                            href="/information/boxes"
                                        >
                                            <ListItemIcon>
                                                <BoxIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Cajas registradas" />
                                        </ListItem>
                                    )}
                                </List>
                            </WrapList>
                        )}
                    </TabContainerNoTabs>
                </div>
            </>
        );
    }

    private findRoute = (pathname: string = this.props.pathname) => {
        const path = pathname.split('/')[2];
        const routeTitle = this.getTitle(path);
        let generic = false;
        if (routeTitle === 'Sección de información') generic = true;
        this.setState({routeTitle, generic});
    };

    private getTitle = (path: string) => {
        switch (path) {
            case 'kpis':
                return 'Gráficas KPIs';
            case 'tasks-employee':
                return 'Tareas por empleado';
            case 'working-now':
                return 'Trabajando actualmente';
            case 'workshift-times':
                return 'Tiempos por turno';
            case 'workshift-task-area':
                return 'Tiempos por area y tarea';
            case 'personnel':
                return 'Registro de tareas';
            case 'pallets':
                return 'Palés registrados';
            case 'area-times':
                return 'Tiempos por area';
            case 'prodparts':
                return 'Todos los partes de producción';
            case 'boxes':
                return 'Cajas registradas';
            case 'areashifts':
                return 'Todos los turnos';
            case 'pallets-info':
                return 'Palés Info';
            case 'batches-info':
                return 'Origen Info';
            default:
                return 'Sección de información';
        }
    };
}

export const InformationIndexPage = connect<SProps, DProps, Props, AppStoreState>(state => ({pathname: RouteHelpers.getLocation(state)}), {push})(
    withStyles(styles)(InformationIndexPageBase),
);

const WrapList = styled.div`
    width: 400px;
    margin: auto;
    a {
        display: flex;
        text-decoration: inherit;
        color: inherit;
    }
`;
