import invoke from 'lodash/invoke';
import React, {Component, FunctionComponent} from 'react';
import styled from 'styled-components';
import {withStyles, Card, CardContent, Typography} from '@material-ui/core';

type Props = {ptables: any[]};
type State = {ptables: any[]};

export class PtablesMesh extends Component<Props, State> {
  readonly state: Readonly<State> = {
    ptables: [],
  };
  componentWillMount() {
    this.setState({ptables: this.props.ptables});
    console.log('RECIVING PTABLES>>>', this.props.ptables);
  }
  componentWillReceiveProps({ptables}: Props) {
    this.setState({ptables});
  }
  render() {
    const {ptables} = this.state;
    return (
      <Container>
        <Div>
          {invoke(ptables, 'map', (ptable: any, index: number) => {
            let ptablePrefix = '';
            const {pallet} = ptable;
            if (ptable.assigned_to) ptablePrefix = 'A: ';
            if (ptable.reserved_to && !ptable.assigned_to) ptablePrefix = 'R: ';
            let percentage = 0;
            if (pallet) {
              if (pallet.boxes_total && pallet.boxes_count)
                percentage = pallet.boxes_count / (pallet.boxes_total / 100);
            }
            return (
              <PtableCard
                key={'ptable' + index}
                color={ptable.color}
                percentage={percentage}>
                <Typography
                  variant="h6"
                  style={{
                    zIndex: 1,
                    textAlign: 'center',
                    color: 'black',
                  }}>
                  {ptablePrefix}
                  {ptable.name}
                  <div style={{fontSize: 25}}>
                    {pallet && pallet.boxes_total && pallet.boxes_count
                      ? pallet.boxes_count + ' / ' + pallet.boxes_total
                      : null}
                  </div>
                </Typography>
              </PtableCard>
            );
          })}
        </Div>
      </Container>
    );
  }
}

const Div = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  height: 100%;
`;

const Container = styled.div`
  height: 94%;
  width: 100%;
`;

type PtableCardProps = {
  color: string;
  percentage: number;
};
const PtableCard: FunctionComponent<PtableCardProps> = ({
  color,
  percentage,
  children,
}) => {
  const StyledCard = withStyles({
    root: {
      height: '100%',
      position: 'relative',
    },
  })(Card);
  return (
    <div
      style={{
        padding: 3,
        position: 'relative',
      }}>
      <StyledCard>
        <CardContent
          style={{
            width: '100%',
            height: '100%',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: `inset 0px 0px 0px 7px ${color}`,
          }}>
          {children}
        </CardContent>
        <div
          style={{
            position: 'absolute',
            backgroundColor: color || '#bbbbbb',
            height: '100%',
            width: `${percentage}%`,
            top: 0,
          }}
        />
      </StyledCard>
    </div>
  );
};
