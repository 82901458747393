import * as React from 'react';

import {withStyles} from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';

const headerStyles = {
  header: {
    width: '55%',
  },
};
export const CustomHeader = withStyles(headerStyles)((props: any) => {
  return (
    <CardHeader
      className={props.classes.header}
      title={props.title}
      style={{fontSize: 3}}
    />
  );
});
