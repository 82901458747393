import {merchandiseScheme} from './merchandise';
import {providerScheme} from './provider';

export const batchScheme = {
  additionalProperties: false,
  type: 'object',
  required: [
    'id',
    'merchandise',
    'provider',
    'arrival_at',
    'erp_batch_code',
    'total_kg',
    'total_ul',
    'total_boxes',
    'state_batch',
    'allowed_customers',
    'quality_protocols',
  ],
  properties: {
    id: {type: 'string'},
    erp_batch_code: {type: 'number'},
    total_ul: {type: 'number'},
    total_boxes: {type: 'number'},
    merchandise: merchandiseScheme,
    provider: providerScheme,
    arrival_at: {type: 'string'},
    total_kg: {type: 'number', minimum: 0},
    state_batch: {
      type: 'string',
    },
    allowed_customers: {
      oneOf: [{type: 'array', items: {type: 'string'}}, {type: 'null'}],
    },
    quality_protocols: {
      oneOf: [{type: 'string'}, {type: 'null'}],
    },
  },
};

export const batchSimpleScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['erp_batch_code', 'boxes'],
  properties: {
    id: {type: 'string'},
    erp_batch_code: {type: 'string'},
    arrival_at: {type: 'string'},
    boxes: {oneOf: [{type: 'string'}, {type: 'number'}]},
  },
};

// export const batchScheme = {
//   additionalProperties: false,
//   type: 'object',
//   required: [
//     'id',
//     'merchandise',
//     'provider',
//     'arrival_at',
//     'total_kg',
//     'total_ul',
//     'total_boxes',
//     'state_batch',
//     'allowed_customers',
//     'quality_protocols',
//   ],
//   properties: {
//     id: { type: 'string' },
//     erp_batch_code: { type: 'number' },
//     total_ul: { type: 'number' },
//     total_boxes: { type: 'number' },
//     merchandise: merchandiseScheme,
//     provider: providerScheme,
//     arrival_at: { type: 'string' },
//     total_kg: { type: 'number', minimum: 0 },
//     state_batch: {
//       type: 'string',
//       // oneOf: ['dumped', 'ongoing', 'batched'],
//     },
//     allowed_customers: {
//       oneOf: [{ type: 'array', items: { type: 'string' } }, { type: 'null' }],
//     },
//     quality_protocols: {
//       oneOf: [{ type: 'string' }, { type: 'null' }],
//     },
//   },
// };
