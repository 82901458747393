import {AppStoreState} from 'src/store';

// import {AppStoreState} from 'src/store';

export const DowntimeSelectors = {
  getDowntimes: (state: AppStoreState) => state.lanemanagement.downtime.downtimes,
  getDowntimesFiltered: (state: AppStoreState) => {
    const {tableFilterCriteria, downtimes} = state.lanemanagement.downtime;
    let filtered = downtimes;
    if (tableFilterCriteria === 'active')
      filtered = downtimes.filter(dt => !dt.finished_at);
    if (tableFilterCriteria === 'inactive')
      filtered = downtimes.filter(dt => dt.finished_at);
    return filtered.map(dt => ({
      ...dt,
      color: dt.finished_at ? '#727272' : '#FF0000',
      active: dt.finished_at ? 'INACTIVA' : 'ACTIVA',
    }));
  },
  getDowntimesFilterState: (state: AppStoreState) =>
    state.lanemanagement.downtime.tableFilterCriteria,
  getCategoriesAndCauses: (state: AppStoreState) => ({
    categories: state.lanemanagement.downtime.categories,
    causes: state.lanemanagement.downtime.causes,
  }),
};
