import * as React from 'react';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Cancel from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import {CustomListItem} from './CustomListItem';
import {CustomModal, ModalHeader} from './Wrap';
import {LaneProdPartState, LanePtableState} from 'src/app/lanemanager/lane/store/types';

export interface ChooseTableProps {
    pts: LanePtableState[];
    pp: LaneProdPartState;
    handleAdd: (payload: any) => () => void;
    handleReserve: (payload: any) => () => void;
    handleTransfer: (payload: any) => () => void;
    open: boolean;
    toggle: () => void;
}

export class ChooseTable extends React.Component<ChooseTableProps> {
    render() {
        if (this.props.open === undefined) return <div>No hay mesas de paletizado disponibles...</div>;
        return (
            <CustomModal open={this.props.open} toggle={this.props.toggle}>
                <ModalHeader>
                    <ListSubheader>
                        {'Lista de Mesas de la Línea (' + this.props.pp.order_id + ' - ' + this.props.pp.customer.name + ' - ' + this.props.pp.variety + ')'}
                    </ListSubheader>
                    <Button style={{display: 'flexEnd', height: '15', width: '15'}} onClick={this.props.toggle}>
                        <Cancel />
                    </Button>
                </ModalHeader>

                <List style={{height: '410px', marginBottom: '10px', overflowX: 'auto'}}>
                    {this.props.pts &&
                        this.props.pts.length > 0 &&
                        this.props.pts.map((pt, i) => (
                            <CustomListItem
                                key={i}
                                pt={pt}
                                pp={this.props.pp}
                                add={
                                    typeof this.props.handleAdd === 'function' &&
                                    this.props.handleAdd({
                                        prodpart: this.props.pp.id,
                                        erpCode: this.props.pp.erp_prodpart_code,
                                        pt
                                    })
                                }
                                reserve={
                                    typeof this.props.handleReserve === 'function' &&
                                    this.props.handleReserve({
                                        prodpart: this.props.pp.id,
                                        erpCode: this.props.pp.erp_prodpart_code,
                                        pt,
                                    })
                                }
                                transfer={
                                    typeof this.props.handleTransfer === 'function' &&
                                    this.props.handleTransfer({
                                        prodpart: this.props.pp.id,
                                        erpCode: this.props.pp.erp_prodpart_code,
                                        pt,
                                    })
                                }
                            />
                        ))}
                </List>
            </CustomModal>
        );
    }
}
