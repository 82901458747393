/**
 * Highlights the row for the exact ID as second argument
 *
 * @param {*} rowInfo - React-table Row info
 * @param {string} id
 * @returns
 */
export const colorSelected = (rowInfo: any, id: string) => {
  if (rowInfo) return '' + rowInfo.original.id === id ? '#00D4AE' : undefined;
  else return;
};
