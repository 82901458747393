import * as React from 'react';

import * as KpiReportsTypes from '../../store/types';
import {CustomButton, CustomDropdown} from 'src/shared/style';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {Typography, Tooltip} from '@material-ui/core';
import {AreasControlServices} from 'src/app/administration/areasControl/store/areasControl.services';
import AssessmentIcon from '@material-ui/icons/Assessment';

export interface FiltersKpiProps {
    areas: KpiReportsTypes.KpiReportsArea[];
    centers: KpiReportsTypes.KpiReportsCenter[];
    kpiTypes: KpiReportsTypes.KpiReportsKpiType[];
    handleSearch(kpiTypeId: string | undefined, centerId: string | undefined, areaId: string | undefined, date: Date | Date[] | undefined): void;
}

const initialState = {
    filteredKpiTypes: [],
    filteredCenters: [],
    filteredAreas: [],
    kpiTypeId: undefined,
    centerId: undefined,
    areaId: undefined,
    date: undefined,
};

const defaultProps: FiltersKpiProps = {
    handleSearch: () => ({}),
    areas: [],
    centers: [],
    kpiTypes: [],
};

type State = Readonly<{
    areaId: string | undefined;
    centerId: string | undefined;
    kpiTypeId: string | undefined;
    date: Date | Date[] | undefined;
    filteredKpiTypes: KpiReportsTypes.KpiReportsKpiType[];
    filteredCenters: KpiReportsTypes.KpiReportsCenter[];
    filteredAreas: KpiReportsTypes.KpiReportsArea[];
}>;

export class FiltersKpi extends React.Component<FiltersKpiProps, State> {
    static readonly defaultProps = defaultProps;
    readonly state = initialState;

    shouldComponentUpdate() {
        return true;
    }
    componentDidMount() {
        //this.filterByCenter();
    }

    public render() {
        const {kpiTypes, centers} = this.props;
        return (
            <Typography
                component="div"
                style={{
                    padding: 16,
                    marginBottom: 10,
                    // marginTop: 5,
                    // minHeight: 300,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                    flexWrap: 'wrap'
                }}
            >
                <div style={{flexGrow: 5, flexBasis: '10%'}}>
                    <CustomDropdown
                        data={kpiTypes}
                        value={this.state.kpiTypeId}
                        margin={'0'}
                        handleChoose={e => this.setState({kpiTypeId: e.target.value})}
                        dropdownName="kpiType"
                        title="Tipo de KPI"
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 250,
                                },
                            },
                        }}
                    />
                </div>
                <div style={{flexGrow: 1}}/>
                <div style={{flexGrow: 5, flexBasis: '10%'}} >
                    <CustomDropdown
                        data={centers}
                        value={this.state.centerId}
                        margin={'0'}
                        handleChoose={this.handleChooseCenter}
                        dropdownName="center"
                        title="Centro"
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 250,
                                },
                            },
                        }}
                    />
                </div>
                <div style={{flexGrow: 1}}/>
                <div style={{flexGrow: 5, flexBasis: '10%'}} >
                    <CustomDropdown
                        data={this.state.filteredAreas}
                        value={this.state.areaId}
                        margin={'0'}
                        handleChoose={e => this.setState({areaId: e.target.value})}
                        dropdownName="area"
                        title="Área"
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 250,
                                },
                            },
                        }}
                    />
                </div>
                <div style={{flexGrow: 1}}/>
                <div style={{alignSelf: 'center'}}>
                    <DateRangePicker
                        //selected={startDate}
                        value={this.state.date}
                        onChange={(date: any) => {
                            this.setState({date: date});
                        }}
                        placeholderText="Seleccione rango de fechas"
                    />
                </div>
                <div style={{flexGrow: 1}}/>
                <div>
                    <CustomButton variantColor={'primary'} width={'50px'} onClick={this.handleSearch}>
                        <Tooltip color={'#FFFFFF'} title={'Realizar consulta'} placement="top">
                            <AssessmentIcon />
                        </Tooltip>
                    </CustomButton>
                </div>
            </Typography>
        );
    }

    private handleSearch = async () => {
        const {kpiTypeId, centerId, areaId, date} = this.state;
        this.props.handleSearch(kpiTypeId, centerId, areaId, date);
    };

    private filterByCenter = (idCenter: string) => {
        if (idCenter === '') {
            this.setState({filteredAreas: []});
        } else {
            AreasControlServices.getAllAreasFromCenter(idCenter).then((res: any) => {
                this.setState({filteredAreas: [{id: '', name: ''}, ...res.data.areas]});
            });
        }
    };

    private handleChooseCenter = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>) => {
        this.setState({centerId: e.target.value});
        this.filterByCenter(e.target.value);
    };
}
