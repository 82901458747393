import styled, {createGlobalStyle} from 'styled-components';
import styledNormalize from 'styled-normalize';

export const InjectGlobal = createGlobalStyle`
  ${styledNormalize}

  /* Global Styles */
  html, body {
    background-color: #efefef;
    width: 100%;
    height: 100vh;
    scrollbar-width: thin;
  }
  body {
    background-color: #efefef;
  }

  *::-webkit-scrollbar {
    width: 0.4em;
    height: 0.4em;
  }
  *::-webkit-scrollbar-track {
    /* webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00); */
    box-shadow: inset 0 0 6px rgba(0,0,0,0.00)
  }
  *::-webkit-scrollbar-thumb {
    background-color: rgba(0,0,0,0.5);
    outline: 1px solid slategrey;
  }
  @media (max-width: 1024px) {
    html, body  {
      scrollbar-width: auto;
    }
    *::-webkit-scrollbar {
      width: 0.85em;
      height: 0.85em;
    }
    *::-webkit-scrollbar-track {
      /* webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.00); */
      box-shadow: inset 0 0 6px rgba(0,0,0,0.00)
    }
    *::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,0.5);
      outline: 1px solid slategrey;
    }
  }

`;

export const AppWaiterWrapper = styled.div`
  width: 100vw;
  height: 95vh;
  display: flex;
`;

export const casi = {
  50: '#FFE4E1',
  100: '#FFC1FF',
  200: '#F089CA',
  300: '#B65494',
  400: '#D83286',
  500: '#C33573',
  600: '#AD52BB',
  700: '#93458A',
  800: '#C33573',
  900: `#${process.env.REACT_APP_COLOR}`,
  A100: '#FF5460',
  A200: `#${process.env.REACT_APP_COLOR}`,
  A400: '#BF0231',
  A700: '#A0001D',
};

// const red = {
//   50: '#ffebee',
//   100: '#ffcdd2',
//   200: '#ef9a9a',
//   300: '#e57373',
//   400: '#ef5350',
//   500: '#f44336',
//   600: '#e53935',
//   700: '#d32f2f',
//   800: '#c62828',
//   900: '#b71c1c',
//   A100: '#ff8a80',
//   A200: '#ff5252',
//   A400: '#ff1744',
//   A700: '#d50000',
// };

// const teal = {
//   50: '#e0f2f1',
//   100: '#b2dfdb',
//   200: '#80cbc4',
//   300: '#4db6ac',
//   400: '#26a69a',
//   500: '#009688',
//   600: '#00897b',
//   700: '#00796b',
//   800: '#00695c',
//   900: '#004d40',
//   A100: '#a7ffeb',
//   A200: '#64ffda',
//   A400: '#1de9b6',
//   A700: '#00bfa5',
// };

// const blue = {
//   50: '#e3f2fd',
//   100: '#bbdefb',
//   200: '#90caf9',
//   300: '#64b5f6',
//   400: '#42a5f5',
//   500: '#2196f3',
//   600: '#1e88e5',
//   700: '#1976d2',
//   800: '#1565c0',
//   900: '#0d47a1',
//   A100: '#82b1ff',
//   A200: '#448aff',
//   A400: '#2979ff',
//   A700: '#2962ff',
// };

// const blueGrey = {
//   50: '#eceff1',
//   100: '#cfd8dc',
//   200: '#b0bec5',
//   300: '#90a4ae',
//   400: '#78909c',
//   500: '#607d8b',
//   600: '#546e7a',
//   700: '#455a64',
//   800: '#37474f',
//   900: '#263238',
//   A100: '#cfd8dc',
//   A200: '#b0bec5',
//   A400: '#78909c',
//   A700: '#455a64',
// };

// const amber = {
//   50: '#fff8e1',
//   100: '#ffecb3',
//   200: '#ffe082',
//   300: '#ffd54f',
//   400: '#ffca28',
//   500: '#ffc107',
//   600: '#ffb300',
//   700: '#ffa000',
//   800: '#ff8f00',
//   900: '#ff6f00',
//   A100: '#ffe57f',
//   A200: '#ffd740',
//   A400: '#ffc400',
//   A700: '#ffab00',
// };
