import * as React from 'react';
import styled from 'styled-components';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {UsersByCenterServices} from "./store/usersByCenter.services";
import {UsersByCenterPanel} from "./UsersByCenterPanel";
import {Button} from "@material-ui/core";
import {
    CenterTreeType,
    ExchangeCentersType
} from "./store/usersByCenter.types";
import _ from 'lodash';
import {IsInRuleset} from "../../../global/authorization/grantSet";
import {Spinner} from "../../global/spinner";
import {RefObject} from "react";

export type StatusType = {
    type: 'module' | 'menu_item';
    enabled: boolean;
}

type State = {
    enableSave: boolean;
    expanded: string | boolean;
    centers : CenterTreeType;
    users : Array<{
        value: string;
        label: string;
    }>;
    hasWriteGrants : boolean;
    loading: boolean;
};
const initialState: Readonly<State> = {
    enableSave: false,
    expanded: false,
    centers: {},
    users: [],
    hasWriteGrants : false,
    loading: true
};

const ALERT_TYPE = 'inactivity_alert';

export class UsersByCenter extends React.Component<{}, State> {
    readonly state = initialState;

    private panelChilds = new Map<string , RefObject<UsersByCenterPanel>>();

    componentDidMount() {
        this.fetchModules().then((d) => {
            // Es importante que los usuarios estén ordenados para comprobar si se activa el botón de guardar
            Object.entries(d.centers as CenterTreeType).forEach(([id, center]) => {
                center.users = _.sortBy(center.users);
                this.panelChilds.set(id, React.createRef<UsersByCenterPanel>());
            });
            const hasWriteGrants = IsInRuleset('SET_INACTIVITY_ALERT_USERS');
            const totalUsers = d.users.map((v : string) => ({value: v, label: v}));

            this.setState({centers : d.centers, users : totalUsers, hasWriteGrants, loading : false});
        });
    }

    private fetchModules = async () => {
        return await fetchWithFeedback(UsersByCenterServices.get(ALERT_TYPE));
    };

    private updateTotalUsers = (value : {value: string, label: string}) => {
        this.setState({users : [...this.state.users, value]}, this.checkIfHasChange);
    }

    private checkIfHasChange = () => {
        let enableSave = false;
        this.panelChilds.forEach(v => {
            const change = v.current ? v.current.isChange() : null;
            if (change) {
                enableSave = true;
                return;
            }
        });

        this.setState({enableSave});
    }

    private handleChange = (panel: string, expanded: boolean) =>
        this.setState({expanded: expanded ? panel : false});

    private handleSave = () => {

        this.setState({loading : true});

        const exchangeCenters : ExchangeCentersType = {
            centers : Object.keys(this.state.centers).map(id => {
                const refs = this.panelChilds.get(id);
                const ref = refs ? refs.current : null;
                let users : string[] = [];
                if (ref) {
                    const selectedUsers = ref.getSelectedUsers();
                    users = selectedUsers.map(v => v.value);
                }

                return {id, users};
            }),
            alertType : ALERT_TYPE
        };

        fetchWithFeedback(
            UsersByCenterServices.save({exchangeCenters}),
            {
                showMessage: true,
                successMessage: 'Se han actualizado los usuarios que recibirán las alertas de inactividad por centro.',
                errorMessage: 'No se ha podido actualizar los datos. Vuelva a intentarlo.'
            }
        ).then(
            r => {
                if (r && r.result === 'OK') {
                    Object.keys(this.state.centers).forEach(id => {
                        const refs = this.panelChilds.get(id);
                        const ref = refs ? refs.current : null;
                        if (ref) ref.updateSelectedUsers();
                    });

                    this.enableSave(false);
                }
            }
        )
            .finally(()=>{
                this.setState({loading : false});
            });
    }

    private enableSave = (enable : boolean) => {
        this.setState({enableSave: enable})
    }

    render() {
        const {expanded, centers, users, enableSave, hasWriteGrants} = this.state;
        return (
            <WrappingDiv>
                <Spinner isLoading={this.state.loading} />
                { centers && Object.entries(centers).map(([id, center]) => (
                    <UsersByCenterPanel
                        ref={this.panelChilds.get(id)}
                        key={id}
                        expanded={expanded}
                        centerId={id}
                        center={center}
                        totalUsers={users}
                        change={this.handleChange}
                        hasWriteGrants={hasWriteGrants}
                        updateTotalUsers={this.updateTotalUsers}
                        checkIfHasSave={this.checkIfHasChange}
                    />
                ))}
                {hasWriteGrants && (<div style={{textAlign: 'center'}}>
                    <Button
                        disabled={!enableSave}
                        style={{
                            marginTop: '25px',
                            marginBottom: '25px',
                            width: 'auto',
                            height: '4.1%',
                            padding: '4 15',
                            fontWeight: 900,
                        }}
                        color="secondary"
                        variant="contained"
                        onClick={this.handleSave}>
                        GUARDAR
                    </Button>
                </div>)}
            </WrappingDiv>

        );
    }
}

const WrappingDiv = styled.div`
  width: calc(75%);
  margin: auto;
  min-height: calc(100% - 7px);
  background-color: #efefef;
  /* min-height: calc(100% - 5px); */
  /* margin-bottom: 5px; */
  padding: 5px;
  /* margin-bottom: 5px; */
`;
