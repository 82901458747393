import {guardedFetcher} from 'src/utils/guardedFetcher';
import {ModulesTreeType, ExchangeStatusType} from "./modulesControl.types";

const prefix = 'system/functionalities';

export const ModulesControlServices = {
    get: () => guardedFetcher.get<{modules: ModulesTreeType}>(prefix + '/allCustomerModules'),

    save: (modules: {modulesStatus: ExchangeStatusType[]}) =>
        guardedFetcher.patch(prefix + '/updateModules', modules),
};
