import {push} from 'connected-react-router';

import {AppStoreState} from 'src/store/interface';
import {changeTaskService, logInDeviceService} from 'src/terminal/store/services';
import {notify} from 'src/utils/Notification';

import {TerminalActionType} from './action-types';

// import {UserTask} from 'src/terminal/store/reducer.bak';

export interface TaskPayload {
  userId: string;
  task: string;
}

export interface ApiTaskSchemaResponse {
  id: string;
  name: string;
  state: 'finalized' | 'current' | 'pending';
  created_at: Date;
  updated_at?: Date;
  finished_at?: Date;
  area_id?: string;
}

export interface ApiEmployeeDataResponse {
  id: string;
  name: string;
  surname: string;
  last_visit: Date;
}

export interface ApiPersonalTasksResponse {
  employee: ApiEmployeeDataResponse;
  tasks: ApiTaskSchemaResponse[];
  active_task: ApiTaskSchemaResponse;
}

export interface ApiAllTasksUnit {
  employee: ApiEmployeeDataResponse;
  task: ApiTaskSchemaResponse;
}

export interface ApiAllTasksResponse {
  all_tasks: ApiAllTasksUnit[];
}

export interface TerminalAction {
  readonly type: TerminalActionType;
  payload?:
    | ApiPersonalTasksResponse
    | ApiAllTasksResponse
    | TaskPayload
    | string[]
    | string;
}

/****** ACTIONS */
export const leaveTerminal = (): TerminalAction => ({
  type: TerminalActionType.finalize,
});

const authorizeTerminal = (code: string = ''): TerminalAction => ({
  type: TerminalActionType.auth,
  payload: code,
});

export const loadTasks = (apiResponse: ApiPersonalTasksResponse): TerminalAction => ({
  type: TerminalActionType.load,
  payload: apiResponse,
});

export const loadAllTasks = (apiResponse: ApiAllTasksResponse): TerminalAction => ({
  type: TerminalActionType.loadAll,
  payload: apiResponse,
});

export const chooseTask = (userId: string, task: string): TerminalAction => ({
  type: TerminalActionType.choose,
  payload: {userId, task} as TaskPayload,
});

export const unChooseTask = (userId: string, task: string): TerminalAction => ({
  type: TerminalActionType.unchoose,
  payload: {userId, task} as TaskPayload,
});

export const finalizeTask = (userId: string, task: string): TerminalAction => ({
  type: TerminalActionType.finalizeTask,
  payload: {userId, task} as TaskPayload,
});

/*** THUNK ACTIONS */
export const authorizeInTerminal = ({code}: {code: string}) => async (
  dispatch: (action: any) => TerminalAction,
) => {
  let loading;
  try {
    loading = await logInDeviceService(code);
  } catch (err) {
    console.error(err);
  }
  if (loading && loading.status === 200) {
    const {data} = loading;
    dispatch(loadTasks(data));
    dispatch(authorizeTerminal(data.employee.id));
    dispatch(push(`/user/${data.employee.id}`));
  } else {
    dispatch(
      notify({
        message: 'No se ha podido obtener datos',
        status: 'error',
      }),
    );
  }
};

export const changeTaskThunk = (userId: string, taskId: string) => async (
  dispatch: (fn: any) => void,
  getState: () => AppStoreState,
) => {
  const response = await changeTaskService(userId, taskId);
  if (response && response.status === 200) {
    dispatch(chooseTask(userId, taskId));
    dispatch(leaveTerminal());
    dispatch(push(`/acceso`));
    dispatch(
      notify({
        message: `Se ha eligido la tarea ${
          getState().terminal.tasks.find(t => t.id === taskId)!.name
        }`,
        status: 'success',
      }),
    );
  } else {
    dispatch(
      notify({
        message: 'No se ha podido obtener datos',
        status: 'error',
      }),
    );
  }
};

export const finalizeTaskThunk = (userId: string, taskId: string) => async (
  dispatch: (fn: any) => void,
) => {
  const response = await changeTaskService(userId, taskId);
  if (response && response.status === 200) {
    dispatch(finalizeTask(userId, taskId));
  }
};

export const loadAllTasksThunk = () => async () => {
  // const response = await getAllTasksService().response;
  // if (
  //   (response && response.status === 200) ||
  //   (response && response.status === 201)
  // )
  //   dispatch(loadAllTasks(response.resBody as ApiAllTasksResponse));
  // else {
  //   dispatch(
  //     notify({
  //       message: 'No se ha podido obtener datos.',
  //       status: 'success',
  //     }),
  //   );
  // }
};
