import React from 'react';

import {ReportsServices} from 'src/app/information/reports/store/services';
import {TaskTimeByAreaData} from 'src/app/information/reports/store/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import { fetchWithFeedback } from 'src/utils/fetcherValidate';
import { ShiftsMapService } from 'src/shared/components/services';

type State = {
    shifts : Array<{
        id:string;
        name:string;
    }>;
    shiftsMap : Map<string, string>;
    shiftsLoaded : boolean;
}

const initialState : State = {
    shifts: [],
    shiftsMap: new Map<string, string>(),
    shiftsLoaded : false
};

export class TasksTimeByArea extends React.Component<{}, State> {
    readonly state = initialState;
    columnFormat : ColumnType<TaskTimeByAreaData> = [];

    private fetchShiftsMap = async () => {
        const shifts : Array<{
            id:string;
            name:string;
        }> = await fetchWithFeedback(ShiftsMapService(), {accessor: 'shifts'});
        this.setState({shifts});
        shifts.forEach(v => this.state.shiftsMap.set(v.id, v.name));
    }

    componentDidMount() {
        this.fetchShiftsMap().then(() => {
            this.columnFormat = this.getColumnFormat();
            this.setState({shiftsLoaded : true});
        });
    }

    private getColumnFormat = () : ColumnType<TaskTimeByAreaData> => {
        return [
            {
                Header: 'Jornada',
                headerClassName: 'header_cell_string',
                accessor: 'workShift',
                filterable: true,
                daterange: true,
                dateFormat: true,
                width: 260,
                className: 'cell_string',
            },
            {
                Header: 'Turno',
                headerClassName: 'header_cell_string',
                Cell: item => <>{this.state.shiftsMap.get(item.original.area_shift)}</>,
                dropdownFilter: true,
                dropdownFilterData: this.state.shifts,
                id: 'area_shift',
                className: 'cell_string',
            },
            {
                Header: 'Tarea',
                headerClassName: 'header_cell_string',
                accessor: 'task_name',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'Área',
                headerClassName: 'header_cell_string',
                accessor: 'area_name',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'Centro',
                headerClassName: 'header_cell_string',
                accessor: 'center_name',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'Duración',
                headerClassName: 'header_cell_number',
                id: 'duration',
                Cell: props => <>{customFormatNumber(Number((props.original.duration / 60).toFixed(0)))} m</>,
                className: 'cell_number',
            },
        ];
    }

    render() {
        return IsInRuleset(['REPORTS_TASKS_TIME_PER_AREA_EXCEL', 'REPORTS_TASKS_TIME_PER_AREA_VIEW']) ?
        this.state.shiftsLoaded && (
            <PaginatedTableComponent
                defaultPageSize={20}
                columnFormat={this.columnFormat}
                service={ReportsServices.getTasksTimeByArea}
                showSizeOptions={true}
                twoWeeksFilter={true}
                loading={true}
                excel={IsInRuleset('REPORTS_TASKS_TIME_PER_AREA_EXCEL')}
            />
        ) : null;
    }

}
