import * as React from 'react';
import {connect} from 'react-redux';
import {RowInfo} from 'react-table';
import styled from 'styled-components';

import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {Message} from 'src/app/messages/store/messages.action-types';
import {MessagesServices} from 'src/app/messages/store/messages.services';
import {MessageResponseUnit} from 'src/app/messages/store/messages.types';
import {MessageComponent} from 'src/app/messages/Message';
import {withStyles, Paper} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import UnreadIcon from '@material-ui/icons/FiberManualRecord';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

const UUID_RFC_LENGTH = 36;
type StyleProps = {
    classes: {[k: string]: any};
};
type SProps = {
    userData: ReturnType<typeof LoginSelectors.getUserData>;
    pathname: string;
};
type ComponentProps = StyleProps & SProps;

type State = Readonly<{
    selectedId: string;
    author: string;
    message: Message;
}>;

const initialState: State = {
    selectedId: '',
    author: '',
    message: {} as Message,
};

class MessagesInboxBase extends React.Component<ComponentProps, State> {
    readonly state = initialState;
    private messageFromLocation = '';
    private messageExists = false;
    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    componentDidMount() {
        this.chooseMessage();
    }

    componentWillReceiveProps(nextProps: ComponentProps) {
        if (this.props.pathname !== nextProps.pathname) this.chooseMessage();
    }

    private chooseMessage = () => {
        this.messageFromLocation = '';
        this.messageExists = false;
        const splitted = this.props.pathname.split('/');
        if (splitted[splitted.length - 1].length === UUID_RFC_LENGTH) this.messageFromLocation = splitted[splitted.length - 1];
    };

    private inboxTable: ColumnType = [
        {
            Header: 'Remitente',
            headerClassName: 'header_cell_string',
            // headerStyle: {height: 0},
            accessor: 'sender',
            sortable: false,
            filterable: true,
            className: 'cell_string',
        },
        {
            Header: 'Asunto',
            headerClassName: 'header_cell_string',
            // headerStyle: {height: 0},
            accessor: 'subject',
            sortable: false,
            filterable: true,
            className: 'cell_string',
        },
        {
            Header: 'Leído?',
            // headerStyle: {height: 0},
            accessor: 'viewed',
            width: 85,
            sortable: false,
            dropdownFilter: true,
            dropdownFilterData: [
                {id: 'true', name: 'Sí'},
                {id: 'false', name: 'No'},
            ],
            Cell: item => <>{item.original.viewed ? <CheckIcon /> : <UnreadIcon />}</>,
            style: {textAlign: 'center', whiteSpace: 'normal', alignSelf: 'center'},
        },
    ];

    render() {
        const {classes, userData} = this.props;
        const {selectedId, message, author} = this.state;
        return (
            <Container>
                <InboxContainer>
                    <PaginatedTableComponent
                        columnFormat={this.inboxTable}
                        defaultPageSize={9}
                        serviceArgs={[userData.employeeId]}
                        service={MessagesServices.getInbox}
                        autorefresh={true}
                        getTableFetchHook={this.getTableHook}
                        autorefreshDelay={15000}
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: RowInfo) => {
                                if (rowInfo) {
                                    const original = rowInfo.original as MessageResponseUnit;
                                    if (this.messageFromLocation === original.id && !this.messageExists) {
                                        this.messageExists = true;
                                        this.setState({
                                            selectedId: this.messageFromLocation,
                                            message: {
                                                body: original.body,
                                                id: original.id,
                                                subject: original.subject,
                                            },
                                            author: `${original.adminEmployeeName} ${original.adminEmployeeSurname} (${original.sender})`,
                                        });
                                    }
                                    return {
                                        onClick: async () => {
                                            if (original) {
                                                if (!original.viewed) {
                                                    await fetchWithFeedback(MessagesServices.read(original.id));
                                                    this.useTableHook();
                                                }
                                                this.setState({
                                                    selectedId: original.id,
                                                    message: {
                                                        body: original.body,
                                                        id: original.id,
                                                        subject: original.subject,
                                                    },
                                                    author: `${original.adminEmployeeName} ${original.adminEmployeeSurname} (${original.sender})`,
                                                });
                                            }
                                        },
                                        style: {
                                            cursor: 'pointer',
                                            backgroundColor: this.getColor(rowInfo),
                                        },
                                    };
                                }
                                return {};
                            },
                        }}
                    />
                </InboxContainer>
                <Paper className={classes.paper}>
                    {selectedId && <MessageComponent onResponse={() => console.log('triggering response')} author={author} body={message.body} subject={message.subject} />}
                </Paper>
            </Container>
        );
    }

    private getColor = (item: RowInfo) => {
        if (!item) return undefined;
        if (this.state.selectedId === item.original.id) return '#53c162';
        if (!!item.original.viewed === false) return '#bfc153';
        return undefined;
    };
}

export const MessagesInbox = withStyles({
    paper: {
        padding: 0,
        // marginLeft: 5,
        marginRight: 5,
        marginBottom: 5,
        height: '99.5%',
        width: '100%',
        display: 'flex',
    },
})(
    connect<SProps, {}, {}, AppStoreState>(state => ({
        userData: LoginSelectors.getUserData(state),
        pathname: state.router.location.pathname,
    }))(MessagesInboxBase),
);

const Container = styled.div`
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
`;

const InboxContainer = styled.div`
    height: 100%;
    width: 45%;
    /* margin-left: 5px; */
`;
