import React, {FunctionComponent} from 'react';
import {Link} from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

type Props = {
    text: string;
    icon?: JSX.Element;
    onClick?(...args: any[]): void;
    href?: string;
    target?: string;
};

export const SecondaryListItem: FunctionComponent<Props> = props => (
    <ListItem
        style={{paddingTop: 3, paddingBottom: 3}}
        href={props.href}
        target={props.target}>
        <ListItemText
            // inset={true}
            style={{cursor: 'pointer'}}
            primary={
                <Link to={props.href || ''} href={props.href} style={{display: 'flex'}}>
                    {props.icon}
                    <Typography style={{alignSelf: 'center'}} variant={'body2'}>
                        {props.text}
                    </Typography>
                </Link>
            }
            onClick={props.onClick}
        />
    </ListItem>
);
