import React from 'react';
import {Dispatch} from 'redux';
import styled from 'styled-components';

import {BargunsControlServices} from 'src/app/administration/bargunsControl/store/bargunsControl.services';
import {BargunBaseUnit} from 'src/app/administration/bargunsControl/store/bargunsControl.types';
import {WrapForm, WrapSaveButton} from 'src/app/administration/export/styled/Wrap';
// import pistolBase from 'src/assets/pistol-base.png';
import {CustomDropdown} from 'src/shared/style';
import {CustomButton} from 'src/shared/style/CustomButton';
import {CustomTextField} from 'src/shared/style/CustomTextField';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notifyValidator} from 'src/utils/notifyValidator';
import {notify} from 'src/utils/Notification';

const initialState: State = {
  addressValid: true,
  address: '',
  name: '',
  id: '',
  port: '',
  bargun_base_type_id: '',
  device_id: '',
  nameValid: true,
  portValid: true,
  bargun_base_type_idValid: true,
  device_idValid: true,
};

type State = Readonly<{
  addressValid: boolean;
  bargun_base_type_id: string;
  port: string;
  portValid: boolean;
  id: string;
  name: string;
  nameValid: boolean;
  address: string;
  device_id: string;
  bargun_base_type_idValid: boolean;
  device_idValid: boolean;
}>;

type Props = {
  dispatch: Dispatch;
  devicesList: Array<{id: string; name: string}>;
  bargunBaseTypes: any[];
  bargun?: BargunBaseUnit;
  handleClose(): void;
  updateBargun(): void;
};

export class BargunForm extends React.Component<Props, State> {
  readonly state = initialState;

  componentDidMount() {
    if (this.props.bargun) {
      const {id, name, address, port, type, device_id} = this.props.bargun;
      const typeValue = this.props.bargunBaseTypes
        ? this.props.bargunBaseTypes.find(it => it.name === type)
        : undefined;
      this.setState({
        device_id: device_id,
        id,
        name,
        address,
        port: '' + port,
        bargun_base_type_id: typeValue ? typeValue.id : '',
        addressValid: true,
        nameValid: true,
        portValid: true,
        bargun_base_type_idValid: true,
        device_idValid: true,
      });
    } else this.setState(initialState);
  }

  // private firstSelectDevice = () => {
  //   if (this.props.devicesList.length && this.props.bargun) {
  //     this.setState({selectedArea: this.props.bargun.device_id});
  //   }
  // };

  render() {
    const {
      address,
      name,
      port,
      bargun_base_type_id,
      bargun_base_type_idValid,
      device_idValid,
      id,
      addressValid,
      device_id,
      nameValid,
      portValid,
    } = this.state;
    const {devicesList, bargunBaseTypes} = this.props;
    return (
      <WrapForm margin={'5px 10px'}>
        <DataDiv>
          <CustomDropdown
            title={'Dispositivo'}
            dropdownName={'devices'}
            data={devicesList || []}
            error={!device_idValid}
            value={device_id}
            margin={'0 0 10px 0'}
            handleChoose={e => this.setState({device_id: e.target.value})}
          />
          <CustomTextField
            margin={'0 0 5px 0'}
            label={'Nombre'}
            variant="outlined"
            error={!nameValid}
            width={'100%'}
            onChange={this.handleInputs('name')}
            value={name}
          />
          <CustomTextField
            margin={'5px 0'}
            label={'Dirección IP'}
            variant="outlined"
            error={!addressValid}
            width={'100%'}
            onChange={this.handleInputs('address')}
            value={address}
          />
          <CustomTextField
            margin={'5px 0 0 0'}
            label={'Puerto'}
            variant="outlined"
            width={'100%'}
            error={!portValid}
            onChange={this.handleInputs('port')}
            value={port}
          />
          <CustomDropdown
            title={'Tipos de automatas'}
            dropdownName={'types'}
            data={bargunBaseTypes || []}
            error={!bargun_base_type_idValid}
            value={bargun_base_type_id}
            margin={'5px 0'}
            handleChoose={e => this.setState({bargun_base_type_id: e.target.value})}
          />
          <WrapSaveButton>
            <CustomButton
              bgcolor="#34baa2"
              onClick={this.handleSend}
              width="100%"
              margin="unset">
              {id ? 'Guardar' : 'Crear'} dispositivo
            </CustomButton>
          </WrapSaveButton>
        </DataDiv>
      </WrapForm>
    );
  }

  private handleSend = async () => {
    const {id, name, address, port, bargun_base_type_id, device_id} = this.state;
    const validator = notifyValidator(this);

    if (
      // tslint:disable-next-line
      !/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        address,
      )
    ) {
      this.setState({addressValid: false});
      notify({message: 'La dirección IP no es válida.', status: 'error'});
    } else if (id) {
      const res = await fetchWithFeedback(
        BargunsControlServices.update(id, {
          address,
          name,
          port: port ? +port : null,
          device_id,
          bargun_base_type_id,
        }),
        {showMessage: true, returnConfirmation: true, notifyValidator: validator},
      );
      if (res) {
        this.props.updateBargun();
        this.props.handleClose();
      }
    } else {
      const res = await fetchWithFeedback(
        BargunsControlServices.create({
          address,
          name,
          port: port ? +port : null,
          bargun_base_type_id,
          device_id,
        }),
        {showMessage: true, returnConfirmation: true, notifyValidator: validator},
      );
      if (res) {
        this.setState(initialState);
        this.props.updateBargun();
      }
    }
  };

  private handleInputs: HandleNamedChange<State> = name => e => {
    this.setState({[name]: e.target.value, [`${name}Valid`]: true} as Pick<
      State,
      keyof State
    >);
  };
}

const DataDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
