import {
  AreasControlResponse,
  AreasControlUnit,
} from 'src/app/administration/areaControl/store/types';
import {TCTaskgroupResponse} from 'src/app/administration/taskGroupsControl/store/taskGroupsControl.types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const areaPrefix = 'crud/areas';
const taskgrPrefix = 'crud/taskgroups';

export const AreaControlServices = {
  getAllAreas: () => guardedFetcher.get<AreasControlResponse>(`${areaPrefix}`),
  getAllAreasWithPtable: () => guardedFetcher.get<AreasControlResponse>(`${areaPrefix}/with-ptable`),
  getAllAreasByCenter: (centerId: string) =>
    guardedFetcher.get<AreasControlResponse>(`${areaPrefix}/center/${centerId}`),
  getAllMyCurrentAreas: () => guardedFetcher.get<AreasControlResponse>(`${areaPrefix}/my-current-areas`),
  createArea: (areaData: {
    name: string;
    manufacturing: boolean;
    maxEmployees: number;
    centerId: string;
  }) => guardedFetcher.post<AreasControlUnit>(`${areaPrefix}`, areaData),
  updateArea: (areaData: {
    id: string;
    name: string;
    manufacturing: boolean;
    maxEmployees: number;
    centerId: string;
  }) => guardedFetcher.patch<AreasControlUnit>(`${areaPrefix}/${areaData.id}`, areaData),
  deleteTask: (areaId: string) => guardedFetcher.delete(`${areaPrefix}/${areaId}`),

  // ! selector part
  getAllTasgkroupsFromArea: (areaId: string) =>
    guardedFetcher.get<TCTaskgroupResponse>(`${taskgrPrefix}/area/${areaId}`),

  // ! togglers
  toggleAreaToTaskgroup: (areaId: string, taskgroupId: string) =>
    guardedFetcher.post(`${areaPrefix}/toggle-relation/taskgroup`, {
      areaId,
      taskgroupId,
    }),

  toggleAutoVinculated: (areaId: string, autoVinculated: boolean) =>
    guardedFetcher.patch(`${areaPrefix}/${areaId}/toggle-auto-vinculated`, {
      auto_vinculated: autoVinculated
    }),
};
