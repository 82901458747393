import {push} from 'connected-react-router';
import * as React from 'react';
import {connect} from 'react-redux';
import {StatusBar} from 'src/app/statusbar/StatusBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {Redirect} from 'react-router-dom';
import {MenuBackground, SpanWrap} from './styled/Wrap';
import {MenuList} from './MenuListComponent';
import {generateUrlImage} from 'src/global/assets';
// import {MenuGrid} from './MenuGridComponent';
// import {Grid} from '@material-ui/core';

const initialState = {open: false, expandAdministration: false};
type State = Readonly<typeof initialState>;
type Props = {static?: boolean};
type DProps = {push(loc: string): void};

export class DrawerMenuBase extends React.Component<Props & DProps /* & SProps*/, State> {
    private logo: string = generateUrlImage('logo.png');

    readonly state = initialState;

    private handleRoute = () => {
        this.setState({open: !this.state.open});
    };

    public render() {
        if (IsInRuleset(['BLUEPRINT_VIEW']) && !IsInRuleset('SHOW_MENU')) {
            return <Redirect to={'blueprints'} />;
        }
        return (
            <>
                {this.props.static && (
                    <>
                        <Drawer open={true} variant={'permanent'}>
                            <MenuList handleRoute={this.handleRoute} />
                        </Drawer>
                        <MenuBackground>
                            <StatusBar disableBurger={true} />

                            <SpanWrap style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <div style={{
                                    width: '60%',
                                    height: '60%',
                                    backgroundColor: 'beige'
                                }}>
                                    <img src={this.logo} style={{width: '100%'}} alt={'logo'} />
                                </div>
                                {/*<Grid style={{height: '100%'}} container alignContent="center" spacing={0}>*/}
                                {/*    <Grid item xs={2} />*/}
                                {/*    <Grid item xs={6}>*/}
                                {/*        <Grid container alignItems="center">*/}
                                {/*            <Grid item xs={12}>*/}
                                {/*                <MenuGrid />*/}
                                {/*            </Grid>*/}
                                {/*        </Grid>*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={1} />*/}
                                {/*    <Grid item xs={3}>*/}
                                {/*        <img src={this.logo} style={{width: '100%'}} alt={'img'} />*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}
                            </SpanWrap>
                        </MenuBackground>
                    </>
                )}
                {!this.props.static && (
                    <>
                        <IconButton
                            onClick={this.handleRoute}
                            style={{
                                color: '#f4f5f7',
                                marginLeft: 5,
                                marginRight: 5,
                                padding: 2,
                                height: 35,
                                width: 35,
                            }}
                            color="inherit"
                            aria-label="Menu"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer open={this.state.open} onClose={this.handleRoute}>
                            <MenuList handleRoute={this.handleRoute} />
                        </Drawer>
                    </>
                )}
            </>
        );
    }
}

const mapDispatchToProps = () => (dispatch: any) => ({
    push: (e: any) => dispatch(push(e)),
});

export const DrawerMenu = connect<{}, DProps, Props, AppStoreState>(null, mapDispatchToProps)(DrawerMenuBase);
