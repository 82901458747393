import {PaginatedServiceArgs} from 'src/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'production/info';
const prefixPresence = 'presence';
export const PersonalServices = {
  // getByArea: ({params, args}: PaginatedServiceArgs) =>
  //   guardedFetcher.get<PaginateResponse>(
  //     `${prefix}/employees-working/area/${args || 'NO_HAY_AREA_SELECCIONADA'}`,
  //     {params},
  //   ),
  getByArea: (areaId: string) =>
    guardedFetcher.get<PaginateResponse>(
      `${prefix}/employees-working/area/${areaId || 'NO_HAY_AREA_SELECCIONADA'}`,
    ),
  get: ({params}: PaginatedServiceArgs) =>
    guardedFetcher.get<PaginateResponse>(`${prefix}/employees-working`, {params}),
  finishTasks: (data: {employee_id: string; employees_to_finish: string[]}) =>
    guardedFetcher.patch(`${prefixPresence}/employees-working/finish-task`, data),
  changeToTask: (data: {
    employees_to_change: string[];
    task_id: string;
    area_id: string;
  }) => guardedFetcher.post(`${prefixPresence}/change-to-task`, data),
  createTask: (data: {area_id: string; employee_id: string; task_id: string}) =>
    guardedFetcher.post(`lanemanager/register-task-manually`, data),
};
