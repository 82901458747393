import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {AppStoreState} from 'src/store/interface';
import {getAllUsersTasksTimedMap} from 'src/terminal/store/selectors';

interface EmployeesTasksProps {
  classes: {
    root: string;
    table: string;
  };
  uTasksMap: any[];
}
const EmployeesTasksTable = (props: EmployeesTasksProps) => {
  const {classes} = props;
  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell numeric={true}>ID</TableCell>
            <TableCell>Trabajador</TableCell>
            <TableCell>Puesto</TableCell>
            <TableCell numeric={true}>Tiempo en tarea en curso</TableCell>
            <TableCell numeric={true}>Tiempo total en tareas</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.uTasksMap.map((u: any) => {
            const currentTask = u.computedTasks.find((t: any) => t.state === 'current');
            const finalizedTasks = u.computedTasks.filter(
              (t: any) => t.state === 'finalized',
            );
            return (
              <TableRow key={u.id}>
                <TableCell numeric={true} component="th" scope="row">
                  {u.id}
                </TableCell>
                <TableCell>{u.name}</TableCell>
                <TableCell>{u.job}</TableCell>
                <TableCell numeric={true}>
                  {(currentTask && currentTask.totalTime) || '0'}
                </TableCell>
                <TableCell numeric={true}>
                  {finalizedTasks.length > 0 && finalizedTasks.length}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Paper>
  );
};

const UsersTableStyled = withStyles({
  root: {
    width: '100%',
    // marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    backgroundColor: '#eaeaea',
    minWidth: 700,
  },
})(EmployeesTasksTable);

const mapStateToProps = (state: AppStoreState) => ({
  uTasksMap: getAllUsersTasksTimedMap(state),
});
// const mapDispatchToProps = (dispatch: any) => {};

export default connect(mapStateToProps)(UsersTableStyled);
