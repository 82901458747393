import {Reducer} from 'redux';

import {
  DowntimeActionInterface,
  DowntimeResponseList,
  DowntimeResponsePreflight,
  LaneDowntime,
  LaneDowntimeState,
} from 'src/app/lanemanager/downtime/store/types';

import {DowntimeActionType} from '../types/downtime.action-types';

const defaultState = {
  // tableFilterCriteria: 'none',
  // downtimes: [
  //   {
  //     id: '1112335',
  //     category: 'Planificada',
  //     cause: 'Almuerzo',
  //     description: 'Almuerzo de las 14:00',
  //     started_at: new Date().toLocaleDateString(),
  //     finished_at: undefined,
  //     total_time: undefined,
  //     personnel_affected: 7,
  //   },
  //   {
  //     id: '1112336',
  //     category: 'No Planificada',
  //     cause: 'Rotura en X',
  //     description: 'Se ha roto la rotula de dispersion del genero',
  //     started_at: new Date().toLocaleDateString(),
  //     finished_at: new Date().toLocaleDateString(),
  //     total_time: '3 h 40 min',
  //     personnel_affected: 12,
  //   },
  // ],
  // causes: [
  //   {
  //     id: '1',
  //     name: 'Almuerzo',
  //     category_id: '1',
  //   },
  //   {
  //     id: '2',
  //     name: 'Rotura en X',
  //     category_id: '2',
  //   },
  // ],
  // categories: [
  //   {
  //     id: '1',
  //     name: 'Planificada',
  //   },
  //   {
  //     id: '2',
  //     name: 'No planificada',
  //   },
  // ],
};

export const downtimeReducer: Reducer<LaneDowntimeState, DowntimeActionInterface> = (
  state = defaultState as any,
  {type, payload},
) => {
  if (!type) return state;
  if (!state) return state;
  switch (type) {
    case DowntimeActionType.set: {
      const {downtimes} = payload as DowntimeResponseList;
      return {...state, downtimes};
    }
    case DowntimeActionType.add: {
      const downtime = payload as LaneDowntime;
      const exist = state.downtimes.find(dt => dt.id === downtime.id);
      let newDowntimes;
      if (!exist) newDowntimes = state.downtimes.concat(downtime);
      return {
        ...state,
        downtimes: newDowntimes ? newDowntimes : state.downtimes,
      };
    }
    case DowntimeActionType.updateDowntime: {
      const downtime = payload as LaneDowntime;
      const downtimes = state.downtimes.map(dt => {
        if (dt.id === downtime.id) return {...dt, ...downtime};
        return dt;
      });
      return {...state, downtimes};
    }
    case DowntimeActionType.clearDowntimes: {
      return {...state, downtimes: []};
    }
    case DowntimeActionType.clearCategories: {
      return {...state, categories: []};
    }
    case DowntimeActionType.clearCauses: {
      return {...state, causes: []};
    }
    case DowntimeActionType.setCategories: {
      const {categories} = payload as DowntimeResponsePreflight;
      return {...state, categories};
    }
    case DowntimeActionType.setCauses: {
      const {causes} = payload as DowntimeResponsePreflight;
      return {...state, causes};
    }
    case DowntimeActionType.setFilterActive: {
      return {...state, tableFilterCriteria: 'active'};
    }
    case DowntimeActionType.setFilterInactive: {
      return {...state, tableFilterCriteria: 'inactive'};
    }
    case DowntimeActionType.clearFilter: {
      return {...state, tableFilterCriteria: 'none'};
    }
  }
  return state;
};
