import * as React from 'react';
import styled from 'styled-components';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {ModulesControlServices} from "./store/modulesControl.services";
import {ModulesControlPanel} from "./ModulesControlPanel";
import {Button} from "@material-ui/core";
import {ExchangeStatusType, ModulesTreeType} from "./store/modulesControl.types";

export type StatusType = {
    type: 'module' | 'menu_item';
    enabled: boolean;
}

type State = {
    enableSave: boolean;
    expanded: string | boolean;
    modules : ModulesTreeType;
    modifiedItems : Map<string, StatusType>;
};
const initialState: Readonly<State> = {
    enableSave: false,
    expanded: false,
    modules: {},
    modifiedItems: new Map<string, StatusType>()
};

export class ModulesControl extends React.Component<{}, State> {
    readonly state = initialState;

    componentDidMount() {
        this.fetchModules().then(modules => {
            this.setState({modules});
        });
    }

    private fetchModules = async () => {
        return await fetchWithFeedback(ModulesControlServices.get(), {
            accessor: 'modules',
        });
    };

    private handleChange = (panel: string, expanded: boolean) =>
        this.setState({expanded: expanded ? panel : false});

    private handleSave = () => {

        this.setState({
            enableSave: false
        });

        const exchangeStatus : ExchangeStatusType[] = [];
        this.state.modifiedItems.forEach((value, id) => {
            exchangeStatus.push({
               id: id,
               type: value.type,
               enabled: value.enabled
            });
        });

        fetchWithFeedback(
            ModulesControlServices.save({modulesStatus : exchangeStatus}),
            {
                showMessage: true,
                successMessage: 'Se han actualizado los módulos disponibles para el cliente.',
                errorMessage: 'No se ha podido actualizar el estado de los módulos. Vuelva a intentarlo.'
            }
        ).then(
            (r) => {
                if (r && r.result === 'OK') {
                    this.state.modifiedItems.clear();
                    this.setState({
                        modules: r.modules,
                        enableSave: false
                    });
                }
                else {
                    this.setState({
                        enableSave: true
                    });
                }
            }
        );
    }

    private enableSave = () => {
        this.setState({enableSave: true})
    }

    render() {
        const {expanded, modules, enableSave, modifiedItems} = this.state;
        return (
            <WrappingDiv>
                { modules && Object.entries(modules).map(([id, module]) => (
                    <ModulesControlPanel
                        key={id}
                        expanded={expanded}
                        moduleId={id}
                        module={module}
                        modifiedItems={modifiedItems}
                        change={this.handleChange}
                        enableSave={this.enableSave}
                    />
                ))}
                <div style={{textAlign: 'center'}}>
                    <Button
                        disabled={!enableSave}
                        style={{
                            marginTop: '25px',
                            marginBottom: '25px',
                            width: 'auto',
                            height: '4.1%',
                            padding: '4 15',
                            fontWeight: 900,
                        }}
                        color="secondary"
                        variant="contained"
                        onClick={this.handleSave}>
                        GUARDAR
                    </Button>
                </div>
            </WrappingDiv>

        );
    }
}

const WrappingDiv = styled.div`
  width: calc(75%);
  margin: auto;
  min-height: calc(100% - 7px);
  background-color: #efefef;
  /* min-height: calc(100% - 5px); */
  /* margin-bottom: 5px; */
  padding: 5px;
  /* margin-bottom: 5px; */
`;
