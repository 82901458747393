import {DevicesControlCreateParams, DevicesControlResponse, DeviceUnit} from 'src/app/administration/devicesControl/store/devicesControl.types';
import {guardedFetcher} from 'src/utils/guardedFetcher';
import {fetcher} from 'src/global/fetcher';

const prefix = 'crud/devices';

export const DevicesControlServices = {
    getAll: () => guardedFetcher.get<DevicesControlResponse>(`${prefix}`),
    getByAreaId: (areaId: string) => guardedFetcher.get<DevicesControlResponse>(`${prefix}/area/${areaId}`),
    create: (params: DevicesControlCreateParams) => guardedFetcher.post(`${prefix}`, params),
    update: (id: string, params: Partial<DevicesControlCreateParams>) => guardedFetcher.patch(`${prefix}/${id}`, params),
    delete: (id: string) => guardedFetcher.delete(`${prefix}/${id}`),
    getSingle: (id: string) => guardedFetcher.get<DeviceUnit>(`${prefix}/${id}`),
    toggleAreaToDevice: (deviceId: string, areaId: string) =>
        fetcher.post(`${prefix}/toggle-relation/area`, {
            areaId,
            deviceId,
        }),
};
