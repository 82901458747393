import * as React from 'react';

import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {ProdPartsServices} from 'src/app/lanemanager/prodparts/store/prodParts.services';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';

export const ProdPartsList: React.FunctionComponent = () => {
    const columns: ColumnType = [
        {
            Header: 'LÍNEA',
            headerClassName: 'header_cell_string',
            accessor: 'area_name',
            className: 'cell_string',
            filterable: true,
            sortable: false,
        },
        {
            Header: 'FECHA SAL. PREV.',
            headerClassName: 'header_cell_string',
            filterable: true,
            sortable: true,
            daterange: true,
            dateFormat: true,
            accessor: 'erp_shipping_date',
            width: 250,
            className: 'cell_string',
        },
        {
            Header: 'Pedido',
            headerClassName: 'header_cell_string',
            accessor: 'order_id',
            filterable: true,
            sortable: true,
            className: 'cell_string',
        },
        {
            Header: 'PARTE',
            headerClassName: 'header_cell_string',
            accessor: 'erp_prodpart_code',
            filterable: true,
            sortable: true,
            className: 'cell_string',
        },
        {
            Header: 'CLIENTE',
            headerClassName: 'header_cell_string',
            id: 'customer',
            accessor: b => b.customer.name,
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            Header: 'PALES',
            headerClassName: 'header_cell_number',
            width: 60,
            id: 'pallets',
            sortable: false,
            className: 'cell_number',
            Cell: items => <>{`${customFormatNumber(items.original.pallets_count)}/${customFormatNumber(items.original.pallets_total)}`}</>,
        },
        {
            Header: 'PEND.',
            headerClassName: 'header_cell_number',
            id: 'pending',
            sortable: false,
            width: 70,
            className: 'cell_number',
            Cell: items => <>{customFormatNumber(items.original.pallets_total - items.original.pallets_count)}</>,
        },
        {
            id: 'merchandise',
            accessor: b => b.merchandise.name,
            Header: 'PRODUCTO',
            headerClassName: 'header_cell_string',
            filterable: true,
            sortable: false,
            className: 'cell_string',
        },
        {
            accessor: 'pallet_name', // b => b.making_type.name,
            Header: 'PALE',
            headerClassName: 'header_cell_string',
            filterable: true,
            sortable: true,
            className: 'cell_string',
        },
        {
            Header: 'Cajas',
            headerClassName: 'header_cell_number',
            id: 'boxes',
            width: 70,
            sortable: false,
            Cell: items => <>{`${customFormatNumber(items.original.boxes_count || 0)}/${customFormatNumber(items.original.boxes_total || 0)}`}</>,
            className: 'cell_number',
        },
        {
            Header: 'Referencia',
            headerClassName: 'header_cell_string',
            accessor: 'order_reference',
            className: 'cell_string',
            filterable: true,
        },
        {
            accessor: 'packaging', // b => b.making_type.name,
            Header: 'ENVASE',
            headerClassName: 'header_cell_string',
            filterable: true,
            sortable: true,
            className: 'cell_string',
        },
        {
            Header: 'ENV.X PALE',
            headerClassName: 'header_cell_number',
            accessor: 'boxes_per_pallet', // b => b.making_type.name,
            Cell: item => <>{customFormatNumber(item.original.boxes_per_pallet)}</>,
            width: 90,
            filterable: false,
            sortable: true,
            className: 'cell_number',
        },
    ];
    return IsInRuleset(['REPORTS_PRODPARTS_VIEW']) ? (
        <PaginatedTableComponent service={ProdPartsServices.getProdPartsPaginate} columnFormat={columns} defaultPageSize={20} loading={true} />
    ) : null;
};
