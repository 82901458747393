import {AppStoreState} from 'src/store';

import {LaneBatchState} from './types';

/* SELECTORS */
export const AreaSelectors = {
  getBatch: (state: AppStoreState): LaneBatchState & {employees: number} => ({
    ...state.lanemanagement.laneBatch,
    employees: state.lanemanagement.laneEmployees.length,
  }),
  getEmployees: (state: AppStoreState) => state.lanemanagement.laneEmployees,
  getEmployeesNumber: (state: AppStoreState) => state.lanemanagement.laneEmployees.length,
  getCurrentDumpFetchState: (state: AppStoreState) =>
    state.lanemanagement.laneDumpReducer.fetchState,
  getPtables: (state: AppStoreState) => state.lanemanagement.lanePtables,
  getPtableById: (state: AppStoreState, id: string) =>
    state.lanemanagement.lanePtables.find(pp => pp.ptable_id === id),
  getProdParts: (state: AppStoreState) => state.lanemanagement.laneProdParts,
  getProdPartById: (state: AppStoreState, id: string) =>
    state.lanemanagement.laneProdParts.find(pp => pp.id === id),
};
