import {combineReducers, ReducersMapObject} from 'redux';

import {chartsReducer} from 'src/app/lanemanager/charts/store/reducers/charts.reducer';
import {downtimeReducer} from 'src/app/lanemanager/downtime/store/reducers';
// import {dumpsReducer} from 'src/app/lanemanager/dumps/store/reducers';
import {LaneManagementStoreState} from 'src/app/lanemanager/export/store/interface';
import {
  laneBatchesReducer,
  laneCurrentDumpReducer,
  laneEmployeesReducer,
  laneProdPartsReducer,
  lanePtablesReducer,
} from 'src/app/lanemanager/lane/store/reducers';
import {prodPartsReducer} from 'src/app/lanemanager/prodparts/store/reducers';

const reducers: ReducersMapObject<LaneManagementStoreState> = {
  laneBatch: laneBatchesReducer,
  laneProdParts: laneProdPartsReducer,
  lanePtables: lanePtablesReducer,
  laneEmployees: laneEmployeesReducer,
  laneDumpReducer: laneCurrentDumpReducer,
  downtime: downtimeReducer,
  prodParts: prodPartsReducer,
  // dumps: dumpsReducer,
  charts: chartsReducer as any,
};

export const lanemanagerReducers = combineReducers(reducers);
