import {push} from 'connected-react-router';
import React from 'react';
import {connect} from 'react-redux';
// import {Route} from 'react-router-dom';
import styled from 'styled-components';

import {MessagesAll} from 'src/app/messages/MessagesAll';
import {MessagesInbox} from 'src/app/messages/MessagesInbox';
import {MessageCreate} from 'src/app/messages/MessageCreate';
import {StatusBar} from 'src/app/statusbar/StatusBar';
import {TabContainerNoTabs} from 'src/app/lanemanager/Wrap';
import {createStyles, withStyles, Theme} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CreateIcon from '@material-ui/icons/Create';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import InboxIcon from '@material-ui/icons/Inbox';
import {GuardSingleAccessRoute} from 'src/global/authorization/GuardAuthorizationRoute';
import {AppStoreState} from 'src/store';
import {RouteHelpers} from 'src/utils/routerHelpers';
import {UsersByCenter} from "./inactivityAlertsUsers/UsersByCenter";

type SProps = {pathname: string};
type DProps = {push(path: string): void};
type Props = {
    classes: {
        root: string;
    };
};

type State = {
    routeTitle: string;
    generic: boolean;
};
const initialState: State = {routeTitle: '', generic: true};

const styles = ({palette}: Theme) =>
    createStyles({
        root: {
            backgroundColor: palette.background.paper,
            padding: 0,
            height: 'calc(100% - 47px)',
            width: '100%',
        },
    });

export class MessagesIndexPageBase extends React.Component<
    Props & SProps & DProps,
    State
    > {
    readonly state = initialState;
    componentDidMount() {
        this.findRoute();
    }

    componentWillReceiveProps(nextProps: SProps) {
        this.findRoute(nextProps.pathname);
    }

    render() {
        const {classes} = this.props;
        const {generic} = this.state;
        return (
            <>
                <StatusBar title={this.state.routeTitle} />
                <div className={classes.root}>
                    <TabContainerNoTabs>
                        <GuardSingleAccessRoute
                            accessSet={['MESSAGES_PERSONAL']}
                            path="/messages/box"
                            component={MessagesInbox}
                        />
                        <GuardSingleAccessRoute
                            accessSet={['MESSAGES_VIEW']}
                            exact={true}
                            path="/messages/all"
                            component={MessagesAll}
                        />
                        <GuardSingleAccessRoute
                            accessSet={['MESSAGES_CREATE']}
                            exact={true}
                            path="/messages/create"
                            component={MessageCreate}
                        />
                        <GuardSingleAccessRoute
                            accessSet={['SET_INACTIVITY_ALERT_USERS', 'VIEW_INACTIVITY_ALERT_USERS']}
                            exact={true}
                            path="/messages/users_by_center_inactivity_alerts"
                            component={UsersByCenter}
                        />
                        {generic && (
                            <WrapList>
                                <List component="nav">
                                    <ListItem
                                        button={true}
                                        component={'a'}
                                        onClick={e => {
                                            this.props.push('/messages/box');
                                            e.preventDefault();
                                        }}
                                        href="/messages/box">
                                        <ListItemIcon>
                                            <InboxIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Bandeja de entrada" />
                                    </ListItem>
                                    <ListItem
                                        button={true}
                                        component={'a'}
                                        onClick={e => {
                                            this.props.push('/messages/create');
                                            e.preventDefault();
                                        }}
                                        href="/messages/create">
                                        <ListItemIcon>
                                            <CreateIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Crear nuevo mensaje" />
                                    </ListItem>
                                    <ListItem
                                        button={true}
                                        component={'a'}
                                        onClick={e => {
                                            this.props.push('/messages/all');
                                            e.preventDefault();
                                        }}
                                        href="/messages/all">
                                        <ListItemIcon>
                                            <ListIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Todos los mensajes" />
                                    </ListItem>
                                    <ListItem
                                        button={true}
                                        component={'a'}
                                        onClick={e => {
                                            this.props.push('/messages/users_by_center_inactivity_alerts');
                                            e.preventDefault();
                                        }}
                                        href="/messages/users_by_center_inactivity_alerts">
                                        <ListItemIcon>
                                            <ListIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Configurar alertas de inactividad" />
                                    </ListItem>
                                </List>
                            </WrapList>
                        )}
                    </TabContainerNoTabs>
                </div>
            </>
        );
    }

    private findRoute = (pathname: string = this.props.pathname) => {
        const path = pathname.split('/')[2];
        const routeTitle = this.getTitle(path);
        let generic = false;
        if (routeTitle === 'Sección de mensajes') generic = true;
        this.setState({routeTitle, generic});
    };

    private getTitle = (path: string) => {
        switch (path) {
            case 'box':
                return 'Bandeja de entrada';
            case 'all':
                return 'Todos los mensajes del sistema';
            case 'create':
                return 'Crear nuevo mensaje';
            case 'users_by_center_inactivity_alerts':
                return 'Establecer usuarios que reciben alertas por inactividad';
            default:
                return 'Sección de mensajes';
        }
    };
}

export const MessagesIndexPage = connect<SProps, DProps, Props, AppStoreState>(
    state => ({pathname: RouteHelpers.getLocation(state)}),
    {push},
)(withStyles(styles)(MessagesIndexPageBase));

const WrapList = styled.div`
  width: 260px;
  margin: auto;
  a {
    display: flex;
    text-decoration: inherit;
    color: inherit;
  }
`;
