import React from 'react';
import styled from 'styled-components';

import {WrapForm} from 'src/app/administration/export/styled/Wrap';
import {CustomButton} from 'src/shared/style/CustomButton';
import {CustomTextField} from 'src/shared/style/CustomTextField';
//import {notifyValidator} from 'src/utils/notifyValidator';
import {FestiveControlData} from './store/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {FestivesControlServices} from 'src/app/administration/festivesControl/store/festivesControl.services';
import {CustomDropdown} from 'src/shared/style';
import {CenterControlServices} from '../centerControl/store/centerControl.services';

type State = Readonly<{
    id: string;
    center_name: string;
    center_id: string;
    workShift: string | undefined;
    description: string;
    centersList: Array<{id: string; name: string}>;
}>;

const initialState: Readonly<State> = {
    id: '',
    center_name: '',
    center_id: '',
    workShift: undefined,
    description: '',
    centersList: [],
};

type Props = {
    festive?: FestiveControlData;
    handleClose(): void;
    update(): void;
};

export class FestiveForm extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidMount() {
        this.fetchCenters();
        if (this.props.festive) {
            const {id, center_name, center_id, workShift, description} = this.props.festive;
            this.setState({
                id,
                center_name,
                center_id,
                workShift,
                description,
            });
        } else this.setState(initialState);
    }

    private fetchCenters = async () => {
        let centersList = await fetchWithFeedback(CenterControlServices.get());
        centersList = [{id: undefined, name: ''}].concat(centersList);
        this.setState({centersList});
    };

    render() {
        const {id, center_id, workShift, description, centersList} = this.state;
        return (
            <WrapForm margin={'5px 10px'}>
                <CustomTextField
                    margin={'10px 0 10px 0'}
                    label={'Descripción'}
                    variant="outlined"
                    width={'500px'}
                    onChange={this.handleInputs('description')}
                    value={description}
                />

                <DataDiv>
                    <LeftPart>
                        <CustomTextField
                            margin={'15px 5px 10px 0'}
                            width={'100%'}
                            label="Fecha"
                            error={false}
                            value={workShift}
                            name="workShift"
                            variant={'outlined'}
                            type="date"
                            shrink={true}
                            onChange={e => this.setState({workShift: e.target.value})}
                        />
                    </LeftPart>
                    <RightPart>
                        <CustomDropdown
                            title={'Centro'}
                            margin={'0 5px 10px 20px'}
                            data={centersList}
                            dropdownName={'center'}
                            value={center_id}
                            handleChoose={e => this.setState({center_id: e.target.value})}
                        />
                    </RightPart>
                </DataDiv>
                <div style={{display: 'flex'}}>
                    <CustomButton margin={'10px 0px 0px auto'} onClick={this.handleSend} width={'25%'}>
                        {id ? 'Guardar' : 'Crear'}
                    </CustomButton>
                </div>
            </WrapForm>
        );
    }

    private handleSend = async () => {
        const {id, center_id, workShift, description} = this.state;
        if (id) {
            const res = await fetchWithFeedback(
                FestivesControlServices.update(id, {
                    center_id,
                    workShift,
                    description,
                }),
                {showMessage: true},
            );
            if (res) {
                this.props.update();
                this.props.handleClose();
            }
        } else {
            const res = await fetchWithFeedback(
                FestivesControlServices.create({
                    center_id,
                    workShift,
                    description,
                }),
                {showMessage: true},
            );
            if (res) {
                this.props.update();
                this.props.handleClose();
            }
        }
    };

    private handleInputs: HandleNamedChange<State> = name => e => {
        this.setState({[name]: e.target.value, [`${name}Valid`]: true} as Pick<State, keyof State>);
    };
}

export const LeftPart = styled.div`
    width: 48%;
`;
export const RightPart = styled.div`
    width: 48%;
    display: flex;
    flex-direction: column;
`;
export const DataDiv = styled.div`
    display: flex;
`;
