import {Reducer} from 'redux';

import {
  PaginatedTableActionsList,
  PaginatedTableActionTypes,
} from 'src/shared/components/store/paginatedTable.action-types';

export type PaginatedTableStoreState = {
  [id: string]:
    | {
        page: number;
        pageSize: number;
        sorted: any[];
        filtered: any[];
      }
    | undefined;
};
const initialState: PaginatedTableStoreState = {};

export const PaginatedTableReducer: Reducer<
  PaginatedTableStoreState,
  PaginatedTableActionsList
> = (state = initialState, action) => {
  if (!action) return state;
  switch (action.type) {
    case PaginatedTableActionTypes.addPaginationData: {
      return {...state, [action.payload.id]: {...action.payload.payload}};
    }
    case PaginatedTableActionTypes.clearPaginationData: {
      return {...state, [action.payload.id]: undefined};
    }
    default:
      return state;
  }
};
