import * as React from 'react';
import isEqual from 'react-fast-compare';
import Select from 'react-select';
import animated from 'react-select/lib/animated';
import ReactTable, {RowInfo} from 'react-table';
import styled from 'styled-components';

import {JobtypesControlServices} from 'src/app/administration/jobtypesControl/store/jobtypesControl.services';
import {TasksControlServices} from 'src/app/administration/tasksControl/store/tasksControl.services';
import {TCTaskResponse} from 'src/app/administration/tasksControl/store/tasksControl.types';
import {Typography} from '@material-ui/core';
import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {CustomButton, CustomTextField} from 'src/shared/style';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';

type State = {
    open: boolean;
    id: string;
    name: string;
    nameValid: boolean;
    tasksList: TCTaskResponse['tasks'];
    jobtypeTasksList: Array<{
        value: any;
        label: any;
    }>;
    jobtypeTasksListRAW: TCTaskResponse['tasks'];
};

const initialState: State = {
    open: false,
    id: '',
    name: '',
    nameValid: true,
    tasksList: [],
    jobtypeTasksList: [],
    jobtypeTasksListRAW: [],
};

export class JobtypesControl extends React.Component<{}, State> {
    readonly state = initialState;
    private tableHook: () => void = () => ({});
    private getTableHooks = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    componentDidMount() {
        this.fetchTasks();
    }

    private fetchTasks = async () => {
        const tasksList = await fetchWithFeedback(TasksControlServices.getAll(), {
            accessor: 'tasks',
        });
        this.setState({tasksList});
    };

    private fetchTasksById = async () => {
        const {id} = this.state;
        if (id) {
            const jobtypeTasksList = await fetchWithFeedback(JobtypesControlServices.getAllTasksFromJobtype(id), {
                accessor: 'tasks',
            });
            this.setState({
                jobtypeTasksList: this.transformToPicker(jobtypeTasksList),
                jobtypeTasksListRAW: jobtypeTasksList,
            });
        }
    };

    private transformToPicker = (arr: any[]) => arr.map(el => ({value: el.id, label: el.name}));

    private handleChange: HandleNamedChange<State> = name => e => this.setState({[name]: e.target.value} as Pick<State, keyof State>);

    private handleClose = () => {
        this.setState({
            open: false,
            id: '',
            name: '',
            jobtypeTasksList: [],
        });
    };

    private handleOpen = () => this.setState({open: true});
    private handleSelectAndOpen = ({id, name}: {id: string; name: string}) => this.setState(() => ({open: true, id, name}), this.fetchTasksById);
    private handleSelect = ({id, name}: {id: string; name: string}) => this.setState(() => ({id, name, jobtypeTasksListRAW: []}), this.fetchTasksById);

    private handleSending = async () => {
        const {name, id, nameValid} = this.state;
        if (nameValid) {
            if (id) {
                await fetchWithFeedback(JobtypesControlServices.update({id, name}), {
                    showMessage: true,
                });
                this.handleClose();
            } else {
                await fetchWithFeedback(JobtypesControlServices.create({name}), {
                    showMessage: true,
                });
                this.setState({
                    id: '',
                    name: '',
                    jobtypeTasksList: [],
                    jobtypeTasksListRAW: [],
                });
            }
            this.useTableHook();
        } else notify({message: 'Campo nombre no puede ir vacio.', status: 'error'});
    };

    private handleChecking = () => {
        const {name} = this.state;
        this.setState({nameValid: !!name}, this.handleSending);
    };

    private columns: ColumnType<{id: string; name: string}> = [
        {
            Header: 'Nombre',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'name',
            className: 'cell_string',
        },
        {
            Header: 'Tareas',
            width: 60,
            sortable: false,
            expander: true,
            style: {
                cursor: 'pointer',
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
            },
        },
    ];

    render() {
        const TOTAL_ACCESS = typeof IsInRuleset === 'function' ? IsInRuleset('JOBTYPES_TOTAL') : false;
        const VIEW_ACCESS = typeof IsInRuleset === 'function' ? IsInRuleset('JOBTYPES_VIEW') : false;

        const {id, name, open, nameValid, jobtypeTasksList, tasksList, jobtypeTasksListRAW} = this.state;
        return (
            (TOTAL_ACCESS || VIEW_ACCESS) && (
                <TableWrap>
                    <TableModal title={'Gestión de rol de trabajador'} open={open} handleClose={this.handleClose}>
                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                width: 330,
                            }}
                        >
                            <CustomTextField
                                margin={'5px auto 10px auto'}
                                width={'100%'}
                                // style={{zIndex: 20, marginTop: 5}}
                                label="Nombre"
                                error={!nameValid}
                                value={name}
                                name="name"
                                variant="outlined"
                                onChange={this.handleChange('name')}
                            />
                            {id && (
                                <>
                                    <label>Tareas afectadas</label>
                                    <Select
                                        backspaceRemovesValue={false}
                                        menuPlacement="top"
                                        styles={affectedJtSelect}
                                        value={jobtypeTasksList}
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        isClearable={false}
                                        // @ts-ignore
                                        components={animated()}
                                        options={this.transformToPicker(tasksList)}
                                        onChange={async sel => {
                                            const cast = sel as Array<{
                                                value: string;
                                                label: string;
                                            }>;

                                            try {
                                                const newAssigned = cast.filter(c => !jobtypeTasksList.find(jtl => isEqual(c, jtl)));
                                                if (newAssigned.length) {
                                                    await fetchWithFeedback(
                                                        JobtypesControlServices.toggleJobtype({
                                                            jobtypeId: id,
                                                            taskId: newAssigned[0].value,
                                                        }),
                                                        {
                                                            successMessage: 'Se ha añadido la tarea correctamente',
                                                            errorMessage: 'No se ha podido añadir la tarea a este rol',
                                                        },
                                                    );
                                                } else {
                                                    const newUnassigned = jobtypeTasksList.filter(jtl => !cast.find(c => isEqual(c, jtl)));
                                                    if (newUnassigned.length) {
                                                        await fetchWithFeedback(
                                                            JobtypesControlServices.toggleJobtype({
                                                                jobtypeId: id,
                                                                taskId: newUnassigned[0].value,
                                                            }),
                                                            {
                                                                successMessage: 'Se ha quitado la tarea correctamente',
                                                                errorMessage: 'No se ha podido quitar la tarea de este rol',
                                                            },
                                                        );
                                                    }
                                                }
                                                this.setState(() => ({jobtypeTasksList: cast}), this.fetchTasksById);
                                            } catch (error) {
                                                console.error(error);
                                            }
                                        }}
                                    />
                                </>
                            )}
                        </Typography>
                        <CustomButton onClick={this.handleChecking} width={'100%'}>
                            {this.state.id ? 'Guardar' : 'Crear'} rol
                        </CustomButton>
                    </TableModal>
                    <TableComponent
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        service={JobtypesControlServices.get}
                        accessor={'jobtypes'}
                        createHook={TOTAL_ACCESS ? this.handleOpen : undefined}
                        getTableFetchHook={this.getTableHooks}
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: RowInfo) => ({
                                onClick: (evt: any) => {
                                    if (evt.target.className.includes('expand')) this.handleSelect(rowInfo.original);
                                    else TOTAL_ACCESS && this.handleSelectAndOpen(rowInfo.original);
                                },
                                style: {cursor: 'pointer'},
                            }),
                            SubComponent: () => {
                                return (
                                    <div style={{display: 'flex'}}>
                                        <ReactTable
                                            style={{
                                                width: '100%',
                                                borderBottom: 3,
                                                borderBottomStyle: 'solid',
                                                borderBottomColor: 'green',
                                            }}
                                            data={jobtypeTasksListRAW}
                                            showPagination={false}
                                            minRows={0}
                                            // onFetchData={this.fetchTasksById}
                                            pageSize={jobtypeTasksListRAW.length}
                                            resizable={false}
                                            sortable={false}
                                            filterable={false}
                                            getNoDataProps={() => {
                                                if (!jobtypeTasksListRAW.length) return {style: {display: 'none'}};
                                                return {};
                                            }}
                                            columns={[
                                                {
                                                    Header: 'Tareas del rol',
                                                    headerClassName: 'subheader_title',
                                                    columns: [
                                                        {
                                                            Header: 'Nombre',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'name',
                                                            className: 'subcell_string',
                                                        },
                                                        {
                                                            Header: 'Directa?',
                                                            headerClassName: 'subheader_cell_string',
                                                            Cell: item => <>{item.original.direct ? 'Sí' : 'No'}</>,
                                                            className: 'subcell_string',
                                                        },
                                                    ],
                                                },
                                            ]}
                                        />
                                    </div>
                                );
                            },
                        }}
                    />
                </TableWrap>
            )
        );
    }
}

const TableWrap = styled.div`
    width: 30%;
    height: 100%;
    margin: auto;
`;
const affectedJtSelect = {
    menu: (provided: any) => ({
        ...provided,
        height: 84,
    }),
    menuList: (provided: any) => ({
        ...provided,
        height: 84,
    }),
};
