import {ajv} from 'src/utils/ajv';

import {palletScheme} from './schemes/pallet';

const palletsResScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['pallets'],
  properties: {
    pallets: {oneOf: [{type: 'array', items: palletScheme}, {type: 'null'}]},
  },
};

const precompile = ajv.compile(palletsResScheme);
export const palletsValidate = precompile;
