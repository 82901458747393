import React from 'react';
import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import {CustomButton, CustomDropdown, WrapCreateButton} from 'src/shared/style';
import {ChartsServices} from './store/services';
import {notify} from 'src/utils/Notification';
// import DatePicker from 'react-date-picker';
import {Typography, Tooltip} from '@material-ui/core';
import styled from 'styled-components';
import {withStyles} from '@material-ui/core/styles';
import {ResumeDataModal} from './ResumeDataModal';
import UpdateIcon from '@material-ui/icons/Update';
import TableChartIcon from '@material-ui/icons/TableChart';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {Spinner} from 'src/app/global/spinner';
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { fetchWithFeedback } from 'src/utils/fetcherValidate';
import { ShiftsMapService } from 'src/shared/components/services';

const stylesTypo = {
    typo: {
        paddingTop: 5,
        fontSize: 16,
        borderBottom: '1px solid #DFDFDF',
        width: '100%',
        paddingLeft: 0,
    },
};

const Heading = styled.div<{fontSize?: number}>`
    display: flex;
    flex-direction: column;
    margin: 0px 4px;
    span {
        text-align: center;
    }
    & > span:nth-child(1) {
        text-transform: uppercase;
        font-weight: bold;
        font-size: ${({fontSize}) => fontSize || '0.7'}rem;
    }
`;

const HeadingGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;
const ThemedTypo = withStyles(stylesTypo)((props: any) => (
    <Typography data-name={props.dataName} className={props.classes.typo}>
        {props.children}
    </Typography>
));

type State = {
    ratio1: string; // Kg volcados / persona
    ratio2: string; // € / Kg volcado
    ratio3: string; // Kg volcado / h
    ratio4: string; // horas / persona
    ratio5: string; // horas / turno
    ratioAverange1: string; // Kg volcados / persona
    ratioAverange2: string; // € / Kg volcado
    ratioAverange3: string; // Kg volcado / h
    ratioAverange4: string; // horas / persona
    ratioAverange5: string; // horas / turno
    areaId: string;
    date: Date[];
    areaShift: string;
    showResumeData: boolean;
    isLoading: boolean;
    shifts : Array<{
        id:string;
        name:string;
    }>;
};

const initialState: State = {
    ratio1: '',
    ratio2: '',
    ratio3: '',
    ratio4: '',
    ratio5: '',
    ratioAverange1: '',
    ratioAverange2: '',
    ratioAverange3: '',
    ratioAverange4: '',
    ratioAverange5: '',
    areaId: '',
    date: [new Date(), new Date()],
    areaShift: '1',
    showResumeData: false,
    isLoading: false,
    shifts: [],
};

type Props = {
    areaId: string;
    areaName: string;
};

export class RatiosChart extends React.Component<Props, State> {
    readonly state = initialState;

    static getDerivedStateFromProps(props: Props) {
        return {
            areaId: props.areaId,
        };
    }

    componentDidMount() {
        this.fetchShiftsMap();
        this.handleSearch();
    }

    private fetchShiftsMap = async () => {
        const shifts : Array<{
            id:string;
            name:string;
        }> = await fetchWithFeedback(ShiftsMapService(), {accessor: 'shifts'});
        this.setState({shifts});
    }

    handleSearch = () => {
        let dateSearch = format(new Date(), 'DD/MM/YYYY', {
            locale: es,
        });
        let toDateSearch = format(new Date(), 'DD/MM/YYYY', {
            locale: es,
        });

        if (this.state.date) {
            dateSearch = format(this.state.date[0], 'DD/MM/YYYY', {
                locale: es,
            });
            toDateSearch = format(this.state.date[1], 'DD/MM/YYYY', {
                locale: es,
            });
        }

        try {
            this.setState({isLoading: true});
            ChartsServices.ratiosDateWorkshift(this.state.areaId, dateSearch, toDateSearch, this.state.areaShift).then(ratios => {
                const ratio1 = ratios.data.ratio1;
                const ratio2 = ratios.data.ratio2;
                const ratio3 = ratios.data.ratio3;
                const ratio4 = ratios.data.ratio4;
                const ratio5 = ratios.data.ratio5;

                const ratioAverange1 = ratios.data.ratioAverange1;
                const ratioAverange2 = ratios.data.ratioAverange2;
                const ratioAverange3 = ratios.data.ratioAverange3;
                const ratioAverange4 = ratios.data.ratioAverange4;
                const ratioAverange5 = ratios.data.ratioAverange5;

                this.setState({
                    ratio1,
                    ratio2,
                    ratio3,
                    ratio4,
                    ratio5,
                    ratioAverange1,
                    ratioAverange2,
                    ratioAverange3,
                    ratioAverange4,
                    ratioAverange5,
                });
                this.setState({isLoading: false});
            });
        } catch (e) {
            notify({message: 'Error al obtener los ratios', status: 'error'});
            this.setState({isLoading: false});
        }
    };

    handleViewResume = () => {
        this.setState({
            showResumeData: true,
        });
    };

    private hideResumeDataModal = () => {
        this.setState({
            showResumeData: false,
        });
    };

    render() {

        const ratio1 = this.state.ratio1 ? customFormatNumber(Number(this.state.ratio1)) : '-';
        const ratio2 = this.state.ratio2 ? customFormatNumber(Number(this.state.ratio2)) : '-';
        const ratio3 = this.state.ratio3 ? customFormatNumber(Number(this.state.ratio3)) : '-';
        const ratio4 = this.state.ratio4 ? customFormatNumber(Number(this.state.ratio4)) : '-';
        const ratio5 = this.state.ratio5 ? customFormatNumber(Number(this.state.ratio5)) : '-';

        const ratioAverange1 = this.state.ratioAverange1 ? customFormatNumber(Number(this.state.ratioAverange1)) : '-';
        const ratioAverange2 = this.state.ratioAverange2 ? customFormatNumber(Number(this.state.ratioAverange2)) : '-';
        const ratioAverange3 = this.state.ratioAverange3 ? customFormatNumber(Number(this.state.ratioAverange3)) : '-';
        const ratioAverange4 = this.state.ratioAverange4 ? customFormatNumber(Number(this.state.ratioAverange4)) : '-';
        const ratioAverange5 = this.state.ratioAverange5 ? customFormatNumber(Number(this.state.ratioAverange5)) : '-';

        return (
            <>
                <ResumeDataModal
                    open={this.state.showResumeData}
                    hideResumeDataModal={this.hideResumeDataModal}
                    initialWorkshift={this.state.date ? this.state.date[0] : new Date()}
                    finalWorkshift={this.state.date ? this.state.date[1] : new Date()}
                    areaId={this.props.areaId}
                    areaName={this.props.areaName}
                />
                <Typography
                    component="div"
                    style={{
                        marginBottom: 10,
                        marginTop: 5,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{flexGrow: 1,width: '240px'/*width: '13%', paddingRight: '30px', , marginTop: '30px'*/}}
                    >
                        <DateRangePicker
                            className={"react-date-picker"}
                            value={this.state.date}
                            onChange={(date: Date[]) => {
                                this.setState({date: date});
                            }}

                            // placeholderText="Seleccione rango de fechas"
                        />
                    </div>
                    {/*<div style={{flexGrow: 1}} />*/}
                    <div style={{flexGrow: 2, /*width: '25%',paddingRight: '30px'*/ }}>
                        <CustomDropdown
                            data={this.state.shifts}
                            value={this.state.areaShift}
                            margin={'0'}
                            handleChoose={e => this.setState({areaShift: e.target.value})}
                            dropdownName="areaShift"
                            title="Turno"
                        />
                    </div>
                    {/*<div style={{flexGrow: 1}} />*/}

                    <div style={{flexGrow: 1}}>
                        <WrapCreateButton>
                            <CustomButton variantColor={'primary'} margin={'auto'} width={'50px'} onClick={this.handleViewResume}>
                                <Tooltip title={'Ver resumen'} placement="top">
                                    <TableChartIcon />
                                </Tooltip>
                            </CustomButton>
                        </WrapCreateButton>
                    </div>

                    <div style={{flexGrow: 1}}>
                        <WrapCreateButton>
                            <CustomButton variantColor={'primary'} margin={'auto'} width={'50px'} onClick={this.handleSearch}>
                                <Tooltip title={'Refrescar ratios'} placement="top">
                                    <UpdateIcon />
                                </Tooltip>
                            </CustomButton>
                        </WrapCreateButton>
                    </div>
                </Typography>
                <>
                    <>
                        <ThemedTypo>
                            <Spinner isLoading={this.state.isLoading} position={'relative'} />
                            {!this.state.isLoading && (
                                <HeadingGroup>
                                    <Heading>
                                        <span>Kg volcados / persona</span>
                                        <span>{ratio1}</span>
                                    </Heading>
                                    <Heading>
                                        <span> € / Kg volcado</span>
                                        <span>{ratio2}</span>
                                    </Heading>
                                    <Heading>
                                        <span>Kg volcado / h</span>
                                        <span>{ratio3}</span>
                                    </Heading>
                                    <Heading>
                                        <span>horas / persona</span>
                                        <span>
                                            {ratio4} ({ratio5})
                                        </span>
                                    </Heading>
                                </HeadingGroup>
                            )}
                        </ThemedTypo>

                        <ThemedTypo>
                            <Spinner isLoading={this.state.isLoading} position={'relative'} />
                            {!this.state.isLoading && (
                                <HeadingGroup>
                                    <Heading>
                                        <span>Kg volcados / persona (Med)</span>
                                        <span>{ratioAverange1}</span>
                                    </Heading>
                                    <Heading>
                                        <span> € / Kg volcado (Med)</span>
                                        <span>{ratioAverange2}</span>
                                    </Heading>
                                    <Heading>
                                        <span>Kg volcado / h (Med)</span>
                                        <span>{ratioAverange3}</span>
                                    </Heading>
                                    <Heading>
                                        <span>horas / persona (Med)</span>
                                        <span>
                                            {ratioAverange4} ({ratioAverange5})
                                        </span>
                                    </Heading>
                                </HeadingGroup>
                            )}
                        </ThemedTypo>
                    </>
                </>
            </>
        );
    }
}
