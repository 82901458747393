import React from 'react';
import isEqual from 'react-fast-compare';
import Select from 'react-select';
import animated from 'react-select/lib/animated';
import styled from 'styled-components';

import {EmployeesControlServices} from 'src/app/administration/employeesControl/store/employeesControl.services';
import {
  EmployeesControlResponseData,
  JobtypeResponseData,
} from 'src/app/administration/employeesControl/store/types';
import {WrapForm, WrapSaveButton} from 'src/app/administration/export/styled/Wrap';
import profileImage from 'src/assets/blank-profile.png';
import {Typography} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CloudUpload from '@material-ui/icons/CloudUpload';
import {CustomButton} from 'src/shared/style/CustomButton';
import {CustomTextField} from 'src/shared/style/CustomTextField';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notifyValidator} from 'src/utils/notifyValidator';

const initialState: State = {
  id: '',
  name: '',
  nameValid: true,
  surname: '',
  surnameValid: true,
  identification: '',
  identificationValid: true,
  active: true,
  code: '',
  codeValid: true,
  file: '',
  profileUrl: '',
  jobtypes: [],
  assignedJobtypes: [],
};

type State = Readonly<{
  id: string;
  name: string;
  nameValid: boolean;
  surname: string;
  surnameValid: boolean;
  identification: string;
  identificationValid: boolean;
  code: string;
  codeValid: boolean;
  active: boolean;
  file: any;
  profileUrl: any;
  jobtypes: Array<{value: string; label: string}>;
  assignedJobtypes: Array<{value: string; label: string}>;
}>;

type Props = {
  employee?: EmployeesControlResponseData;
  handleClose(): void;
  updateEmployee(): void;
  allJobtypes: JobtypeResponseData[];
};

export class EmployeeForm extends React.Component<Props, State> {
  readonly state = initialState;

  inputRef = React.createRef<HTMLInputElement>();

  componentDidMount() {
    if (this.props.employee) {
      const {
        id,
        name,
        active,
        code,
        identification,
        profileUrl,
        surname,
      } = this.props.employee;
      this.setState({
        id,
        name,
        nameValid: true,
        surname,
        surnameValid: true,
        identification,
        identificationValid: true,
        code,
        codeValid: true,
        active: !!+active,
        profileUrl,
      });
    } else this.setState(initialState);
    this.parseJobtypes();
  }

  private parseJobtypes = () => {
    const {allJobtypes, employee} = this.props;
    if (!allJobtypes) return;
    const parsed = allJobtypes.map(jt => ({value: jt.id, label: jt.name}));
    let parsedEmployees: typeof parsed = [];
    if (employee) {
      parsedEmployees = employee.jobtypes.map(jt => ({
        value: jt.id,
        label: jt.name,
      }));
    }
    this.setState({jobtypes: parsed, assignedJobtypes: parsedEmployees});
  };

  render() {
    const {
      jobtypes,
      nameValid,
      name,
      active,
      code,
      codeValid,
      id,
      identification,
      identificationValid,
      profileUrl,
      surname,
      surnameValid,
      assignedJobtypes,
    } = this.state;
    return (
      <WrapForm margin={'5px 10px'}>
        <DataDiv>
          <LeftPart>
            <CustomTextField
              margin={'5px 0 5px 0'}
              variant={'outlined'}
              label={'Nombre'}
              error={!nameValid}
              width={'80%'}
              onChange={this.handleInputs('name')}
              value={name}
            />
            <CustomTextField
              variant={'outlined'}
              margin={'5px 0'}
              label={'Apellidos'}
              error={!surnameValid}
              width={'80%'}
              onChange={this.handleInputs('surname')}
              value={surname}
            />
            <CustomTextField
              margin={'5px 0'}
              variant={'outlined'}
              label={'DNI'}
              error={!identificationValid}
              width={'80%'}
              maxLength={'10'}
              onChange={this.handleInputs('identification')}
              value={identification}
            />
            <CustomTextField
              margin={'5px 0 0 0'}
              variant={'outlined'}
              label={'Código del empleado'}
              error={!codeValid}
              width={'80%'}
              maxLength={'5'}
              onChange={e => {
                const {value} = e.target;
                if (/^\d+$/.test(value) || value === '')
                  this.setState({code: value, codeValid: true});
              }}
              value={code}
            />
            <FormControlLabel
              style={{width: 120}}
              control={
                <Checkbox
                  checked={active}
                  onChange={this.handleCheckbox}
                  value="activeCB"
                />
              }
              label="Activo?"
            />
            <WrapSaveButton>
              <CustomButton
                // bgcolor={'#34baa2'}
                onClick={this.handleSend}
                width={'80%'}
                margin="unset">
                {id ? 'Guardar' : 'Crear'} empleado
              </CustomButton>
            </WrapSaveButton>
          </LeftPart>
          <RightPart>
            {id && (
              <>
                <Typography component="div" style={{overflow: 'inherit'}}>
                  <Select
                    backspaceRemovesValue={false}
                    isMulti={true}
                    styles={selectorJobtypes}
                    closeMenuOnSelect={false}
                    isClearable={false}
                    // @ts-ignore
                    components={animated()}
                    options={jobtypes}
                    placeholder={'Roles de empleado...'}
                    value={assignedJobtypes}
                    onChange={async sel => {
                      const cast = sel as Array<{
                        value: string;
                        label: string;
                      }>;

                      try {
                        const newAssigned = cast.filter(
                          c => !assignedJobtypes.find(ajt => isEqual(c, ajt)),
                        );
                        if (newAssigned.length) {
                          await fetchWithFeedback(
                            EmployeesControlServices.mAssignJobtypes({
                              employeeIds: [id],
                              jobtypeIds: newAssigned.map(ea => ea.value),
                            }),
                            {
                              successMessage: 'Se ha asignado un rol correctamente',
                              errorMessage:
                                'No se ha podido asignar el rol a este empleado',
                            },
                          );
                        } else {
                          const newUnassigned = assignedJobtypes.filter(
                            ajt => !cast.find(c => isEqual(c, ajt)),
                          );
                          if (newUnassigned.length) {
                            await fetchWithFeedback(
                              EmployeesControlServices.mDesassignJobtypes({
                                employeeIds: [id],
                                jobtypeIds: newUnassigned.map(ea => ea.value),
                              }),
                              {
                                successMessage: 'Se ha desasignado un rol correctamente',
                                errorMessage:
                                  'No se ha podido desasignar el rol de este empleado',
                              },
                            );
                          }
                        }
                        this.setState({assignedJobtypes: cast});
                      } catch (error) {
                        console.error(error);
                      }
                    }}
                  />
                </Typography>
                <div style={{flexGrow: 1}} />
              </>
            )}
            <PhotoDiv>
              <img
                style={{
                  margin: 'auto',
                  marginTop: 10,
                  width: 150,
                  height: 150,
                  objectFit: 'scale-down',
                }}
                src={!!profileUrl ? profileUrl : profileImage}
                alt={'img'}
              />
              <div>
                <label
                  htmlFor="fileInput"
                  style={{
                    cursor: 'pointer',
                    fontSize: 16,
                    display: 'flex',
                    margin: 'auto',
                    width: 141,
                  }}>
                  <Typography component="span" style={{marginRight: 15}}>
                    Subir una foto
                  </Typography>
                  <CloudUpload />
                </label>
                <input
                  style={{visibility: 'hidden'}}
                  ref={this.inputRef}
                  name="photo"
                  id="fileInput"
                  type="file"
                  onChange={e => {
                    e.preventDefault();
                    const ev = e.target;
                    const reader = new FileReader();
                    reader.readAsDataURL(ev.files![0]);
                    reader.onloadend = () =>
                      this.setState({
                        file: ev.files![0],
                        profileUrl: [reader.result],
                      });
                  }}
                />
              </div>
            </PhotoDiv>
          </RightPart>
        </DataDiv>
      </WrapForm>
    );
  }

  private handleSend = async () => {
    const {id, name, surname, active, identification, file, code} = this.state;
    const validate = notifyValidator(this);
    if (this.state.id) {
      const res = await fetchWithFeedback(
        EmployeesControlServices.updateEmployee({
          id,
          active,
          code,
          identification,
          name,
          file,
          surname,
        }),
        {returnConfirmation: true, showMessage: true, notifyValidator: validate},
      );
      if (res) {
        this.setState({file: ''});
        this.inputRef.current!.value = '';
        this.props.updateEmployee();
        this.props.handleClose();
      }
    } else {
      const res = await fetchWithFeedback(
        EmployeesControlServices.createEmployee({
          name,
          surname,
          active,
          identification,
          file,
          code,
        }),
        {returnConfirmation: true, showMessage: true, notifyValidator: validate},
      );
      if (res) {
        this.setState(initialState);
        this.inputRef.current!.value = '';
        this.props.updateEmployee();
      }
    }
  };

  private handleInputs: HandleNamedChange<State> = name => e => {
    this.setState({[name]: e.target.value, [`${name}Valid`]: true} as Pick<
      State,
      keyof State
    >);
  };

  private handleCheckbox = () => {
    this.setState({active: !this.state.active});
  };

  // private handleErrors = (errors: {[k: string]: boolean}) => {
  //   const errorsObject = {
  //     codeValid: true,
  //     nameValid: true,
  //     surnameValid: true,
  //     identificationValid: true,
  //   };
  //   const keys = Object.keys(errors);
  //   for (const error of keys) {
  //     if (error === 'code') {
  //       errorsObject.codeValid = false;
  //     } else if (error === 'name') {
  //       errorsObject.nameValid = false;
  //     } else if (error === 'surname') {
  //       errorsObject.surnameValid = false;
  //     } else if (error === 'identification') {
  //       errorsObject.identificationValid = false;
  //     }
  //   }
  //   this.setState(errorsObject as State);
  // };
}
const LeftPart = styled.div`
  width: 55%;
`;
const RightPart = styled.div`
  width: 47%;
  display: flex;
  flex-direction: column;
`;
const DataDiv = styled.div`
  display: flex;
`;
const PhotoDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const selectorJobtypes = {
  menu: (provided: any) => ({
    ...provided,
    height: 200,
  }),
  menuList: (provided: any) => ({
    ...provided,
    height: 200,
  }),
};
