export const customFormatNumber = (value: number, roundDecimals: boolean = false ) => {

    if(roundDecimals){
        value = Math.round(value * 100) / 100
    }
    const SEPARATOR = '.'; // separador para los miles
    const SEPARATOR_DECIMAL = ','; // separador para los decimales

    const valueStr = value + '';
    const splitStr = valueStr.split('.');
    let splitLeft = splitStr[0];
    const splitRight = splitStr.length > 1 ? SEPARATOR_DECIMAL + splitStr[1] : '';
    const regx = /(\d+)(\d{3})/;
    while (regx.test(splitLeft)) {
        splitLeft = splitLeft.replace(regx, '$1' + SEPARATOR + '$2');
    }

    if (splitLeft + splitRight === 'null' || splitLeft + splitRight === 'NaN') {
        return '';
    }

    return splitLeft + splitRight;
};
