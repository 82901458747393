import * as React from 'react';
import {connect} from 'react-redux';
import {RowInfo} from 'react-table';
import styled from 'styled-components';

import {PalletDefinitionCstmFieldsControlServices} from 'src/app/administration/palletDefinitionCstmFieldsControl/store/palletDefinitionCstmFieldsControl.services';
import {PalletDefinitionCstmField} from 'src/app/administration/palletDefinitionCstmFieldsControl/store/palletDefinitionCstmFieldsControl.types';
import {PalletDefinitionCstmFieldForm} from 'src/app/administration/palletDefinitionCstmFieldsControl/PalletDefinitionCstmFieldForm';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {Checkbox} from '@material-ui/core';
import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';

type SProps = {
    userData: ReturnType<typeof LoginSelectors.getUserData>;
};
type State = Readonly<{
    open: boolean;
    selectedPalletDefinitionCstmField?: PalletDefinitionCstmField;
}>;

const initialState: State = {
    open: false,
    selectedPalletDefinitionCstmField: undefined,
};

export class PalletDefinitionCstmFieldsControlBase extends React.Component<SProps, State> {
    readonly state = initialState;

    // private slectedEmployees: Array<{employeeId: string; rules: string[]}> = [];
    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    private handleClosePalletDefinitionCstmField = () => {
        this.setState({open: false, selectedPalletDefinitionCstmField: undefined});
        this.useTableHook();
    };
    private handleOpenNewPalletDefinitionCstmField = () => this.setState({open: true});
    private handleOpenPalletDefinitionCstmField = (selectedPalletDefinitionCstmField?: PalletDefinitionCstmField) => this.setState({open: true, selectedPalletDefinitionCstmField});

    private handleUpdatePalletDefinitionCstmField = () => {
        this.useTableHook();
        // this.setState({open: false, selectedDevice: undefined});
    };

    private columns: ColumnType<PalletDefinitionCstmField> = [
        {
            Header: 'Nombre Campo',
            headerClassName: 'header_cell_string',
            accessor: 'name',
            filterable: true,
            className: 'cell_string',
        },
        {
            Header: 'Descripción',
            headerClassName: 'header_cell_string',
            accessor: 'description',
            filterable: true,
            className: 'cell_string',
        },
        {
            Header: 'Código ERP',
            headerClassName: 'header_cell_string',
            accessor: 'code_erp',
            filterable: true,
            className: 'cell_string',
        },
        {
            Header: 'ID ERP',
            headerClassName: 'header_cell_string',
            accessor: 'id_erp',
            filterable: true,
            className: 'cell_string',
        },
        {
            Header: 'Activo',
            accessor: 'active',
            width: 190,
            Cell: item => <Checkbox checked={!!+item.original.active} />,
            centerCell: true,
        },
        {
            Header: 'Orden',
            headerClassName: 'header_cell_number',
            accessor: 'order_item',
            className: 'cell_number',
            sortable: true,
            width: 120,
            filterable: true,
        },
    ];

    render() {
        const {open, selectedPalletDefinitionCstmField} = this.state;
        return (
            IsInRuleset(['PALLET_DEFINITION_CSTM_FIELDS']) && (
                <TableWrap>
                    <TableModal title={'Gestión del campo personalizado de palé'} open={open} handleClose={this.handleClosePalletDefinitionCstmField}>
                        <PalletDefinitionCstmFieldForm
                            palletDefinitionCstmField={selectedPalletDefinitionCstmField}
                            handleClose={this.handleClosePalletDefinitionCstmField}
                            updatePalletDefinitionCstmField={this.handleUpdatePalletDefinitionCstmField}
                        />
                    </TableModal>
                    <TableComponent
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        service={PalletDefinitionCstmFieldsControlServices.getAll}
                        getTableFetchHook={this.getTableHook}
                        accessor={'palletDefinitionCstmFields'}
                        createHook={IsInRuleset('PALLET_DEFINITION_CSTM_FIELDS') ? this.handleOpenNewPalletDefinitionCstmField : undefined}
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: RowInfo) => ({
                                onClick: (evt: any) => {
                                    if (!evt.target.className.includes('expand') && IsInRuleset('PALLET_DEFINITION_CSTM_FIELDS')) {
                                        this.handleOpenPalletDefinitionCstmField(rowInfo.original);
                                    }
                                },
                                style: {
                                    cursor: 'pointer',
                                },
                            }),
                        }}
                    />
                </TableWrap>
            )
        );
    }
}

export const PalletDefinitionCstmFieldsControl = connect<SProps, {}, {}, AppStoreState>(state => ({
    userData: LoginSelectors.getUserData(state),
}))(PalletDefinitionCstmFieldsControlBase);

const TableWrap = styled.div`
    width: 100%;
    height: 100%;
    margin: auto;
`;