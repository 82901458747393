import {CardControlAction} from '../types';
// import {Action} from 'redux';
import {CardControlActionTypes} from '../types/cardcontrol.action-types';

export const CardControlActions = {
  setActive: (activeSessionCode: string): CardControlAction => ({
    type: CardControlActionTypes.setActive,
    payload: activeSessionCode,
  }),
  setInactive: (activeSessionCode?: string): CardControlAction => ({
    type: CardControlActionTypes.setInactive,
    payload: activeSessionCode,
  }),
};
