import {minibatchScheme} from './minibatch';

export const palletScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['id', 'boxes_count', 'boxes_total', 'started_at', 'finished_at', 'batches'],
  properties: {
    id: {type: 'string'},
    boxes_count: {type: 'number'},
    boxes_total: {type: 'number'},
    started_at: {type: 'string'},
    finished_at: {oneOf: [{type: 'string'}, {type: 'null'}]},
    batches: {
      oneOf: [{type: 'array', items: minibatchScheme}, {type: 'null'}],
    },
  },
};
