import {ajv} from 'src/utils/ajv';

import {boxScheme} from './schemes/box';

const boxesResScheme = {
  additionalProperties: false,
  type: 'object',
  required: ['boxes'],
  properties: {
    boxes: {oneOf: [{type: 'array', items: boxScheme}, {type: 'null'}]},
  },
};

const precompile = ajv.compile(boxesResScheme);
export const boxesValidate = precompile;
