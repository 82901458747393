import {Reducer} from 'redux';

import {LoginActions, LoginActionTypes, LogInPayload} from 'src/app/login/store/login.action-types';

export type LoginStoreState = LogInPayload;

const initialState = {};

export const LoginReducer: Reducer<LoginStoreState, LoginActions> = (state = initialState as any, action) => {
    if (!state) return initialState;
    switch (action.type) {
        case LoginActionTypes.login:
            return action.payload;
        case LoginActionTypes.logout:
            return {} as any;
        default:
            return state;
    }
};
