import React from 'react';
import {Snackbar} from '@material-ui/core';
import {CustomSnackbar} from '../../utils/Notification/Wrap';
import {NotifyActionType} from '../../utils/Notification/action-types';

export interface Props {
    open: boolean;
    message: string;
    hide: () => void;
    type: NotifyActionType;
    autoHideDuration?: number;
}

export class Advertisement extends React.Component<Props> {
    render() {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={this.props.open}
                autoHideDuration={this.props.autoHideDuration || 6000}
                onClose={this.props.hide}
                message={this.props.message}
                ContentProps={{'aria-describedby': 'message-id'}}
            >
                <CustomSnackbar variant={this.props.type} message={this.props.message} onClose={this.props.hide} />
            </Snackbar>
        );
    }
}
