import debounce from 'lodash/debounce';
import * as React from 'react';
import {connect} from 'react-redux';
import Select from 'react-select';
import animated from 'react-select/lib/animated';
import AsyncSelect from 'react-select/lib/Async';
import styled from 'styled-components';

import {EmployeesControlServices} from 'src/app/administration/employeesControl/store/employeesControl.services';
import {WrapForm, WrapSaveButton} from 'src/app/administration/export/styled/Wrap';
import {RulegroupsControlServices} from 'src/app/grants/rulegroupsControl/store/rulegroupsControl.services';
import {UsersControlServices} from 'src/app/grants/usersControl/store/usersControl.services';
import {UsersControlUnit} from 'src/app/grants/usersControl/store/usersControl.types';
import {Checkbox, FormControlLabel, InputAdornment, TextField, Tooltip, Typography} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import {PaginatedTableSelectors} from 'src/shared/components/store/paginatedTable.selectors';
import {TableModal} from 'src/shared/components/Modal';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {CustomButton} from 'src/shared/style';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import { CenterControlServices } from 'src/app/administration/centerControl/store/centerControl.services';

type SProps = {
    tableFilters: ReturnType<typeof PaginatedTableSelectors.getFilters>;
};
type State = Readonly<{
    id: string;
    username: string;
    password: string;
    banned: boolean;
    showPassword: boolean;
    passwordValid: boolean;
    repeatPassword: string;
    showRepeatPassword: boolean;
    passwordsMatch: boolean;
    massSelect: boolean;
    usernameValid: boolean;
    selectedUsers: string[];
    open: boolean;
    massOpen: boolean;
    selectedEmployee?: {label: string; value: string};
    rulegroupsList: Array<{value: string; label: string}>;
    selectedRulegroups: Array<{value: string; label: string}>;
    centersList: Array<{value: string; label: string}>;
    selectedCenters: Array<{value: string; label: string}>;
}>;
const initialState: State = {
    id: '',
    username: '',
    password: '',
    repeatPassword: '',
    open: false,
    massOpen: false,
    banned: false,
    massSelect: false,
    showPassword: false,
    selectedUsers: [],
    showRepeatPassword: false,
    passwordValid: true,
    passwordsMatch: true,
    usernameValid: true,
    rulegroupsList: [],
    selectedRulegroups: [],
    centersList: [],
    selectedCenters: [],
};

export class UsersControlBase extends React.Component<SProps, State> {
    readonly state = initialState;
    private tableHook: () => void = () => ({});
    private debounced = debounce((fn: () => any) => fn(), 400);
    private getTableHooks = (fn: () => void) => {
        this.tableHook = fn;
    };
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    componentDidMount() {
        this.fetchRulegroups();
        this.fetchCenters();

    }

    private fetchRulegroups = async () => {
        const rulegroupsList = await fetchWithFeedback(RulegroupsControlServices.get(), {
            accessor: 'rulegroups',
        });
        this.setState({rulegroupsList: this.parseSelectable(rulegroupsList) || []});
    };

    private fetchCenters = async () => {
        const centersList = await fetchWithFeedback(CenterControlServices.get());
        this.setState({centersList: this.parseSelectable(centersList) || []});
    };

    private parseSelectable = (
        rulegroups: Array<{
            id: string;
            name: string;
        }>,
    ): Array<{value: string; label: string}> => rulegroups.map(rg => ({value: rg.id, label: rg.name}));

    private handleChange: HandleNamedChange<State> = name => e => {
        this.setState({[name]: e.target.value, passwordsMatch: true} as Pick<State, keyof State>);
    };

    private handleClose = () =>
        this.setState({
            open: false,
            id: '',
            passwordValid: true,
            passwordsMatch: true,
            password: '',
            repeatPassword: '',
            banned: false,
            usernameValid: true,
            username: '',
            selectedEmployee: undefined,
            selectedRulegroups: [],
            selectedCenters: [],
        });

    private handleCloseMass = () =>
        this.setState({
            massOpen: false,
            selectedRulegroups: [],
        });

    private handleOpenMass = () => {
        if (this.state.selectedUsers.length)
            this.setState({
                massOpen: true,
                selectedRulegroups: [],
            });
    };

    private handleOpen = () => {
        this.setState({
            open: true,
            id: '',
            passwordValid: true,
            passwordsMatch: true,
            banned: false,
            usernameValid: true,
            username: '',
            password: '',
            repeatPassword: '',
            selectedEmployee: undefined,
            selectedRulegroups: [],
            selectedCenters: [],
        });
    };

    private handleSelect = (user: UsersControlUnit) =>
        this.setState({
            open: true,
            id: user.id,
            username: user.username,
            passwordValid: true,
            passwordsMatch: true,
            usernameValid: true,
            banned: user.banned,
            selectedEmployee: user.employee
                ? {
                      value: user.employee.id,
                      label: `${user.employee.name} ${user.employee.surname} ${user.employee.code}`,
                  }
                : undefined,
            selectedRulegroups: this.parseSelectable(user.rulegroups),
            selectedCenters: this.parseSelectable(user.centers),
        });

    private handleSending = async () => {
        const {id, username, password, banned, selectedEmployee} = this.state;
        const fields = this.checkFields();
        if (fields.usernameValid && fields.passwordValid && fields.passwordsMatch) {
            if (id) {
                await fetchWithFeedback(
                    UsersControlServices.update({
                        id: id,
                        username,
                        password: password || undefined,
                        banned,
                        employee_id: selectedEmployee ? selectedEmployee.value : '',
                        rulegroupsIds: this.state.selectedRulegroups.map(rg => rg.value),
                        centersIds: this.state.selectedCenters.map(rg => rg.value),
                    }),
                    {showMessage: true},
                );
                this.handleClose();
            } else {
                await fetchWithFeedback(
                    UsersControlServices.create({
                        username,
                        password,
                        banned,
                        employee_id: selectedEmployee ? selectedEmployee.value : '',
                        rulegroupsIds: this.state.selectedRulegroups.map(rg => rg.value),
                    }),
                    {showMessage: true},
                );
                this.setState({
                    id: '',
                    username: '',
                    password: '',
                    repeatPassword: '',
                    banned: false,
                    selectedEmployee: undefined,
                    selectedRulegroups: [],
                    selectedCenters: [],
                });
            }
            this.useTableHook();
        }
    };

    private checkFields = () => {
        const {username, password, repeatPassword} = this.state;
        const checks: any = {password: true, passwordsMatch: true, username: true};
        if (username.length < 5) checks.username = false;
        if (password.length < 8 && password.length !== 0) checks.password = false;
        if (repeatPassword !== password) checks.passwordsMatch = false;
        this.setState({
            passwordValid: checks.password,
            passwordsMatch: checks.passwordsMatch,
            usernameValid: checks.username,
        });
        return {
            passwordValid: checks.password,
            passwordsMatch: checks.passwordsMatch,
            usernameValid: checks.username,
        };
    };

    private handleSelection = (user: UsersControlUnit) => {
        if (!user) return;
        const {id} = user;
        const {selectedUsers} = this.state;
        const exist = selectedUsers.find(it => it === id);
        if (exist) {
            this.setState({
                selectedUsers: selectedUsers.filter(it => it !== id),
                massSelect: false,
            });
        } else {
            this.setState({
                selectedUsers: selectedUsers.concat(id),
                massSelect: false,
            });
        }
    };

    private fireSearch = (inputValue: string) => {
        return new Promise(res => {
            this.debounced(async () => {
                const result = await this.searchFunction(inputValue);
                if (result) res(result);
            });
        });
    };

    private searchFunction = async (inputValue: string): Promise<Array<{value: string; label: string}> | void> => {
        let options: any[] = [];
        let result: any[] = [];

        if (!isNaN(+inputValue)) {
            result = await this.searchCall('code', inputValue);
            options = options.concat(result);
        } else {
            result = await this.searchCall('name', inputValue);
            options = options.concat(result);
            result = await this.searchCall('surname', inputValue);
            options = options.concat(result.filter(op => options.findIndex(res => res.value === op.value) < 0));
        }
        return options;
    };

    private searchCall = async (key: string, inputValue: string) => {
        const response = await fetchWithFeedback(
            EmployeesControlServices.getAllEmployees({
                params: {page: 1, 'page-size': 9999, filtered: {[key]: inputValue}, sorted: []},
            }),
        );
        if (response) {
            return response.data.map((dt: any) => ({
                label: `${dt.name} ${dt.surname} ${dt.code}`,
                value: dt.id,
            }));
        }
    };

    private onEmployeeSelect = (e: any) => this.setState({selectedEmployee: e});

    private handleSendMass = async () => {
        if (!this.state.selectedUsers.length) return;
        // const employeesIds = this.state.selectedUsers.map(e => e);
        const {selectedRulegroups} = this.state;
        await fetchWithFeedback(
            UsersControlServices.toRulegroups({
                ids: this.state.selectedUsers,
                rulegroupIds: selectedRulegroups.map(srg => srg.value),
            }),
            {showMessage: true},
        );

        this.useTableHook();
    };

    private columns: ColumnType = [
        {
            Header: 'Usuario (login)',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'username',
            className: 'cell_string',
        },
        {
            Header: 'Acceso prohibido?',
            accessor: 'banned',
            sortable: true,
            dropdownFilter: true,
            dropdownFilterData: [
                {id: 'true', name: 'Prohibido'},
                {id: 'false', name: 'Permitido'},
            ],
            width: 140,
            Cell: item => <Checkbox checked={!!+item.original.banned} />,
            centerCell: true,
        },
        {
            Header: 'Nombre',
            headerClassName: 'header_cell_string',
            filterable: true,
            id: 'name',
            Cell: item => <>{item.original.employee ? item.original.employee.name : 'no tiene empleado asociado'}</>,
            className: 'cell_string',
        },
        {
            Header: 'Apellidos',
            headerClassName: 'header_cell_string',
            filterable: true,
            id: 'surname',
            sortable: false,
            Cell: item => <>{item.original.employee ? item.original.employee.surname : ''}</>,
            className: 'cell_string',
        },
        {
            Header: 'Código de empleado',
            headerClassName: 'header_cell_string',
            filterable: true,
            id: 'code',
            sortable: false,
            Cell: item => <>{item.original.employee ? item.original.employee.code : ''}</>,
            className: 'cell_string',
        },
        {
            Header: 'Grupos de Acceso',
            headerClassName: 'header_cell_string',
            filterable: true,
            id: 'rulegroup',
            sortable: false,
            Cell: item => {
                const {rulegroups} = item.original;
                return <>{rulegroups && rulegroups.length ? rulegroups.map((rg: any, indx: number) => <p key={indx}>- {rg.name} </p>) : 'NO TIENE'}</>;
            },
            className: 'cell_string',
        },
    ];
    render() {
        const {
            id,
            username,
            banned,
            selectedRulegroups,
            selectedCenters,
            usernameValid,
            open,
            password,
            passwordsMatch,
            passwordValid,
            rulegroupsList,
            centersList,
            showPassword,
            showRepeatPassword,
            repeatPassword,
            selectedEmployee,
            massOpen,
        } = this.state;
        
        return (
            <TableWrap>
                <TableModal title={'Gestión del los Grupos de Acceso'} open={massOpen} handleClose={this.handleCloseMass}>
                    <WrapForm margin={'5px 10px'}>
                        <Typography component="div" style={{overflow: 'inherit', minHeight: 150, width: 300}}>
                            <Select
                                backspaceRemovesValue={false}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                isClearable={false}
                                styles={customStylesSmall}
                                // @ts-ignore
                                components={animated()}
                                options={rulegroupsList}
                                placeholder={'Seleccionar roles de empleados...'}
                                value={selectedRulegroups}
                                onChange={sel => this.setState({selectedRulegroups: sel as any})}
                            />
                        </Typography>
                        <WrapSaveButton>
                            <CustomButton
                                // bgcolor={'#34baa2'}
                                onClick={this.handleSendMass}
                                width={'80%'}
                                margin="auto"
                            >
                                Guardar selección
                            </CustomButton>
                        </WrapSaveButton>
                    </WrapForm>
                </TableModal>
                <TableModal title={'Gestión del usuario'} open={open} handleClose={this.handleClose} maxWidth={'false'}>
                    <div style={{width: 800}}>

                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >

                            <div style={{flexGrow: 4}}>
                                <TextField
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        //width: '100%',
                                        marginTop: 20,
                                        marginBottom: 15,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title={'El nombre de usuario debe ser entre 5 y 50 carácteres.'} placement="top">
                                                    <HelpIcon />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                    label="Nombre de usuario"
                                    autoFocus={true}
                                    required={true}
                                    error={!usernameValid}
                                    name="username"
                                    variant="outlined"
                                    onChange={this.handleChange('username')}
                                    value={username}
                                />
                            </div>
                            <div style={{flexGrow: 4}}>
                                <TextField
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        //width: '100%',
                                        marginTop: 20,
                                        marginBottom: 15,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                <InputAdornment position="end" style={{cursor: 'pointer'}} onClick={() => this.setState({showPassword: !showPassword})}>
                                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </InputAdornment>
                                                <InputAdornment position="end">
                                                    <Tooltip title={'La contraseña de usuario debe ser entre 8 y 50 carácteres.'} placement="top">
                                                        <HelpIcon />
                                                    </Tooltip>
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                    label="Contaseña"
                                    name="password"
                                    error={!passwordValid || !passwordsMatch}
                                    type={showPassword ? 'text' : 'password'}
                                    required={true}
                                    variant="outlined"
                                    onChange={this.handleChange('password')}
                                    value={password}
                                />
                            </div>
                            <div style={{flexGrow: 4}}>
                                <TextField
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        //width: '100%',
                                        marginTop: 20,
                                        marginBottom: 15,
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                <InputAdornment position="end" style={{cursor: 'pointer'}} onClick={() => this.setState({showRepeatPassword: !showRepeatPassword})}>
                                                    {showRepeatPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </InputAdornment>
                                                <InputAdornment position="end">
                                                    <Tooltip title={'La contraseña de usuario debe ser entre 8 y 50 carácteres.'} placement="top">
                                                        <HelpIcon />
                                                    </Tooltip>
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                    label="Repetir contaseña"
                                    name="password"
                                    error={!passwordValid || !passwordsMatch}
                                    required={true}
                                    type={showRepeatPassword ? 'text' : 'password'}
                                    variant="outlined"
                                    onChange={this.handleChange('repeatPassword')}
                                    value={repeatPassword}
                                />
                            </div>
                        </Typography>


                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div style={{flexGrow: 10}}>
                                <label>Empleado asociado</label>
                                <AsyncSelect
                                    backspaceRemovesValue={false}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    menuPlacement="top"
                                    styles={customStyles}
                                    // @ts-ignore
                                    components={animated()}
                                    cacheOptions={true}
                                    placeholder={'Asociar a empleado...'}
                                    value={selectedEmployee}
                                    loadOptions={this.fireSearch}
                                    onChange={this.onEmployeeSelect}
                                />
                            </div>
                            <div style={{flexGrow: 2}}>
                                <FormControlLabel
                                    style={{marginLeft: 30, marginTop: 15}}
                                    control={<Checkbox checked={banned} onChange={() => this.setState({banned: !banned})} value="activeCB" />}
                                    label="Desactivado?"
                                />
                            </div>
                        </Typography>

                        <Typography
                            component="div"
                            style={{
                                marginBottom: 10,
                                marginTop: 5,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <div style={{flexGrow: 4, maxHeight: 100, height: 100, width: '45%', marginRight: '10px'}}>
                                {id && (
                                    <>
                                        <div style={{margin: '0 auto'}}>
                                            <label>Grupos de acceso</label>
                                            <Select
                                                backspaceRemovesValue={false}
                                                isMulti={true}
                                                menuPlacement="top"
                                                styles={customStyles}
                                                closeMenuOnSelect={false}
                                                isClearable={false}
                                                // @ts-ignore
                                                components={animated()}
                                                options={rulegroupsList}
                                                placeholder={'Grupos de acceso...'}
                                                value={selectedRulegroups}
                                                onChange={sel => this.setState({selectedRulegroups: sel as any})}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div style={{flexGrow: 4, maxHeight: 100, minHeight: 100, width: '45%', marginLeft: '10px'}}>
                                {id && (
                                    <div style={{margin: '0 auto 5px auto'}}>
                                        <label>Centros acceso</label>
                                        <Select
                                                backspaceRemovesValue={false}
                                                isMulti={true}
                                                menuPlacement="top"
                                                styles={customStyles}
                                                closeMenuOnSelect={false}
                                                isClearable={false}
                                                // @ts-ignore
                                                components={animated()}
                                                options={centersList}
                                                placeholder={'Centros acceso...'}
                                                value={selectedCenters}
                                                onChange={sel => this.setState({selectedCenters: sel as any})}
                                            
                                        />
                                    </div>
                                )}
                            </div>
                        </Typography>
                        <CustomButton onClick={this.handleSending} width={'100%'}>
                            {this.state.id ? 'Guardar' : 'Crear'} usuario
                        </CustomButton>
                    </div>
                </TableModal>
                <PaginatedTableComponent
                    defaultPageSize={13}
                    columnFormat={this.columns}
                    service={UsersControlServices.get}
                    createHook={this.handleOpen}
                    getTableFetchHook={this.getTableHooks}
                    toStore={'users_control'}
                    additionalButtons={[
                        {
                            tooltipText: 'Asignación masiva',
                            icon: <PlaylistAddCheck />,
                            onClick: () => this.handleOpenMass(),
                        },
                    ]}
                    propsToOwnTable={{
                        getTrProps: (_: any, rowInfo: any) => ({
                            onClick: (evt: any) => {
                                if (evt.target.id.includes('cb-')) this.handleSelection(rowInfo.original);
                                else if (!id) this.handleSelect(rowInfo.original);
                                else this.handleClose();
                            },
                            style: {cursor: 'pointer'},
                        }),
                    }}
                />
            </TableWrap>
        );
    }
}

export const UsersControl = connect<SProps, {}, {}, AppStoreState>(state => ({
    tableFilters: PaginatedTableSelectors.getFilters('users_control', state),
}))(UsersControlBase);

const TableWrap = styled.div`
    width: 78%;
    height: 100%;
    margin: auto;
`;

const customStyles = {
    menu: (provided: any) => ({
        ...provided,
        height: 100,
    }),
    menuList: (provided: any) => ({
        ...provided,
        height: 100,
    }),
};

const customStylesSmall = {
    menu: (provided: any) => ({
        ...provided,
        height: 126,
    }),
    menuList: (provided: any) => ({
        ...provided,
        height: 126,
    }),
};

/****  BADGE */

// type BadgeProps = {
//   display: boolean;
//   content?: string;
//   margin?: string;
//   classes?: any;
//   color?: 'primary' | 'secondary' | 'default' | 'error';
//   style?: {[k: string]: any};
// };

// const styles = {
//   badge: {
//     top: 14,
//     padding: 3,
//     right: -15,
//     width: 20,
//     height: 20,
//   },
// };

// const CustomBadgeBase: React.FunctionComponent<BadgeProps> = props => {
//   return (
//     <div
//       style={{
//         margin: props.margin,
//       }}>
//       <Badge
//         component={'div'}
//         // tslint:disable-next-line
//         title="Selección TOTAL, selecciona todos los empleados con los criteros de los filtros"
//         classes={{badge: props.classes.badge}}
//         invisible={!props.display}
//         badgeContent={props.content || ''}
//         color={props.color || 'primary'}>
//         {props.children}
//       </Badge>
//     </div>
//   );
// };

// const CustomBadge = withStyles(styles)(CustomBadgeBase);
