import * as React from 'react';
import {connect, MapDispatchToProps} from 'react-redux';
import {RowInfo} from 'react-table';
import {Dispatch} from 'redux';
import styled from 'styled-components';

import {BargunsControlServices} from 'src/app/administration/bargunsControl/store/bargunsControl.services';
import {BargunBaseUnit} from 'src/app/administration/bargunsControl/store/bargunsControl.types';
import {BargunForm} from 'src/app/administration/bargunsControl/BargunForm';
import {DevicesControlServices} from 'src/app/administration/devicesControl/store/devicesControl.services';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

type SProps = {
    userData: ReturnType<typeof LoginSelectors.getUserData>;
};
type DProps = {
    dispatch: Dispatch;
};
type State = Readonly<{
    open: boolean;
    openPreview: boolean;
    selectedUrl: string;
    selectedBargun?: BargunBaseUnit;
    devicesList: Array<{id: string; name: string}>;
    bargunBaseTypes: any[];
}>;

const initialState: State = {
    open: false,
    openPreview: false,
    selectedUrl: '',
    selectedBargun: undefined,
    devicesList: [],
    bargunBaseTypes: [],
};

// const TOTAL_ACCESS =
//   typeof IsInRuleset === 'function' ? IsInRuleset('BARGUNBASES_TOTAL') : false;
// const VIEW_ACCESS =
//   typeof IsInRuleset === 'function' ? IsInRuleset('BARGUNBASES_VIEW') : false;

export class BargunsControlBase extends React.Component<SProps & DProps, State> {
    readonly state = initialState;

    componentDidMount() {
        this.fetchDevices();
        this.fetchAutomataType();
    }
    // private slectedEmployees: Array<{employeeId: string; rules: string[]}> = [];
    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    private handleClose = () => this.setState({open: false, selectedBargun: undefined});
    private handleEmptyOpen = () => this.setState({open: true});
    private handleOpen = (selectedBargun?: BargunBaseUnit) => this.setState({open: true, selectedBargun});
    private handleUpdate = () => this.useTableHook();

    private fetchDevices = async () => {
        const devicesList = await fetchWithFeedback(DevicesControlServices.getAll(), {
            accessor: 'devices',
        });
        this.setState({devicesList});
    };
    private fetchAutomataType = async () => {
        const bargunBaseTypes = await fetchWithFeedback(BargunsControlServices.getAllTypes(), {accessor: 'bargunBaseTypes'});
        this.setState({bargunBaseTypes});
    };

    private columns: ColumnType<BargunBaseUnit> = [
        {
            Header: 'Nombre',
            headerClassName: 'header_cell_string',
            accessor: 'name',
            className: 'cell_string',
        },
        {
            Header: 'Dirección IP',
            headerClassName: 'header_cell_string',
            accessor: 'address',
            className: 'cell_string',
        },
        {
            Header: 'Puerto',
            headerClassName: 'header_cell_string',
            accessor: 'port',
            width: 70,
            className: 'cell_string',
        },
        {
            Header: 'Tipo',
            headerClassName: 'header_cell_string',
            accessor: 'type',
            className: 'cell_string',
        },
    ];

    render() {
        const {open, selectedBargun, devicesList, bargunBaseTypes} = this.state;
        const {dispatch} = this.props;
        return (
            IsInRuleset(['BARGUNBASES_TOTAL', 'BARGUNBASES_VIEW']) && (
                <TableWrap>
                    <TableModal title={'Gestión de la base'} open={open} handleClose={this.handleClose}>
                        <BargunForm
                            devicesList={devicesList}
                            dispatch={dispatch}
                            bargun={selectedBargun}
                            handleClose={this.handleClose}
                            updateBargun={this.handleUpdate}
                            bargunBaseTypes={bargunBaseTypes}
                        />
                    </TableModal>
                    <TableComponent
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        service={BargunsControlServices.getAll}
                        getTableFetchHook={this.getTableHook}
                        accessor={'bargunBases'}
                        createHook={IsInRuleset('BARGUNBASES_TOTAL') ? this.handleEmptyOpen : undefined}
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: RowInfo) => ({
                                onClick: () => IsInRuleset('BARGUNBASES_TOTAL') && this.handleOpen(rowInfo.original),
                                style: {cursor: 'pointer'},
                            }),
                        }}
                    />
                </TableWrap>
            )
        );
    }
}

const md2p: MapDispatchToProps<DProps, {}> = dispatch => ({
    dispatch,
});

export const BargunsControl = connect<SProps, DProps, {}, AppStoreState>(
    state => ({
        userData: LoginSelectors.getUserData(state),
    }),
    md2p,
)(BargunsControlBase);

const TableWrap = styled.div`
    width: 60%;
    height: 100%;
    margin: auto;
`;
