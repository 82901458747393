import {Grant, Grantset} from 'src/global/authorization/grantSet';
import {GrantGroupsRanks} from 'src/global/authorization/ranking';

// import {RANKED} from 'src/global/authorization/ranking';

export type NormalizedGrantset = {
  category: string;
  same_category: string;
  grants: NormalizedGrantOfSet[];
};
export type NormalizedGrantOfSet = {
  name: string;
  same_name: string;
  key: string;
};

// export const finalNormalizeGrants = (parsed: any) => {
//   const final: any[] = [];

//   const RANKED_WITH_IDS = secondNormalizationStep(parsed);

//   RANKED_WITH_IDS.forEach(rid => {
//     const isInFinal = final.findIndex(f => f.category === rid.category);
//     if (isInFinal > -1) {
//       const category = final[isInFinal];
//       const isSameName = category.grants.findIndex((gr: any) => gr.name === rid.name);
//       if (isSameName === -1) {
//         console.log('RID>>', rid);
//         category.grants.push({name: rid.name, key: rid.key, ids: rid.values});
//       }
//     } else {
//       final.push({
//         category: rid.category,
//         grants: [{name: rid.name, key: rid.key, ids: rid.values}],
//       });
//     }
//   });
//   console.log('RANKED FINAL>> ', final);
//   return final;
// };

export const firstNormalizationStep = (grants: Grant[]) => {
  const parsed: any = [];
  grants.forEach(g => {
    let isAddedName = parsed[g.same_name];
    if (isAddedName) {
      if (!isAddedName.find((it: any) => it === g.id)) isAddedName = g.id;
    } else parsed[g.same_name] = g.id;
  });
  return parsed;
};

export const firstNormalizationStepNEW = (grantsets: Grantset[]) => {
  const parsed: any = [];
  grantsets.forEach(g => {
    const isAddedCategory = parsed.findIndex(
      (p: any) => p.same_category === g.same_category,
    );
    if (isAddedCategory > -1) {
      const category = parsed[isAddedCategory];

      const isAddedName = category.grants.findIndex(
        (gr: any) => gr.same_name === g.same_name,
      );
      if (isAddedName === -1)
        category.grants.push({name: g.name, same_name: g.same_name, key: g.id});
    } else {
      parsed.push({
        category: g.category,
        same_category: g.same_category,
        grants: [{name: g.name, same_name: g.same_name, key: g.id}],
      });
    }
  });
  return parsed;
};

// export const secondNormalizationStep = (parsed: any) => {
//   return RANKED.map(it => {
//     let ids: string[] | null = [];
//     for (const [key, value] of Object.entries(it)) {
//       if (!value.length || typeof value === 'string')
//         return {...it, values: [], key: key};
//       value.forEach(entry => {
//         const exist = parsed[entry];
//         ids = exist ? ids!.concat(parsed[entry]) : null;
//       });
//       return {...it, values: ids, key: key};
//     }
//     return it;
//   });
// };

// export const secondNormalizationStepKV = (parsed: any) => {
//   const dictionary: any = {};
//   RANKED.forEach(it => {
//     let ids: string[] | null = [];
//     for (const [key, value] of Object.entries(it)) {
//       if (value.length && typeof value !== 'string') {
//         value.forEach(entry => {
//           const exist = parsed[entry];
//           ids = exist ? ids!.concat(parsed[entry]) : null;
//         });
//         if (ids !== null) dictionary[key] = ids;
//       }
//     }
//   });
//   return dictionary;
// };

export const secondNormalizationGetValues = (parsed: {[k: string]: string}) => {
  if (!parsed) return [];
  const values: string[] = [];
  for (const [, value] of Object.entries(parsed)) values.push(value);
  return values;
};

export const secondNormalizationGetKeys = (grantsets: Grantset[]) => {
  if (!grantsets) return [];
  const keys: Array<keyof typeof GrantGroupsRanks> = [];
  for (const [, value] of Object.entries(grantsets)) keys.push(value.same_name);
  return keys;
};
