import {
  BargunsControlResponse,
  BargunBaseControlsCreateParams,
} from 'src/app/administration/bargunsControl/store/bargunsControl.types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/bargun-bases';

export const BargunsControlServices = {
  getAll: () => guardedFetcher.get<BargunsControlResponse>(prefix),
  getAllTypes: () => guardedFetcher.get(`${prefix}/types`),
  create: (params: BargunBaseControlsCreateParams) => guardedFetcher.post(prefix, params),
  update: (id: string, params: Partial<BargunBaseControlsCreateParams>) =>
    guardedFetcher.patch(`${prefix}/${id}`, params),
  delete: (id: string) => guardedFetcher.delete(`${prefix}/${id}`),
};
