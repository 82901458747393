import {Action} from 'redux';

export enum ParametersActionType {
  success = '[Parameters] Success',
  error = '[Parameters] Error',
}
export interface ParametersActions extends Action {
  readonly type: string;
  payload?: ParametersPayload;
}
export interface ParametersPayload {
  parameters: [];
}
