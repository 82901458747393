import invoke from 'lodash/invoke';
import * as React from 'react';
import styled from 'styled-components';

import {ParametersControlServices} from 'src/app/administration/parametersControl/store/parametersControl.services';
import {ParameterControlUnit} from 'src/app/administration/parametersControl/store/parametersControl.types';
import {ParameterControlPanel} from 'src/app/administration/parametersControl/ParameterControlPanel';
import {TasksControlServices} from 'src/app/administration/tasksControl/store/tasksControl.services';
import {TCTaskResponse} from 'src/app/administration/tasksControl/store/tasksControl.types';
// import {Button, Typography} from '@material-ui/core';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {notify} from 'src/utils/Notification';
import {Button} from '@material-ui/core';
import {ProdPartsServices} from 'src/app/lanemanager/prodparts/store/prodParts.services';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {GrantGroupsRanks} from 'src/global/authorization/ranking';

type State = {
    expanded: string | boolean;
    tasksList: TCTaskResponse['tasks'];
    parametersList: ParameterControlUnit[];
};
const initialState: Readonly<State> = {
    expanded: false,
    tasksList: [],
    parametersList: [],
};
export class ParametersControl extends React.Component<
    {
        internal?: boolean;
    },
    State
> {
    readonly state = initialState;
    componentDidMount() {
        this.fetchTasks();
        this.fetchParameters();
    }

    private fetchTasks = async () => {
        const tasksList = await fetchWithFeedback(TasksControlServices.getAll(), {
            accessor: 'tasks',
        });
        this.setState({tasksList});
    };

    private fetchParameters = async () => {
        const parametersList = await fetchWithFeedback(this.props.internal ? ParametersControlServices.geInternalParameters() : ParametersControlServices.getAppParameters(), {
            accessor: 'parameters',
        });
        this.setState({parametersList});
    };

    private handleChange = (panel: string, expanded: boolean) => this.setState({expanded: expanded ? panel : false});

    private handleChangeParameterValue = ({parameterId, value}: {parameterId: string; value: string}) => {
        const param = this.state.parametersList.find(ram => ram.id === parameterId);
        if (param) param.value = value;
        this.setState({
            parametersList: this.state.parametersList.map(par => {
                if (par.id === parameterId) {
                    return {...par, value};
                }
                return par;
            }),
        });
    };

    private handleParameterSave = async (parameterId: string) => {
        const parameter = this.state.parametersList.find(par => par.id === parameterId);
        if (parameter)
            await fetchWithFeedback(ParametersControlServices.save({parameterId, value: parameter.value}), {
                showMessage: true,
                successMessage: 'El parámetro se ha guardado con éxito',
            });
        else notify({message: 'Parametro no existe.', status: 'error'});
    };

    private callUpdateProdParts = async () => {
        console.log('import parts button clicked');
        try {
            await fetchWithFeedback(ProdPartsServices.updateProdParts(), {
                successMessage: 'Solicitud de actualización generada',
                errorMessage: 'Error al actualizar partes',
            });
        } catch (error) {
            console.error('Error al actualizar');
        }
    };

    render() {
        const {expanded, tasksList, parametersList} = this.state;
        const isSystem = IsInRuleset(GrantGroupsRanks.SYSTEM);

        return (
            <WrappingDiv>
                {invoke(parametersList, 'map', (param: ParameterControlUnit, index: number) => (
                    <ParameterControlPanel
                        key={index}
                        editable={isSystem ? true : param.edit_in_app}
                        expanded={expanded}
                        parameter={param}
                        // category={category}
                        // category={'Categoria'}
                        // chooseGrant={this.chooseGrant}
                        handleParameterSave={this.handleParameterSave}
                        handleChangeParameterValue={this.handleChangeParameterValue}
                        change={this.handleChange}
                        tasksList={param.name === 'TRANSIT_TASK_ID' ? tasksList : undefined}
                        // selectedGrants={selectedGrants}
                    />
                ))}
                <div style={{textAlign: 'center'}}>
                    <Button
                        style={{
                            marginTop: '15px',
                            width: 'auto',
                            height: '4.1%',
                            padding: '4 15',
                            fontWeight: 900,
                        }}
                        color="secondary"
                        variant="contained"
                        onClick={this.callUpdateProdParts}
                    >
                        Recargar partes
                    </Button>
                </div>
            </WrappingDiv>
        );
    }
}

const WrappingDiv = styled.div`
    width: calc(75%);
    margin: auto;
    min-height: calc(100% - 7px);
    background-color: #efefef;
    /* min-height: calc(100% - 5px); */
    /* margin-bottom: 5px; */
    padding: 5px;
    /* margin-bottom: 5px; */
`;
