// import {Map} from './reduce';
import * as api from './services';
import {actionTypes} from './types';

export const fetchMaps = () => (dispatch: any) => {
    dispatch({type: actionTypes.FETCH_MAPS + '_START'});
    return api
        .fetchMaps()
        .then(response => {
            dispatch({type: actionTypes.FETCH_MAPS + '_SUCCESS', playload: response.data});
        })
        .catch(() => {
            dispatch({type: actionTypes.FETCH_MAPS + '_FAILURE'});
        });
};

export const fetchMap = (mapId: string) => (dispatch: any) => {
    dispatch({type: actionTypes.FETCH_MAP + '_START', mapId: mapId});
    return api
        .fetchMap(mapId)
        .then(response => {
            dispatch({
                type: actionTypes.FETCH_MAP + '_SUCCESS',
                id: mapId,
                map: response.data,
            });
        })
        .catch(() => {
            dispatch({type: actionTypes.FETCH_MAP + '_FAILURE'});
        });
};
