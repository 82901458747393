import * as React from 'react';
import ReactTable, {RowInfo} from 'react-table';
import styled from 'styled-components';

// import {CenterControlServices} from 'src/app/administration/centerControl/store/centerControl.services';
import {RulegroupsControlServices} from 'src/app/grants/rulegroupsControl/store/rulegroupsControl.services';
import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {CustomButton, CustomTextField} from 'src/shared/style';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

type State = {
    id: string;
    name: string;
    idValid: boolean;
    nameValid: boolean;
    open: boolean;
    selectedRulegroup?: {id: string; name: string};
    usersRAW: any[];
    grantsRAW: any[];
};
const initialState: State = {
    id: '',
    name: '',
    open: false,
    idValid: true,
    nameValid: true,
    usersRAW: [],
    grantsRAW: [],
};

export class RulegroupsControl extends React.Component<{}, State> {
    readonly state = initialState;
    private tableHook: () => void = () => ({});
    private openNow: string = '';
    private columns: ColumnType<{id: string; name: string}> = [
        {
            Header: 'Grupos de acceso',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'name',
            className: 'cell_string',
        },
        {
            Header: 'Usuarios y Reglas',
            width: 125,
            sortable: false,
            expander: true,
            style: {
                cursor: 'pointer',
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
            },
        },
    ];
    private handleChange: HandleNamedChange<State> = name => e => this.setState({[name]: e.target.value} as Pick<State, keyof State>);

    private handleOpenRulegroup = (selectedRulegroup: {id: string; name: string}) =>
        this.setState(
            {
                open: true,
                id: selectedRulegroup.id,
                name: selectedRulegroup.name,
                nameValid: true,
            },
            this.fetchSelectedData,
        );
    private handleExpandRulegroup = (selectedRulegroup?: State['selectedRulegroup']) =>
        this.setState({selectedRulegroup, usersRAW: [], grantsRAW: []}, () => this.fetchSelectedData(selectedRulegroup ? selectedRulegroup.id : 'NO_HAY_RULEGROUP'));

    private fetchSelectedData = async (id?: string) => {
        const {selectedRulegroup, usersRAW, grantsRAW} = this.state;
        if (id !== this.openNow && !usersRAW.length && !grantsRAW.length) {
            const users = await fetchWithFeedback(
                RulegroupsControlServices.getUsers({
                    id: selectedRulegroup ? selectedRulegroup.id : 'NO_NAY_GRUPO_DE_ACCESO',
                }),
                {accessor: 'users'},
            );
            const grants = await fetchWithFeedback(
                RulegroupsControlServices.getGrantset({
                    id: selectedRulegroup ? selectedRulegroup.id : 'NO_NAY_GRUPO_DE_ACCESO',
                }),
                {accessor: 'grantsets'},
            );
            this.setState({usersRAW: users, grantsRAW: grants});
            this.openNow = id || '';
        } else this.openNow = '';
    };

    private handleClose = () =>
        this.setState({
            open: false,
            id: '',
            idValid: true,
            name: '',
            nameValid: true,
        });

    private handleOpen = () => {
        this.setState({
            open: true,
            id: '',
            idValid: true,
            name: '',
            nameValid: true,
        });
    };

    private handleSending = async () => {
        const {id, name, nameValid} = this.state;
        // we update name if we have selec
        if (id && name && nameValid) {
            await fetchWithFeedback(RulegroupsControlServices.update({id, name}), {
                showMessage: true,
            });
            this.handleClose();
        } else if (name && nameValid && !id) {
            await fetchWithFeedback(RulegroupsControlServices.create({name}), {
                showMessage: true,
            });
            this.setState({id: '', name: ''});
        }
        this.useTableHook();
    };

    private getTableHooks = (fn: () => void) => {
        this.tableHook = fn;
    };

    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    render() {
        const {id, name, nameValid, open, usersRAW, grantsRAW} = this.state;
        return (
            <TableWrap>
                <TableModal title={'Grupo de acceso'} open={open} handleClose={this.handleClose}>
                    <div style={{width: 260}}>
                        <CustomTextField
                            label="Nombre del grupo de acceso"
                            error={!nameValid}
                            value={name}
                            name="name"
                            variant={'outlined'}
                            width={'100%'}
                            color="secondary"
                            onChange={this.handleChange('name')}
                            margin={'10px 0 15px 0'}
                        />
                        <CustomButton onClick={this.handleSending} width={'100%'}>
                            {id ? 'Guardar' : 'Crear'} grupo
                        </CustomButton>
                    </div>
                </TableModal>
                <TableComponent
                    defaultPageSize={20}
                    columnFormat={this.columns}
                    service={RulegroupsControlServices.get}
                    accessor={'rulegroups'}
                    createHook={this.handleOpen}
                    getTableFetchHook={this.getTableHooks}
                    propsToOwnTable={{
                        getTrProps: (_: any, rowInfo: RowInfo) => ({
                            onClick: (evt: any) => {
                                if (evt.target.className.includes('expand')) this.handleExpandRulegroup(rowInfo.original);
                                else this.handleOpenRulegroup(rowInfo.original);
                            },
                            style: {cursor: 'pointer'},
                        }),
                        SubComponent: () => {
                            return (
                                <div style={{display: 'flex'}}>
                                    <ReactTable
                                        style={{
                                            width: '50%',
                                            borderBottom: 3,
                                            borderBottomStyle: 'solid',
                                            borderBottomColor: 'green',
                                        }}
                                        data={usersRAW}
                                        showPagination={false}
                                        minRows={0}
                                        pageSize={usersRAW.length}
                                        resizable={false}
                                        sortable={false}
                                        filterable={false}
                                        getNoDataProps={() => {
                                            if (!usersRAW.length) return {style: {display: 'none'}};
                                            return {};
                                        }}
                                        columns={[
                                            {
                                                Header: 'Usuarios',
                                                headerClassName: 'subheader_title',
                                                columns: [
                                                    {
                                                        Header: 'Nombre',
                                                        headerClassName: 'subheader_cell_string',
                                                        accessor: 'name',
                                                        className: 'subcell_string',
                                                    },
                                                    {
                                                        Header: 'Username',
                                                        headerClassName: 'subheader_cell_string',
                                                        accessor: 'username',
                                                        className: 'subcell_string',
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                    <ReactTable
                                        style={{
                                            width: '50%',
                                            borderBottom: 3,
                                            borderBottomStyle: 'solid',
                                            borderBottomColor: 'green',
                                        }}
                                        data={grantsRAW}
                                        showPagination={false}
                                        minRows={0}
                                        pageSize={grantsRAW.length}
                                        resizable={false}
                                        sortable={false}
                                        filterable={false}
                                        getNoDataProps={() => {
                                            if (!grantsRAW.length) return {style: {display: 'none'}};
                                            return {};
                                        }}
                                        columns={[
                                            {
                                                Header: 'Reglas',
                                                headerClassName: 'subheader_title',
                                                columns: [
                                                    {
                                                        Header: 'Categoría',
                                                        headerClassName: 'subheader_cell_string',
                                                        accessor: 'category',
                                                        className: 'subcell_string',
                                                    },
                                                    {
                                                        Header: 'Nombre',
                                                        headerClassName: 'subheader_cell_string',
                                                        Cell: a => (a.original.name ? a.original.name : 'sin nombre'),
                                                        className: 'subcell_string',
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                </div>
                            );
                        },
                    }}
                />
            </TableWrap>
        );
    }
}

const TableWrap = styled.div`
    width: 60%;
    height: 100%;
    margin: auto;
`;
