import {DowntimeActionType} from '../types/downtime.action-types';
import {DowntimeActionInterface} from '../types';

export const FilterActions = {
  active: (): DowntimeActionInterface => ({
    type: DowntimeActionType.setFilterActive,
  }),
  inactive: (): DowntimeActionInterface => ({
    type: DowntimeActionType.setFilterInactive,
  }),
  clear: (): DowntimeActionInterface => ({
    type: DowntimeActionType.clearFilter,
  }),
};
