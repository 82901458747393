import React from 'react';

// import {withStyles, Theme} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

type Props = {
    label?: string;
    classes?: {
        [x: string]: string;
    };
    margin?: string;
    width?: string;
    onChange(...args: any[]): void;
    context?: any;
    value?: string | number;
    name?: string;
    maxLength?: string;
    placeholder?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    error?: boolean;
    multiline?: boolean;
    color?: 'secondary' | 'primary';
    variant?: 'filled' | 'outlined' | 'standard';
    type?: string;
    shrink?: boolean;
};

export const CustomTextField = (props: Props) => (
    // @ts-ignore
    <TextField
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        color={props.color || 'primary'}
        error={props.error}
        autoComplete="off"
        multiline={props.multiline}
        name={props.name}
        placeholder={props.placeholder}
        fullWidth={true}
        // margin={'dense'}
        InputLabelProps={{
            shrink: props.shrink,
        }}
        variant={props.variant || 'standard'}
        inputProps={{maxLength: props.maxLength, step: 1}}
        label={props.label}
        value={props.value}
        style={{margin: props.margin, width: props.width}}
        onChange={props.onChange}
        type={props.type}
    />
);
