import * as React from 'react';
import {connect, MapDispatchToProps, MapStateToProps} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import {AreaSelectors} from 'src/app/lanemanager/lane/store/selectors';
import {changePtableAssign, CPA} from 'src/app/lanemanager/lane/store/thunks/changePtableAssign';
import {LaneProdPartState, LanePtableState} from 'src/app/lanemanager/lane/store/types';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store/interface';

import {ChooseContainerConnected} from './ChooseContainer';
import {ChipsDivWrap, ChipsWrap, ChipWrap} from './Wrap';

type Props = {
    prodpart: LaneProdPartState;
    ptables?: LanePtableState[];
    callback?: () => void;
};

type SProps = {
    ptablesData: LanePtableState[];
    // prodpartsData: LaneProdPartState[];
};

type DProps = {
    changeTable: (config: CPA) => any;
};

type State = Readonly<{
    disabled: boolean;
    openModal: boolean;
}>;

const initialState: State = {disabled: false, openModal: false};

export class ChipTables extends React.Component<Props & DProps & SProps, State> {
    readonly state = initialState;
    tables?: LanePtableState[];

    async componentDidMount() {
        this.tables = await this.computeTables();
    }

    async componentDidUpdate() {
        this.tables = await this.computeTables();
    }

    handleClose = (pt: LanePtableState) => () => {
        if (pt.state !== 'ACTIVA')
            this.props.changeTable({
                ptable: pt,
                newAssign: undefined,
                newReserve: undefined,
                callback: this.props.callback,
            });
    };

    // toggleModal = () => {
    //
    //     this.setState({
    //         disabled: this.state.disabled,
    //         openModal: !this.state.openModal,
    //     });
    // };

    computeTables = () => {
        const tablesData = this.props.ptables ? this.props.ptables : this.props.ptablesData;
        if (this.props.prodpart && tablesData) {
            const {prodpart} = this.props;
            const filteredAssigned: LanePtableState[] = [];
            const filteredReserved: LanePtableState[] = [];
            if (prodpart && tablesData) {
                const {ptables, id} = prodpart;
                if (ptables && ptables.length > 0 && tablesData.length > 0) {
                    tablesData.forEach(pt => {
                        const exist = ptables.find(
                            p => (typeof p === 'string' ? p : (p as any).id) === pt.ptable_id,
                        );
                        if (exist) {
                            if (pt.assigned_to === id && pt.pallet)
                                filteredAssigned.push({
                                    ...pt,
                                    reserved_to: undefined,
                                    state: 'ACTIVA',
                                });
                            else if (pt.assigned_to === id && !pt.pallet)
                                filteredAssigned.push({
                                    ...pt,
                                    reserved_to: undefined,
                                    state: 'ASIGNADA',
                                });
                            else if (pt.reserved_to === id && pt.assigned_to)
                                filteredReserved.push({
                                    ...pt,
                                    assigned_to: undefined,
                                    state: 'RESERVADA',
                                });
                        }
                    });
                }
            }
            const result = filteredAssigned.concat(filteredReserved);
            return result;
        }
        return [];
    };

    render() {
        const TOTAL_ACCESS = IsInRuleset([
            'LANEMANAGER_COMMON_TOTAL',
            'LANEMANAGER_STATE_TOTAL',
        ]);
        this.tables = this.computeTables();
        const {tables} = this;
        if (tables)
            return (
                <ChipsWrap>
                    <ChipsDivWrap>
                        {(tables.length > 0 &&
                            tables.map((pt, i) => {
                                let color = '';
                                if (pt) {
                                    if (pt.state === 'INACTIVA') return undefined;
                                    else if (pt.state === 'ACTIVA') color = '#04A64E7F';
                                    else if (pt.state === 'ASIGNADA') color = 'yellow';
                                    else color = '#99999940';
                                    return (
                                        <ChipWrap
                                            state={pt.state}
                                            key={i}
                                            name={pt.name}
                                            color={color}
                                            onDelete={TOTAL_ACCESS ? this.handleClose(pt) : undefined}
                                        />
                                    );
                                } else return undefined;
                            })) ||
                        'No hay mesas asignadas...'}
                    </ChipsDivWrap>
                    {TOTAL_ACCESS && (
                        <ChooseContainerConnected
                            prodpart={this.props.prodpart}
                            ptablesProp={this.props.ptables ? this.props.ptables : undefined}
                            callback={this.props.callback!}
                        />
                    )}
                </ChipsWrap>
            );
        return 'no hay datos';
    }
}

const mapStateToProps: MapStateToProps<SProps, Props, AppStoreState> = state => ({
    ptablesData: AreaSelectors.getPtables(state),
});

const mapDispatchToProps: MapDispatchToProps<DProps, Props> = (
    dispatch: ThunkDispatch<AppStoreState, undefined, AnyAction>,
) => ({
    changeTable: (payload: CPA) => dispatch(changePtableAssign(payload)),
});

export const ChipTablesConnected = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChipTables);
