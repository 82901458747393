import React from 'react';
import ReactTable from 'react-table';

import * as CardControlTypes from 'src/app/administration/cardControl/store/types';
import {Button} from '@material-ui/core';

export interface CardsControlProps {
  registeredCards: CardControlTypes.CardControlRegisteredCard[];
  selectCard(cardACode: string, cardCode: string): void;
  unassignCard(): void;
}

type State = {
  selectedCardId: string | undefined;
};

const initialState = {selectedCardId: undefined};

export class RegisteredCardsTable extends React.Component<CardsControlProps, State> {
  readonly state = initialState;

  private handleSelectCard = (value: CardControlTypes.CardControlRegisteredCard) => {
    this.setState({selectedCardId: value.id});
    this.props.selectCard(value.id, value.card_code);
  };

  private hightlightSelected = () => {
    return this.props.registeredCards.map(rc => {
      if (this.state.selectedCardId === rc.id) return {...rc, highlight: true};
      return rc;
    });
  };

  public render() {
    return (
      <>
        <Button
          style={{width: '100%', height: '4.1%', padding: 0}}
          color="primary"
          variant="contained"
          onClick={this.props.unassignCard}>
          Desasignar tarjeta
        </Button>
        <ReactTable
          data={this.hightlightSelected()}
          // minRows={21}
          style={{height: '95.8%'}}
          resizable={false}
          columns={[
            {
              Header: 'Tarjetas',
              accessor: 'card_code',
              sortable: false,
              style: {whiteSpace: 'normal', textAlign: 'center'},
              Cell: item => (
                <div
                  style={{
                    cursor: 'pointer',
                    backgroundColor: item.original.highlight ? '#00D4AE' : undefined,
                  }}
                  onClick={() => this.handleSelectCard(item.original)}>
                  {item.value}
                </div>
              ),
            },
            {
              Header: 'Fecha',
              sortable: false,
              accessor: 'card_register_at',
              style: {whiteSpace: 'normal', textAlign: 'center'},
            },
          ]}
          previousText={'Anterior'}
          nextText={'Siguiente'}
          noDataText={'No hay tarjetas'}
        />
      </>
    );
  }
}
