import * as React from 'react';

import * as CardControlTypes from 'src/app/administration/cardControl/store/types';
import {CustomButton, CustomDropdown, WrapCreateButton} from 'src/shared/style';

export interface FormCardControlProps {
  areas: CardControlTypes.CardControlArea[];
  devices: CardControlTypes.CardcontrolDevice[];
  formState: CardControlTypes.CardControlState['formState'];
  handleEnable(): void;
  handleDisable(): void;
  handleSelectDevice(deviceId: string): void;
}

const initialState = {
  filteredDevices: [],
  areaId: undefined,
  deviceId: undefined,
};

const defaultProps: FormCardControlProps = {
  handleEnable: () => ({}),
  handleDisable: () => ({}),
  handleSelectDevice: _ => ({}),
  formState: 'inactive',
  areas: [],
  devices: [],
};

type State = Readonly<{
  areaId: string | undefined;
  deviceId: string | undefined;
  filteredDevices: FilteredDevices;
}>;

export class FormCardControl extends React.Component<FormCardControlProps, State> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  shouldComponentUpdate() {
    return true;
  }
  componentDidMount() {
    this.filterByArea();
  }

  public render() {
    const {areas, formState, handleEnable, handleDisable} = this.props;
    const {filteredDevices} = this.state;
    return (
      <>
        <CustomDropdown
          data={areas}
          handleChoose={this.handleChoose}
          value={this.state.areaId}
          dropdownName="area"
          title="Área"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }}
        />
        <CustomDropdown
          data={this.state.areaId ? filteredDevices[this.state.areaId!] : []}
          handleChoose={this.handleChoose}
          value={this.state.deviceId}
          dropdownName="device"
          title="Dispositivo"
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
              },
            },
          }}
        />
        <WrapCreateButton>
          <CustomButton
            variantColor={formState === 'inactive' ? 'primary' : 'secondary'}
            margin={'auto'}
            onClick={formState === 'inactive' ? handleEnable : handleDisable}>
            {formState === 'inactive'
              ? 'Activar el modo de asignación'
              : 'Desactivar el modo de asignación'}
          </CustomButton>
        </WrapCreateButton>
      </>
    );
  }

  // private updateDevices = () => {};
  private filterByArea = () => {
    const {areas, devices} = this.props;
    const obj: FilteredDevices = {};
    if (areas)
      areas.forEach(c => {
        const filteredDevices = devices.filter(cau => cau.area_id === c.id);
        Object.defineProperty(obj, c.id, {
          enumerable: true,
          value: filteredDevices,
        });
      });
    this.setState({filteredDevices: obj});
    this.forceUpdate();
  };

  private handleChoose = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (e.target.name === 'area' || e.target.name === 'device') {
      e.persist();
      if (e.target.name === 'area') {
        this.filterByArea();
        if (e.target.value !== this.state.areaId)
          this.setState({deviceId: undefined, areaId: e.target.value});
      } else if (e.target.name === 'device') {
        this.setState({deviceId: e.target.value});
        this.props.handleSelectDevice(e.target.value);
      }
    }
  };
}

type FilteredDevices = {
  [key: number]: CardControlTypes.CardcontrolDevice[];
};
