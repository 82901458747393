import {
  EmployeeTaskSelectActionClear,
  EmployeeTaskSelectActionSelect,
  EmployeeTaskSelectActionTypes,
} from 'src/app/presencemanagement/store/employeeTaskSelect.action-types';

export const EmployeeTaskSelectActions = {
  select: (
    employeeId: string,
    workshift: string,
    employeeFullname: string,
    centerId: string,
  ): EmployeeTaskSelectActionSelect => ({
    type: EmployeeTaskSelectActionTypes.select,
    payload: {employeeId, workshift, employeeFullname, centerId},
  }),
  clear: (): EmployeeTaskSelectActionClear => ({
    type: EmployeeTaskSelectActionTypes.clear,
  }),
};
