import * as React from 'react';
import styled from 'styled-components';

import {EmployeeCostsControlServices} from 'src/app/administration/employeeCostsControl/store/employeeCostsControl.services';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {EmployeeCostsControlData} from './store/types';
import {TableModal} from 'src/shared/components/Modal';
import {EmployeeCostForm} from './EmployeeCostForm';
import {Tooltip} from '@material-ui/core';

type State = {
    open: boolean;
    id: string;
    selectedEmployeeCost: EmployeeCostsControlData | undefined;
};

const initialState: State = {
    open: false,
    id: '',
    selectedEmployeeCost: undefined,
};

type CostsTypes = 'hours' | 'ordinary_rate' | 'ordinary_rate_special' | 'festive_rate' | 'festive_rate_special';

export class EmployeeCostsControl extends React.Component<{}, State> {
    readonly state = initialState;

    extractCosts = (items: any, type: CostsTypes) => {
        const cost = items.original[type];
        return cost ? +cost : 0;
    };

    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    private handleClose = () => {
        this.setState({open: false, selectedEmployeeCost: undefined});
    };

    private handleSelect = (employeeCost: EmployeeCostsControlData) => {
        const selectedEmployeeCost = {
            id: employeeCost.id,
            employee_name: employeeCost.employee_name,
            employee_surname: employeeCost.employee_surname,
            employee_code: employeeCost.employee_code,
            employee_identification: employeeCost.employee_identification,
            hours: +Number(employeeCost.hours).toFixed(2),
            ordinary_rate: +Number(employeeCost.ordinary_rate).toFixed(2),
            ordinary_rate_special: +Number(employeeCost.ordinary_rate_special).toFixed(2),
            festive_rate: +Number(employeeCost.festive_rate).toFixed(2),
            festive_rate_special: +Number(employeeCost.festive_rate_special).toFixed(2),
        };
        this.setState({
            selectedEmployeeCost: selectedEmployeeCost,
            id: employeeCost.id,
            open: true,
        });
    };

    private handleUpdateEmployeeCost = () => {
        this.useTableHook();
        this.setState({open: false, selectedEmployeeCost: undefined});
    };

    private tooltipTextEditEmployeeCost = 'Editar costes';

    private columns: ColumnType<{id: string; name: string}> = [
        {
            Header: 'Nombre',
            headerClassName: 'header_cell_string',
            filterable: true,
            width: 260,
            accessor: 'employee_name',
            Cell: item => (
                <>
                    {item.original.employee_name && (
                        <>
                            <Tooltip title={this.tooltipTextEditEmployeeCost} placement="top">
                                <div>{item.original.employee_name}</div>
                            </Tooltip>
                        </>
                    )}
                </>
            ),
            className: 'cell_string',
        },
        {
            Header: 'Apellidos',
            headerClassName: 'header_cell_string',
            filterable: true,
            width: 260,
            accessor: 'employee_surname',
            Cell: item => (
                <>
                    {item.original.employee_surname && (
                        <>
                            <Tooltip title={this.tooltipTextEditEmployeeCost} placement="top">
                                <div>{item.original.employee_surname}</div>
                            </Tooltip>
                        </>
                    )}
                </>
            ),
            className: 'cell_string',
        },
        {
            Header: 'Código',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'employee_code',
            Cell: item => (
                <>
                    {item.original.employee_code && (
                        <>
                            <Tooltip title={this.tooltipTextEditEmployeeCost} placement="top">
                                <div>{item.original.employee_code}</div>
                            </Tooltip>
                        </>
                    )}
                </>
            ),
            className: 'cell_string',
        },
        {
            Header: 'DNI',
            headerClassName: 'header_cell_string',
            filterable: true,
            accessor: 'employee_identification',
            Cell: item => (
                <>
                    {item.original.employee_identification && (
                        <>
                            <Tooltip title={this.tooltipTextEditEmployeeCost} placement="top">
                                <div>{item.original.employee_identification}</div>
                            </Tooltip>
                        </>
                    )}
                </>
            ),
            className: 'cell_string',
        },
        {
            Header: 'Horas',
            headerClassName: 'header_cell_number',
            sortable: false,
            accessor: 'hours',
            Cell: item => (
                <>
                    {item.original.hours && (
                        <>
                            <Tooltip title={this.tooltipTextEditEmployeeCost} placement="top">
                                <div>{customFormatNumber(Number(this.extractCosts(item, 'hours').toFixed(2)))}</div>
                            </Tooltip>
                        </>
                    )}
                </>
            ),
            className: 'cell_number',
        },
        {
            Header: 'T. normal',
            headerClassName: 'header_cell_number',
            sortable: false,
            Cell: item => (
                <>
                    {item.original.ordinary_rate && (
                        <>
                            <Tooltip title={this.tooltipTextEditEmployeeCost} placement="top">
                                <div>
                                    {customFormatNumber(Number(this.extractCosts(item, 'ordinary_rate').toFixed(2)))} {'€'}
                                </div>
                            </Tooltip>
                        </>
                    )}
                </>
            ),
            className: 'cell_number',
        },
        {
            Header: 'T. Extra',
            headerClassName: 'header_cell_number',
            sortable: false,
            Cell: item => (
                <>
                    {item.original.ordinary_rate_special && (
                        <>
                            <Tooltip title={this.tooltipTextEditEmployeeCost} placement="top">
                                <div>
                                    {customFormatNumber(Number(this.extractCosts(item, 'ordinary_rate_special').toFixed(2)))} {'€'}
                                </div>
                            </Tooltip>
                        </>
                    )}
                </>
            ),
            className: 'cell_number',
        },
        {
            Header: 'T. festivo',
            headerClassName: 'header_cell_number',
            sortable: false,
            Cell: item => (
                <>
                    {item.original.festive_rate && (
                        <>
                            <Tooltip title={this.tooltipTextEditEmployeeCost} placement="top">
                                <div>
                                    {customFormatNumber(Number(this.extractCosts(item, 'festive_rate').toFixed(2)))} {'€'}
                                </div>
                            </Tooltip>
                        </>
                    )}
                </>
            ),
            className: 'cell_number',
        },
        {
            Header: 'T. fest. Extra',
            headerClassName: 'header_cell_number',
            sortable: false,
            Cell: item => (
                <>
                    {item.original.festive_rate_special && (
                        <>
                            <Tooltip title={this.tooltipTextEditEmployeeCost} placement="top">
                                <div>
                                    {customFormatNumber(Number(this.extractCosts(item, 'festive_rate_special').toFixed(2)))} {'€'}
                                </div>
                            </Tooltip>
                        </>
                    )}
                </>
            ),
            className: 'cell_number',
        },
    ];

    render() {
        const TOTAL_ACCESS = typeof IsInRuleset === 'function' ? IsInRuleset('EMPLOYEE_COSTS_TOTAL') : false;
        const {selectedEmployeeCost, open} = this.state;
        return IsInRuleset(['EMPLOYEE_COSTS_TOTAL', 'EMPLOYEE_COSTS_VIEW']) ? (
            <TableWrap>
                <TableModal title={'Gestión del coste'} open={open} handleClose={this.handleClose}>
                    <EmployeeCostForm employeeCost={selectedEmployeeCost} handleClose={this.handleClose} update={this.handleUpdateEmployeeCost} />
                </TableModal>
                <PaginatedTableComponent
                    columnFormat={this.columns}
                    defaultPageSize={20}
                    service={EmployeeCostsControlServices.get}
                    showSizeOptions={true}
                    twoWeeksFilter={true}
                    loading={true}
                    getTableFetchHook={this.getTableHook}
                    propsToOwnTable={{
                        getTrProps: (_: any, rowInfo: any) => ({
                            onClick: () => {
                                if (TOTAL_ACCESS) {
                                    this.handleSelect(rowInfo.original);
                                }
                            },
                            style: {cursor: 'pointer'},
                        }),
                    }}
                />
            </TableWrap>
        ) : null;
    }
}

const TableWrap = styled.div`
    width: 95%;
    height: 100%;
    margin: auto;
`;
