import * as React from 'react';
import {connect} from 'react-redux';
import ReactTable, {RowInfo} from 'react-table';
import styled from 'styled-components';

import {AreaControlServices} from 'src/app/administration/areaControl/store/services';
import {DevicesControlServices} from 'src/app/administration/devicesControl/store/devicesControl.services';
import {DeviceControlUnitNoAutomatas, DeviceUnit} from 'src/app/administration/devicesControl/store/devicesControl.types';
import {DeviceForm} from 'src/app/administration/devicesControl/DeviceForm';
import {LoginSelectors} from 'src/app/login/store/login.selectors';
import {Checkbox} from '@material-ui/core';
import {TableModal} from 'src/shared/components/Modal';
import {TableComponent} from 'src/shared/components/TableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {AppStoreState} from 'src/store';
import {ColumnType} from 'src/types';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';

type SProps = {
    userData: ReturnType<typeof LoginSelectors.getUserData>;
};
type State = Readonly<{
    open: boolean;
    openPreview: boolean;
    selectedUrl: string;
    selectedDevice?: DeviceControlUnitNoAutomatas;
    areasList: Array<{id: string; name: string}>;
}>;

const initialState: State = {
    open: false,
    openPreview: false,
    selectedUrl: '',
    selectedDevice: undefined,
    areasList: [],
};

// const TOTAL_ACCESS =
//   typeof IsInRuleset === 'function' ? IsInRuleset('DEVICES_TOTAL') : false;
// const VIEW_ACCESS =
//   typeof IsInRuleset === 'function' ? IsInRuleset('DEVICES_VIEW') : false;
export class DevicesControlBase extends React.Component<SProps, State> {
    readonly state = initialState;

    componentDidMount() {
        this.fetchAreas();
        // this.fetchTypes();
    }
    // private slectedEmployees: Array<{employeeId: string; rules: string[]}> = [];
    private tableHook: () => void = () => ({});
    private getTableHook = (fn: () => void) => (this.tableHook = fn);
    private useTableHook = () => {
        if (typeof this.tableHook === 'function') this.tableHook();
    };

    private handleClosePreview = () => this.setState({openPreview: false, selectedUrl: ''});

    private handleOpenPreview = (selectedUrl: string) => this.setState({openPreview: true, selectedUrl});

    private handleCloseDevice = () => {
        this.setState({open: false, selectedDevice: undefined});
        this.useTableHook();
    };
    private handleOpenNewDevice = () => this.setState({open: true});
    private handleOpenDevice = (selectedDevice?: DeviceControlUnitNoAutomatas) => this.setState({open: true, selectedDevice});

    private handleUpdateDevice = () => {
        this.useTableHook();
        // this.setState({open: false, selectedDevice: undefined});
    };

    private fetchAreas = async () => {
        const areasList = await fetchWithFeedback(AreaControlServices.getAllAreas(), {
            accessor: 'areas',
        });
        this.setState({areasList});
    };

    private columns: ColumnType<DeviceControlUnitNoAutomatas> = [
        {
            Header: 'Nombre dispositivo',
            headerClassName: 'header_cell_string',
            accessor: 'name',
            filterable: true,
            className: 'cell_string',
        },
        {
            Header: 'Área dispositivo',
            headerClassName: 'header_cell_string',
            accessor: 'areaName',
            filterable: true,
            width: 140,
            className: 'cell_string',
        },
        {
            Header: 'NFC incrustado',
            accessor: 'smartcardIOReader',
            width: 190,
            Cell: item => <Checkbox checked={!!+item.original.smartcardIOReader} />,
            centerCell: true,
        },
        {
            Header: 'NFC blanco',
            accessor: 'pn532Reader',
            width: 180,
            Cell: item => <Checkbox checked={!!+item.original.pn532Reader} />,
            centerCell: true,
        },
        {
            Header: 'regTimeoutSec',
            headerClassName: 'header_cell_number',
            accessor: 'regTimeoutSec',
            className: 'cell_number',
            sortable: true,
            width: 120,
            filterable: true,
        },
        {
            Header: 'Dirección IP',
            headerClassName: 'header_cell_string',
            accessor: 'address',
            className: 'cell_string',
            sortable: true,
            width: 140,
            filterable: true,
        },
        {
            Header: 'Imágen Splash',
            accessor: 'idleUrl',
            sortable: true,
            Cell: item => <div id={`splash-${item.original.id}`}>{item.original.idleUrl}</div>,
            centerCell: true,
            width: 250,
            filterable: true,
        },
        {
            Header: 'Automatas',
            // accessor: 'batches',
            minWidth: 90,
            maxWidth: 90,
            width: 90,
            sortable: false,
            expander: true,
            style: {
                cursor: 'pointer',
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
            },
        },
    ];

    render() {
        const {open, openPreview, selectedUrl, selectedDevice, areasList} = this.state;
        return (
            IsInRuleset(['DEVICES_TOTAL', 'DEVICES_VIEW']) && (
                <TableWrap>
                    <TableModal title={'Vista previa de la imagen'} open={openPreview} handleClose={this.handleClosePreview}>
                        <img src={selectedUrl} alt={'img'} />
                    </TableModal>
                    <TableModal title={'Gestión del dispositivo'} open={open} handleClose={this.handleCloseDevice}>
                        <DeviceForm areasList={areasList} device={selectedDevice} handleClose={this.handleCloseDevice} updateDevice={this.handleUpdateDevice} />
                    </TableModal>
                    <TableComponent
                        defaultPageSize={20}
                        columnFormat={this.columns}
                        service={DevicesControlServices.getAll}
                        getTableFetchHook={this.getTableHook}
                        accessor={'devices'}
                        createHook={IsInRuleset('DEVICES_TOTAL') ? this.handleOpenNewDevice : undefined}
                        propsToOwnTable={{
                            getTrProps: (_: any, rowInfo: RowInfo) => ({
                                onClick: (evt: any) => {
                                    if (evt.target.id.includes('splash')) this.handleOpenPreview(rowInfo.original.idleUrl);
                                    else if (!evt.target.className.includes('expand')) IsInRuleset('DEVICES_TOTAL') && this.handleOpenDevice(rowInfo.original);
                                },
                                style: {
                                    cursor: 'pointer',
                                },
                            }),
                            SubComponent: (info: RowInfo) => {
                                const data = info.original as DeviceUnit;
                                return (
                                    <div style={{display: 'flex'}}>
                                        <ReactTable
                                            style={{width: '50%'}}
                                            data={data.barReaders}
                                            showPagination={false}
                                            minRows={0}
                                            pageSize={data.barReaders.length}
                                            resizable={false}
                                            sortable={false}
                                            filterable={false}
                                            getNoDataProps={() => {
                                                if (!data.barReaders.length) return {style: {display: 'none'}};
                                                return {};
                                            }}
                                            columns={[
                                                {
                                                    Header: 'Lectores fijos',
                                                    headerClassName: 'subheader_title',
                                                    columns: [
                                                        {
                                                            Header: 'Nombre',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'name',
                                                            className: 'subcell_string',
                                                        },
                                                        {
                                                            Header: 'Dirección IP',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'address',
                                                            className: 'subcell_string',
                                                            width: 140,
                                                        },
                                                        {
                                                            Header: 'Puerto',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'port',
                                                            width: 70,
                                                            className: 'subcell_string',
                                                        },
                                                        {
                                                            Header: 'Tipo',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'type',
                                                            width: 90,
                                                            className: 'subcell_string',
                                                        },
                                                        {
                                                            Header: 'Área',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'area_name',
                                                            width: 90,
                                                            className: 'subcell_string',
                                                        },
                                                    ],
                                                },
                                            ]}
                                        />
                                        <ReactTable
                                            style={{width: '50%'}}
                                            data={data.bargunBases}
                                            showPagination={false}
                                            minRows={0}
                                            pageSize={data.bargunBases.length}
                                            resizable={false}
                                            sortable={false}
                                            filterable={false}
                                            getNoDataProps={() => {
                                                if (!data.bargunBases.length) return {style: {display: 'none'}};
                                                return {};
                                            }}
                                            columns={[
                                                {
                                                    Header: 'Bases de pistolas lectoras',
                                                    headerClassName: 'subheader_title',
                                                    columns: [
                                                        {
                                                            Header: 'Nombre',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'name',
                                                            className: 'subcell_string',
                                                        },
                                                        {
                                                            Header: 'Dirección IP',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'address',
                                                            width: 140,
                                                            className: 'subcell_string',
                                                        },
                                                        {
                                                            Header: 'Puerto',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'port',
                                                            width: 70,
                                                            className: 'subcell_string',
                                                        },
                                                        {
                                                            Header: 'Tipo',
                                                            headerClassName: 'subheader_cell_string',
                                                            accessor: 'type',
                                                            width: 70,
                                                            className: 'subcell_string',
                                                        },
                                                    ],
                                                },
                                            ]}
                                        />
                                    </div>
                                );
                            },
                        }}
                    />
                </TableWrap>
            )
        );
    }
}

export const DevicesControl = connect<SProps, {}, {}, AppStoreState>(state => ({
    userData: LoginSelectors.getUserData(state),
}))(DevicesControlBase);

const TableWrap = styled.div`
    width: 100%;
    height: 100%;
    margin: auto;
`;
