import React, {useState, useEffect, useRef} from 'react';

import format from 'date-fns/format';
import es from 'date-fns/locale/es';

import {WrapDivsCharts} from 'src/app/lanemanager/charts/styled/Wrap';
import {PersonnelHourChart} from 'src/app/lanemanager/charts/PersonnelHourChart';

import {OCard, WrapFullContainer} from 'src/shared/style';
import {connect} from 'react-redux';
import {AppStoreState} from 'src/store';
import {LaneSelectSelectors} from 'src/app/laneSelect/store';
import {ChartsServices} from 'src/app/lanemanager/charts/store/services';
import {LaneCostKgDumpedWeekType, LaneKgDumpedWeekType, LanePersonnelKgDumpedWeekType} from 'src/app/lanemanager/charts/store/types';
import {notify} from 'src/utils/Notification';
import {RatiosChart} from './RatiosChart';
import {KgDumpedWeekChart} from './KgDumpedWeekChart';
import {CostKgDumpedChart} from './CostKgDumpedChart';
import {KgDumpedTotalDayChart} from './KgDumpedTotalDayChart';

type SProps = {
    lane: ReturnType<typeof LaneSelectSelectors.getLane>;
};
const ChartsBase: React.FunctionComponent<SProps> = props => {
    const [kgDumpedTotalDay, setKgDumpedTotalDay] = useState<LaneKgDumpedWeekType>({
        days: [],
        kgsDumpedDays1: [],
        kgsDumpedDays2: [],
        costKgsDumpedDays1: [],
        costKgsDumpedDays2: [],
    } as any);

    const [kgDumpedWeek, setKgDumpedWeek] = useState<LaneKgDumpedWeekType>({
        days: [],
        kgsDumpedDays1: [],
        kgsDumpedDays2: [],
    } as any);

    const [costKgDumpedWeek, setCostKgDumpedWeek] = useState<LaneCostKgDumpedWeekType>({
        days: [],
        costKgsDumpedDays1: [],
        costKgsDumpedDays2: [],
    } as any);

    const [PersonnelKgDumpedWeek, setPersonnelKgDumpedWeek] = useState<LanePersonnelKgDumpedWeekType>({
        days: [],
        PersonnelKgsDumpedDays1: [],
        PersonnelKgsDumpedDays2: [],
        PersonnelDays1: [],
        PersonnelDays2: [],
    } as any);

    const [loadingkgDumpedTotalDay, setLoadingkgDumpedTotalDay] = useState(false);
    const [loadingkgDumpedWeek, setLoadingkgDumpedWeek] = useState(false);
    const [loadingcostKgDumpedWeek, setLoadingcostKgDumpedWeek] = useState(false);
    const [loadingPersonnelKgDumpedWeek, setLoadingPersonnelKgDumpedWeek] = useState(false);

    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            try {
                const currentWorkshift = format(new Date(), 'YYYY-MM-DD', {
                    locale: es,
                });

                setLoadingkgDumpedTotalDay(true);
                setLoadingkgDumpedWeek(true);
                setLoadingcostKgDumpedWeek(true);
                setLoadingPersonnelKgDumpedWeek(true);

                ChartsServices.totalWeek(props.lane.laneId, currentWorkshift).then(kgDumpedData => {
                    const days = [];
                    const kgsDumpedDays1 = [];
                    const kgsDumpedDays2 = [];

                    if (kgDumpedData) {
                        for (const index in kgDumpedData.data.kgDumpedWeek) {
                            if (index) {
                                days.push(index);
                                kgsDumpedDays1.push(kgDumpedData.data.kgDumpedWeek[index][1]);
                                kgsDumpedDays2.push(kgDumpedData.data.kgDumpedWeek[index][2]);
                            }
                        }
                    }

                    const data = {
                        days: days,
                        kgsDumpedDays1: kgsDumpedDays1,
                        kgsDumpedDays2: kgsDumpedDays2,
                    };

                    setKgDumpedWeek({...data});
                    setLoadingkgDumpedWeek(false);

                    const days2 = [];
                    const PersonnelKgsDumpedDays1 = [];
                    const PersonnelKgsDumpedDays2 = [];
                    const PersonnelDays1 = [];
                    const PersonnelDays2 = [];

                    if (kgDumpedData) {
                        for (const index in kgDumpedData.data.PersonnelKgDumpedWeek) {
                            if (index) {
                                days2.push(index);
                                PersonnelKgsDumpedDays1.push(kgDumpedData.data.PersonnelKgDumpedWeek[index].kgDumped[1]);
                                PersonnelKgsDumpedDays2.push(kgDumpedData.data.PersonnelKgDumpedWeek[index].kgDumped[2]);
                                PersonnelDays1.push(kgDumpedData.data.PersonnelKgDumpedWeek[index].employeesDistinct[1]);
                                PersonnelDays2.push(kgDumpedData.data.PersonnelKgDumpedWeek[index].employeesDistinct[2]);
                            }
                        }
                    }

                    const data2 = {
                        days: days2,
                        PersonnelKgsDumpedDays1: PersonnelKgsDumpedDays1,
                        PersonnelKgsDumpedDays2: PersonnelKgsDumpedDays2,
                        PersonnelDays1: PersonnelDays1,
                        PersonnelDays2: PersonnelDays2,
                    };

                    setPersonnelKgDumpedWeek({...data2});
                    setLoadingPersonnelKgDumpedWeek(false);

                    const days3 = [];
                    const costKgsDumpedDays1 = [];
                    const costKgsDumpedDays2 = [];

                    if (kgDumpedData) {
                        for (const index in kgDumpedData.data.costKgDumpedWeek) {
                            if (index) {
                                days3.push(index);
                                costKgsDumpedDays1.push(kgDumpedData.data.costKgDumpedWeek[index][1]);
                                costKgsDumpedDays2.push(kgDumpedData.data.costKgDumpedWeek[index][2]);
                            }
                        }
                    }

                    const data3 = {
                        days: days3,
                        costKgsDumpedDays1: costKgsDumpedDays1,
                        costKgsDumpedDays2: costKgsDumpedDays2,
                    };

                    setCostKgDumpedWeek({...data3});
                    setLoadingcostKgDumpedWeek(false);

                    const days4 = [];
                    const kgsDumpedTotal1 = [];
                    const kgsDumpedTotal2 = [];
                    const costKgsDumpedTotal1 = [];
                    const costKgsDumpedTotal2 = [];

                    if (kgDumpedData) {
                        for (const index in kgDumpedData.data.kgDumpedTotalDay) {
                            if (index) {
                                days4.push(index);
                                kgsDumpedTotal1.push(kgDumpedData.data.kgDumpedTotalDay[index][1]);
                                kgsDumpedTotal2.push(kgDumpedData.data.kgDumpedTotalDay[index][2]);
                                costKgsDumpedTotal1.push(kgDumpedData.data.costKgDumpedTotalDay[index][1]);
                                costKgsDumpedTotal2.push(kgDumpedData.data.costKgDumpedTotalDay[index][2]);
                            }
                        }
                    }

                    const data4 = {
                        days: days4,
                        kgsDumpedDays1: kgsDumpedTotal1,
                        kgsDumpedDays2: kgsDumpedTotal2,
                        costKgsDumpedDays1: costKgsDumpedTotal1,
                        costKgsDumpedDays2: costKgsDumpedTotal2,
                    };

                    setKgDumpedTotalDay({...data4});
                    setLoadingkgDumpedTotalDay(false);
                });
            } catch (e) {
                notify({message: 'Error al obtener los datos de las gráficas', status: 'error'});
            }
        }
    }, [props]);
    return (
        <WrapFullContainer>
            <WrapDivsCharts>
                <OCard title={'Ratios'} heightTitle={'30'} width={'50%'} height={'100%'} contentHeight={'85%'} contentPadding={'5px'}>
                    <RatiosChart areaId={props.lane.laneId} areaName={props.lane.laneName} />
                </OCard>

                <OCard title={'kg volcados por día'} heightTitle={'30'} width={'50%'} height={'100%'} contentHeight={'85%'} contentPadding={'5px'}>
                    {<KgDumpedTotalDayChart chartData={kgDumpedTotalDay} isLoading={loadingkgDumpedTotalDay} />}
                </OCard>
            </WrapDivsCharts>
            <WrapDivsCharts>
                <OCard
                    // headerComponent={<ConnectedChartSelect chartId="test" />}
                    title={'€ / kg volcados (semanas)'}
                    heightTitle={'30'}
                    width={'50%'}
                    height={'100%'}
                    contentHeight={'86%'}
                    contentPadding={'5px'}
                >
                    <CostKgDumpedChart chartData={costKgDumpedWeek} isLoading={loadingcostKgDumpedWeek} />
                </OCard>
                <OCard title={'kg volcados por empleado (semanas)'} heightTitle={'30'} width={'50%'} height={'100%'} contentHeight={'85%'} contentPadding={'5px'}>
                    <KgDumpedWeekChart chartData={kgDumpedWeek} isLoading={loadingkgDumpedWeek} />
                </OCard>
            </WrapDivsCharts>
            <WrapDivsCharts>
                <OCard
                    // headerComponent={<ConnectedChartSelect chartId="test" />}
                    title={'Personal / Kg / día (semanas)'}
                    heightTitle={'30'}
                    width={'50%'}
                    height={'100%'}
                    contentHeight={'86%'}
                    contentPadding={'5px'}
                >
                    <PersonnelHourChart chartData={PersonnelKgDumpedWeek} isLoading={loadingPersonnelKgDumpedWeek} />
                </OCard>
                <OCard heightTitle={'30'} width={'50%'} height={'100%'} contentHeight={'94%'} contentPadding={'5px'}>
                    <div>-</div>
                </OCard>
            </WrapDivsCharts>
        </WrapFullContainer>
    );
};

export const Charts = connect<SProps, {}, {}, AppStoreState>(state => ({
    lane: LaneSelectSelectors.getLane(state),
}))(ChartsBase);

// <OCard
//   // title={'Variedad en %'}
//   width={'24.7%'}
//   height={'100%'}
//   contentHeight={'85%'}
//   contentPadding={'5px'}>
//   {/* <VarietyChart /> */}
// </OCard>
