import {LaneSelectSelectors} from 'src/app/laneSelect/store';
// import {PersonalServices} from 'src/app/lanemanager/personal/store/personal.services';
import {AppStoreState} from 'src/store';
// import {fillStorage} from 'src/utils/localforage';
import {notifyConnect, notifyServerError} from 'src/utils/Notification';

import {LaneEmployeeActions} from '../actions';
import {AreaServices} from '../services';
// import {employeesInstance} from '../storage';
import {LaneActionInterface} from '../types';
import {areaEmployeesResSchemeValidate} from '../validators/AreaEmployeesRes';

export const getAreaAllEmployees = () => async (
  dispatch: (action: any) => LaneActionInterface,
  getState: () => AppStoreState,
) => {
  try {
    const lane = LaneSelectSelectors.getLane(getState());
    const area = lane ? lane.laneId : 'NO_EXISTE_LINEA';

    // FIXME: change that endpoint to PersonalServices for the actual workers
    const req = await AreaServices.getAllEmployees(area);
    const {data} = req;
    if (req.status < 400) {
      const validate = await areaEmployeesResSchemeValidate;
      const valid = await validate(data);

      if (!valid) {
        console.error('NO HE VALIDADO> ', validate.errors); // ! POR CONSOLA???
      }
      dispatch(LaneEmployeeActions.set(data));
    } else dispatch(notifyServerError());
  } catch (error) {
    dispatch(notifyConnect());
    console.error(error);
    throw new Error(error.message);
  }
};
