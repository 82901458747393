import {Action} from 'redux';

export enum NotifyActionType {
  warning = '[Notification] Warning',
  notify = '[Notification] Notify',
  success = '[Notification] Success',
  error = '[Notification] Error',
  close = '[Notification] Close',
}
export interface NotifyAction extends Action {
  readonly type: string;
  payload?: NotifyPayload;
}
export interface NotifyPayload {
  message: string;
  timeout?: number;
  status: 'warning' | 'notify' | 'success' | 'error';
}
