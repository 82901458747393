import * as React from 'react';
import styled from 'styled-components';

import {withStyles, Divider} from '@material-ui/core';
// import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

// import ReplyIcon from '@material-ui/icons/Reply';

type Props = {
  author: string;
  subject: string;
  body: string;
  onResponse(id: string): void;
  classes: {[k: string]: any};
};
export const MessageBase: React.FunctionComponent<Props> = ({
  subject = '',
  body = '',
  author = '',
}) => {
  return (
    <MessageContainer>
      <TitleContainer>
        <Typography variant="h5">De: {author || 'AUTOR TEST'}</Typography>
        <Divider />
        <Typography variant="h5">Asunto: {subject || 'ASUNTO TEST'}</Typography>
        <Divider />
      </TitleContainer>
      <MessageBody>
        <Typography
          component="span"
          variant="body1"
          dangerouslySetInnerHTML={{__html: body || 'Cuerpo del mensaje'}}>
          {/* {} */}
        </Typography>
      </MessageBody>
      <div style={{flexGrow: 1}} />
      {/* <MessageActions>
        <IconButton color="secondary" style={{height: 50, width: 50}}>
          <ReplyIcon />
        </IconButton>
      </MessageActions> */}
    </MessageContainer>
  );
};

export const MessageComponent = withStyles({
  chip: {
    whiteSpace: 'normal',
  },
})(MessageBase);

const MessageContainer = styled.div`
  width: 100%;
  margin: 5px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const TitleContainer = styled.div`
  display: flex;
  margin: 5px;
  height: 70px;
  width: 100%;
  /* flex-grow: 1; */
  flex-direction: column;
`;

const MessageBody = styled.div`
  display: flex;
  margin: 10px;
  height: 80%;
  width: 100%;
  overflow: auto;
`;

// const MessageActions = styled.div`
//   display: flex;
//   flex-direction: row-reverse;
//   height: 50px;
//   width: 100%;
// `;
