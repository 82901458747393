import * as React from 'react';

import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';

export const TabContainer = (props: any) => {
  return (
    <Typography
      component="div"
      style={{
        paddingTop: 5,
        backgroundColor: '#efefef',
        height: 'calc(100% - 55px)',
      }}>
      {props.children}
    </Typography>
  );
};

export const TabContainerNoTabs = (props: any) => {
  return (
    <Typography
      component="div"
      style={{
        paddingTop: 5,
        backgroundColor: '#efefef',
        height: '100%',
      }}>
      {props.children}
    </Typography>
  );
};

export const CustomAppBar = withStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
}))((props: any) => (
  <AppBar className={props.classes.root} position="static">
    {props.children}
  </AppBar>
));
