import * as React from 'react';

import {
    Button,
    Checkbox,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {FunctionalitiesTreeType} from "./store/functionalityControl.types";


const FUNCTIONALITY_ENABLED = 4;
const CATEGORY_ENABLED = 8;
const FUNCTIONALITY_ACTIVE = FUNCTIONALITY_ENABLED | CATEGORY_ENABLED;


type State = {
    enabled : boolean,
    modulesCount: number,
}

type Props = {
    expanded: string | boolean;
    change(...args: any[]): any;
    openModuleModal(functionalityId : string): any;
    functionalities : FunctionalitiesTreeType;
    categoryName: string;
    functionalitiesIds: string[];
    updatedFunctionalities: Map<string, number>;
    enableSave(): void;
};

const initialState: Readonly<State> = {
    enabled: false,
    modulesCount: 0,
};



export class  FunctionalityControlPanel extends React.Component<Props, State> {
    readonly state = initialState;

    componentDidMount() {
        this.checksIfEnabled();
        this.updateModulesCount();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.functionalities !== prevProps.functionalities) {
            this.checksIfEnabled();
            this.updateModulesCount();
            this.forceUpdate();
        }
    }

    setEnabled(enabled : boolean) {
        this.setState({'enabled' : enabled});
    }

    private checksIfEnabled = () => {
        const enabled = this.props.functionalitiesIds.some(id => (this.props.functionalities[id].status & CATEGORY_ENABLED) === CATEGORY_ENABLED);
        this.setState({enabled});
    }

    updateModulesCount = () => {
        let modulesCount = 0;
        this.props.functionalitiesIds.forEach(id => (modulesCount += Object.keys(this.props.functionalities[id].modules).length));
        this.setState({modulesCount});
    }



    private handleCheckCategory = (e : any) => {

        this.setState({'enabled' : e.target.checked});

        this.props.functionalitiesIds.forEach((id) => {
            if (e.target.checked) {
                this.props.functionalities[id].status |= CATEGORY_ENABLED;
            }
            else {
                this.props.functionalities[id].status &= ~CATEGORY_ENABLED;
            }
            this.props.updatedFunctionalities.set(id, this.props.functionalities[id].status & FUNCTIONALITY_ACTIVE);
        });

        this.props.enableSave();

        e.stopPropagation();
    }

    private handleCheckFunctionality = (e : any, id : string) => {
        if (e.target.checked) {
            this.props.functionalities[id].status |= FUNCTIONALITY_ENABLED;
        }
        else {
            this.props.functionalities[id].status &= ~FUNCTIONALITY_ENABLED;
        }
        this.props.updatedFunctionalities.set(id, this.props.functionalities[id].status & FUNCTIONALITY_ACTIVE);
        this.props.enableSave();
    }

    render() {
        const {expanded, categoryName, functionalitiesIds, functionalities, change} = this.props;
        if (functionalities)
            return (
                <ExpansionPanel
                    expanded={expanded === categoryName}
                    onChange={(_: any, expanded: boolean) => change(categoryName, expanded)}
                >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container key={categoryName} spacing={16} alignItems="center">
                            <Grid item xs={1}>
                                <Checkbox
                                    checked={this.state.enabled}
                                    disableRipple
                                    color="default"
                                    id={categoryName + "_checkBox"}
                                    inputProps={{ 'aria-label': 'Checkbox A' }}
                                    onClick={this.handleCheckCategory}
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="h5">
                                    {categoryName}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1">
                                    (Módulos: {this.state.modulesCount})
                                </Typography>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{display: 'block'}}>
                        {functionalitiesIds.map((id) => {
                            const functionality = functionalities[id];
                            return functionality && (
                                <Grid container key={id} spacing={16} alignItems="center">
                                    <Grid item xs={1}/>
                                    <Grid item xs={7}>
                                        <div style={{display:'flex', flexDirection: 'row', alignItems:'center'}}>
                                            <Checkbox
                                                checked={(functionalities[id].status & FUNCTIONALITY_ENABLED) === FUNCTIONALITY_ENABLED}
                                                disableRipple
                                                disabled={!this.state.enabled}
                                                color="default"
                                                id={id + "_checkBox"}
                                                onClick={(e) => this.handleCheckFunctionality (e, id)}
                                            />
                                            <Typography variant="body1" style={{color: this.state.enabled ? 'black' : 'grey'}}>
                                                {functionalities[id].name}
                                            </Typography>
                                        </div>

                                    </Grid>
                                    <Grid item xs={1}>
                                        {Object.keys(functionalities[id].modules).length}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            color="default"
                                            variant="contained"
                                            onClick={() => this.props.openModuleModal(id)}
                                        >
                                            Asociar módulos
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );
        else return null;
    }



}

