import React from 'react';
import {ReportsServices} from 'src/app/information/reports/store/services';
import {TaskByEmployeeData} from 'src/app/information/reports/store/types';
import {PaginatedTableComponent} from 'src/shared/components/PaginatedTableComponent';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {ColumnType} from 'src/types';
import {EmployeeTaskSelectActions} from 'src/app/presencemanagement/store';
import {connect} from 'react-redux';
import {push, ConnectedRouterProps, RouterAction} from 'connected-react-router';
import {Tooltip} from '@material-ui/core';
import {PresenceManagementModal} from 'src/app/presencemanagement/PresenceManagementModal';
import {ParametersSelectors} from 'src/utils/Parameters/selectors';
import {AppStoreState} from 'src/store';
import {customFormatNumber} from 'src/shared/functions/FormatHelpers';
import {ParameterCPN} from 'src/types/app_types';
import { ShiftsMapService } from 'src/shared/components/services';
import { fetchWithFeedback } from 'src/utils/fetcherValidate';

type DProps = {
    push(path: string, state?: any): RouterAction;
    selectEmployeeTask(employeeId: string, employeeName: string, employeeSurname: string, workShift: string, centerId: string): void;
};
type SProps = {
    presenceManagementActive: ReturnType<typeof ParametersSelectors.getValuePresenceManagement>;
    employeeCodeModeParam?: String;
};

type State = {
    showPresenceManagement: boolean;
    shifts : Array<{
        id:string;
        name:string;
    }>;
    shiftsMap : Map<string, string>;
    shiftsLoaded : boolean;
}

const initialState : State = {
    showPresenceManagement: false,
    shifts: [],
    shiftsMap: new Map<string, string>(),
    shiftsLoaded : false
};

export class TasksByEmployeeBase extends React.Component<ConnectedRouterProps & DProps & SProps, State> {
    state = initialState;

    columnFormat : ColumnType<TaskByEmployeeData> = [];

    private fetchShiftsMap = async () => {
        const shifts : Array<{
            id:string;
            name:string;
        }> = await fetchWithFeedback(ShiftsMapService(), {accessor: 'shifts'});
        this.setState({shifts});
        shifts.forEach(v => this.state.shiftsMap.set(v.id, v.name));
    }

    componentDidMount() {
        this.fetchShiftsMap().then(() => {
            this.columnFormat = this.getColumnFormat();
            this.setState({shiftsLoaded : true});
        });
    }

    getContentCell(props: any, rowInfo: any, value: string) {
        const tooltipTextPresenceManagement = 'GESTIÓN DE MARCAJES DE EMPLEADO';

        return props.presenceManagementActive === '1' && IsInRuleset(['PRESENCEMANAGEMENT_TOTAL', 'PRESENCEMANAGEMENT_VIEW']) ? (
            <Tooltip title={tooltipTextPresenceManagement} placement="top">
                <div
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        props.selectEmployeeTask(
                            rowInfo.original.employee_id,
                            rowInfo.original.employee_name,
                            rowInfo.original.employee_surname,
                            rowInfo.original.workShift,
                            rowInfo.original.center_id,
                        );
                        this.setState({
                            showPresenceManagement: true,
                        });
                    }}
                >
                    {value}
                </div>
            </Tooltip>
        ) : (
            <div>{value}</div>
        );
    }

    showErpEmployeeCode = this.props.employeeCodeModeParam === 'ERP_MODE' ? true : false;
    showInternalEmployeeCode = this.props.employeeCodeModeParam === 'INTERNAL_MODE' ? true : false;

    tooltipTextPresenceManagement = 'GESTIÓN DE MARCAJES DE EMPLEADO';

    private getColumnFormat = () : ColumnType<TaskByEmployeeData> => {
        return [
            {
                Header: 'Jornada',
                headerClassName: 'header_cell_string',
                accessor: 'workShift',
                dateFormat: true,
                daterange: true,
                width: 260,
                filterable: true,
                className: 'cell_string',
            },
            {
                Header: 'Turno',
                headerClassName: 'header_cell_string',
                Cell: item => <>{this.state.shiftsMap.get(item.original.area_shift)}</>,
                dropdownFilter: true,
                dropdownFilterData: this.state.shifts,
                id: 'area_shift',
                // accessor: 'area_shift',
                className: 'cell_string',
            },
            {
                Header: 'EMP.Nombre ',
                headerClassName: 'header_cell_string',
                accessor: 'employee_name',
                className: 'cell_string',
                filterable: true,
                Cell: rowInfo => this.getContentCell(this.props, rowInfo, rowInfo.original.employee_name),
            },
            {
                Header: 'EMP.Apellidos',
                headerClassName: 'header_cell_string',
                accessor: 'employee_surname',
                className: 'cell_string',
                filterable: true,
                Cell: rowInfo => this.getContentCell(this.props, rowInfo, rowInfo.original.employee_surname),
            },
            {
                Header: 'EMP.Código',
                headerClassName: 'header_cell_string',
                accessor: 'code',
                show: this.showInternalEmployeeCode,
                className: 'cell_string',
                filterable: true,
                sortable: false,
                Cell: rowInfo => this.getContentCell(this.props, rowInfo, rowInfo.original.code),
            },
            {
                Header: 'EMP.Código',
                headerClassName: 'header_cell_string',
                accessor: 'erp_employee_code',
                show: this.showErpEmployeeCode,
                className: 'cell_string',
                filterable: true,
                sortable: false,
                Cell: rowInfo => this.getContentCell(this.props, rowInfo, rowInfo.original.erp_employee_code),
            },
            {
                Header: 'Área',
                headerClassName: 'header_cell_string',
                accessor: 'area_name',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'Centro',
                headerClassName: 'header_cell_string',
                accessor: 'center_name',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'Tarea',
                headerClassName: 'header_cell_string',
                accessor: 'task_name',
                className: 'cell_string',
                filterable: true,
            },
            {
                Header: 'Tiempo total',
                headerClassName: 'header_cell_number',
                id: 'duration',
                Cell: props => <>{props.original.duration ? customFormatNumber(Number((props.original.duration / 60).toFixed(0))) : '0'} m</>,
                className: 'cell_number',
                sortable: true,
            },
            {
                Header: 'Coste',
                headerClassName: 'header_cell_number',
                id: 'cost',
                Cell: item => {
                    const {cost} = item.original;
                    const costConverted = cost ? (typeof cost === 'number' ? cost : +cost) : undefined;
                    return <>{costConverted ? `${customFormatNumber(Number(costConverted.toFixed(2)))} € ` : 'no hay datos'}</>;
                },
                className: 'cell_number',
            },
        ];
    }

    private hidePresenceManagementModal = () => {
        this.setState({
            showPresenceManagement: false,
        });
    };

    render() {
        return IsInRuleset(['REPORTS_TASKS_BY_EMPLOYEE_EXCEL', 'REPORTS_TASKS_BY_EMPLOYEE_VIEW']) ? (
            <>
                <PresenceManagementModal open={this.state.showPresenceManagement} hidePresenceManagementModal={this.hidePresenceManagementModal} />
                {this.state.shiftsLoaded && (<PaginatedTableComponent
                    defaultPageSize={20}
                    columnFormat={this.columnFormat}
                    service={ReportsServices.getTasksByEmployee}
                    showSizeOptions={true}
                    twoWeeksFilter={true}
                    loading={true}
                    excel={IsInRuleset('REPORTS_TASKS_BY_EMPLOYEE_EXCEL')}
                />)}
            </>
        ) : null;
    }
}

const selectEmployeeTask = (employeeId: string, employeeName: string, employeeSurname: string, workShift: string, centerId: string) =>
    EmployeeTaskSelectActions.select(employeeId, workShift, employeeName + ' ' + employeeSurname, centerId);

export const TasksByEmployee = connect<SProps, DProps, {}, AppStoreState>(
    state => ({
        presenceManagementActive: ParametersSelectors.getValuePresenceManagement(state),
        employeeCodeModeParam: ParametersSelectors.getValueOfParameter(state, ParameterCPN.EMPLOYEE_CODE_MODE),
    }),
    {
        push,
        selectEmployeeTask,
    },
)(TasksByEmployeeBase);
