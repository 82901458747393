import React from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {AppStoreState} from 'src/store/interface';
import {getAllUsersTasksTimedMap} from 'src/terminal/store/selectors';
import {loadAllTasksThunk} from 'src/terminal/store/actions';

interface TasksAllProps {
  classes: {
    root: string;
    table: string;
  };
  uTasksMap: any[];
}

interface DispatchTasksAllProps {
  fetchAllTasks: () => void;
}

class TasksAll extends React.Component<TasksAllProps & DispatchTasksAllProps, {}> {
  classes = this.props.classes;

  componentDidMount() {
    this.props.fetchAllTasks();
  }

  render() {
    return (
      <Paper className={this.classes.root}>
        <Table className={this.classes.table}>
          <TableHead>
            <TableRow>
              <TableCell numeric={true}>ID Emp.</TableCell>
              <TableCell>Nombre Empleado</TableCell>
              <TableCell>Tarea</TableCell>
              <TableCell>F/H Inicio</TableCell>
              <TableCell>F/H Final</TableCell>
              <TableCell>Área</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.uTasksMap.map((u: any) => {
              return u.computedTasks.map((ct: any) => {
                if (ct.state === 'current' || ct.state === 'finalized')
                  return (
                    <TableRow key={Math.random()}>
                      <TableCell numeric={true} component="th" scope="row">
                        {u.id}
                      </TableCell>
                      <TableCell>{`${u.name} ${u.surname}`}</TableCell>
                      <TableCell>{ct.taskName}</TableCell>
                      <TableCell>
                        {ct.startedTime && new Date(ct.startedTime).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {(ct.finishedTime &&
                          new Date(ct.finishedTime).toLocaleString()) ||
                          'actualmente activa'}
                      </TableCell>
                      <TableCell>Próximamente</TableCell>
                    </TableRow>
                  );
                return null;
              });
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  }
}

const TableStyled = withStyles({
  root: {
    width: '100%',
    // marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    backgroundColor: '#eaeaea',
    minWidth: 700,
  },
})(TasksAll);

const mapStateToProps = (state: AppStoreState) => ({
  uTasksMap: getAllUsersTasksTimedMap(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  fetchAllTasks: () => dispatch(loadAllTasksThunk()),
});

export const TasksAllConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TableStyled);
