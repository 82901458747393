import * as React from 'react';
import {IsInRuleset} from 'src/global/authorization/grantSet';
import {fetchWithFeedback} from 'src/utils/fetcherValidate';
import {AlertServices} from './store/alert.services';
import {ActivitiesExcesiveInactiveTaskType} from './store/types';
import {AlertExcessiveInactiveTaskModal} from './AlertExcessiveInactiveTaskModal';

export interface Props {
    laneId: string;
    employeeId: string;
}

type State = {
    openAlertExcessiveInactiveTask: boolean;
    excesiveTasks: ActivitiesExcesiveInactiveTaskType[] | undefined;
    showConfirmCheckAlert: boolean;
    idToCheck: string;
};

const initialState: Readonly<State> = {
    openAlertExcessiveInactiveTask: false,
    excesiveTasks: undefined,
    showConfirmCheckAlert: false,
    idToCheck: '',
};

export class AlertExcessiveInactiveTask extends React.Component<Props, State> {
    readonly state = initialState;

    async componentDidMount() {
        this.fetchAlertExcessiveInactiveTask();
        setInterval(() => {
            if (this.state.openAlertExcessiveInactiveTask === false) {
                this.fetchAlertExcessiveInactiveTask();
            }
        }, 120000);
    }

    private checkAlertTransitInactiveExcessive = (idToCheck: string) => {
        if (this.state.excesiveTasks && this.state.excesiveTasks.length) {
            fetchWithFeedback(AlertServices.checkedExcessiveInactiveTask(idToCheck, this.props.employeeId));

            const activitiesWithAlerts = this.state.excesiveTasks.filter(item => item.id !== idToCheck);

            this.setState({
                excesiveTasks: activitiesWithAlerts,
            });
        }
    };

    private fetchAlertExcessiveInactiveTask = async () => {
        const isAdmin = IsInRuleset(['ALERTS_PERSONAL_ADMIN', 'ADMIN']) ? 1 : 0;
        const isAllowed = IsInRuleset(['ALERTS_PERSONAL']) ? 1 : 0;

        if (this.props.laneId && (isAdmin || isAllowed)) {
            const alertExcessiveInactiveTask = await fetchWithFeedback(AlertServices.excessiveInactiveTask(this.props.laneId, isAdmin));

            if (alertExcessiveInactiveTask) {
                this.setState({
                    excesiveTasks: alertExcessiveInactiveTask.activitiesAlerts,
                });

                if (alertExcessiveInactiveTask.activitiesAlerts.length !== 0 && !this.state.openAlertExcessiveInactiveTask) this.toggleAlertExcessiveInactiveTask();
            }

        }
    };

    private toggleAlertExcessiveInactiveTask = () => {
        this.setState({
            openAlertExcessiveInactiveTask: !this.state.openAlertExcessiveInactiveTask,
        });
    };

    render() {
        return (
            <>
                <AlertExcessiveInactiveTaskModal
                    openAlertExcessiveInactiveTask={this.state.openAlertExcessiveInactiveTask}
                    excesiveTasks={this.state.excesiveTasks}
                    handleClose={() => this.setState({openAlertExcessiveInactiveTask: false})}
                    checkAlertTransitInactiveExcessive={this.checkAlertTransitInactiveExcessive}
                />
            </>
        );
    }
}
