import React, {ErrorInfo} from 'react';

// import {withRouter} from 'react-router-dom';
import {ENV} from 'src/global/api';
import {ErrorMessage} from 'src/utils/ErrorHandler/ErrorMessage';

// import {history} from 'src/store';
// import {notify} from 'src/utils/Notification';
import {ErrorExpansion} from './ErorrExpansion';

const initialState = {hasError: false, error: undefined, info: undefined};

type State = {
  hasError: boolean;
  error?: Error;
  info?: React.ErrorInfo;
};

export class ErrorHandler extends React.Component<{}, State> {
  readonly state: State = initialState;

  static contextTypes = {
    router: () => true, // replace with PropTypes.object if you use them
  };

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState(() => ({hasError: true, info, error}));
  }

  render() {
    const {info, error} = this.state;
    if (this.state.hasError && info && error) {
      if (ENV !== 'development') return <ErrorMessage error={error} info={info} />;
      return <ErrorExpansion error={error} info={info} />;
    }
    return this.props.children;
  }
}

// export const ErrorHandler = withRouter(ErrorHandlerBase);
