import styled from 'styled-components';

export const WrapMenuList = styled.div`
  width: 300px;
  paddig-left: 24px;
  a {
    text-decoration: inherit;
    color: inherit;
  }
  overflow-x: hidden;
`;

export const MenuBackground = styled.div`
  margin-left: 300px;
  width: calc(100% - 300px);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const SpanWrap = styled.div`
  color: #111111;
  font-size: 40px;
  margin: auto;
  /* height: 50px; */
  /*width: 800px;*/
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  flex-grow:1;
`;


