export enum LaneActionTypes {
  setEmployees = '[Lane] Set employees',
  setBatchData = '[Lane] Set batch data', // loads actual batch data
  clearBatchData = '[Lane] Clear batch data',
  setBatches = '[Lane] Set batches', // full batches array
  clearBatches = '[Lane] Clear batches',
  setPtables = '[Lane] Set ptables', // loads ptables from the backend
  updatePtable = '[Lane] Update ptable', // update single value in ptable array
  clearPtables = '[Lane] Clear ptables', // clears to Ptables array to default values
  assignPPtable = '[Late] Assign ptable',
  setProdParts = '[Lane] Set prod parts', // loads an array of Batch Parts existed in the system
  clearProdParts = '[Lane] Clear prod parts',
  updateProdPart = '[Lane] Update prod part',
  deletePPtable = '[Lane] Delete pp-table from prodpart',
  clearEmployees = '[Lane] Clear employees',
  dumpFetchSuccess = '[Lane] Current dump fetch success',
  dumpFetchFail = '[Lane]  Current dump fetch fail',
  dumpFetchWaiting = '[Lane]  Current dump fetch waiting',
}
