import invoke from 'lodash/invoke';
import React, {Component, FunctionComponent} from 'react';
import styled from 'styled-components';
import {ProdPartGeneric} from 'src/app/lanemanager/lane/store/types';
import {Card, CardContent, withStyles, Typography} from '@material-ui/core';

type Props = {prodparts: Array<ProdPartGeneric & {color: string}>};
type State = {slice: Array<ProdPartGeneric & {color: string}>};

const MAGIC_PRODPARTS_MAX_NUMBER = 16;

export class ProdPartsMesh extends Component<Props, State> {
  readonly state: Readonly<State> = {slice: []};
  tuple = [0, MAGIC_PRODPARTS_MAX_NUMBER];
  componentWillMount() {
    this.setState({slice: this.props.prodparts.slice(...this.tuple)});
  }
  componentWillReceiveProps(props: Props) {
    this.setState({slice: props.prodparts.slice(...this.tuple)});
  }

  timer = setInterval(() => {
    if (
      this.props.prodparts.length > MAGIC_PRODPARTS_MAX_NUMBER &&
      this.tuple[1] < this.props.prodparts.length - 1
    ) {
      this.tuple = this.tuple.map(it => it + 1);
    } else if (this.tuple[1] === this.props.prodparts.length - 1) {
      this.tuple[0] = 0;
      this.tuple[1] = MAGIC_PRODPARTS_MAX_NUMBER;
    }
    this.setState({slice: this.props.prodparts.slice(...this.tuple)});
  }, 5000);

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <Div>
        {invoke(
          this.state.slice,
          'map',
          (
            pp: ProdPartGeneric & {color: string; darkLight: 'light' | 'dark'},
            index: number,
          ) => {
            // console.log(pp);
            return (
              <ProdPartCard key={'pp' + index}>
                <Typography
                  variant="h4"
                  style={{
                    textTransform: 'uppercase',
                    backgroundColor: pp.color,
                    color: pp.darkLight === 'dark' ? 'whitesmoke' : 'black',
                    borderRadius: 4,
                    textAlign: 'center',
                    marginBottom: 10,
                  }}>
                  {pp.customer.name}
                </Typography>
                <Typography variant="h5" style={{marginBottom: 10}}>
                  {pp.merchandise.name}
                </Typography>
                <Typography variant="h4" style={{marginBottom: 10}}>
                  {pp.pallets_count}/{pp.pallets_total}
                </Typography>
                <Typography variant="h5">{pp.packaging}</Typography>
              </ProdPartCard>
            );
          },
        )}
      </Div>
    );
  }
}

const cardStyle = {
  root: {
    height: '100%',
    backgroundColor: '#e4ede1',
  },
};
const StyledCard = withStyles(cardStyle)(Card);

const ProdPartCard: FunctionComponent = ({children}) => {
  return (
    <div style={{padding: 3}}>
      <StyledCard>
        <CardContent>{children}</CardContent>
      </StyledCard>
    </div>
  );
};

const Div = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  height: 94%;
`;
