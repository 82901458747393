import {guardedFetcher} from 'src/utils/guardedFetcher';
import {TransferBoxesType} from "./liftPalletsControl.types";

const prefix = 'production/control';

export const LiftPalletsControlService = {

    transferBoxes: (transfer : TransferBoxesType) => {
        return guardedFetcher.post<any>(
        `${prefix}/pallets/transfer-boxes`, transfer);
    }
};
