import * as React from 'react';
import {WrapFullContainer, OCard} from 'src/shared/style';
import {
    HourTotalType,
    EmployeesTotalTasksType,
    //PalletTotalProductType,
    //PalletHourTotalType,
    //PalletHourTotalProductType,
    //ProductPalletInfoType,
} from 'src/app/information/kpis/store/types/chart-types';
import {KpiChartsServices} from 'src/app/information/kpis/store/services';
import {HourTotalDowntimeChart} from 'src/app/information/kpis/charts/DowntimeCalculation/HourTotalDowntimeChart';
//import {PalletTotalProductChart} from 'src/app/information/kpis/charts/ULCalculation/PalletTotalProductChart';
//import {PalletHourTotalProductChart} from 'src/app/information/kpis/charts/ULCalculation/PalletHourTotalProductChart';
//import {PalletHourTotalChart} from 'src/app/information/kpis/charts/ULCalculation/PalletHourTotalChart';

export interface ConfectionCalculationProps {
    areaId: string;
    centerId: string;
}
type State = {
    areaId: string | undefined;
    centerId: string | undefined;
    date: Date | Date[] | undefined;
    hourTotal: HourTotalType | undefined;
    employeesTotalTasks: EmployeesTotalTasksType | undefined;
    loadingHourTotalData: boolean;
};

const initialState = {
    areaId: undefined,
    centerId: undefined,
    date: undefined,
    hourTotal: {
        hourProduccion: '',
        hourByTasksValues: [],
        hourByTasksLabels: [],
    },
    employeesTotalTasks: {
        numEmployees: '',
        employeesByTasksValues: [],
        employeesByTasksLabels: [],
    },
    loadingHourTotalData: false,
};

type Props = {
    areaId: string;
    centerId: string;
    startDate: string;
    endDate: string;
};

export class DowntimeCalculation extends React.Component<Props, State> {
    state = initialState;

    componentDidMount() {
        this.showData(this.props);
    }

    componentWillReceiveProps(props: Props) {
        this.showData(props);
    }

    showData(props: Props) {
        const {centerId, areaId, startDate, endDate} = props;

        this.setState({loadingHourTotalData: true});

        KpiChartsServices.hourTotalDowntime(centerId, areaId, startDate, endDate).then((res: any) => {
            const tasksLabel = [];
            const tasksValue = [];

            if (res) {
                for (const index in res.data.hourByTasks) {
                    if (index) {
                        tasksLabel.push(index);
                        tasksValue.push(res.data.hourByTasks[index]);
                    }
                }
            }

            const data = {
                hourProduccion: res.data.hourProduccion,
                hourByTasksValues: tasksValue,
                hourByTasksLabels: tasksLabel,
            };

            this.setState({hourTotal: data});
            this.setState({loadingHourTotalData: false});
        });
    }

    public render() {
        return (
            <>
                <h1 className="text-center">Cálculo de paradas</h1>
                <WrapFullContainer>
                    <OCard title={'Horas totales de paradas'} heightTitle={'30'} width={'100%'} height={'600px'} contentHeight={'500px'} contentPadding={'5px'} margin={'20px'}>
                        <HourTotalDowntimeChart chartData={this.state.hourTotal} isLoading={this.state.loadingHourTotalData} />
                    </OCard>
                </WrapFullContainer>
            </>
        );
    }
}
