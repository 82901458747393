import {notify} from 'src/utils/Notification';

export const notifyValidator = <T extends {[k: string]: any}>(ctx: T) => {
  if (!ctx || !ctx.state) return undefined;
  const tempSetState = ctx.setState.bind(ctx);
  const tempState = ctx.state;

  return (errors: LaravelExplicitError[]) => {
    const messages: string[] = [];
    const extract = errors.length >= 1 ? errors[0] : undefined;
    if (extract) {
      for (const [key, value] of Object.entries(extract.errors)) {
        const findKeyInState = Object.keys(tempState).find(k => k === key);
        if (findKeyInState && typeof tempState[`${key}Valid`] === 'boolean') {
          tempState[`${key}Valid`] = false;
          messages.push(value[0] || 'Error en los datos');
        }
      }
    }
    let humanRead = '';
    for (const msg of messages) {
      humanRead += msg + ' \n';
    }
    try {
      tempSetState(tempState);
      notify({status: 'error', message: humanRead});
    } catch (error) {
      // console.log('GOT ERROR IN NOTIFY VALIDATOR', error);
    }
  };
};
