import localForage from 'localforage';

export const persistentInstance = localForage.createInstance({
  name: 'persistent-store',
  version: 1.0,
  storeName: 'persistent-store',
});

export const localStore = localForage.createInstance({
  name: 'local-store',
  version: 1.0,
  storeName: 'local-store',
});
