import {AreasControlResponse, AreasControlUnit, AreasControlUnitForSend} from 'src/app/administration/areaControl/store/types';
import {guardedFetcher} from 'src/utils/guardedFetcher';

const prefix = 'crud/areas';

export const AreasControlServices = {
    get: () => guardedFetcher.get<AreasControlResponse>(prefix),
    getAllAreasFromCenter: (centerId: string) => guardedFetcher.get<AreasControlResponse>(`${prefix}/center/${centerId}`),
    create: ({
        dump_box,
        center_id,
        areaset_id,
        dump_barcode,
        dump_modify_barcode,
        lane_dump_delay,
        manufacturing,
        max_employees,
        max_capacity,
        name,
        auto_palletise,
    }: Omit<AreasControlUnitForSend, 'id' | 'center_name' | 'areaset_name'>) =>
        guardedFetcher.post<AreasControlUnitForSend>(prefix, {
            dump_box,
            center_id: center_id || null,
            areaset_id: areaset_id || null,
            dump_barcode: dump_barcode || null,
            dump_modify_barcode: dump_modify_barcode || null,
            lane_dump_delay: lane_dump_delay || null,
            manufacturing,
            max_capacity,
            max_employees: typeof max_employees !== undefined && Number.isInteger(max_employees!) ? max_employees : null,
            name: name || null,
            auto_palletise,
        }),
    update: ({
        dump_box,
        center_id,
        center_name,
        areaset_id,
        areaset_name,
        dump_barcode,
        dump_modify_barcode,
        lane_dump_delay,
        id,
        max_capacity,
        manufacturing,
        max_employees,
        name,
        auto_palletise,
    }: Partial<AreasControlUnit>) => {
        return guardedFetcher.patch<AreasControlUnitForSend>(`${prefix}/${id}`, {
            dump_box,
            center_id: center_id || null,
            center_name: center_name || null,
            areaset_id: areaset_id || null,
            areaset_name: areaset_name || null,
            dump_barcode: dump_barcode || null,
            dump_modify_barcode: dump_modify_barcode || null,
            lane_dump_delay: lane_dump_delay || null,
            max_capacity,
            manufacturing,
            max_employees: typeof max_employees !== undefined && Number.isInteger(max_employees!) ? max_employees : null,
            name: name || null,
            auto_palletise,
        });
    },
    delete: (areaId: string) => guardedFetcher.delete(`${prefix}/${areaId}`),

    // // ! selector part
    // getAllTasgkroupsFromArea: (areaId: string) =>
    //   guardedFetcher.get<TCTaskgroupResponse>(`${taskgrPrefix}/all/area/${areaId}`),

    // ! togglers
    toggleAreaToTaskgroup: (areaId: string, taskgroupId: string) =>
        guardedFetcher.post(`${prefix}/toggle-relation/taskgroup`, {
            areaId,
            taskgroupId,
        }),
};
